<template>
  <div
    id="outer"
    class="reveal vc-default-show"
    v-chakra
    d="flex"
    justify-content="center"
    align-items="center"
    :style="{
      fontFamily: selectedFont
        ? `'${selectedFont.family}', ${selectedFont.category}`
        : '',
    }"
  >
    <div
      id="wrap"
      class="slides"
      v-chakra
      :class="isFullScreen ? 'scaled-view' : ''"
    >
      <section enterPrev="slideInLeft" leaveNext="slideOutLeft">
        <ElevatorPitch :isEdit="isEdit && !isFullScreen" />
      </section>
      <!-- <slide
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <ExecutiveSummary />
      </slide>-->
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <Problem :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <div style="position: relative; height: 100%">
          <Solution :isEdit="isEdit && !isFullScreen" />
        </div>
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <HowItWorks :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <ValueProposition :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <TargetCustomers :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <BusinessModel :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <MarketSize :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <Competition :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <PointOfDifferentiation :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <MarketStrategy :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <MarketGoals :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <MarketGoals :type="'traction'" :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <Team :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <Team :type="'advisory'" :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <Capital :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <Financials :isEdit="isEdit && !isFullScreen" />
      </section>
      <section
        enterNext="slideInRight"
        enterPrev="slideInLeft"
        leavePrev="slideOutRight"
        leaveNext="slideOutLeft"
      >
        <Goals :isEdit="isEdit && !isFullScreen" />
      </section>
    </div>
  </div>
</template>

<script>
import ElevatorPitch from './slides/ElevatorPitch.vue';

// import { Slideshow } from "eagle.js";
// import ExecutiveSummary from "./slides/ExecutiveSummary.vue";
import Problem from './slides/Problem.vue';
import Solution from './slides/Solution.vue';
import ValueProposition from './slides/ValueProposition.vue';
import TargetCustomers from './slides/TargetCustomers.vue';
import BusinessModel from './slides/BusinessModel.vue';
import MarketSize from './slides/MarketSize.vue';
import Competition from './slides/Competition.vue';
import HowItWorks from './slides/HowItWorks.vue';
import PointOfDifferentiation from './slides/PointOfDifferentiation.vue';
import MarketStrategy from './slides/MarketStrategy.vue';
import MarketGoals from './slides/MarketGoals.vue';
import Team from './slides/Team.vue';
import Capital from './slides/Capital.vue';
import Financials from './slides/Financials.vue';
import Goals from './slides/Goals.vue';

import { mapState } from 'vuex';

// import slideshowFix from "@/mixins/slideshowFix.js";
import Reveal from 'reveal.js';
// import Markdown from 'reveal.js/plugin/markdown/markdown.js';
// import Highlight from "reveal.js/plugin/highlight/highlight.js";
import 'reveal.js/dist/reveal.css';
// import 'reveal.js/dist/theme/black.css';
// import "reveal.js/plugin/highlight/monokai.css";

export default {
  // mixins: [Slideshow, slideshowFix],
  name: 'VCDefault1Template',
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ElevatorPitch,
    // ExecutiveSummary,
    Problem,
    Solution,
    HowItWorks,
    ValueProposition,
    TargetCustomers,
    BusinessModel,
    MarketSize,
    Competition,
    PointOfDifferentiation,
    MarketStrategy,
    MarketGoals,
    Team,
    Capital,
    Financials,
    Goals,
  },
  data() {
    return {
      isFullScreen: false,
      slideScale: 1,
      deck: null,
    };
  },
  computed: {
    ...mapState({
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedFont() {
      return this.presentation.data.font;
    },
  },
  methods: {
    setSlide(index) {
      this.deck.slide(index);
    },
    previousSlide() {
      this.deck.prev();
    },
    nextSlide() {
      this.deck.next();
    },
    resize() {
      let outer = document.getElementById('outer'),
        wrapper = document.getElementById('wrap'),
        maxWidth = outer.clientWidth,
        maxHeight = outer.clientHeight;

      let scale,
        width = window.innerWidth,
        height = window.innerHeight,
        isMax = width >= maxWidth && height >= maxHeight;

      scale = Math.min(width / maxWidth, height / maxHeight);
      outer.style.transform = isMax ? '' : 'scale(' + scale + ')';
      wrapper.style.width = isMax ? '' : maxWidth * scale;
      wrapper.style.height = isMax ? '' : maxHeight * scale;
      this.slideScale = scale;
    },
  },
  mounted() {
    this.deck = new Reveal({
      width: 1550,
      height: 750,

      // Factor of the display size that should remain empty around
      // the content
      margin: 0.04,
      // Bounds for smallest/largest possible scale to apply to content
      minScale: 0.2,
      maxScale: 2.0,
      center: true,
      embedded: false,
      // plugins: [Highlight],
      controls: false,
    });
    this.deck.initialize();

    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        this.isFullScreen = true;
        // this.deck.configure({ width: "100%", height: "100%" });
      } else {
        this.isFullScreen = false;
        // this.deck.configure({ width: "100%", height: "100%" });
      }
    });

    this.deck.on('slidechanged', (event) => {
      this.$emit('slideChanged', event.indexh);
      // event.previousSlide, event.currentSlide, event.indexh, event.indexv
    });

    // this.resize();
  },
};
</script>

<style lang="scss" scoped>
// .scaled-view {
//   transform: scale(1.4);
//   transform-origin: 50% 50% 0px;
// }

.vc-default-show {
  position: relative;
  height: 100%;
  width: 100%;
  font-size: inherit !important;
  * {
    font-family: inherit;
    &.control-element {
      font-family: 'Montserrat', sans-serif;
    }
  }
}

section {
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
}

.reveal .slides > section.present,
.reveal .slides > section > section.present {
  min-height: 100% !important;
  min-width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  position: absolute !important;
  top: 0 !important;
}

section > h1 {
  position: absolute !important;
  top: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0 !important;
  right: 0 !important;
}

.print-pdf .reveal .slides > section.present,
.print-pdf .reveal .slides > section > section.present {
  min-height: 770px !important;
  position: relative !important;
}
</style>

<style lang="scss">
.item-selector {
  position: absolute !important;
}
</style>
