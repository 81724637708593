<template>
  <c-flex
    my="1"
    w="100%"
    maxHeight="100%"
    align="center"
    direction="column"
    justify="center"
  >
    <c-flex
      align="center"
      direction="column"
      h="calc(100% - 10px)"
      overflowY="scroll"
    >
      <template v-for="revenueType in revenueTypes">
        <c-flex
          px="2"
          py="3"
          minHeight="30px"
          minWidth="95px"
          fontWeight="600"
          fontSize="xs"
          borderRadius="4px"
          borderWidth="1px"
          mb="3"
          display="flex"
          align="center"
          justify="center"
          backgroundColor="#fff"
          :key="revenueType.value"
          v-if="isSelectedType(revenueType.value)"
        >
          {{ revenueType.label }}
        </c-flex>
      </template>
    </c-flex>
    <c-popover placement="bottom" v-if="!disabled" v-slot="{ onClose }">
      <c-popover-trigger>
        <c-pseudo-box
          as="a"
          display="flex"
          mt="2"
          alignItems="center"
          :_hover="{ textDecoration: 'none', cursor: 'pointer' }"
        >
          <svg
            v-chakra="{
              width: '18px',
              height: '18px',
              fill: '#ef5323',
              mr: '1',
            }"
          >
            <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
          </svg>
          <c-text color="#ef5323" fontWeight="600" fontSize="sm">
            Add Revenue
          </c-text>
        </c-pseudo-box>
      </c-popover-trigger>
      <c-popover-content w="max-content" zIndex="modal">
        <c-popover-body p="0">
          <c-list>
            <c-list-item
              v-for="revenueType in revenueTypes"
              :key="revenueType.value"
              fontSize="sm"
            >
              <c-box
                display="flex"
                px="3"
                py="2"
                cursor="pointer"
                justifyContent="space-between"
                @click="onRevenueSelect(revenueType, onClose)"
              >
                <c-text mr="2">{{ revenueType.label }}</c-text>
                <c-icon
                  v-if="isSelectedType(revenueType.value)"
                  name="check"
                  color="vue.400"
                ></c-icon>
              </c-box>
            </c-list-item>
          </c-list>
        </c-popover-body>
      </c-popover-content>
    </c-popover>
  </c-flex>
</template>

<script>
import { revenueTypes } from '@/helpers/data';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedRevenueTypes: [],
      revenueTypes,
    };
  },
  created() {
    if (this.value) {
      this.setSelectedRevenueType();
    }
  },
  methods: {
    onRevenueSelect(revenueType, closePopover) {
      if (this.isSelectedType(revenueType.value)) {
        const index = this.selectedRevenueTypes.indexOf(revenueType.value);
        this.selectedRevenueTypes.splice(index, 1);
      } else {
        this.selectedRevenueTypes.push(revenueType.value);
      }
      closePopover();
      this.$emit('input', [...this.selectedRevenueTypes]);
    },
    isSelectedType(value) {
      return this.selectedRevenueTypes.includes(value);
    },
    setSelectedRevenueType() {
      this.selectedRevenueTypes = this.value;
    },
  },
};
</script>

<style></style>
