<template>
  <c-flex w="100%" h="100%" bg="#fff">
    <c-flex direction="column" w="45%" h="100%">
      <c-flex ml="10" mt="16" direction="column" align="start">
        <c-text color="#000" fontWeight="700" fontSize="4xl" zIndex="2"
          >Goals</c-text
        >
        <c-box
          mt="5"
          h=".5em"
          w="8em"
          :bg="`${presentation.data.color}80`"
          zIndex="2"
        ></c-box>
      </c-flex>

      <c-box w="90%" ml="20px" mt="auto" mb="70px" textAlign="left">
        <c-text fontSize="20px" fontWeight="600"
          >Sustainable Development goals</c-text
        >
        <c-box display="flex">
          <c-box display="flex" class="sdg-fields__goals__list">
            <div
              class="sdg-fields__goals__list-item"
              v-for="(selectedGoal, index) in selectedItems"
              :key="index"
            >
              <img :src="selectedGoal.sustainable_development_goal.image" />
            </div>
          </c-box>
        </c-box>
      </c-box>
    </c-flex>
    <c-box w="55%" h="100%">
      <c-link
        textDecoration="underline"
        position="absolute"
        right="5"
        top="5"
        class="item-selector"
        fontSize="xl"
        v-if="isEdit"
        @click="isDrawerOpen = true"
        >Select goals to display</c-link
      >
      <c-flex
        h="50%"
        w="100%"
        :bgImage="`linear-gradient(
        to bottom ,
        ${presentation.data.color}80,
        #fff
      )`"
      >
        <c-box w="50%" py="30px" px="30px" textAlign="left">
          <img
            mt="20px"
            v-chakra
            h="40px"
            w="40px"
            :src="require('@/assets/icons/puzzle-piece.svg')"
          />

          <c-text
            mt="20px"
            fontSize="20px"
            fontWeight="600"
            :color="presentation.data.color"
            >Belief</c-text
          >
          <EditableText
            :disabled="!isEdit"
            v-model="goalsData.belief"
            @blur="onGoalsInputBlur($event, 'belief')"
          />
        </c-box>
        <c-box w="50%" py="30px" px="30px" textAlign="left">
          <!-- <svg>
            <use
              href="@/assets/icons/telescope.svg"
              style="--color_fill: #000; color: #000"
            ></use>
          </svg> -->
          <img
            mt="20px"
            :color="presentation.data.color"
            v-chakra
            h="40px"
            w="40px"
            :src="require('@/assets/icons/telescope.svg')"
          />

          <c-text
            mt="20px"
            fontSize="20px"
            fontWeight="600"
            :color="presentation.data.color"
            >Vision</c-text
          >
          <EditableText
            :disabled="!isEdit"
            v-model="goalsData.vision"
            @blur="onGoalsInputBlur($event, 'vision')"
          />
        </c-box>
      </c-flex>

      <c-box
        w="100%"
        h="50%"
        :backgroundImage="`url(https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/goals_default_1.png)`"
        backgroundSize="cover"
      ></c-box>
    </c-box>
    <TextListDrawer
      :title="`Select visible goals`"
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :items="sdgGoals"
      :updateFn="updateGoalsData"
      :isUpdatingData="isUpdatingData"
    >
      <template #list-item="{ item }">
        <c-text as="span">{{ item.sustainable_development_goal.name }}</c-text>
      </template>
    </TextListDrawer>
    <c-box position="absolute" bottom="5" left="5">
      <WaterMark :direction="'row'" />
    </c-box>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex';
import WaterMark from '@/components/WaterMark.vue';
import goalsMixin from '@/mixins/data/goals.js';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';
import TextListDrawer from '@/components/DataComponents/TextListDrawer.vue';

export default {
  components: { WaterMark, EditableText, TextListDrawer },
  mixins: [goalsMixin],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      activeCompany: (state) => state.company.activeCompany,
      presentation: (state) => state.presentation.activePresentation,
    }),
  },
};
</script>

<style lang="scss" scoped>
.sdg-fields {
  &__goals {
    margin-top: 20px;
    &__list {
      &-item {
        width: 100px;
        margin-top: 20px;
        margin-right: 20px;
        height: 100px;
        border-radius: 11px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
