import { render, staticRenderFns } from "./Competition.vue?vue&type=template&id=1c94b9de&scoped=true"
import script from "./Competition.vue?vue&type=script&lang=js"
export * from "./Competition.vue?vue&type=script&lang=js"
import style0 from "./Competition.vue?vue&type=style&index=0&id=1c94b9de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c94b9de",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CStack: require('@chakra-ui/vue').CStack})
