<template>
  <c-flex
    w="100%"
    h="100%"
    :bg="presentation.data.elevator.backgroundColor"
    p="relative"
  >
    <c-flex direction="column" w="40%" h="100%">
      <c-flex direction="column" h="100%" w="100%" align="start">
        <c-box ml="20">
          <c-text
            mt="12"
            color="#000"
            fontWeight="700"
            fontSize="4xl"
            textAlign="left"
            zIndex="2"
            >Elevator Pitch</c-text
          >
          <c-box
            mt="5"
            h=".5em"
            w="10em"
            :bg="`${presentation.data.color}80`"
            zIndex="2"
          ></c-box>
        </c-box>
      </c-flex>
      <div
        class="draggable-image"
        @mousedown="startImageDrag"
        @mousemove="handleImageDrag"
        @mouseup="stopDrag"
        @mouseleave="stopDrag"
        :style="{
          backgroundImage: 'url(' + imageUrl + ')',
          backgroundSize: 'cover',
          width: '30em',
          height: '20em',
          left: '0',
          top: imagePosition.top + 'px',
          left: imagePosition.left + 'px',
        }"
      ></div>
    </c-flex>
    <c-flex
      :backgroundImage="`linear-gradient(
        to bottom ,
        ${presentation.data.color}80,
        #fff
      )`"
      backgroundSize="cover"
      w="15%"
      ml="auto"
      h="50%"
      justify="center"
    ></c-flex>
    <c-flex
      direction="column"
      flex="1"
      p="10"
      h="100%"
      position="relative"
      justify="center"
      align="center"
    >
      <div
        class="draggable-div"
        :style="{
          top: position.top + 'px',
          left: position.left + 'px',
          fontSize: presentation.data.elevator.fontSize + 'px',
        }"
        @mousedown="startDrag"
        @mousemove="handleDrag"
        @mouseup="stopDrag"
        @mouseleave="stopDrag"
      >
        <EditableText
          :showOptions="false"
          :noHeight="true"
          :disabled="!isEdit"
          :value="elevatorPitch"
          @updateDescription="onElevatorPitchInputBlur"
        />
      </div>

      <c-box ml="auto" mt="auto">
        <WaterMark :direction="'row'" />
      </c-box>
    </c-flex>
  </c-flex>
</template>

<script>
import { mapMutations } from 'vuex';
import { EventBus } from '@/eventBus.js';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';
import WaterMark from '@/components/WaterMark.vue';
import { updatePresentation } from '@/services/presentation';

import elevatorPitch from '@/mixins/data/elevatorPitch.js';

export default {
  mixins: [elevatorPitch],
  props: {
    isEdit: {
      type: Boolean,
    },
  },
  components: { WaterMark, EditableText },
  data() {
    return {
      imageUrl:
        'https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/prob_sol_default_1.png',
      isDragging: false,
      startPosition: { x: 0, y: 0 },
      position: { top: 300, left: 0 },
      startImagePosition: { x: 0, y: 0 },
      imagePosition: { top: 300, left: 0 },
    };
  },
  mounted() {
    EventBus.$on('elevator', async (configForm) => {
      this.updatePresentation({
        data: {
          ...this.presentation.data,
          elevator: configForm,
        },
      });

      try {
        const response = await updatePresentation({
          id: this.presentation.id,
          append: { data: { elevator: configForm } },
        });
        const pre = response.data.update_presentation_by_pk;
        this.updatePresentation({
          data: { ...this.presentation.data, elevator: pre.data.elevator },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, elevator: configForm },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
    startDrag(event) {
      this.isDragging = true;
      this.startPosition.x = event.clientX;
      this.startPosition.y = event.clientY;
    },
    handleDrag(event) {
      if (this.isDragging) {
        const offsetX = event.clientX - this.startPosition.x;
        const offsetY = event.clientY - this.startPosition.y;
        this.position.left += offsetX;
        this.position.top += offsetY;
        this.startPosition.x = event.clientX;
        this.startPosition.y = event.clientY;
      }
    },
    startImageDrag(event) {
      this.isDragging = true;
      this.startImagePosition.x = event.clientX;
      this.startImagePosition.y = event.clientY;
    },
    handleImageDrag(event) {
      if (this.isDragging) {
        const offsetX = event.clientX - this.startImagePosition.x;
        const offsetY = event.clientY - this.startImagePosition.y;
        this.imagePosition.left += offsetX;
        this.imagePosition.top += offsetY;
        this.startImagePosition.x = event.clientX;
        this.startImagePosition.y = event.clientY;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
  },
};
</script>

<style>
.draggable-image {
  position: absolute;
  cursor: move;
}
.draggable-div {
  position: absolute;
  cursor: move;
}
</style>
