<template>
  <div class="options__wrapper">
    <div class="options__placeholder" v-if="hasPlaceholder">
      {{ field.placeholder }}
    </div>
    <div class="options">
      <template v-for="option in options">
        <label
          class="options__option"
          :for="option[valueKey] || option"
          :key="option[valueKey] || option"
          :disabled="disabled"
        >
          <c-visually-hidden
            :name="field.label"
            :id="option[valueKey] || option"
            as="input"
            type="radio"
            :value="option[valueKey] || option"
            :checked="
              option[valueKey] === selectedOption || option === selectedOption
            "
            @change="onChange"
            :disabled="disabled"
          />
          <c-control-box
            border-width="1px"
            px="10px"
            w="max-content"
            minWidth="50px"
            h="35px"
            rounded="md"
            type="radio"
            borderColor="#37d5aa"
            fontWeight="500"
            :disabled="disabled"
            :_checked="{
              backgroundColor: '#37d5aa',
              color: 'white',
            }"
          >
            {{ option[label] || option }}
          </c-control-box>
        </label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    valueKey: {
      type: String,
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hasPlaceholder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedOption: null,
      options: [],
    };
  },
  created() {
    if (this.value) {
      this.selectedOption = this.value;
    }
    if (typeof this.field.options === 'function') {
      this.options = this.field.options();
    } else {
      this.options = this.field.options;
    }
  },
  watch: {
    value(val) {
      this.selectedOption = val;
    },
    selectedOption(val) {
      if (val) {
        this.$emit('input', val);
      } else {
        this.$emit('input', null);
      }
    },
  },
  methods: {
    onChange(event) {
      this.selectedOption = event.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  @apply flex w-full flex-wrap;
  &__placeholder {
    @apply text-sm mb-4;
  }
  &__option {
    @apply mr-5 mt-3 cursor-pointer text-sm relative;
    &[disabled] {
      @apply cursor-not-allowed;
    }
  }
  @screen lg {
    max-width: 60%;
  }
  @screen xl {
    max-width: 85%;
  }
}
</style>
