<template>
  <div class="section-header">
    <div class="section-header__details">
      <c-heading as="h1">
        {{ criteria.title }}
        <small v-if="showShare && !isShareView">
          <c-link @click="getShareLink">
            Share
            <svg>
              <use href="@/assets/icons/share-box-fill.svg#share"></use>
            </svg>
          </c-link>
        </small>
      </c-heading>
      <c-text fontWeight="300" color="#495057" v-if="criteria.description">
        {{ criteria.description }}
      </c-text>
    </div>
    <div class="section-header__progress" v-if="!isShareView && completion">
      <ProgressBar
        :value="completion.totalPercentage"
        height="25px"
        v-if="typeof completion.totalPercentage === 'number'"
      />
      <div class="section-header__progress__tasks">
        <c-heading
          color="#495057"
          as="h4"
          fontWeight="500"
          fontSize="sm"
          v-if="incompleteTasks.length"
        >
          Requires completion:
        </c-heading>
        <c-list display="flex" flexWrap="wrap">
          <template v-for="(task, index) in incompleteTasks">
            <c-list-item
              maxWidth="65%"
              mr="30px"
              mt="10px"
              :key="task.key"
              v-if="index <= 2 || showAllTasks"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="50" />
              </svg>
              {{ task.title }}
            </c-list-item>
          </template>
        </c-list>
        <c-link
          @click="showAllTasks = !showAllTasks"
          class="section-header__progress__tasks__toggle"
          v-if="incompleteTasks.length > 3"
        >
          <span v-if="!showAllTasks">
            Show {{ incompleteTasks.length - 3 }}
            more
          </span>
          <span v-else> Hide </span>
        </c-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProgressBar from '@/views/App/components/ProgressBar.vue';
import sjcl from 'sjcl';

export default {
  props: {
    criteria: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    completion: {
      type: Object,
      default: () => null,
    },
    showShare: {
      tyoe: Boolean,
      default: true,
    },
  },
  components: {
    ProgressBar,
  },
  data() {
    return {
      showAllTasks: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      isShareView: (state) => state.isShareView,
    }),
    incompleteTasks() {
      return this.criteria.tasks.filter(
        (task) => this.completion[task.value] !== 'DONE'
      );
    },
  },
  methods: {
    async getShareLink() {
      const numberString = this.activeCompany.id.toString();
      const encryptedId = sjcl.encrypt('E#2rjK7@Z9qy$L!', numberString);

      // Base64 encode the encrypted data
      const encodedId = btoa(encryptedId);

      const routeArray = this.$route.fullPath.split('/');

      const page = routeArray[routeArray.length - 1].split('-').join('-');
      const link = `${location.origin}/app/share?company_id=${encodedId}&page=${page}`;
      await navigator.clipboard.writeText(link);
      this.$toast({
        title: 'Link copied to clipboard',
        description: ``,
        status: 'success',
        position: 'top',
        duration: 3000,
      });
    },
  },
};
</script>

<style lang="scss">
.section-header {
  @apply flex my-8 flex-wrap px-6;
  &__details {
    @apply mb-8 w-full;
    h1 {
      @apply flex items-baseline text-4xl mb-4;
      small {
        @apply flex items-center ml-4;
        color: $color-orange;
        font-size: small;
        a {
          @apply flex items-center;
        }
        svg {
          width: 1em;
          height: 1em;
          fill: $color-orange;
        }
      }
    }
  }
  &__progress {
    @apply flex-1;
    &__tasks {
      @apply my-5 flex flex-col;
      li {
        @apply flex items-center font-semibold text-sm;
        svg {
          @apply mr-1;
          width: 6px;
          height: 6px;
          fill: $color-green;
        }
      }
      &__toggle {
        @apply text-sm ml-auto font-semibold;
        color: $color-orange;
      }
    }
  }
  @screen sm {
    @apply px-6;
  }
  @screen md {
    &__details {
      @apply mr-10;
      width: 70%;
    }
  }
  @screen lg {
    @apply px-0;
    &__details {
    }
  }
}
</style>
