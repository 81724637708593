<template>
  <div class="member">
    <div class="member__header">
      <div class="member__details">
        <c-popover v-if="!disabled" placement="bottom" v-slot="{ onClose }">
          <c-popover-trigger
            position="absolute"
            right="15px"
            top="15px"
            cursor="pointer"
          >
            <c-box>
              <svg
                v-chakra="{
                  width: '10px',
                  height: '16px',
                  fill: '#ef5323',
                }"
              >
                <use href="@/assets/icons/vertical-dots.svg#dots" />
              </svg>
            </c-box>
          </c-popover-trigger>
          <c-popover-content maxWidth="150px" zIndex="modal">
            <c-popover-body p="0">
              <c-list fontSize="sm">
                <c-list-item v-if="isCompanyOwner || isCompanyAdmin">
                  <c-box
                    cursor="pointer"
                    w="100%"
                    px="2"
                    py="2"
                    @click="deleteMember(member, onClose)"
                    >Delete</c-box
                  >
                </c-list-item>
              </c-list>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
        <c-avatar
          class="member__avatar"
          size="lg"
          :src="member.user.profilePhoto"
          :name="
            member.user.firstname
              ? member.user.firstname + ' ' + member.user.lastname
              : member.user.email
          "
          mr="2"
        />
        <c-box>
          <h5 v-if="member.user.firstname" class="member__name">
            {{ member.user.firstname }} {{ member.user.lastname }}
          </h5>
          <h5 v-else class="member__name">
            {{ member.user.email }}
          </h5>
          <p class="member__position">
            <span>{{ member.position }} </span>
          </p>
        </c-box>
      </div>
      <!-- <c-link fontWeight="500" fontSize="sm" color="blue.300">LinkedIn</c-link> -->
    </div>
    <div
      class="member__content"
      v-if="!disabled"
      @drop="onSkillDrop"
      @dragover.prevent
      @dragenter.prevent
    >
      <div class="member__skills">
        <div
          class="member__skill"
          v-for="(skill, index) in skills"
          :key="skill.id"
        >
          <span class="member__skill__text">
            {{ skill.company_skill_value.skillBySkill.name }}
          </span>
          <span
            class="member__skill__remove"
            @click="removeSkill(index, skill)"
          >
            <svg>
              <use href="@/assets/icons/icon-close.svg#close"></use>
            </svg>
          </span>
        </div>
      </div>
      <div
        class="member__skills__drop"
        :class="{ 'member__skills__drop--dropped': skills.length }"
      >
        <span>Drag and drop skill</span>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      skills: [],
    };
  },
  computed: {
    ...mapGetters('company', ['isCompanyAdmin', 'isCompanyOwner']),
  },
  watch: {
    member() {
      this.skills = cloneDeep(this.member.team_skills);
    },
  },
  created() {
    this.skills = cloneDeep(this.member.team_skills);
  },
  methods: {
    onSkillDrop(event) {
      const skill = JSON.parse(event.dataTransfer.getData('skill'));
      const hasSkill = this.member.team_skills.find(
        (skillValue) => skillValue.companySKVId === skill.id
      );
      const skillData = {
        companySKVId: skill.id,
        teamId: this.member.id,
      };
      if (!hasSkill) {
        this.skills.push({
          ...skillData,
          company_skill_value: cloneDeep(skill),
        });
        this.$emit('skillAdded', skillData);
      }
    },
    removeSkill(index, skill) {
      this.skills.splice(index, 1);
      this.$emit('skillRemoved', skill);
    },
    deleteMember(member, onClose) {
      this.$emit('memberDeleted', member);
      onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.member {
  @apply px-4 py-7;
  border-radius: 8px;
  background-color: rgba(121, 136, 146, 0.04);
  height: max-content;
  position: relative;

  &__header {
    @apply flex justify-between items-center;
  }
  &__details {
    @apply flex items-center;
  }
  &:hover {
    .member__delete {
      display: block;
    }
  }
  &__name {
    @apply font-semibold text-base mb-1;
  }
  &__position {
    @apply font-semibold text-xs;
    color: #49505780;
  }
  &__avatar {
    @apply mr-2;
  }
  &__content {
    @apply my-4;
  }
  &__skills {
    @apply flex flex-wrap;
    &__drop {
      @apply flex justify-center items-center font-semibold mt-4 text-sm;
      height: 50px;
      color: $color-orange;
      border: 1px dashed $color-orange;
      border-radius: 4px;
      &--dropped {
        height: 30px;
        opacity: 0.3;
        span {
          @apply hidden;
        }
      }
    }
  }
  &__skill {
    @apply flex overflow-hidden mr-2 mt-2 py-2;
    color: #fff;
    min-width: 120px;
    max-width: 50%;
    min-height: 35px;
    border-radius: 4px;
    &__text {
      @apply flex flex-1 justify-center items-center px-4 py-2 font-semibold text-xs;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: #56d6b3;
    }
    &__remove {
      @apply flex justify-center items-center cursor-pointer;
      background-color: #f4f6fc;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      width: 18px;
      svg {
        width: 8px;
        height: 8px;
        fill: $color-orange;
      }
    }
  }
}
</style>
