import { mapState, mapMutations } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
// import { getItems } from "@/helpers/utils.js";

export default {
  inject: ['updatePresentation'],
  data() {
    return {
      propositionItems: null,
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedItems() {
      return this.propositionItems;
    },
  },
  created() {
    this.setPropositionItems();
  },
  watch: {
    presentation() {
      this.setPropositionItems();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: 'presentation/updatePresentation',
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setPropositionItems() {
      const valuePropositionData = this.presentationData.business_models.find(
        (data) => data.type === 'overall_value_proposition'
      );
      if (!this.presentation.data.slidesData?.valueProposition) {
        this.propositionItems = valuePropositionData.description;
      } else {
        this.propositionItems = valuePropositionData.description;
      }
    },
    async updateValuePropositionData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                valueProposition: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while updating selected Value propositions, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
