var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-flex',{attrs:{"w":"100%","h":"100%","bg":_vm.presentation.data.elevator.backgroundColor,"p":"relative"}},[_c('c-flex',{attrs:{"direction":"column","w":"40%","h":"100%"}},[_c('c-flex',{attrs:{"direction":"column","h":"100%","w":"100%","align":"start"}},[_c('c-box',{attrs:{"ml":"20"}},[_c('c-text',{attrs:{"mt":"12","color":"#000","fontWeight":"700","fontSize":"4xl","textAlign":"left","zIndex":"2"}},[_vm._v("Elevator Pitch")]),_c('c-box',{attrs:{"mt":"5","h":".5em","w":"10em","bg":`${_vm.presentation.data.color}80`,"zIndex":"2"}})],1)],1),_c('div',{staticClass:"draggable-image",style:({
        backgroundImage: 'url(' + _vm.imageUrl + ')',
        backgroundSize: 'cover',
        width: '30em',
        height: '20em',
        left: '0',
        top: _vm.imagePosition.top + 'px',
        left: _vm.imagePosition.left + 'px',
      }),on:{"mousedown":_vm.startImageDrag,"mousemove":_vm.handleImageDrag,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}})],1),_c('c-flex',{attrs:{"backgroundImage":`linear-gradient(
      to bottom ,
      ${_vm.presentation.data.color}80,
      #fff
    )`,"backgroundSize":"cover","w":"15%","ml":"auto","h":"50%","justify":"center"}}),_c('c-flex',{attrs:{"direction":"column","flex":"1","p":"10","h":"100%","position":"relative","justify":"center","align":"center"}},[_c('div',{staticClass:"draggable-div",style:({
        top: _vm.position.top + 'px',
        left: _vm.position.left + 'px',
        fontSize: _vm.presentation.data.elevator.fontSize + 'px',
      }),on:{"mousedown":_vm.startDrag,"mousemove":_vm.handleDrag,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}},[_c('EditableText',{attrs:{"showOptions":false,"noHeight":true,"disabled":!_vm.isEdit,"value":_vm.elevatorPitch},on:{"updateDescription":_vm.onElevatorPitchInputBlur}})],1),_c('c-box',{attrs:{"ml":"auto","mt":"auto"}},[_c('WaterMark',{attrs:{"direction":'row'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }