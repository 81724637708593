<template>
  <c-box w="100%" h="100%" bg="white" display="flex" position="relative">
    <c-box w="35%">
      <c-flex ml="10" mt="16" direction="column" align="start">
        <c-text color="#000" fontWeight="700" fontSize="4xl" zIndex="2"
          >Go to market strategy</c-text
        >
        <c-box
          mt="5"
          h=".5em"
          w="8em"
          :bg="`${presentation.data.color}80`"
          zIndex="2"
        ></c-box>
      </c-flex>

      <c-box w="40%" ml="10" mt="12" textAlign="left">
        <c-text as="h4" mb="5" fontSize="1.2em" fontWeight="700"
          >Details</c-text
        >
        <EditableText
          :disabled="!isEdit"
          v-model="marketStrategyData.description"
          @blur="onMarketStragetyInputBlur"
        />
      </c-box>
    </c-box>
    <c-box
      w="45%"
      :bgImage="`linear-gradient(
        to bottom ,
        ${presentation.data.color}80,
        #fff
      )`"
    >
      <c-box ml="16" mt="20">
        <c-text as="h4" mb="10" fontSize="1.2em" fontWeight="700"
          >Keypoints</c-text
        >
        <ul v-chakra listStyle="none" w="80%">
          <template v-for="(item, index) in selectedItems">
            <li v-if="item.selected" :key="index">
              <c-flex>
                <c-icon mr="5" name="angle-double-right"></c-icon>
                <c-text ml="3" textAlign="justify-all" fontSize="2xl">
                  {{ item.text }}
                </c-text>
              </c-flex>
              <ul v-chakra ml="10" mb="10">
                <li
                  v-for="(child, childIndex) in item.children"
                  :key="childIndex"
                >
                  <c-text textAlign="justify-all" fontSize="2xl">
                    {{ child }}
                  </c-text>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </c-box>
    </c-box>
    <c-box
      w="20%"
      :backgroundImage="`url(https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/val_prop_default_1.png)`"
      backgroundSize="cover"
    ></c-box>
    <c-link
      textDecoration="underline"
      position="absolute"
      right="5"
      top="5"
      class="item-selector"
      fontSize="xl"
      v-if="isEdit"
      @click="isDrawerOpen = true"
      >Select keypoints to display</c-link
    >

    <c-box position="absolute" left="10px" bottom="10px">
      <WaterMark :direction="'row'" />
    </c-box>

    <TextListDrawer
      :title="'Select visible key points'"
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :items="keyItems"
      :updateFn="updateMarketStrategyData"
      :isUpdatingData="isUpdatingData"
    />
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import marketStrategyMixin from '@/mixins/data/marketStrategy.js';
import TextListDrawer from '@/components/DataComponents/TextListDrawer.vue';
import WaterMark from '@/components/WaterMark.vue';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';

export default {
  components: { WaterMark, TextListDrawer, EditableText },
  mixins: [marketStrategyMixin],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      presentation: (state) => state.presentation.activePresentation,
    }),
  },
};
</script>

<style></style>
