<template>
  <c-box>
    <TaskCard
      title="Traction"
      :description="'Set your company’s traction'"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :hasAccess="hasAccess"
      :taskData="foundationData"
      :accessKey="'foundation_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'foundation_visibilities'"
      :visibilityFn="updateVisibility"
      explanationKey="traction"
      @update="updateData"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="120" />
        <rect x="120" y="0" rx="3" ry="3" width="100" height="120" />
      </ContentLoader>
      <Traction :disabled="disabled" v-else />
    </TaskCard>
  </c-box>
</template>

<script>
import foundationMixin from '@/mixins/foundation.js';
import Traction from './Traction.vue';

import { ContentLoader } from 'vue-content-loader';

import TaskCard from './../TaskCard';

export default {
  components: { Traction, ContentLoader, TaskCard },
  mixins: [foundationMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      foundationType: 'traction',
      hasMounted: false,
    };
  },
};
</script>

<style></style>
