<template>
  <c-box id="swot">
    <!--       :isLocked="!getCurrentPlan.access.swot" -->
    <TaskCard
      :title="'SWOT'"
      :canAssign="true"
      :hasProgress="true"
      :hasAssumption="false"
      :hasImportance="true"
      :hasFooter="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="riskAssessmentData"
      :accessKey="'risk_assessment_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'risk_assessment_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="swot"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 300 200"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="50" />
        <rect x="200" y="0" rx="3" ry="3" width="100" height="50" />
        <rect x="0" y="100" rx="3" ry="3" width="100" height="50" />
      </ContentLoader>
      <c-grid
        :template-columns="{ base: '1fr', lg: 'repeat(2, 1fr)' }"
        gap="12"
        my="6"
        v-else
      >
        <c-box
          class="factor__item"
          w="100%"
          v-for="(area, areaIndex) in swot"
          :key="area.key"
          @drop="moveFactor($event, areaIndex)"
          @dragover.prevent
          @dragenter.prevent
        >
          <c-flex mb="8" align="center">
            <c-heading color="#495057" fontSize="lg" fontWeight="600">
              {{ area.title }}
            </c-heading>
            <c-link v-if="!disabled" @click="addFactor(areaIndex)">
              <svg
                fill="#ef5323"
                v-chakra="{
                  width: '20px',
                  height: '20px',
                  ml: '0.8em',
                }"
              >
                <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
              </svg>
            </c-link>
          </c-flex>
          <c-grid
            :template-columns="{ base: '1fr', lg: 'repeat(2, 250px)' }"
            gap="12"
          >
            <c-box
              h="180px"
              w="100%"
              p="6"
              borderRadius="8px"
              boxShadow="3px 3px 10px rgba(186, 186, 186, 0.36)"
              class="point-card"
              v-for="(factor, index) in area.factors"
              :key="factor.id || factor.tempId"
              :draggable="!disabled"
              @dragstart="pickupFactor($event, index, areaIndex)"
              @drop.stop="moveFactor($event, areaIndex, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              <svg
                class="point-card__delete"
                @click.stop="deleteFactor({ factor, index, areaIndex, area })"
                v-chakra="{
                  w: '4',
                  h: '4',
                  cursor: 'pointer',
                  fill: 'vc-orange.400',
                  position: 'absolute',
                  top: '-5px',
                  right: '-2px',
                  display: 'none',
                }"
              >
                <use href="@/assets/icons/icon-close-fill.svg#close"></use>
              </svg>
              <c-editable
                :default-value="factor.title || 'Title'"
                font-size="lg"
                :isDisabled="disabled"
                w="100%"
                fontWeight="600"
                mb="2"
                @submit="onTitleSubmit($event, areaIndex, index)"
              >
                <c-editable-preview textAlign="center" w="100%" />
                <c-editable-input textAlign="center" />
              </c-editable>
              <EditableText
                :type="`${area.title}${index}`"
                :disabled="disabled"
                :editabelIndex="{ areaIndex, index }"
                @updateDescription="updateDescription"
                v-model="factor.description"
              />
            </c-box>
          </c-grid>
        </c-box>
      </c-grid>
    </TaskCard>
  </c-box>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

import { ContentLoader } from 'vue-content-loader';

import {
  addRiskAssessmentValue,
  updateRiskAssessmentValue,
} from '@/services/risk-assessment';

import riskAssessmentMixin from '@/mixins/risk-assessment.js';

import EditableText from '../EditableText.vue';
import TaskCard from '../TaskCard.vue';
import cloneDeep from 'lodash.clonedeep';
import { generateID } from '@/helpers/data';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [riskAssessmentMixin],
  components: {
    TaskCard,
    ContentLoader,
    EditableText,
  },
  data() {
    return {
      swot: [
        {
          title: 'Strengths',
          key: 'STRENGTHS',
          factors: [],
        },
        {
          title: 'Weaknesses',
          key: 'WEAKNESSES',
          factors: [],
        },
        {
          title: 'Opportunities',
          key: 'OPPORTUNITIES',
          factors: [],
        },
        {
          title: 'Threats',
          key: 'THREATS',
          factors: [],
        },
      ],
      riskAssessmentType: 'swot',
      hasMounted: false,
    };
  },
  watch: {
    'riskAssessmentData.risk_assessment_values': function () {
      this.setSwotFactors(this.riskAssessmentData.risk_assessment_values);
    },
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    ...mapMutations({
      setSwotFactors: 'company/setSwotFactors',
    }),
    onDataFetch() {
      this.setFactors();
    },
    setFactors() {
      this.swot.forEach((type) => {
        type.factors = this.riskAssessmentData.risk_assessment_values.filter(
          (value) => value.type === type.key
        );
      });
    },
    addFactor(areaIndex) {
      this.swot[areaIndex].factors.push({
        tempId: generateID(4),
        title: '',
        description: '',
        type: this.swot[areaIndex].key,
      });
    },
    pickupFactor(event, factorIndex, fromAreaIndex) {
      if (this.disabled) {
        return;
      }
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';

      event.dataTransfer.setData('factor-index', factorIndex);
      event.dataTransfer.setData('from-area-index', fromAreaIndex);
    },
    async moveFactor(event, toAreaIndex, toFactorIndex) {
      if (this.disabled) {
        return;
      }
      const factorIndex = event.dataTransfer.getData('factor-index');
      const fromAreaIndex = event.dataTransfer.getData('from-area-index');
      const fromArea = this.swot[fromAreaIndex];
      const toArea = this.swot[toAreaIndex];
      const factor = {
        ...fromArea.factors.splice(factorIndex, 1)[0],
        type: toArea.key,
      };
      toArea.factors.splice(
        toFactorIndex !== undefined ? toFactorIndex : toArea.factors.length,
        0,
        factor
      );
      if (factor.id) {
        try {
          await updateRiskAssessmentValue({
            id: factor.id,
            set: { type: factor.type },
          });
        } catch (e) {
          this.swot[fromAreaIndex].splice(factorIndex, 0, {
            ...factor,
            type: this.swot[fromAreaIndex].key,
          });
          this.$toast({
            title: 'Error!!!',
            description: `An error occurred while updating factor`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    onTitleChange(event, areaIndex, factorIndex) {
      if (typeof event === 'string') {
        this.swot[areaIndex].factors[factorIndex].title = event;
      }
    },
    onTitleSubmit(e, areaIndex, factorIndex) {
      if (e && e.trim()) {
        this.swot[areaIndex].factors[factorIndex].title = e;
        this.saveFactor({ areaIndex, factorIndex }, 'title');
      } else {
        this.$toast({
          title: 'Input Error',
          description: `Title is Required to add a risk factor`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    updateDescription(value, idx) {
      this.onDescriptionBlur(value, idx.areaIndex, idx.index);
    },
    onDescriptionBlur(e, areaIndex, factorIndex) {
      this.swot[areaIndex].factors[factorIndex].description = e;
      const title = this.swot[areaIndex].factors[factorIndex].title;
      if (title && title.trim()) {
        this.saveFactor({ areaIndex, factorIndex }, 'description');
      } else {
        this.$toast({
          title: 'Input Error',
          description: `Title is Required to add a risk factor`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    saveFactor({ areaIndex, factorIndex }, updateKey = null) {
      const { type, title, description, id } =
        this.swot[areaIndex].factors[factorIndex];
      if (id) {
        this.updateFactor({
          areaIndex,
          type,
          title,
          description,
          id,
          updateKey,
        });
      } else {
        this.addNewFactor({ type, title, description, areaIndex, factorIndex });
      }
    },
    async addNewFactor({ type, title, description, areaIndex, factorIndex }) {
      try {
        const res = await addRiskAssessmentValue({
          type,
          title,
          description,
          riskAssessmentId: this.riskAssessmentData.id,
        });
        this.swot[areaIndex].factors[factorIndex].id =
          res.data.insert_risk_assessment_value_one.id;
        this.riskAssessmentData.risk_assessment_values.push(
          res.data.insert_risk_assessment_value_one
        );
        this.prevData.risk_assessment_values = cloneDeep(
          this.riskAssessmentData.risk_assessment_values
        );
      } catch (e) {
        this.swot[areaIndex].factors.splice(factorIndex, 1);
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while adding the factor, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateFactor({
      id,
      type,
      title,
      description,
      areaIndex,
      factorIndex,
      updateKey,
      isDeleted = false,
    }) {
      const index = this.riskAssessmentData.risk_assessment_values.findIndex(
        (value) => value.id === id
      );
      try {
        if (isDeleted) {
          this.swot[areaIndex].factors.splice(factorIndex, 1);
        }

        await updateRiskAssessmentValue({
          id,
          set: { type, title, description, isDeleted },
        });
      } catch (e) {
        if (!isDeleted) {
          this.swot[areaIndex][factorIndex][updateKey] =
            this.riskAssessmentData.risk_assessment_values[index][updateKey];
        } else {
          this.swot[areaIndex].factors.splice(factorIndex, 1, {
            id,
            type,
            title,
            description,
          });
        }
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while ${
            isDeleted ? 'deleting' : 'updating'
          } the factor, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    deleteFactor({ index, factor, areaIndex, updateKey = null }) {
      //  check if factor has a title or description
      if (!factor.title || !factor.description) {
        this.swot[areaIndex].factors.splice(index, 1);
      } else {
        this.updateFactor({
          areaIndex,
          factorIndex: index,
          ...factor,
          isDeleted: true,
          updateKey,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.point-card {
  position: relative;

  ::v-deep {
    .editable__input {
      @apply text-xs;
      min-height: 100px;
      max-height: 100px;
    }
  }

  &:hover {
    .point-card__delete {
      display: block;
    }
  }
}
</style>
