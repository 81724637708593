import { render, staticRenderFns } from "./Foundation.vue?vue&type=template&id=7cf14b2c"
import script from "./Foundation.vue?vue&type=script&lang=js"
export * from "./Foundation.vue?vue&type=script&lang=js"
import style0 from "./Foundation.vue?vue&type=style&index=0&id=7cf14b2c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid, CStack: require('@chakra-ui/vue').CStack})
