import { render, staticRenderFns } from "./RiskAnalysis.vue?vue&type=template&id=27dab1b2&scoped=true"
import script from "./RiskAnalysis.vue?vue&type=script&lang=js"
export * from "./RiskAnalysis.vue?vue&type=script&lang=js"
import style0 from "./RiskAnalysis.vue?vue&type=style&index=0&id=27dab1b2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27dab1b2",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CSpinner: require('@chakra-ui/vue').CSpinner, CHeading: require('@chakra-ui/vue').CHeading, CPopoverArrow: require('@chakra-ui/vue').CPopoverArrow, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CLink: require('@chakra-ui/vue').CLink, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CInput: require('@chakra-ui/vue').CInput, CModalBody: require('@chakra-ui/vue').CModalBody, CButton: require('@chakra-ui/vue').CButton, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal, CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogHeader: require('@chakra-ui/vue').CAlertDialogHeader, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
