var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',[(_vm.step.type === 'ELEMENT')?[_c('c-box',{attrs:{"minHeight":"200px","borderColor":"#2D2D2D","borderWidth":"1px","borderRadius":"0 0 8px 8px","p":"8"}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between","mb":"3"}},[_c('c-text',{attrs:{"fontSize":"2xl","fontWeight":"700"}},[_vm._v(" "+_vm._s(_vm.step.title)+" ")]),_c('c-text',{attrs:{"color":"gray.400","fontSize":"xl","fontWeight":"500"}},[_vm._v(" "+_vm._s(_vm.step.time)+" ")])],1),_c('c-list',{attrs:{"maxWidth":"70%","mb":"6"}},_vm._l((_vm.step.descriptions),function(description,index){return _c('c-list-item',{key:index},[_c('c-flex',{attrs:{"mb":"2"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '4',
                h: '4',
                mr: '2',
              }),expression:"{\n                w: '4',\n                h: '4',\n                mr: '2',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-tools.svg") + "#tools"}})]),_c('c-text',{attrs:{"fontSize":"xs","flex":"1","color":"gray.400"}},[_vm._v(" "+_vm._s(description)+" ")])],1)],1)}),1),_c('c-flex',{attrs:{"px":"6","bg":"#F8F8F8","justify":"space-between","align":"center","flexWrap":"wrap","borderRadius":"70px","h":"80px"}},[_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","alignItems":"center","cursor":"pointer","_hover":{
            textDecoration: 'none',
          }},on:{"click":function($event){return _vm.$emit('goToStep')}}},[(_vm.progress === 'DONE')?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              w: '8',
              h: '8',
            }),expression:"{\n              w: '8',\n              h: '8',\n            }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/circle-tick-fill.svg") + "#circle-tick"}})]):_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              w: '12',
              h: '12',
            }),expression:"{\n              w: '12',\n              h: '12',\n            }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/play-circle-fill.svg") + "#play-circle"}})]),_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"lg","fontWeight":"600","mr":"2","ml":"2"}},[_vm._v(" Start ")])],1),(
            _vm.step.title !== 'Team' &&
            _vm.step.title !== 'Elevator Pitch' &&
            _vm.step.title !== 'Problem Interview'
          )?_c('c-box',{attrs:{"w":{ lg: '100%', xl: '55%' }}},[_c('ProgressBar',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              w: '100%',
            }),expression:"{\n              w: '100%',\n            }"}],attrs:{"value":_vm.progressStages[_vm.progress],"valueOutside":true,"height":'10px'}})],1):_vm._e()],1)],1)]:[_c('c-flex',{attrs:{"bg":"#F8F8F8","borderRadius":"8px 8px 0 0","w":"50%","h":"50px","align":"center","justify":"space-between","px":"4"}},[_c('ProgressBar',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          flex: 1,
        }),expression:"{\n          flex: 1,\n        }"}],attrs:{"value":_vm.growthFrameworkCompletion[_vm.step.key].totalPercentage,"valueOutside":true,"height":'10px'}})],1),_c('c-flex',{attrs:{"minHeight":"250px","borderColor":"#2D2D2D","borderWidth":"1px","borderRadius":"0 0 8px 8px","p":"8"}},[_c('c-box',{attrs:{"w":"40%","borderRightWidth":"1px","borderColor":"#79889233","pr":"8"}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between","mb":"3"}},[_c('c-text',{attrs:{"fontSize":"2xl","fontWeight":"700"}},[_vm._v(" "+_vm._s(_vm.step.title)+" ")]),_c('c-text',{attrs:{"color":"gray.400","fontSize":"xl","fontWeight":"500"}},[_vm._v(" "+_vm._s(_vm.step.time)+" ")])],1),_c('c-list',{attrs:{"maxWidth":"70%","mb":"6"}},_vm._l((_vm.step.descriptions),function(description,index){return _c('c-list-item',{key:index},[_c('c-flex',{attrs:{"mb":"2"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  w: '4',
                  h: '4',
                  mr: '2',
                }),expression:"{\n                  w: '4',\n                  h: '4',\n                  mr: '2',\n                }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-tools.svg") + "#tools"}})]),_c('c-text',{attrs:{"fontSize":"xs","flex":"1","color":"gray.400"}},[_vm._v(" "+_vm._s(description)+" ")])],1)],1)}),1),_c('c-flex',{attrs:{"justify":"flex-end"}},[_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","alignItems":"center","cursor":"pointer","_hover":{
              textDecoration: 'none',
            }},on:{"click":function($event){return _vm.$emit('goToPage', { page: _vm.step, pageIndex: _vm.stepIndex })}}},[_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"lg","fontWeight":"600","mr":"2"}},[_vm._v(" Start ")]),(
                _vm.growthFrameworkCompletion[_vm.step.key].totalPercentage === 100
              )?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '8',
                h: '8',
              }),expression:"{\n                w: '8',\n                h: '8',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/circle-tick-fill.svg") + "#circle-tick"}})]):_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '12',
                h: '12',
              }),expression:"{\n                w: '12',\n                h: '12',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/play-circle-fill.svg") + "#play-circle"}})])],1)],1)],1),_c('c-box',{attrs:{"flex":"1","ml":"8"}},[_c('c-text',{attrs:{"fontWeight":"500","mb":"2"}},[_vm._v(" Content ")]),_c('c-grid',{attrs:{"w":"100%","template-columns":{ base: '1fr', lg: 'repeat(3, 1fr)' },"gap":"8"}},_vm._l((_vm.step.tour_steps),function(pageStep,index){return _c('c-flex',{key:pageStep.target,attrs:{"align":"center","w":"100%"}},[_c('c-text',{attrs:{"fontSize":"xl","color":"gray.300","fontWeight":"500","mr":"3"}},[_vm._v(" "+_vm._s(index < 10 ? '0' : '')+_vm._s(index + 1)+" ")]),_c('c-box',{attrs:{"mr":"3"}},[_c('c-text',{attrs:{"fontSize":"10px","fontWeight":"500","color":"gray.300","mb":"1"}},[_vm._v(" "+_vm._s(pageStep.time)+" ")]),_c('c-text',{attrs:{"fontSize":"sm","fontWeight":"600"}},[_vm._v(" "+_vm._s(pageStep.title)+" ")])],1),_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","alignItems":"center","cursor":"pointer","_hover":{
                textDecoration: 'none',
              },"ml":"auto"},on:{"click":function($event){return _vm.$emit('goToStep', { parentTour: _vm.step, stepIndex: index })}}},[(
                  _vm.growthFrameworkCompletion[_vm.step.key][pageStep.key] === 'DONE'
                )?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  w: '8',
                  h: '8',
                }),expression:"{\n                  w: '8',\n                  h: '8',\n                }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/circle-tick-fill.svg") + "#circle-tick"}})]):_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  w: '8',
                  h: '8',
                }),expression:"{\n                  w: '8',\n                  h: '8',\n                }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/play-circle-fill.svg") + "#play-circle"}})])])],1)}),1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }