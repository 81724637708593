<template>
  <c-box mb="3" p="5" boxShadow="0px 3px 6px rgba(186, 186, 186, 0.16)">
    <c-flex justify="space-between">
      <c-heading fontSize="md" fontWeight="600" as="h6"
        >Willingness to pay</c-heading
      >
      <!-- <c-link
        display="flex"
        @click="onEditClick"
        color="#ef5323"
        fontWeight="500"
        v-if="!disabled"
      >
        <c-text as="span" fontSize="sm">Edit</c-text>
        <svg
          v-chakra="{
            w: '18px',
            h: '18px',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/edit-fill.svg#edit"></use>
        </svg>
      </c-link> -->
    </c-flex>
    <c-text fontSize="sm" my="2" color="#798892">
      How much would customers be willing to pay for your product or service?
    </c-text>
    <c-flex my="3">
      <c-box mr="16">
        <c-heading as="h6" fontSize="base" fontWeight="500" mb="2">
          From
        </c-heading>
        <c-editable
          :default-value="willingnessValues.from"
          color="gray.500"
          fontSize="xs"
          fontWeight="500"
          @change="onInputChange($event, 'from')"
          @submit="onInputSubmit"
          :isDisabled="disabled"
        >
          <c-editable-preview />
          <c-editable-input type="number" w="80px" />
        </c-editable>
      </c-box>
      <c-box mr="8">
        <c-heading as="h6" fontSize="base" fontWeight="500" mb="2">
          To
        </c-heading>
        <c-editable
          :default-value="willingnessValues.to"
          color="gray.500"
          fontSize="xs"
          fontWeight="500"
          @change="onInputChange($event, 'to')"
          @submit="onInputSubmit"
          :isDisabled="disabled"
        >
          <c-editable-preview />
          <c-editable-input w="80px" />
        </c-editable>
      </c-box>
      <AppSelect
        color="orange"
        :reduce="(currency) => currency.code"
        label="code"
        :options="currencies"
        v-model="willingnessValues.currency"
        indicator-fill="orange"
        :disabled="disabled"
        @input="onInputSubmit"
      />
      <AppSelect
        color="orange"
        label="metric"
        :options="[
          'Per Item',
          'Per Service',
          'Per Day',
          'Per Month',
          'Per Year',
        ]"
        v-model="willingnessValues.metric"
        indicator-fill="orange"
        :disabled="disabled"
        @input="onInputSubmit"
      />
    </c-flex>
  </c-box>
</template>

<script>
import currenciesArray from '@/helpers/currencies.js';
import AppSelect from './../../../components/AppSelect';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AppSelect,
  },
  created() {
    if (this.value) {
      this.willingnessValues = { ...this.value, metric: 'Per Item' };
    }
  },
  data() {
    return {
      currencies: currenciesArray,
      willingnessValues: {
        currency: 'USD',
        metric: 'Per Item',
        from: null,
        to: null,
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.willingnessValues = { ...this.value };
      },
      deep: true,
    },
  },
  methods: {
    onInputChange(value, key) {
      if (typeof value === 'string') {
        this.willingnessValues[key] = value;
      }
    },
    onInputSubmit() {
      this.$emit('updateField', {
        key: 'willingnessToPay',
        value: { ...this.willingnessValues },
      });
    },
  },
};
</script>

<style></style>
