<template>
  <c-box>
    <AppSegment
      :segments="competitionSegments"
      name="competition"
      v-model="selectedSegment"
    />
    <transition name="fade" mode="out-in">
      <component :is="selectedSegmentComponent" :disabled="disabled" />
    </transition>
  </c-box>
</template>

<script>
import AppSegment from './../AppSegment';
import CompetitionCard from '../competition/CompetitionCard.vue';
import ComparisonCard from '../competition/ComparisonCard.vue';
import PointOfDifferentiation from '../competition/PointOfDifferentiation.vue';

export default {
  props: ['disabled'],
  components: { AppSegment },
  data() {
    return {
      competitionSegments: [
        {
          title: 'Point of Differentiation',
          value: 'point_of_differentiation',
        },
        { title: 'Comparison', value: 'comparison' },
        { title: 'Competition', value: 'competition' },
      ],
      selectedSegment: 'point_of_differentiation',
      components: {
        point_of_differentiation: PointOfDifferentiation,
        comparison: ComparisonCard,
        competition: CompetitionCard,
      },
    };
  },
  computed: {
    selectedSegmentComponent() {
      return this.components[this.selectedSegment];
    },
  },
};
</script>

<style></style>
