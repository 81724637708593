import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      revenues: [],
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedRevenues() {
      return this.revenues?.filter((revenue) => revenue.selected);
    },
  },
  watch: {
    presentation() {
      this.setRevenues();
    },
  },
  created() {
    this.setRevenues();
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setRevenues() {
      const revenueModel = this.presentationData.business_models?.find(
        (data) => data.type === "revenue"
      );

      const revenuesData = revenueModel && revenueModel?.revenues;
      if (!this.presentation.data.slidesData?.revenues) {
        this.revenues = revenuesData.map((revenue, index) => ({
          ...revenue,
          selected: index <= 4,
        }));
      } else {
        const { revenues } = this.presentation.data.slidesData;
        this.revenues = revenuesData.map((item) => {
          const itemInPresentation = revenues.find(
            (_item) => _item.id === item.id
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async updateRevenuesData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                revenues: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected Customers, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
    labelRenderFn(label) {
      return label.toLowerCase().split("_").join(" ");
    },
  },
};
