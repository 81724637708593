<template>
  <div>
    <form @submit.prevent="onMilestoneFormSubmit">
      <c-modal-body>
        <c-box p="3">
          <c-box mb="5">
            <c-input
              size="lg"
              placeholder="Milestone Name"
              v-model="$v.milestoneForm.title.$model"
            ></c-input>
          </c-box>
          <c-box mb="5">
            <AppDatePicker
              format="YYYY-MM-DD"
              type="date"
              v-model="$v.milestoneForm.date.$model"
            >
              <template v-slot:input>
                <c-input
                  size="lg"
                  placeholder="Select Date"
                  v-model="milestoneDateDisplay"
                ></c-input>
              </template>
            </AppDatePicker>
          </c-box>
          <c-box mb="5">
            <AppSelect
              label="title"
              :reduce="(scenario) => scenario.id"
              :options="scenarios"
              :reset="true"
              :fullWidth="true"
              :bordered="true"
              indicator-fill="orange"
              color="dark"
              :borderColor="'light'"
              size="large"
              v-model="$v.milestoneForm.scenarioId.$model"
              placeholder="Add Scenario (optional)"
            />
          </c-box>
          <c-box mb="5">
            <CategorySelect v-model="$v.milestoneForm.categoryId.$model" />
          </c-box>
          <c-box>
            <textarea
              v-model="$v.milestoneForm.description.$model"
              placeholder="Description"
              v-chakra="{
                w: '100%',
                h: '2rem',
                outline: 'none',
                borderRadius: '0.25rem',
                borderWidth: '1px',
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                fontSize: '1rem',
                minHeight: '80px',
              }"
            ></textarea>
          </c-box>
        </c-box>
      </c-modal-body>
      <c-modal-footer>
        <c-button
          :disabled="$v.milestoneForm.$invalid || isAddingMilestone"
          variant-color="vc-orange"
          type="submit"
          mr="3"
        >
          <c-text color="#fff">
            {{ 'Create Milestone' }}
          </c-text>
          <c-spinner
            v-if="isAddingMilestone"
            ml="2"
            color="#fff"
            thickness="2px"
          />
        </c-button>
      </c-modal-footer>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import AppSelect from './../../../components/AppSelect.vue';
import AppDatePicker from '@/views/App/components/AppDatePicker.vue';
import CategorySelect from '@/views/App/Timeline/components/CategorySelect.vue';

import { addMilestone } from '@/services/timeline';

export default {
  components: {
    AppSelect,
    AppDatePicker,
    CategorySelect,
  },
  validations: {
    milestoneForm: {
      title: {
        required,
      },
      date: {
        required,
      },
      description: {},
      categoryId: {},
      scenarioId: {},
    },
  },
  computed: {
    ...mapState('timeline', {
      categories: (state) => state.categories,
      scenarios: (state) => state.scenarios,
    }),

    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),

    milestoneDateDisplay() {
      return this.milestoneForm.date
        ? this.$moment(this.milestoneForm.date).format('YYYY-MM-DD')
        : null;
    },
  },
  data() {
    return {
      isAddingMilestone: false,
      milestoneForm: {
        title: null,
        date: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      },
    };
  },
  methods: {
    ...mapMutations({
      addNewMilestone: 'timeline/addMilestone',
    }),
    async onMilestoneFormSubmit() {
      this.isAddingMilestone = true;
      const { title, date, description, categoryId, scenarioId } =
        this.milestoneForm;
      const data = {
        title,
        categoryId,
        description,
        scenarioId,
        date: date.toISOString(),
        companyId: this.activeCompany.id,
      };
      try {
        const res = await addMilestone(data);
        this.addNewMilestone(res.data.insert_milestone_one);

        this.clearMilestoneForm();
        this.isAddingMilestone = false;
        this.$emit('onAddMilestone', res.data.insert_milestone_one.id);
      } catch (e) {
        this.isAddingMilestone = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while creating milestone, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    clearMilestoneForm() {
      this.milestoneForm = {
        title: null,
        date: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      };
    },
  },
};
</script>
