<template>
  <div class="culture-fields">
    <div v-for="field of sortedFields" :key="field.id">
      <div v-if="showSaved(field.id)">
        <c-text as="span" color="#ef5323" fontSize="14px" v-if="saved"
          ><b>Saved successfully 👍</b></c-text
        >
      </div>

      <EditableText
        :title="field.label"
        :type="`CultureField${field.id}`"
        v-model="field.description"
        @updateDescription="updateDescription"
        :disabled="disabled"
        :editabelIndex="field.id"
        :showTitle="true"
      />
    </div>
  </div>
</template>

<script>
// import ListTextInput from '../ListTextInput.vue';
import EditableText from '../EditableText';

export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditableText,
  },
  data() {
    return {
      sortedFields: [],
      saved: false,
      currentId: null,
    };
  },
  created() {
    this.setSortedFields();
  },
  watch: {
    fields() {
      this.setSortedFields();
    },
  },
  computed: {
    showSaved() {
      return (fieldId) => {
        return this.saved && this.currentId === fieldId;
      };
    },
  },
  methods: {
    setSortedFields() {
      this.sortedFields = [...this.fields].sort((a, b) => a.id - b.id);
      // save data of first field description in section storage
      // to be used in the overview section for AI analysis
      if (this.sortedFields.length) {
        sessionStorage.setItem('culture', this.sortedFields[0].description);
      }
    },
    onInputBlur(description, fieldId) {
      this.$emit('updateField', { description, id: fieldId });

      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },
    updateDescription(description, fieldId) {
      // edit the field description using the field id
      const fieldIndex = this.fields.findIndex((field) => field.id === fieldId);
      this.fields[fieldIndex].description = description;

      sessionStorage.setItem('culture', this.fields[fieldIndex].description);
      this.currentId = fieldId;
      this.onInputBlur(description, fieldId);
    },
  },
};
</script>

<style lang="scss">
.culture-fields {
  @apply grid;
  grid-template-columns: 1fr;
  gap: 30px;
  @screen lg {
    grid-template-columns: repeat(2, 1fr);
  }
  padding-bottom: 70px;
}
</style>
