<template>
  <c-box class="segment" width="max-content" maxWidth="100%" overflowX="scroll">
    <label
      v-chakra="{
        position: 'relative',
        cursor: 'pointer',
        mr: index < segments.length - 1 ? '2' : '',
      }"
      v-for="(segment, index) in segments"
      :key="segment.value"
    >
      <c-visually-hidden
        as="input"
        type="radio"
        :name="name"
        :value="segment.value"
        :checked="segment.value === selectedSegment"
        @change="onChange"
        :disabled="disabled"
      />

      <c-control-box
        width="max-content"
        min-width="120px"
        h="100%"
        type="radio"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="transparent"
        px="4"
        :_checked="{
          bg: 'white',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          boxShadow: '0 3px 6px rgba(186, 186, 186, 0.16)',
        }"
      >
        <c-box as="span" fontSize="sm" fontWeight="500">
          {{ segment.title }}
        </c-box>
      </c-control-box>
    </label>
  </c-box>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    segments: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.selectedSegment = this.value || this.segments[0].value;
  },
  data() {
    return {
      selectedSegment: null,
    };
  },
  watch: {
    selectedSegment() {
      this.$emit('input', this.selectedSegment);
    },
  },
  methods: {
    onChange(event) {
      this.selectedSegment = event.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.segment {
  @apply flex;
  height: 40px;
  background-color: #eaf0f6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>
