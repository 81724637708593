<template>
  <c-box>
    <AppSegment :segments="segments" name="name" v-model="selectedSegment" />
    <transition name="fade" mode="out-in">
      <keep-alive>
        <component :is="selectedSegmentComponent" :disabled="disabled" />
      </keep-alive>
    </transition>
  </c-box>
</template>

<script>
import AppSegment from './../AppSegment';

export default {
  props: ['disabled', 'segments', 'name'],

  components: { AppSegment },
  data() {
    return {
      selectedSegment: this.segments[0].value,
    };
  },
  computed: {
    selectedSegmentComponent() {
      return this.segments.find(
        (segment) => segment.value === this.selectedSegment
      ).component;
    },
  },
};
</script>

<style></style>
