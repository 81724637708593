<template>
  <div class="editable-progress">
    <v-select
      :clearable="false"
      :searchable="false"
      class="vc-select editable-progress__select"
      v-model="innerValue"
      label="title"
      :reduce="(progress) => progress.value"
      :options="marketGoalCard ? marketGoalStages : progressStages"
      placeholder="select"
      :disabled="disabled"
    >
      <template #list-header>
        <div class="editable-progress__select__header">Completion</div>
      </template>
      <template #selected-option="{ title }">
        <div>{{ title }}</div>
      </template>
      <template v-slot:option="option">
        <span class="editable-progress__select__option">
          • {{ option.title }}
        </span>
      </template>
    </v-select>
    <div class="editable-progress__bar">
      <c-progress
        height="15px"
        borderRadius="10px"
        color="vc-green"
        :value="progress"
      >
        <div class="dots">
          <span :class="{ white: progress >= 25 }"></span>
          <span :class="{ white: progress >= 50 }"></span>
          <span :class="{ white: progress >= 75 }"></span>
        </div>
      </c-progress>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    assumption: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    typeCard: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progress: 0,
      innerValue: 'NOT_DONE',
      progressStages: [
        { title: 'Not done', progressValue: 0, value: 'NOT_DONE' },
        { title: 'In progress', progressValue: 25, value: 'IN_PROGRESS' },
        { title: 'Half way', progressValue: 50, value: 'HALF_WAY' },
        { title: 'Almost done', progressValue: 75, value: 'ALMOST_DONE' },
        { title: 'Done 👏🏽', progressValue: 100, value: 'DONE' },
      ],
      marketGoalStages: [
        { title: 'Not done', progressValue: 0, value: 'NOT_DONE' },
        { title: 'Chronically late', progressValue: 25, value: 'IN_PROGRESS' },
        {
          title: 'Last minute warrior',
          progressValue: 75,
          value: 'ALMOST_DONE',
        },
        { title: 'Structured progress 👏🏽', progressValue: 100, value: 'DONE' },
      ],
    };
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  computed: {
    marketGoalCard() {
      return this.typeCard === 'Market Goals';
    },
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
    innerValue(currVal, prevVal) {
      if (currVal !== prevVal) {
        this.$emit('input', currVal);
        this.setProgressBar();
      }
    },
  },
  methods: {
    setProgressBar() {
      const stage = this.progressStages.find(
        (_stage) => _stage.value === this.innerValue
      );
      this.progress = stage.progressValue;
    },
    setProgressName(name) {
      if (this.typeCard == 'Market Goals') {
        console.log(this.value);
        let progressName = name;
        switch (name) {
          case 'Not Done': {
            progressName = 'Chronically late';
            break;
          }
          case 'In progress': {
            progressName = 'Last minute warrior';
            break;
          }
          default:
            break;
        }

        return progressName;
      } else {
        return name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-progress {
  @apply flex flex-col items-start;
  &__select {
    &.vs--open {
      ::v-deep {
        .vs__selected {
          margin-top: 4px;
          opacity: 1;
        }
        .vs__open-indicator {
          transform: rotate(180deg) scale(1) translateY(5px);
        }
      }
    }
    ::v-deep {
      .vs__open-indicator {
        @apply block;
        fill: $color-orange;
        width: 10px;
        transform: scale(1) translateY(0);
        path {
          d: path(
            'M10.555,13.073l-2.8-2.8.933-.932,1.865,1.865L12.42,9.343l.933.932Z'
          );
          fill: red;
          transform: translate(-7.757px, -9.343px);
        }
      }
      .vs__dropdown-toggle {
        height: 25px;
        width: 100%;
        font-size: smaller;
        border: none;
        padding: 0;
      }
      .vs__selected {
        color: $color-green;
        font-weight: bold;
      }
      .vs__dropdown-menu {
        min-width: 190px;
      }
      .vs__dropdown-option {
        height: 25px;
        padding: 0;
      }
      .vs__actions {
        padding: 8px 6px 0 3px;
      }
    }
    &__header {
      @apply px-4 font-medium text-xs;
      color: $color-grey;
    }
    &__option {
      @apply w-full px-4;
      color: $color-green;
      &:hover {
        color: #fff;
        background-color: $color-green;
      }
    }
  }
  &__bar {
    width: 150px;
    ::v-deep {
      div[data-chakra-component='CProgress'] {
        @apply relative;
      }
      div[role='progressbar'] {
        .dots {
          @apply absolute flex justify-around items-center;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          padding-left: 13px;
          padding-right: 24px;
          span {
            display: flex;
            width: 4px;
            height: 4px;
            background-color: $color-grey;
            border-radius: 50%;
            &.white {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
