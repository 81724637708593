import { render, staticRenderFns } from "./OptionSelectField.vue?vue&type=template&id=09c8fe20&scoped=true"
import script from "./OptionSelectField.vue?vue&type=script&lang=js"
export * from "./OptionSelectField.vue?vue&type=script&lang=js"
import style0 from "./OptionSelectField.vue?vue&type=style&index=0&id=09c8fe20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c8fe20",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CVisuallyHidden: require('@chakra-ui/vue').CVisuallyHidden, CControlBox: require('@chakra-ui/vue').CControlBox})
