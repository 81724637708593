<template>
  <c-flex
    top="0"
    left="0"
    right="0"
    bottom="0"
    align="center"
    zIndex="docked"
    justify="center"
    position="absolute"
    borderRadius="inherit"
    @click.stop="() => {}"
    backgroundImage="linear-gradient(
                    to right top,
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
                )"
  >
    <c-spinner v-if="!failed" color="#fff" thickness="2px" />
    <template v-if="failed && canReload">
      <c-button
        px="0"
        variant-color="blue"
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="center"
        @click.stop="$emit('reload')"
      >
        <svg
          v-chakra="{
            w: '20px',
            h: '20px',
            fill: '#fff',
          }"
        >
          <use href="@/assets/icons/icon-upload.svg#upload"></use>
        </svg>
      </c-button>
    </template>
  </c-flex>
</template>

<script>
export default {
  props: {
    canReload: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
