var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{directives:[{name:"chakra",rawName:"v-chakra"}],staticClass:"customers",attrs:{"my":"8","w":"100%","maxW":"100%"}},[_c('c-tabs',{directives:[{name:"chakra",rawName:"v-chakra"}],key:_vm.customers.length,staticClass:"customers__tabs",attrs:{"w":"100%","maxW":"100%","variant":"enclosed-colored"},on:{"change":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onTabIndexChange.apply(null, arguments)}}},[_c('c-tab-list',{directives:[{name:"chakra",rawName:"v-chakra"}],staticClass:"customers__tabs__list",attrs:{"w":"100%","pt":"5","maxW":"100%","overflowX":"scroll"}},[(_vm.showAdd && !_vm.disabled)?_c('c-tab',{staticClass:"customers__tabs__add"},[_c('svg',{attrs:{"width":"30","height":"30","fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})])]):_vm._e(),_vm._l((_vm.customers),function(customer,ix){return _c('c-tab',{key:customer.name,staticClass:"customers__tabs__list__item",class:[
          `customers__tabs__list__item--${customer.category.toLowerCase()}`,
        ]},[_c('span',{staticClass:"customers__tabs__list__item__category"},[_vm._v(_vm._s(customer.category))]),(_vm.showAdd)?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            w: '4',
            h: '4',
            cursor: 'pointer',
            fill: 'vc-orange.400',
            position: 'absolute',
            top: '-5px',
            right: '-2px',
            display: 'none',
            zIndex: '999999',
          }),expression:"{\n            w: '4',\n            h: '4',\n            cursor: 'pointer',\n            fill: 'vc-orange.400',\n            position: 'absolute',\n            top: '-5px',\n            right: '-2px',\n            display: 'none',\n            zIndex: '999999',\n          }"}],staticClass:"customers__tabs__list__item__delete",on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteCustomer(customer.id)}}},[_c('use',{attrs:{"href":require("@/assets/icons/icon-close-fill.svg") + "#close"}})]):_vm._e(),(_vm.showAdd)?_c('c-editable',{attrs:{"isDisabled":_vm.tabIndex - 1 !== ix,"default-value":customer.name,"value":customer.name,"selectAllOnFocus":false},on:{"change":function($event){return _vm.onFieldChange($event, ix, 'name')}}},[_c('c-editable-preview'),_c('c-editable-input',{on:{"click":function($event){$event.stopPropagation();}}})],1):_c('c-text',[_vm._v(" "+_vm._s(customer.name)+" ")])],1)})],2),_c('c-tab-panels',{staticClass:"customers__tabs__panels",class:[
        `customers__tabs__panels--${
          _vm.showAdd && !_vm.disabled
            ? 'default'
            : _vm.customers[_vm.tabIndex]
            ? _vm.customers[_vm.tabIndex].category.toLowerCase()
            : 'default'
        }`,
      ]},[(_vm.showAdd && !_vm.disabled)?_c('c-tab-panel',[_c('form',{staticClass:"customers__form",on:{"submit":function($event){$event.preventDefault();return _vm.onCustomerFormSubmit.apply(null, arguments)}}},[_c('c-heading',{attrs:{"as":"h5","fontSize":"sm","color":"#798892"}},[_vm._v(" Add Target Market Details ")]),_c('c-form-control',{staticClass:"customers__form-control"},[_c('c-input',{attrs:{"type":"text","size":"lg","placeholder":"Name of Target Market"},model:{value:(_vm.$v.customerForm.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.customerForm.name, "$model", $$v)},expression:"$v.customerForm.name.$model"}}),(
                _vm.$v.customerForm.name.$dirty && !_vm.$v.customerForm.name.required
              )?_c('c-form-helper-text',{attrs:{"color":"vc-orange.400","fontSize":"xs"}},[_vm._v(" Customer's name is required ")]):_vm._e(),_c('c-link',{attrs:{"my":"3"},on:{"click":function($event){_vm.isTeamDrawerOpen = !_vm.isTeamDrawerOpen}}},[_c('c-tag',{attrs:{"size":"md","variant-color":"red","mx":"1","my":"3","mb":""}},[_vm._v("AI Generate")])],1)],1),_c('c-form-control',{staticClass:"customers__form-control",attrs:{"as":"fieldset"}},[_c('c-form-helper-text',{attrs:{"my":"2"}},[_vm._v(" Select customer category ")]),_c('div',{staticClass:"customers__categories"},[_c('label',{attrs:{"for":"consumer"}},[_c('c-visually-hidden',{attrs:{"name":"customerCategory","id":"consumer","as":"input","type":"radio","value":"consumer"},on:{"change":_vm.onCustomerSelect}}),_c('c-control-box',{attrs:{"border-width":"1px","px":"10px","w":{ base: '100%', lg: 'max-content' },"minWidth":"120px","h":"45px","rounded":"md","type":"radio","borderColor":"#389FB4","fontWeight":"500","color":"#389FB4","_checked":{
                    backgroundColor: '#389FB4',
                    color: 'white',
                  }}},[_vm._v(" Consumer ")])],1),_c('label',{attrs:{"for":"business"}},[_c('c-visually-hidden',{attrs:{"name":"customerCategory","id":"business","as":"input","type":"radio","value":"business"},on:{"change":_vm.onCustomerSelect}}),_c('c-control-box',{attrs:{"border-width":"1px","px":"10px","w":{ base: '100%', lg: 'max-content' },"minWidth":"120px","h":"45px","rounded":"md","type":"radio","borderColor":"#14A27B","fontWeight":"500","color":"#14A27B","_checked":{
                    backgroundColor: '#14A27B',
                    color: 'white',
                  }}},[_vm._v(" Business ")])],1),_c('label',{attrs:{"for":"government"}},[_c('c-visually-hidden',{attrs:{"name":"customerCategory","id":"government","as":"input","type":"radio","value":"government"},on:{"change":_vm.onCustomerSelect}}),_c('c-control-box',{attrs:{"border-width":"1px","px":"10px","w":{ base: '100%', lg: 'max-content' },"minWidth":"120px","h":"45px","rounded":"md","type":"radio","borderColor":"#3762D5","fontWeight":"500","color":"#3762D5","_checked":{
                    backgroundColor: '#3762D5',
                    color: 'white',
                  }}},[_vm._v(" Government ")])],1)])],1),_c('c-form-control',{attrs:{"mb":"2rem"}},[_c('v-select',{staticClass:"vc-select",attrs:{"clearable":false,"searchable":false,"options":_vm.customerTypes,"placeholder":'Type of Customer'},model:{value:(_vm.$v.customerForm.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.customerForm.type, "$model", $$v)},expression:"$v.customerForm.type.$model"}})],1),_c('c-form-control',{attrs:{"mb":"2rem"}},[_c('textarea',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '100%',
                h: '2rem',
                outline: 'none',
                borderRadius: '0.25rem',
                borderWidth: '1px',
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                fontSize: '1rem',
                minHeight: '80px',
              }),expression:"{\n                w: '100%',\n                h: '2rem',\n                outline: 'none',\n                borderRadius: '0.25rem',\n                borderWidth: '1px',\n                paddingTop: '8px',\n                paddingBottom: '8px',\n                paddingLeft: '1rem',\n                paddingRight: '1rem',\n                fontSize: '1rem',\n                minHeight: '80px',\n              }"},{name:"model",rawName:"v-model",value:(_vm.$v.customerForm.description.$model),expression:"$v.customerForm.description.$model"}],attrs:{"placeholder":"Description"},domProps:{"value":(_vm.$v.customerForm.description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.customerForm.description, "$model", $event.target.value)}}})]),_c('c-button',{attrs:{"w":"100%","type":"submit","variant-color":"vc-orange","variant":"solid","disabled":_vm.$v.customerForm.$invalid || _vm.isAddingCustomer}},[(_vm.isAddingCustomer)?_c('c-spinner',{attrs:{"color":"#fff","thickness":"3px"}}):_c('c-text',{attrs:{"color":"#fff"}},[_vm._v("Create ")])],1)],1)]):_vm._e(),_vm._l((_vm.customers),function(customer){return _c('c-tab-panel',{key:customer.name},[_vm._t("default",null,{"customer":customer})],2)})],2)],1),_c('TeamDrawer',{attrs:{"title":"Target Market","isOpen":_vm.isTeamDrawerOpen},on:{"close":_vm.closeTeamDrawer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }