<template>
  <c-box>
    <AppSegment :segments="segments" name="members" v-model="selectedSegment" />
    <transition name="fade" mode="out-in">
      <component :is="selectedSegmentComponent" :disabled="disabled" />
    </transition>
  </c-box>
</template>

<script>
import AppSegment from './../AppSegment';
import BoardCard from '../foundation/BoardCard.vue';
import TeamCard from '../foundation/TeamCard.vue';
import SkillsCard from '../foundation/SkillsCard.vue';

export default {
  props: ['disabled'],
  components: { AppSegment },
  data() {
    return {
      segments: [
        { title: 'Team', value: 'team' },
        { title: 'Advisory Board', value: 'advisory-board' },
        { title: 'Skills and Competence', value: 'skill-competence' },
      ],
      selectedSegment: 'team',
      componentsEnum: {
        team: TeamCard,
        'advisory-board': BoardCard,
        'skill-competence': SkillsCard,
      },
    };
  },
  computed: {
    selectedSegmentComponent() {
      return this.componentsEnum[this.selectedSegment];
    },
  },
};
</script>

<style></style>
