import { render, staticRenderFns } from "./CustomerDetails.vue?vue&type=template&id=a23f16b0&scoped=true"
import script from "./CustomerDetails.vue?vue&type=script&lang=js"
export * from "./CustomerDetails.vue?vue&type=script&lang=js"
import style0 from "./CustomerDetails.vue?vue&type=style&index=0&id=a23f16b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a23f16b0",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CVisuallyHidden: require('@chakra-ui/vue').CVisuallyHidden, CControlBox: require('@chakra-ui/vue').CControlBox, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CGrid: require('@chakra-ui/vue').CGrid})
