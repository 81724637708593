<template>
  <c-box
    p="8"
    my="8"
    w="100%"
    bg="#fff"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16), -2px -1px 6px rgba(186, 186, 186, 0.16)"
  >
    <c-flex align="baseline" mb="4">
      <c-heading fontSize="2xl" fontWeight="700" mr="3"> Milestones </c-heading>
      <c-link
        as="router-link"
        to="/app/timeline"
        display="flex"
        alignItems="center"
      >
        <svg
          v-chakra="{
            w: '6',
            h: '6',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/share-box-fill.svg#share"></use>
        </svg>
      </c-link>
    </c-flex>
    <c-flex mb="5" align="center">
      <c-flex>
        <c-pseudo-box
          as="a"
          px="3"
          py="2"
          mr="3"
          cursor="pointer"
          @click="onScenarioSelect(scenarioIndex)"
          display="flex"
          :key="scenario.id"
          :color="scenario.isActive ? '#fff' : scenario.color"
          :bg="scenario.isActive ? scenario.color : 'transparent'"
          v-for="(scenario, scenarioIndex) in scenariosData"
          fontWeight="500"
          fontSize="xs"
          borderRadius="2px"
        >
          {{ scenario.title }}
        </c-pseudo-box>
      </c-flex>
      <c-link
        v-if="selectedScenario !== 'ALL'"
        @click="clearScenarioFilter"
        color="red.400"
        fontSize="xs"
        ml="3"
      >
        Clear filter
      </c-link>
    </c-flex>
    <c-flex align="center">
      <!-- <svg
        fill="#ef5323"
        v-chakra="{
          width: '16px',
          height: '16px',
          mr: 8,
        }"
      >
        <use href="@/assets/icons/chevron-left.svg#chevron"></use>
      </svg> -->
      <c-flex flex="1" flexWrap="wrap">
        <c-pseudo-box
          borderColor="vc-orange.400"
          borderWidth="1px"
          p="3"
          borderRadius="4px"
          w="200px"
          position="relative"
          alignSelf="center"
          v-for="milestone in displayedMilestones"
          :key="milestone.id"
          mr="8"
          mb="8"
        >
          <c-flex justify="space-between" mb="2">
            <c-heading fontSize="10px" fontWeight="500">
              {{
                milestone.calender_category
                  ? milestone.calender_category.title
                  : ''
              }}
            </c-heading>
            <svg
              fill="#ef5323"
              v-chakra="{
                width: '14px',
                height: '14px',
                transform: 'unset !important',
              }"
            >
              <use href="@/assets/icons/dots-horizontal.svg#dots"></use>
            </svg>
          </c-flex>
          <c-popover trigger="hover" placement="top-end">
            <c-popover-trigger>
              <c-heading
                maxWidth="100%"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                fontSize="sm"
                fontWeight="600"
              >
                {{ milestone.title }}
              </c-heading>
            </c-popover-trigger>
            <c-popover-content>
               <c-popover-arrow />
              <c-popover-body zIndex="modal">
                <c-text fontSize="sm" overflowWrap="break-word">
                  {{ milestone.title }}
                </c-text>
              </c-popover-body>
            </c-popover-content>
          </c-popover>
          <c-flex justify="space-between" mt="2">
            <c-text color="gray.500" fontSize="10px" fontWeight="500">
              {{ milestone.date | moment('DD, MMMM') }}
            </c-text>
            <c-text
              color="vc-orange.400"
              fontSize="10px"
              display="flex"
              fontWeight="500"
              alignItems="center"
              v-if="milestone.status === 'IN_PROGRESS'"
            >
              In Progress
              <svg
                fill="#ef5323"
                v-chakra="{
                  width: '14px',
                  height: '14px',
                  ml: '2',
                }"
              >
                <use href="@/assets/icons/circle.svg#circle"></use>
              </svg>
            </c-text>
            <c-text
              color="#0FC694"
              fontSize="10px"
              display="flex"
              fontWeight="500"
              alignItems="center"
              v-else
            >
              Acheived
              <svg
                v-chakra="{
                  width: '14px',
                  height: '14px',
                  ml: '2',
                }"
              >
                <use href="@/assets/icons/check-circle.svg#check"></use>
              </svg>
            </c-text>
          </c-flex>
        </c-pseudo-box>
      </c-flex>
      <!-- <svg
        fill="#ef5323"
        v-chakra="{
          width: '16px',
          height: '16px',
          ml: 8,
        }"
      >
        <use href="@/assets/icons/chevron-right.svg#chevron"></use>
      </svg> -->
    </c-flex>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedScenario: 'ALL',
      displayedMilestones: [],
      scenariosData: [],
    };
  },
  computed: {
    ...mapState('timeline', {
      milestones: (state) => state.milestones.slice(0, 10),
      scenarios: (state) => state.scenarios,
    }),
  },
  mounted() {
    this.setScenariosData();
  },
  watch: {
    scenarios: {
      immediate: true,
      handler: function () {
        this.setScenariosData();
      },
    },
    selectedScenario: {
      immediate: true,
      handler: function () {
        if (this.selectedScenario === 'ALL') {
          this.displayedMilestones = this.milestones.filter(
            (milestones) => milestones
          );
        } else {
          this.displayedMilestones = this.milestones.filter(
            (milestones) => milestones.scenarioId === this.selectedScenario
          );
        }
      },
    },
  },
  methods: {
    onScenarioSelect(scenarioIndex) {
      this.scenariosData.forEach((scenario, index) => {
        scenario.isActive = scenarioIndex === index;
      });
      if (scenarioIndex !== null) {
        this.selectedScenario = this.scenariosData[scenarioIndex].id;
      }
    },
    clearScenarioFilter() {
      this.selectedScenario = 'ALL';
      this.onScenarioSelect(null);
    },
    setScenariosData() {
      this.scenariosData = this.scenarios.map((scenario) => ({
        ...scenario,
        isActive: scenario.isActive || false,
      }));
    },
  },
};
</script>

<style></style>
