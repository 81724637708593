<template>
  <c-flex
    :direction="{ base: 'row', xs: 'column' }"
    boxShadow="0px 3px 6px #BABABA29;"
    minHeight="100px"
    borderRadius="8px"
    bg="#fff"
  >
    <c-box
      borderRight="1px solid rgba(112, 112, 112, 0.10)"
      py="12"
      px="7"
      w="100%"
      :minWidth="{ base: '350px' }"
    >
      <c-stack :spacing="4">
        <c-heading fontSize="lg" fontWeight="600">Business Name</c-heading>
        <c-input
          focus-border-color="green.400"
          placeholder="Business name"
          size="lg"
          :value="buinessName"
          :readonly="isInvestorView"
          v-if="!isInvestorView"
          @blur="onInputBlur"
        />
        <c-text fontSize="lg" v-else>
          {{ buinessName }}
        </c-text>
      </c-stack>
      <br />

      <c-stack :spacing="4">
        <c-heading fontSize="lg" fontWeight="600">Business Email</c-heading>
        <c-input
          focus-border-color="green.400"
          placeholder="Business email"
          size="lg"
          :value="buinessEmail"
          :readonly="isInvestorView"
          v-if="!isInvestorView"
          @blur="onInputBlur"
        />
        <c-text fontSize="lg" v-else>
          {{ buinessEmail }}
        </c-text>
      </c-stack>
    </c-box>
    <c-box
      borderRight="1px solid rgba(112, 112, 112, 0.10)"
      px="7"
      minWidth="300px"
      maxWidth="350px"
    >
      <c-stack :spacing="4">
        <c-heading fontSize="lg" fontWeight="600">Business Sectors</c-heading>
        <SectorField
          size="full"
          :disabled="isInvestorView"
          :searchable="true"
          v-model="sectors"
          v-if="!isInvestorView"
        />
        <c-flex wrap="wrap" v-else>
          <c-flex
            h="30px"
            px="2"
            mr="2"
            mb="2"
            bg="gray.200"
            fontSize="xs"
            align="center"
            borderRadius="8px"
            fontWeight="500"
            v-for="sector in sectors"
            :key="sector"
          >
            {{ sector }}
          </c-flex>
        </c-flex>
      </c-stack>
    </c-box>
    <c-box py="12" px="7">
      <c-stack :spacing="2">
        <c-heading fontSize="lg" fontWeight="600">Business Stage</c-heading>
        <BusinessStageSelect />
      </c-stack>
    </c-box>
  </c-flex>
</template>

<script>
import SectorField from '../../../../Onboarding/components/SectorField.vue';
import BusinessStageSelect from './../BusinessStageSelect.vue';

import { updateCompanySectors } from '@/services/company.js';

import { mapState, mapGetters, mapActions } from 'vuex';
import { updateCompany } from '@/services/company';

export default {
  props: ['view'],
  components: { BusinessStageSelect, SectorField },
  data() {
    return {
      options: [
        'Ideation stage',
        'Testing stage',
        'MVP stage',
        'Validation stage',
        'Operational stage',
        'Scaling stage',
        'Fully established stage',
      ],
      buinessName: null,
      buinessEmail: null,
      sectors: [],
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['getSectorIds']),
    isInvestorView() {
      return this.view === 'investor';
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    sectors(val, oldVal) {
      if (oldVal.length) {
        updateCompanySectors({
          data: this.sectors,
          companySectors: this.getSectorIds,
          companyId: this.activeCompany.id,
        }).then((res) => {
          this.setActiveCompany({
            ...this.activeCompany,
            company_sectors: [...res],
          });
        });
      }
    },
  },
  created() {
    this.buinessName = this.activeCompany.name;
    this.buinessEmail = this.activeCompany.contactEmail;
    this.sectors = this.getSectorIds;
  },
  methods: {
    ...mapActions({
      setActiveCompany: 'company/setActiveCompany',
    }),
    async onInputBlur(e) {
      const { value } = e.target;
      if (value === this.activeCompany.name) return;
      if (value === this.activeCompany.contactEmail) return;
      try {
        await updateCompany({
          id: this.activeCompany.id,
          set: { name: value },
        });
      } catch (e) {
        this.buinessName = this.activeCompany.name;
        this.buinessEmail = this.activeCompany.contactEmail;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating business stage, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style></style>
