<template>
  <div class="market-potential">
    <GrowthSectionHeader
      :criteria="criteria"
      :completion="getMarketPotentialLevel"
    />
    <div class="market-potential__tasks">
      <c-stack :spacing="8">
        <c-box>
          <MarketStrategyCard :disabled="isShareView" />
        </c-box>
        <c-box id="market-goals">
          <MarketGoalsCard :disabled="isShareView" />
        </c-box>
        <c-box id="traction">
          <TractionCard :disabled="isShareView" />
        </c-box>
        <c-box>
          <OverviewCard :disabled="isShareView" />
        </c-box>
        <c-box>
          <TAM :disabled="isShareView" />
        </c-box>
        <c-box>
          <SAM :disabled="isShareView" />
        </c-box>
        <c-box>
          <SOM :disabled="isShareView" />
        </c-box>
      </c-stack>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import GrowthSectionHeader from './components/GrowthSectionHeader.vue';
import MarketStrategyCard from './components/market-potential/MarketStrategyCard';
import TractionCard from './components/market-potential/TractionCard.vue';
import MarketGoalsCard from './components/market-potential/MarketGoalsCard.vue';

import TAM from './components/market-potential/TAM.vue';
import SAM from './components/market-potential/SAM.vue';
import SOM from './components/market-potential/SOM.vue';
import OverviewCard from './components/market-potential/OverviewCard.vue';
import scrollToComponent from '@/mixins/scroll-to-component.js';
import pageVisits from '@/mixins/page-visits.js';

export default {
  mixins: [scrollToComponent, pageVisits],
  components: {
    GrowthSectionHeader,
    MarketStrategyCard,
    MarketGoalsCard,
    TractionCard,
    TAM,
    SAM,
    SOM,
    OverviewCard,
  },
  data() {
    return {
      criteria: {
        title: 'Market Potential',
        description: `Market potential provides you and your team a quantitative and qualitative assessment of your market thus identifying your market opportunities. It dives into the size of the market both in volume and in value. The main objective is to show investors that you know your market and that the market is large enough to build a sustainable business. It also gives you the opportunity to identify a clear go-to-market strategy based on your market opportunities.
                    `,
        tasks: [
          { title: 'Go to Market Strategy', value: 'go_to_market_strategy' },
          { title: 'TAM', value: 'tam' },
          { title: 'SAM', value: 'sam' },
          { title: 'SOM', value: 'som' },
        ],
      },
    };
  },
  computed: {
    ...mapState('company', {
      isShareView: (state) => state.isShareView,
    }),
    ...mapGetters('company', ['getMarketPotentialLevel']),
  },
};
</script>

<style lang="scss" scoped>
.market-potential {
  &__tasks {
    @apply px-6 pb-8;
  }
  @screen lg {
    &__tasks {
      @apply px-0;
    }
  }
}
</style>
