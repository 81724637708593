var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"w":"100%","h":"100%","px":"30px","pt":"10px","pb":"80px","position":"relative","bg":"#fff"}},[_c('c-flex',{attrs:{"mb":"20px","direction":"column","justify":"center","align":"center"}},[_c('c-text',{attrs:{"as":"h3","mb":"20px","fontSize":"4xl","fontWeight":"700"}},[_vm._v(" "+_vm._s(_vm.type === 'traction' ? 'Traction' : 'Market Goals')+" ")]),_c('c-box',{attrs:{"h":".5em","w":"8em","bg":`${_vm.presentation.data.color}80`}})],1),(_vm.isEdit)?_c('c-link',{staticClass:"item-selector",attrs:{"textDecoration":"underline","position":"absolute","right":"5","top":"5","fontSize":"xl"},on:{"click":function($event){_vm.isDrawerOpen = true}}},[_vm._v(" Select "+_vm._s(_vm.type === 'traction' ? 'Traction' : 'Market Goals')+" to display ")]):_vm._e(),_c('c-flex',{attrs:{"h":"calc(100% - 70px)","mx":"-30px","px":"20px","position":"relative","backgroundImage":`linear-gradient(
      to bottom ,
      ${_vm.presentation.data.color}20,
      #fff
    )`}},[_vm._l((_vm.selectedTractions),function(traction,ix){return _c('c-box',{key:ix,attrs:{"h":"100%","w":"250px","position":"relative"}},[_c('c-box',{attrs:{"px":"20px","pt":ix % 2 === 0 ? '5px' : '20px',"pb":ix % 2 === 0 ? '10px' : '5px',"position":"absolute","h":"50%","w":"100%","top":ix % 2 === 0 ? '0' : '50%'}},[_c('c-box',{attrs:{"w":"6","mt":"1","h":"6","mr":"5","bg":'#000',"borderRadius":"50%","position":"absolute","top":ix % 2 === 0 ? '96%' : '-4%',"left":"0","zIndex":"4","transform":"translate(-50%)"}}),_c('c-text',{attrs:{"textAlign":"left","mb":"10px","fontSize":"18px","fontWeight":"700"}},[_vm._v(" "+_vm._s(traction.title)+" ")]),_c('c-text',{attrs:{"w":"100px","h":"30px","display":"flex","alignItems":"center","justifyContent":"center","bg":_vm.presentation.data.color,"fontSize":"14px","color":"#fff","fontWeight":"700"}},[_vm._v(" "+_vm._s(traction.status === 'IN_PROGRESS' ? 'In Progress' : 'Achieved')+" ")]),_c('c-box',{attrs:{"mt":"3","textAlign":"left"}},[_c('c-text',{attrs:{"color":"#8A8A8A","fontSize":"14px"}},[_vm._v(" "+_vm._s(_vm.type === 'traction' ? 'Traction' : 'Market goal')+" value ")]),_c('c-text',{attrs:{"fontSize":"18px"}},[_vm._v(" "+_vm._s(traction.milestoneValue)+" "),(traction.metricType === 'percentage')?_c('c-text',[_vm._v("%")]):_vm._e()],1)],1),_c('c-box',{attrs:{"mt":"3","textAlign":"left"}},[_c('c-text',{attrs:{"color":"#8A8A8A","fontSize":"14px"}},[_vm._v("Due Date")]),_c('c-text',{attrs:{"fontSize":"18px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(traction.date,'DD MMM. YYYY'))+" ")])],1)],1)],1)}),_c('c-box',{attrs:{"h":"2px","position":"absolute","top":"51.5%","transform":"translateY(-50%)","left":"0","right":"0","zIndex":"3","bg":'#8a8a8a'}})],2),_c('c-box',{attrs:{"position":"absolute","right":"5","bottom":"5"}},[_c('WaterMark',{attrs:{"direction":'row'}})],1),_c('TextListDrawer',{attrs:{"title":`Select visible ${
      _vm.type === 'traction' ? 'Tractions' : 'Market Goals'
    }`,"isDrawerOpen":_vm.isDrawerOpen,"closeDrawer":_vm.closeDrawer,"items":_vm.filteredTractions,"updateFn":_vm.updateTractionData,"isUpdatingData":_vm.isUpdatingData,"max":6},scopedSlots:_vm._u([{key:"list-item",fn:function({ item }){return [_c('c-text',{attrs:{"as":"span"}},[_vm._v(_vm._s(item.title))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }