<template>
  <c-box>
    <c-modal :is-open="isOpen" :on-close="onClose" :closeOnOverlayClick="false">
      <c-modal-content ref="content" borderRadius="8px">
        <c-modal-header fontSize="xs" color="vc-orange.400">{{
          isAdvisory ? 'Advisory Board' : 'Team'
        }}</c-modal-header>
        <c-modal-close-button />
        <c-modal-body pb="6">
          <c-text mb="4" fontSize="xl" fontWeight="600">
            Invite {{ isAdvisory ? 'Advisory Board ' : 'Team' }} Member
          </c-text>
          <c-stack :spacing="6">
            <form @submit.prevent="onInviteFormSubmit">
              <c-stack :spacing="5">
                <c-input
                  type="email"
                  placeholder="Email Address"
                  v-model="$v.inviteForm.email.$model"
                  :disabled="isEditMode"
                />
                <c-box w="100%" v-if="type !== 'board'">
                  <AppSelect
                    :bordered="true"
                    :size="'large'"
                    :reduce="(position) => position.name"
                    label="name"
                    :placeholder="'Select Position'"
                    :options="positions"
                    :fullWidth="true"
                    :borderColor="'light'"
                    :color="'dark'"
                    v-model="$v.inviteForm.position.$model"
                  />
                </c-box>
                <c-box w="100%">
                  <AppSelect
                    :bordered="true"
                    :size="'large'"
                    :reduce="(accessType) => accessType.value"
                    label="title"
                    :placeholder="'Select Access Type'"
                    :options="accessTypes"
                    :fullWidth="true"
                    :borderColor="'light'"
                    :searchable="false"
                    :color="'dark'"
                    v-model="$v.inviteForm.accessType.$model"
                  />
                </c-box>
                <c-flex justify="flex-end">
                  <c-button
                    type="submit"
                    :disabled="$v.inviteForm.$invalid || isSendingInvite"
                    variant-color="vc-orange"
                  >
                    <c-spinner
                      v-if="isSendingInvite"
                      color="#fff"
                      thickness="3px"
                    />
                    <c-text color="#fff" v-else>
                      {{ isEditMode ? 'Update' : 'Send Invite' }}
                    </c-text>
                  </c-button>
                </c-flex>
              </c-stack>
            </form>
            <!-- <c-box>
              <c-flex mb="4" align="baseline">
                <c-text
                  color="vc-orange.400"
                  fontSize="4xl"
                  fontWeight="300"
                  mr="3"
                  >5</c-text
                >
                <c-text mb="4" fontSize="sm" fontWeight="600">
                  Invites sent
                </c-text>
              </c-flex>
              <c-list>
                <c-list-item>
                  <c-flex align="center" justify="space-between">
                    <c-avatar :src="null" mr="2"></c-avatar>
                    <c-text mr="3">someemail@gmail.com</c-text>
                    <c-link
                      fontSize="xs"
                      color="vc-orange.400"
                      fontWeight="600"
                    >
                      Send Again
                    </c-link>
                  </c-flex>
                </c-list-item>
              </c-list>
            </c-box> -->
          </c-stack>
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, requiredIf, email } from 'vuelidate/lib/validators';

import AppSelect from '@/views/App/components/AppSelect.vue';
import { inviteCompanyMember, updateTeamMember } from '@/services/company';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'team',
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    editingMember: {
      type: Object,
      default: () => ({}),
    },
    onClose: {
      type: Function,
    },
  },
  components: {
    AppSelect,
  },
  validations: {
    inviteForm: {
      email: {
        email,
        required,
      },
      accessType: {
        required,
      },
      position: {
        required: requiredIf(function () {
          return !this.isAdvisory;
        }),
      },
    },
  },
  data() {
    return {
      isSendingInvite: false,
      inviteForm: {
        email: null,
        position: null,
        accessType: null,
      },
      accessTypes: [
        { title: 'Edit Access', value: 'edit' },
        { title: 'View Access', value: 'view' },
        { title: 'Custom Access', value: 'custom' },
      ],
    };
  },
  computed: {
    ...mapGetters('config', ['positions']),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    isAdvisory() {
      return this.type === 'board';
    },
  },
  watch: {
    editingMember() {
      if (this.editingMember && Object.keys(this.editingMember).length) {
        this.inviteForm = {
          email: this.editingMember.user.email,
          accessType: this.editingMember.accessType,
          position: this.editingMember.position,
        };
      }
    },
    isOpen() {
      if (!this.isOpen) {
        this.inviteForm = {
          email: null,
          position: null,
          accessType: null,
        };
      }
    },
  },
  methods: {
    ...mapActions({
      updateMember: 'company/updateMember',
    }),
    onInviteFormSubmit() {
      this.isSendingInvite = true;
      if (this.isEditMode) {
        updateTeamMember({
          id: this.editingMember.id,
          set: {
            position: this.inviteForm.position,
            accessType: this.inviteForm.accessType,
          },
        })
          .then((res) => {
            const updatedMember = {
              ...this.editingMember,
              ...res.data.update_team_by_pk,
            };
            this.updateMember(updatedMember);
            this.isSendingInvite = false;
            this.onClose(true);
          })
          .catch(() => {
            this.isSendingInvite = false;
            this.$toast({
              title: 'Error!!!',
              description: 'Error while updating invite, please try again',
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      } else {
        this.sendInvite();
      }
    },
    sendInvite() {
      const position = this.positions.find(
        (_position) => _position.name === this.inviteForm.position
      );
      const data = {
        ...this.inviteForm,
        department: position ? position.team_department.name : null,
        companyId: this.activeCompany.id,
        companyName: this.activeCompany.name,
        isAdvisory: this.isAdvisory,
      };
      inviteCompanyMember(data)
        .then(() => {
          this.isSendingInvite = false;
          this.onClose(true);
        })
        .catch((e) => {
          this.isSendingInvite = false;
          console.log({ e });
          if (e.response.status === 400) {
            this.$toast({
              title: 'Error!!!',
              description: e.response.data.message,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          }
        });
    },
  },
};
</script>

<style></style>
