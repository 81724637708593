var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-flex',{attrs:{"direction":_vm.direction,"w":"max-content","align":"center"}},[_c('c-box',{attrs:{"maxWidth":"100px","h":"30px"}},[_c('img',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        w: '100%',
        h: '100%',
        objectFit: 'contain',
      }),expression:"{\n        w: '100%',\n        h: '100%',\n        objectFit: 'contain',\n      }"}],attrs:{"src":_vm.activeCompany.logo ||
        'https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/company-logo.webp',"alt":""}})]),_c('c-flex',{attrs:{"mt":_vm.direction == 'column' ? '5px' : ''}},[_c('c-text',{attrs:{"fontSize":"10px","mr":"2"}},[_vm._v("Powered by")]),_c('img',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        w: '80px',
        h: '20px',
        objectFit: 'contain',
      }),expression:"{\n        w: '80px',\n        h: '20px',\n        objectFit: 'contain',\n      }"}],attrs:{"src":"\n          https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/vibrantcreator-logo-orange.png\n        ","alt":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }