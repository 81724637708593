<template>
  <c-box>
    <TaskCard
      :title="'Culture'"
      :description="'Company culture defines the environment in which employees work. Company culture includes a variety of elements, including work environment, company mission, value, ethics, expectations, and goals.'"
      :hasVisibility="true"
      :hasNotification="true"
      :hasImportance="true"
      :hasAssumption="false"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      @update="updateData"
      :accessKey="'foundation_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'foundation_visibilities'"
      :visibilityFn="updateVisibility"
      :taskData="foundationData"
      explanationKey="culture"
      :hasAccess="hasAccess"
      :disabled="disabled"
    >
      <CultureFields
        :fields="foundationData.foundation_items"
        @updateField="onUpdateField"
        :disabled="disabled"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { updateFoundationItem } from '@/services/foundation.js';

import TaskCard from './../TaskCard.vue';
import CultureFields from './CultureFields.vue';

import foundationMixin from '@/mixins/foundation';

export default {
  mixins: [foundationMixin],
  components: {
    TaskCard,
    CultureFields,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      foundationType: 'culture',
      hasMounted: false,
    };
  },
  methods: {
    onUpdateField({ id, description }) {
      updateFoundationItem({ id, set: { description } })
        .then(() => {})
        .catch(() => {
          const itemIndex = this.foundationData.foundation_items.findIndex(
            (_item) => _item.id === id
          );

          this.foundationData.foundation_items[itemIndex].description =
            this.prevData.foundation_items[itemIndex].description;
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating value, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style></style>
