<template>
  <div class="field__card">
    <div class="field__card__header">
      <h5 class="field__card__title">{{ title }}</h5>
      <!-- <c-link
        display="flex"
        alignItems="center"
        fontWeight="500"
        fontSize="14px"
        color="vc-orange.400"
        @click="$refs.textField.onEditClick()"
        v-if="!disabled"
      >
        Edit
        <svg
          v-chakra="{
            w: '15px',
            h: '15px',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/edit-fill.svg#edit"></use>
        </svg>
      </c-link> -->
    </div>
    <c-text
      maxWidth="75%"
      marginBottom="8px"
      marginTop="8px"
      fontSize="sm"
      color="#798892"
    >
      {{ description }}
    </c-text>
    <c-text as="span" color="#ef5323" fontSize="14px" v-if="saved"
      ><b>Saved successfully 👍</b></c-text
    >
    <EditableText
      :type="type"
      :title="title"
      v-model="textValue"
      @updateDescription="updateDescription"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import EditableText from '../EditableText.vue';

export default {
  props: ['title', 'description', 'disabled', 'value', 'fieldKey', 'type'],
  components: {
    EditableText,
  },
  data() {
    return {
      textValue: null,
      saved: false,
    };
  },
  created() {
    if (this.value) {
      this.textValue = this.value;
    }
  },
  watch: {
    value() {
      if (this.value !== this.textValue) {
        this.textValue = this.value;
      }
    },
  },
  methods: {
    updateDescription(newAIcontent) {
      this.textValue = newAIcontent;

      this.$emit('updateField', { key: this.fieldKey, value: newAIcontent });

      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  &__card {
    @apply p-5 mb-8;
    width: 100%;
    min-height: 200px;
    box-shadow: 0px 3px 6px rgba(#bababa, 0.16);
    &__header {
      @apply flex justify-between;
    }
    &__title {
      @apply font-semibold;
    }
  }
}
</style>
