<template>
  <c-box>
    <TaskCard
      :title="'Skills and Competence you need'"
      :description="'What skills and competency do you need to achieve your vision and purpose? '"
      :hasVisibility="true"
      :hasNotification="true"
      :hasImportance="true"
      :hasAssumption="false"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :disabled="disabled"
      :hasMounted="hasMounted"
      :hasComments="true"
      :taskData="cardData"
      :accessKey="'company_skill_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'company_skill_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateMetaData"
      :isUpdatingMetaData="isUpdatingMetaData"
      explanationKey="skills"
      :hasAccess="hasAccess"
    >
      <ContentLoader
        v-if="isFetchingData"
        height="150"
        viewBox="0 0 200 150"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="30" />
        <rect x="110" y="0" rx="3" ry="3" width="100" height="30" />
        <rect x="220" y="0" rx="3" ry="3" width="100" height="30" />
      </ContentLoader>
      <SkillsSelect
        v-else
        :value="skillsData.company_skill_values"
        @updateSkills="onSkillsUpdate"
        @addSkill="onSkillAdd"
        :disabled="disabled"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import cloneDeep from 'lodash.clonedeep';
import { ContentLoader } from 'vue-content-loader';
import { generateID } from '@/helpers/data';

import {
  createCompanySkill,
  getCompanySkill,
  updateCompanySkill,
  addCompanySkillValues,
  removeCompanySkillValues,
  addCompanySkillsAccess,
  removeCompanySkillsAccess,
  updateSkillsVisibility,
} from '@/services/foundation.js';

import { debounce } from '@/helpers/utils.js';

import TaskCard from './../TaskCard.vue';
import SkillsSelect from './SkillsSelect.vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SkillsSelect,
    TaskCard,
    ContentLoader,
  },
  data() {
    return {
      skillsData: {},
      prevData: {},
      isFetchingData: false,
      isUpdatingMetaData: false,
      hasAccess: true,
      hasMounted: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
    cardData() {
      return { ...this.skillsData, type: 'skills_competence' };
    },
  },
  created() {
    this.getCompanySkillsData();
  },
  methods: {
    ...mapActions({
      setCompanySkills: 'company/setActiveCompanySkills',
    }),
    getCompanySkillsData() {
      this.isFetchingData = true;
      getCompanySkill(this.activeCompany.id)
        .then((res) => {
          this.isFetchingData = false;
          if (res.data.company_skill.length) {
            this.skillsData = cloneDeep(res.data.company_skill[0]);
            this.setSkillsInStore();
            this.prevData = cloneDeep(res.data.company_skill[0]);
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.createSkillSeedData();
          } else {
            this.hasAccess = false;
          }
        })
        .finally(() => {
          this.hasMounted = true;
        });
    },
    createSkillSeedData() {
      this.isFetchingData = true;
      const data = {
        companyId: this.activeCompany.id,
      };
      createCompanySkill(data).then((res) => {
        this.skillsData = cloneDeep(res.data.insert_company_skill_one);
        this.prevData = cloneDeep(res.data.insert_company_skill_one);
        this.isFetchingData = false;
      });
    },
    updateMetaData({ key, value }) {
      const set = { [key]: value };
      updateCompanySkill({
        id: this.skillsData.id,
        set,
      })
        .then(() => {
          this.skillsData[key] = value;
        })
        .catch(() => {
          this.showError();
          this.skillsData[key] = this.prevData[key];
        });
    },
    onSkillAdd() {
      //
    },
    onSkillsUpdate: debounce(async function (skillsIds) {
      const newSkills = skillsIds.filter(
        (id) =>
          !this.skillsData.company_skill_values.find(
            (value) => value.skillBySkill.id == id
          )
      );
      const removedSkills = this.skillsData.company_skill_values.filter(
        (value) => !skillsIds.includes(value.skillBySkill.id)
      );
      await this.addNewSkills(newSkills);
      this.removeSkills(removedSkills);
    }, 1500),
    addNewSkills(skillsIds) {
      if (!skillsIds.length) return;
      const data = skillsIds.map((id) => {
        return {
          skill: id,
          companySkill: this.skillsData.id,
        };
      });
      return addCompanySkillValues(data)
        .then((res) => {
          this.skillsData.company_skill_values.push(
            ...cloneDeep(res.data.insert_company_skill_values.returning)
          );
          this.prevData.company_skill_values.push(
            ...cloneDeep(res.data.insert_company_skill_values.returning)
          );
          this.setSkillsInStore();
        })
        .catch((e) => {
          this.showError(e);
          this.skillsData.company_skill_values = cloneDeep(
            this.prevData.company_skill_values
          );
        });
    },
    removeSkills(values) {
      if (!values.length) return;
      const ids = values.map((value) => value.id);
      removeCompanySkillValues(ids)
        .then(() => {
          const companySkills = [...this.skillsData.company_skill_values];
          ids.forEach((id) => {
            const index = companySkills.findIndex(
              (companySkill) => companySkill.id == id
            );
            companySkills.splice(index, 1);
          });
          this.skillsData.company_skill_values = cloneDeep(companySkills);
          this.prevData.company_skill_values = cloneDeep(companySkills);
          this.setSkillsInStore();
        })
        .catch((e) => {
          this.showError(e);
          this.skillsData.company_skill_values = cloneDeep(
            this.prevData.company_skill_values
          );
        });
    },
    showError(e) {
      console.log({ e });
      this.$toast({
        title: 'An error occurred.',
        description: `Error while updating Skills data, please try again.`,
        status: 'error',
        position: 'top',
        duration: 3000,
      });
    },
    setSkillsInStore() {
      this.setCompanySkills(this.skillsData.company_skill_values);
    },
    updateAccess(user) {
      const accessIndex = this.skillsData.company_skill_assignments.findIndex(
        (access) => access.user.id == user.id
      );
      if (accessIndex === -1) {
        const tempId = generateID(4);
        this.skillsData.company_skill_assignments.push({
          id: tempId,
          user,
        });
        this.addAccess(user, tempId);
      } else {
        this.removeAccess(accessIndex);
      }
    },
    async addAccess(user, tempId) {
      const data = {
        companySkillId: this.skillsData.id,
        userId: user.id,
      };
      const accessIndex = this.skillsData.company_skill_assignments.findIndex(
        (access) => access.id == tempId
      );
      try {
        const res = await addCompanySkillsAccess(data);
        this.skillsData.company_skill_assignments[accessIndex] =
          res.data.insert_company_skill_assignment_one;
        this.prevData.company_skill_assignments = cloneDeep(
          this.skillsData.company_skill_assignments
        );
      } catch (e) {
        this.skillsData.company_skill_assignments = cloneDeep(
          this.prevData.company_skill_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while assigning user, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async removeAccess(accessIndex) {
      const access = this.skillsData.company_skill_assignments.splice(
        accessIndex,
        1
      )[0];
      try {
        await removeCompanySkillsAccess(access.id);
        this.prevData.company_skill_assignments = cloneDeep(
          this.skillsData.company_skill_assignments
        );
      } catch (e) {
        this.skillsData.company_skill_assignments = cloneDeep(
          this.prevData.company_skill_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing member access, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateVisibility(users) {
      this.isUpdatingMetaData = true;
      const newUsers = users.filter(
        (user) =>
          !this.skillsData.company_skill_visibilities.find(
            (visibility) => visibility.userId === user.id
          )
      );
      const removedVisibilities = this.skillsData.company_skill_visibilities.filter(
        (visibility) => !users.find((user) => visibility.userId === user.id)
      );
      const data = {
        addVisibility: newUsers.map((user) => ({
          userId: user.id,
          companySkillId: this.skillsData.id,
        })),
        removedVisibilities: removedVisibilities.map(
          (visibility) => visibility.id
        ),
      };
      const visibilityData = cloneDeep(
        this.skillsData.company_skill_visibilities
      );
      try {
        const res = await updateSkillsVisibility(data);
        res.data.delete_company_skill_visibility.returning.forEach(
          (deletedVisibility) => {
            const deletedVisibilityIndex = visibilityData.find(
              (visibility) => visibility.id === deletedVisibility.id
            );
            visibilityData.splice(deletedVisibilityIndex, 1);
          }
        );
        visibilityData.push(
          ...res.data.insert_company_skill_visibility.returning
        );
        this.skillsData.company_skill_visibilities = cloneDeep(visibilityData);
        this.prevData.company_skill_visibilities = cloneDeep(visibilityData);
        this.isUpdatingMetaData = false;
      } catch (e) {
        this.isUpdatingMetaData = false;
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
