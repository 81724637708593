import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";
// import { getItems } from "@/helpers/utils.js";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      solutionItems: null,
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedItems() {
      return this.solutionItems;
    },
  },
  created() {
    this.setSolutionItems();
  },
  watch: {
    presentation() {
      this.setSolutionItems();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setSolutionItems() {
      const solutionData = this.presentationData.business_models.find(
        (data) => data.type === "overall_solution"
      );

      if (!this.presentation.data.slidesData?.solution) {
        this.solutionItems = solutionData.description;
      } else {
        const { solution } = this.presentation.data.slidesData;
        console.log("let me solution", solution);
        this.solutionItems = solutionData.description;
      }
    },
    async updateSolutionData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                solution: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected Solutions, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
