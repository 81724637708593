<template>
  <c-box py="3">
    <c-heading
      mt="3"
      as="h1"
      fontSize="3xl"
      v-if="$route.query.page !== 'presentation'"
    >
      {{ activeCompany.name }}
    </c-heading>
    <component :is="pageComponent" :activeView="activeView" />
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import Foundation from '../GrowthFramework/Foundation.vue';
import BusinessModel from '../GrowthFramework/BusinessModel.vue';
import Competition from '../GrowthFramework/Competition.vue';
import MarketPotential from '../GrowthFramework/MarketPotential.vue';
import RiskAssessment from '../GrowthFramework/RiskAssessment.vue';
import OverviewVue from '../GrowthFramework/Overview.vue';
import Presentation from '../Presentation/EditPresentation.vue';
// import sjcl from 'sjcl';
// import { getCompetitors } from '@/services/competition';

export default {
  data() {
    return {
      pages: {
        foundation: Foundation,
        overview: OverviewVue,
        competition: Competition,
        'business-model': BusinessModel,
        'market-potential': MarketPotential,
        'risk-assessment': RiskAssessment,
        presentation: Presentation,
      },
      activeView: '',
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    pageComponent() {
      return this.pages[this.$route.query.page];
    },
  },
  created() {
    this.activeView = this.$route.query.page;
  },
};
</script>

<style></style>
