<template>
  <c-box
    w="100%"
    h="100%"
    position="relative"
    p="14px"
    :bg="presentation.data.business_model.backgroundColor"
  >
    <c-box position="absolute" right="5" bottom="5">
      <WaterMark :direction="'row'" />
    </c-box>
    <c-link
      right="5"
      top="-5"
      fontSize="xl"
      position="absolute"
      textDecoration="underline"
      class="item-selector"
      v-if="isEdit"
      @click="isDrawerOpen = true"
      >Select revenue to display</c-link
    >
    <c-flex mb="10" direction="column" justify="center" align="center">
      <c-text as="h3" mb="5" fontSize="4xl" fontWeight="700"
        >Business Model</c-text
      >
      <c-box h=".5em" w="8em" :bg="`${presentation.data.color}80`"></c-box>
    </c-flex>
    <c-flex mt="10" flexWrap="wrap" position="relative" justify="center">
      <c-grid h="100%" w="100%" template-columns="repeat(2, 1fr)" gap="8">
        <template v-for="(revenue, idx) in selectedRevenues">
          <c-flex
            h="14em"
            w="100%"
            :key="revenue.id"
            :direction="idx % 2 === 0 ? 'row' : 'row-reverse'"
          >
            <c-box
              d="flex"
              w="75%"
              p="10"
              :borderColor="'#ddd'"
              borderWidth=".1em"
            >
              <c-box mr="20px">
                <c-text
                  :color="presentation.data.color"
                  fontWeight="600"
                  fontSize="1.2em"
                  mb="18px"
                  textAlign="left"
                  >{{ labelRenderFn(revenue.type) | capitalize }}</c-text
                >
                <c-flex align="center" fontWeight="600">
                  <c-text fontSize="1.5em" color="#000" mr="20px">
                    {{ revenue.value }}
                    <c-text as="span" fontSize="small">
                      {{ revenue.currency }}
                    </c-text>
                  </c-text>
                  <c-text fontSize="1.2em" color="#8A8A8A">Daily</c-text>
                </c-flex>
              </c-box>
              <c-box textAlign="left">
                <c-text
                  v-for="customer in revenue.revenue_customers"
                  :key="customer.id"
                  whiteSpace="nowrap"
                  fontWeight="600"
                  fontSize="1em"
                  color="#000"
                  mb="4px"
                  >{{ customer.customer.name | capitalize }}</c-text
                >
              </c-box>
            </c-box>
            <c-box
              w="25%"
              h="100%"
              bgImage="url(https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/slide1-bg.webp)"
              bgRepeat="no-repeat"
              bgSize="cover"
            ></c-box>
          </c-flex>
        </template>
      </c-grid>
    </c-flex>
    <ListDrawer
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :updateFn="updateRevenuesData"
      :isUpdatingData="isUpdatingData"
      :items="revenues"
      :itemLabel="'type'"
      :labelRenderFn="labelRenderFn"
      :maximumItemsVisible="4"
    />
  </c-box>
</template>

<script>
import WaterMark from '@/components/WaterMark.vue';
import { mapMutations } from 'vuex';
import { EventBus } from '@/eventBus.js';
import businessModelMixin from '@/mixins/data/businessModel.js';
import ListDrawer from '@/components/DataComponents/ListDrawer.vue';
import { updatePresentation } from '@/services/presentation';

import { capitalize } from '@/helpers/filters.js';

export default {
  mixins: [businessModelMixin],
  components: { WaterMark, ListDrawer },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    capitalize,
  },
  mounted() {
    EventBus.$on('business_model', async (configForm) => {
      this.updatePresentation({
        data: {
          ...this.presentation.data,
          business_model: configForm,
        },
      });

      try {
        const response = await updatePresentation({
          id: this.presentation.id,
          append: { data: { business_model: configForm } },
        });
        const pre = response.data.update_presentation_by_pk;
        this.updatePresentation({
          data: {
            ...this.presentation.data,
            business_model: pre.data.business_model,
          },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, business_model: configForm },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
  },
};
</script>

<style></style>
