<template>
  <c-box>
    <!--     :isLocked="!getCurrentPlan.access.belief" -->
    <TaskCard
      :title="'Belief'"
      :description="'Your belief statement/ purpose'"
      :hasVisibility="true"
      :hasNotification="true"
      :hasImportance="true"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="foundationData"
      :accessKey="'foundation_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'foundation_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :hasAccess="hasAccess"
      explanationKey="belief"
    >
      <c-text as="span" color="#ef5323" fontSize="14px" v-if="saved"
        ><b>Saved successfully 👍</b></c-text
      >
      <EditableText
        v-model="foundationData.description"
        @blur="onTextBlur"
        @updateDescription="updateDescription"
        :disabled="disabled"
        v-if="!isFetchingData"
        title="Belief"
        type="belief"
      />
      <ContentLoader v-else viewBox="0 0 250 130" primaryColor="#ddd">
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

import TaskCard from './../TaskCard';
import EditableText from './../EditableText';

import foundationMixin from '@/mixins/foundation';
import { mapGetters } from 'vuex';

export default {
  mixins: [foundationMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    ContentLoader,
    EditableText,
  },
  data() {
    return {
      foundationType: 'belief',
      hasMounted: false,
      saved: false,
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    onTextBlur() {
      this.updateData({
        key: 'description',
        value: this.foundationData.description,
      });
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },
    updateDescription(newAIresults) {
      this.foundationData.description = newAIresults;
      this.onTextBlur();
    },
  },
};
</script>

<style></style>
