import { render, staticRenderFns } from "./HowItWorks.vue?vue&type=template&id=47c826b0"
import script from "./HowItWorks.vue?vue&type=script&lang=js"
export * from "./HowItWorks.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox})
