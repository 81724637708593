<template>
  <c-box py="3">
    <c-pseudo-box
      v-if="milestone"
      borderColor="vc-orange.400"
      borderWidth="1px"
      p="3"
      borderRadius="4px"
      w="200px"
      position="relative"
      alignSelf="center"
      mr="8"
      mb="8"
    >
      <c-flex justify="space-between" mb="2">
        <c-heading fontSize="10px" fontWeight="500">
          {{
            milestone.calender_category ? milestone.calender_category.title : ''
          }}
        </c-heading>
        <c-popover placement="bottom">
          <c-popover-trigger>
            <c-link>
              <svg
                fill="#ef5323"
                v-chakra="{
                  width: '14px',
                  height: '14px',
                }"
              >
                <use href="@/assets/icons/dots-horizontal.svg#dots" />
              </svg>
            </c-link>
          </c-popover-trigger>
          <c-popover-content w="max-content">
            <c-popover-body p="0">
              <c-list>
                <c-list-item>
                  <c-pseudo-box
                    cursor="pointer"
                    as="a"
                    display="flex"
                    px="2"
                    py="3"
                    @click="onEditMilestoneClick"
                    >Edit Milestone</c-pseudo-box
                  >
                </c-list-item>
                <c-list-item>
                  <c-pseudo-box
                    cursor="pointer"
                    as="a"
                    display="flex"
                    px="2"
                    py="3"
                    @click="onRemoveMilestoneClick"
                    >Remove Milestone</c-pseudo-box
                  >
                </c-list-item>
              </c-list>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
      </c-flex>
      <c-popover trigger="hover" placement="top-end">
        <c-popover-trigger>
          <c-heading
            maxWidth="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="sm"
            fontWeight="600"
          >
            {{ milestone.title }}
          </c-heading>
        </c-popover-trigger>
        <c-popover-content>
          <c-popover-arrow />
          <c-popover-body zIndex="modal">
            <c-text fontSize="sm" overflowWrap="break-word">
              {{ milestone.title }}
            </c-text>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
      <c-flex justify="space-between" mt="2">
        <c-text color="gray.500" fontSize="10px" fontWeight="500">
          {{ milestone.date | moment('DD, MMMM') }}
        </c-text>
        <c-link
          textDecoration="none"
          color="vc-orange.400"
          @click="toggleStatus"
        >
          <c-text
            color="vc-orange.400"
            fontSize="10px"
            display="flex"
            fontWeight="500"
            alignItems="center"
            v-if="milestone.status === 'IN_PROGRESS'"
          >
            In Progress
            <svg
              fill="#ef5323"
              v-chakra="{
                width: '14px',
                height: '14px',
                ml: '2',
              }"
            >
              <use href="@/assets/icons/circle.svg#circle"></use>
            </svg>
          </c-text>
          <c-text
            color="#0FC694"
            fontSize="10px"
            display="flex"
            fontWeight="500"
            alignItems="center"
            v-else
          >
            Acheived
            <svg
              v-chakra="{
                width: '14px',
                height: '14px',
                ml: '2',
              }"
            >
              <use href="@/assets/icons/check-circle.svg#check"></use>
            </svg>
          </c-text>
        </c-link>
      </c-flex>
    </c-pseudo-box>
    <c-modal
      :is-open="isMilestoneModalOpen"
      :on-close="closeMilestoneModal"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            {{ isEditMode ? 'Edit' : 'Add' }}
            <c-text textTransform="capitalize" ml="1"> Milestone </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <form @submit.prevent="onMilestoneFormSubmit">
          <c-modal-body>
            <c-box p="3">
              <c-box mb="5">
                <c-input
                  size="lg"
                  placeholder="Milestone Name"
                  v-model="$v.milestoneForm.title.$model"
                ></c-input>
              </c-box>
              <c-box mb="5">
                <AppDatePicker
                  format="YYYY-MM-DD"
                  type="date"
                  v-model="$v.milestoneForm.date.$model"
                >
                  <template v-slot:input>
                    <c-input
                      size="lg"
                      placeholder="Select Date"
                      v-model="milestoneDateDisplay"
                    ></c-input>
                  </template>
                </AppDatePicker>
              </c-box>
              <c-box mb="5">
                <AppSelect
                  label="title"
                  :reduce="(scenario) => scenario.id"
                  :options="scenarios"
                  :reset="true"
                  :fullWidth="true"
                  :bordered="true"
                  indicator-fill="orange"
                  color="dark"
                  :borderColor="'light'"
                  size="large"
                  v-model="$v.milestoneForm.scenarioId.$model"
                  placeholder="Add Scenario (optional)"
                />
              </c-box>
              <c-box mb="5">
                <CategorySelect v-model="$v.milestoneForm.categoryId.$model" />
                <!-- <AppSelect
                  label="title"
                  :reduce="(category) => category.id"
                  :options="categories"
                  :reset="true"
                  :fullWidth="true"
                  :bordered="true"
                  indicator-fill="orange"
                  color="dark"
                  v-model="$v.milestoneForm.categoryId.$model"
                  :borderColor="'light'"
                  size="large"
                  placeholder="Add Category"
                /> -->
              </c-box>
              <c-box>
                <textarea
                  v-model="$v.milestoneForm.description.$model"
                  placeholder="Description"
                  v-chakra="{
                    w: '100%',
                    h: '2rem',
                    outline: 'none',
                    borderRadius: '0.25rem',
                    borderWidth: '1px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    fontSize: '1rem',
                    minHeight: '80px',
                  }"
                ></textarea>
              </c-box>
            </c-box>
          </c-modal-body>
          <c-modal-footer>
            <c-button
              :disabled="$v.milestoneForm.$invalid || isAddingMilestone"
              variant-color="vc-orange"
              type="submit"
              mr="3"
            >
              <c-text color="#fff">
                {{ isEditMode ? 'Edit' : 'Create' }}
              </c-text>
              <c-spinner
                v-if="isAddingMilestone"
                ml="2"
                color="#fff"
                thickness="2px"
              />
            </c-button>
          </c-modal-footer>
        </form>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
    <c-alert-dialog
      :is-open="isStatusModalOpen && statusModalType === 'ACHIEVED_MODAL'"
      :on-close="closeStatusModal"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Achieved Milestone
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          Set Date Milestone was acheived
          <AppDatePicker
            format="YYYY-MM-DD"
            type="date"
            v-model="achievedMilestoneDate"
          >
            <template v-slot:input>
              <c-input
                size="lg"
                readonly
                placeholder="Select Date"
                :value="achievedMilestoneDateDisplay"
              ></c-input>
            </template>
          </AppDatePicker>
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeStatusModal">
            Cancel
          </c-button>
          <c-button
            :disabled="!achievedMilestoneDate"
            variantColor="vue"
            @click="saveMilestoneAsAchieved"
            ml="3"
          >
            Save
            <c-spinner
              ml="2"
              v-if="isMarkingAsAchieved"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
    <c-alert-dialog
      :is-open="isStatusModalOpen && statusModalType === 'PROGRESS_MODAL'"
      :on-close="closeStatusModal"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Milestone In Progress
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          <c-text
            >Are you sure you want to set milestone as in progress?</c-text
          >
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeStatusModal">
            Cancel
          </c-button>
          <c-button
            variantColor="vue"
            @click="saveMilestoneAsInProgress"
            ml="3"
          >
            Save
            <c-spinner
              ml="2"
              v-if="isMarkingAsAchieved"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import AppSelect from './../../../components/AppSelect.vue';
import AppDatePicker from '@/views/App/components/AppDatePicker.vue';
import CategorySelect from '@/views/App/Timeline/components/CategorySelect.vue';

import { addMilestone, updateMilestone } from '@/services/timeline';

export default {
  props: {
    milestone: {
      type: Object,
    },
  },
  components: {
    AppSelect,
    AppDatePicker,
    CategorySelect,
  },
  validations: {
    milestoneForm: {
      title: {
        required,
      },
      date: {
        required,
      },
      description: {},
      categoryId: {},
      scenarioId: {},
    },
  },
  data() {
    return {
      isEditMode: false,
      isMilestoneModalOpen: false,
      isAddingMilestone: false,
      milestoneForm: {
        title: null,
        date: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      },
      statusModalType: null,
      isStatusModalOpen: false,
      achievedMilestoneDate: null,
      isMarkingAsAchieved: false,
    };
  },
  computed: {
    ...mapState('timeline', {
      categories: (state) => state.categories,
      scenarios: (state) => state.scenarios,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    milestoneDateDisplay() {
      return this.milestoneForm.date
        ? this.$moment(this.milestoneForm.date).format('YYYY-MM-DD')
        : null;
    },
    achievedMilestoneDateDisplay() {
      return this.achievedMilestoneDate
        ? this.$moment(this.achievedMilestoneDate).format('YYYY-MM-DD')
        : null;
    },
  },
  methods: {
    closeMilestoneModal() {
      this.isMilestoneModalOpen = false;
    },
    clearMilestoneForm() {
      this.milestoneForm = {
        title: null,
        date: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      };
    },
    async onMilestoneFormSubmit() {
      this.isAddingMilestone = true;
      if (this.isEditMode) {
        this.updateMilestone();
      } else {
        this.addMilestone();
      }
    },
    toggleStatus() {
      if (this.milestone.status === 'IN_PROGRESS') {
        this.isStatusModalOpen = true;
        this.statusModalType = 'ACHIEVED_MODAL';
      } else {
        this.isStatusModalOpen = true;
        this.statusModalType = 'PROGRESS_MODAL';
      }
    },
    closeStatusModal() {
      this.isStatusModalOpen = false;
      this.statusModalType = null;
    },
    async saveMilestoneAsAchieved() {
      this.isMarkingAsAchieved = true;
      try {
        const res = await updateMilestone({
          id: this.milestone.id,
          set: {
            dateAchieved: this.achievedMilestoneDate,
            status: 'ACHIEVED',
          },
        });
        this.$emit('milestoneUpdated', { ...res.data.update_milestone_by_pk });
        this.isMarkingAsAchieved = false;
        this.closeStatusModal();
      } catch (e) {
        console.log({ e });
      }
    },
    async saveMilestoneAsInProgress() {
      this.isMarkingAsAchieved = true;
      try {
        const res = await updateMilestone({
          id: this.milestone.id,
          set: {
            dateAchieved: null,
            status: 'IN_PROGRESS',
          },
        });
        this.$emit('milestoneUpdated', { ...res.data.update_milestone_by_pk });
        this.isMarkingAsAchieved = false;
        this.closeStatusModal();
      } catch (e) {
        console.log({ e });
      }
    },
    async addMilestone() {
      const { title, date, description, categoryId, scenarioId } =
        this.milestoneForm;
      const data = {
        title,
        categoryId,
        description,
        scenarioId,
        date: date.toISOString(),
        companyId: this.activeCompany.id,
      };
      try {
        const res = await addMilestone(data);
        this.$emit('milestoneAdded', { ...res.data.insert_milestone_one });
        // this.clearMilestoneForm();
        // this.isAddingMilestone = false;
        // this.closeMilestoneModal();
      } catch (e) {
        this.isAddingMilestone = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while creating milestone, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateMilestone() {
      const { title, date, description, categoryId, scenarioId } =
        this.milestoneForm;

      try {
        const res = await updateMilestone({
          id: this.milestone.id,
          set: {
            title,
            date,
            description,
            categoryId,
            scenarioId,
          },
        });

        this.milestone = { ...res.data.update_milestone_by_pk };
        this.clearItemForm();
        this.isAddingMilestone = false;
        this.isEditMode = false;
        this.closeMilestoneModal();
      } catch (e) {
        this.isAddingMilestone = false;
        this.isEditMode = false;
        this.closeMilestoneModal();
      }
    },
    onRemoveMilestoneClick() {
      this.$emit('removeMilestone');
    },
    onEditMilestoneClick() {
      this.milestoneForm = {
        title: this.milestone.title,
        date: this.milestone.date,
        description: this.milestone.description,
        categoryId: this.milestone.categoryId,
        scenarioId: this.milestone.scenarioId,
      };
      this.isEditMode = true;
      this.isMilestoneModalOpen = true;
    },
  },
};
</script>

<style></style>
