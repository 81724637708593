import { mapState, mapMutations } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

export default {
  inject: ['updatePresentation'],
  data() {
    return {
      howItWorksData: {
        description: '',
        link: '',
      },
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
  },
  created() {
    this.setHowItWorks();
  },
  watch: {
    presentation() {
      this.setHowItWorks();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: 'presentation/updatePresentation',
      updatePresentationData: 'presentation/updatePresentationData',
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setHowItWorks() {
      if (this.presentation.data.slidesData?.howItWorksData) {
        this.howItWorksData = cloneDeep(
          this.presentation.data.slidesData?.howItWorksData
        );
      }
    },
    async onHowItWorksInputBlur(key) {
      const oldValue = this.presentationData?.howItWorksData;
      console.log(this.howItWorksData);
      try {
        this.updatePresentationData({
          ...this.presentationData,
          howItWorksData: this.howItWorksData,
        });
        this.updateHowItWorksData();
      } catch (e) {
        console.log(e);
        this.updatePresentationData({
          ...this.presentationData,
          howItWorksData: oldValue,
        });
        this.howItWorksData = oldValue;
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while updating ${key} details, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateHowItWorksData() {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                howItWorksData: this.howItWorksData,
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while updating how it works, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
