<template>
  <div class="assumption">
    <div
      @click="isPremiseModalOpen = true"
      class="assumption__select__option"
      v-chakra="{
        color: selectedAssumption.color,
        backgroundColor: selectedAssumption.colorRgba,
      }"
    >
      <svg v-chakra="{ fill: selectedAssumption.color }">
        <use href="@/assets/icons/shield-star-fill.svg#shield-star"></use>
      </svg>
      <span>
        {{ selectedAssumption.name }}
      </span>
    </div>

    <c-modal
      :is-open="isPremiseModalOpen"
      :on-close="closePremiseModal"
      :closeOnOverlayClick="false"
      isCentered
    >
      <c-modal-content
        ref="content"
        w="25em"
        px="8"
        py="5"
        maxWidth="100%"
        borderRadius="8px"
        overflow="hidden"
      >
        <c-modal-close-button />
        <div v-if="form.step === 0">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >Is this
            <span
              class="font-bold"
              v-chakra="{
                color: '#ef7923',
              }"
              >{{ title }}</span
            >
            assumption verified?</c-text
          >
          <c-flex justify-content="center">
            <c-radio-group variant-color="green" v-model="form.selectedStatus">
              <c-radio
                :value="status"
                v-for="(status, ix) in statusOptions"
                :key="ix"
                >{{ status }}</c-radio
              >
            </c-radio-group>
          </c-flex>
        </div>
        <div v-if="form.step === 1">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >The premise of this assumption is verified through</c-text
          >
          <c-flex justify-content="center">
            <c-checkbox-group
              variant-color="green"
              v-model="form.selectedPremise"
            >
              <c-checkbox
                mx="2"
                :value="premise"
                v-for="(premise, ix) in premiseOptions"
                :key="ix"
                >{{ premise }}</c-checkbox
              >
            </c-checkbox-group>
          </c-flex>
        </div>
        <div v-if="form.step === 2">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >Congratulation! This is a very important milestone!</c-text
          >
          <c-text align="center">🎉🎉🎈😄</c-text>
        </div>
        <c-box v-if="form.step === 3">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >Maximize your time efficiently by trying to verify your assumption!
            Maybe you should consider Pivoting your business?</c-text
          >
          <c-text align="center" color="#ef7923"
            >Did you know that research shows that you increase your success
            rate the more you actively pivot your business!</c-text
          >
          <c-flex direction="column" justify-content="center">
            <c-button
              v-if="isSolution || isProblem || isValuePrep || isTargetMarket"
              variant-color="vc-orange"
              my="4"
              @click="() => onValidateAssumption('in-progress')"
            >
              Verify and validate your assumption
            </c-button>
            <!-- @click="() => onFinish('in-progress')" -->
            <c-button variant-color="blue" @click="() => onFinish('failed')">
              I need time to pivot my business
            </c-button>
          </c-flex>
        </c-box>
        <c-flex
          justify-content="center"
          pt="5"
          v-if="form.step !== 3 && form.step !== 2"
        >
          <c-button
            variant-color="vc-orange"
            mr="4"
            @click="onBack"
            v-if="form.step > 0"
          >
            Back
          </c-button>

          <c-button
            variant-color="blue"
            @click="() => onDone(true)"
            :disabled="isDisabled"
            size="sm"
          >
            Done
          </c-button>
        </c-flex>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-modal
      :is-open="isValidateModalOpen"
      :on-close="closeValidateModal"
      :closeOnOverlayClick="false"
      isCentered
    >
      <c-modal-content
        ref="content"
        w="25em"
        px="5"
        py="5"
        maxWidth="100%"
        borderRadius="8px"
        overflow="hidden"
      >
        <c-modal-close-button />
        <c-text mb="5" pt="5" class="font-bold"
          >Validate your
          <span
            class="font-bold"
            v-chakra="{
              color: '#ef7923',
            }"
            >{{ title }}</span
          >
          with the Vibrant Community and receive feedback from others.</c-text
        >
        <EditableText
          v-model="aiResults"
          @updateDescription="updateDescription"
          :title="title"
          :type="title"
          :disabled="true"
        />

        <c-button
          variant-color="blue"
          @click="postValidate"
          :isLoading="isLoadingPost"
        >
          Post to community for validation
        </c-button>
      </c-modal-content>
    </c-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EditableText from './EditableText';
import { createPost } from '@/services/insight';
import {
  getBusinessModelByType,
  getCustomers,
} from '@/services/business-model.js';

export default {
  props: ['disabled', 'value', 'title', 'descriptionData', 'type'],
  components: {
    EditableText,
  },
  data() {
    return {
      businessSectionData: '',
      isLoadingPost: false,
      isValidateModalOpen: false,
      assumption: 'unverified',
      isPremiseModalOpen: false,
      form: {
        // stepValue: 'No',
        step: 0,
        selectedPremise: [],
        selectedStatus: '',
      },
      assumptionLevels: [
        {
          name: 'Unverified assumption',
          value: 'unverified',
          color: '#ef7923',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        {
          name: 'Assumption Verified',
          value: 'verified',
          color: '#1a86d0',
          colorRgba: 'rgba(26, 134, 208, 0.1)',
        },
        {
          name: 'Assumption Failed',
          value: 'failed',
          color: '#ee2f1f',
          colorRgba: 'rgba(238, 48, 31, 0.1)',
        },
      ],
      assumptionSteps: [
        {
          name: 'Verify Assumption',
          value: 'unverified',
          color: '#ef7923',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        {
          name: 'Verified Assumption',
          value: 'verified',
          color: '#1a86d0',
          colorRgba: 'rgba(26, 134, 208, 0.1)',
        },
        {
          name: 'Assumption in progress! \nPivot?',
          value: 'in-progress',
          color: '#27B18F',
          colorRgba: 'rgba(39, 177, 143, 0.1)',
        },
        {
          name: 'I need to pivot?',
          value: 'failed',
          color: '#EF3E2F',
          colorRgba: 'rgba(239, 62, 47, 0.1)',
        },
      ],
      premiseOptions: [
        'Academic literature',
        'Q&A with customer base',
        'Market observations',
        'Conversation with customers',
        'Survey from customers',
        'Research reports',
        'Testing',
        'Feedback from customers',
        'Other',
      ],
      statusOptions: ['No', 'Not yet! I need time', 'Yes'],
      description: '',
      aiResults: '',
    };
  },
  created() {
    if (this.value) {
      this.assumption = this.value;
    }
    this.getBusinessModelSection();
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    isSolution() {
      return this.title === 'Overall Solution';
    },
    isProblem() {
      return this.title === 'Overall Problem';
    },
    isValuePrep() {
      return this.title === 'Overall Value Proposition';
    },
    isTargetMarket() {
      return this.title === 'Target Market';
    },
    selectedAssumption() {
      return this.assumptionSteps.find(
        (step) => step.value === this.assumption
      );
    },

    isDisabled() {
      let result = true;
      if (this.form.step === 0) {
        if (this.form.selectedStatus) result = false;
      } else if (this.form.step === 1) {
        if (this.form.selectedPremise.length > 0) result = false;
      }

      return result;
    },
  },
  watch: {
    value() {
      this.assumption = this.value;
    },
  },
  methods: {
    getBusinessModelSection() {
      const businessSection = this.isSolution
        ? 'overall_problem'
        : 'overall_solution';

      getBusinessModelByType({
        type: businessSection,
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          if (res.data.business_model.length) {
            this.businessSectionData = res.data.business_model[0].description;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateDescription(newAIresults) {
      this.description = newAIresults;
    },
    async postValidate() {
      const payload = {
        isValidation: true,
        validation_field: this.title,
        isCompanyPost: false,
        postType: 'PUBLIC',
        content: this.aiResults,
      };

      this.isLoadingPost = true;

      try {
        await createPost(payload);
        this.isLoadingPost = false;
        this.closePremiseModal();
        this.closeValidateModal();

        this.$toast({
          title: 'You have successfully added a post',
          description: `A post have been successfully added`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      } catch (e) {
        this.isLoadingPost = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while creating post, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    setIsPremiseModal(value) {
      this.isPremiseModalOpen = value;
    },
    closePremiseModal() {
      this.isPremiseModalOpen = false;
      if (this.form.step !== 2) {
        this.$emit('input', {
          assumption: this.assumption,
          assumptionData: this.form.selectedPremise,
        });
      }
      this.form.step = 0;
    },
    closeValidateModal() {
      this.isValidateModalOpen = false;
    },

    onBack() {
      this.form.step = 0;
    },
    onFinish(assumption) {
      this.assumption = assumption;
      this.closePremiseModal();
    },
    async onValidateAssumption(assumption) {
      // const question = `We have created <solution> to customers problem <problem> ${this.title} which is ${this.descriptionData}.`;

      if (
        this.isSolution &&
        this.descriptionData !== '<ul><li><br></li></ul>'
      ) {
        this.aiResults = `We've created <blockquote style="padding:1.2em 20px; background:#EDEDED;border-left:8px solid #d6621a;color: #555555;font-style:italic;"> ${this.descriptionData}</blockquote> <br/> to address the critical issue that <blockquote style="padding:1.2em 20px; background:#EDEDED;border-left:8px solid #d6621a;color: #555555;font-style:italic;"> ${this.businessSectionData} </blockquote> <br/> Now we need your input! Does this solution meet your needs? Share your honest feedback and help us refine it. Your voice is key to making real impact`;
      } else if (
        this.isSolution &&
        this.descriptionData === '<ul><li><br></li></ul>'
      ) {
        this.$toast({
          title: 'Error',
          description: `Sorry, there has to be an Overall Solution`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });

        return;
      }

      // problem
      if (this.isProblem && this.descriptionData !== '<ul><li><br></li></ul>') {
        this.aiResults = `Our Problem is <blockquote style="padding:1.2em 20px; background:#EDEDED;border-left:8px solid #d6621a;color: #555555;font-style:italic;">${this.descriptionData}</blockquote> <br/> Is this something you can relate to? <br/> We’d love to hear your thoughts and personal experiences. Your insights could spark an important conversation! Share your perspective below.`;
      } else if (
        this.isProblem &&
        this.descriptionData === '<ul><li><br></li></ul>'
      ) {
        this.$toast({
          title: 'Error',
          description: `Sorry, there has to be an Overall Problem`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });

        return;
      }

      if (
        this.isValuePrep &&
        this.descriptionData !== '<ul><li><br></li></ul>'
      ) {
        getBusinessModelByType({
          type: 'overall_problem',
          companyId: this.activeCompany.id,
        })
          .then((res) => {
            if (res.data.business_model.length) {
              const problem = res.data.business_model[0].description;

              this.aiResults = `We've developed <blockquote style="padding:1.2em 20px;background:#EDEDED;border-left:8px solid #d6621a;color: #555555;font-style:italic;">${this.businessSectionData}</blockquote> <br/> to address the problem: 
        <blockquote style="padding:1.2em 20px;background:#EDEDED;border-left:8px solid #d6621a;color: #555555;font-style:italic;">${problem}</blockquote> <br/> Now we need your feedback! Does this solution offer or provide you this value:
          <blockquote style="padding:1.2em 20px;background:#EDEDED;border-left:8px solid #d6621a;color: #555555;font-style:italic;">${this.descriptionData}</blockquote>
        <br/>Share your honest feedback and help us understand if we are making a difference.`;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (this.isTargetMarket) {
        getCustomers(this.activeCompany.id).then((res) => {
          console.log(res.data.customer);
          // this.aiResults = res.data.customer;
        });
      }

      this.isValidateModalOpen = true;

      this.assumption = assumption;
      this.closePremiseModal();
    },
    onDone(complete) {
      if (this.form.step === 0) {
        if (
          this.form.selectedStatus === 'No' ||
          this.form.selectedStatus === 'Not yet! I need time'
        ) {
          this.form.step = 3;
        } else if (this.form.selectedStatus === 'Yes') {
          this.form.step = 1;
        }
      } else if (this.form.step === 1 && complete) {
        this.assumption = 'verified';
        this.$emit('input', {
          assumption: this.assumption,
          assumptionData: this.form.selectedPremise,
        });
        this.form.step = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assumption {
  &__select {
    min-width: 250px;

    &__option,
    &__item {
      @apply h-full w-full font-medium flex items-center justify-center px-4 text-xs cursor-pointer;
      border-radius: 8px;
      padding: 0 10px;
      height: 35px;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    &__item {
      @apply justify-start text-sm;
    }
  }
}
</style>
