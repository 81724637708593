var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"overflowX":"scroll","w":"100%","maxWidth":"100%"}},[_c('table',{staticClass:"grid__table"},[(_vm.hasHeader)?_c('thead',{staticClass:"grid__table__header"},[_c('tr',_vm._l((_vm.columns
            .filter((elm) => !elm.disabled)
            .map(({ title }) => title)),function(title){return _c('th',{key:title,staticClass:"grid__table__header__cell"},[_vm._v(" "+_vm._s(title)+" ")])}),0)]):_vm._e(),_c('tbody',{staticClass:"grid__table__body"},[_vm._t("default")],2),(!_vm.disabled)?_c('tfoot',{staticClass:"grid__table__footer"},[_c('tr',[_c('td',{staticClass:"grid__table__footer__cell",attrs:{"colspan":"100"}},[_c('c-link',{attrs:{"display":"flex","alignItems":"center","color":"#798892","fontWeight":"500","fontSize":"sm","width":"max-content","mb":"3"},on:{"click":function($event){return _vm.onAddRow()}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                width: '12px',
                height: '12px',
                mr: '2',
              }),expression:"{\n                width: '12px',\n                height: '12px',\n                mr: '2',\n              }"}],attrs:{"fill":"#798892"}},[_c('use',{attrs:{"href":require("@/assets/icons/icon-add.svg") + "#add"}})]),_c('span',[_vm._v(" "+_vm._s(_vm.addRowLabel))])]),(!_vm.hideAIGenerate)?_c('c-link',{on:{"click":_vm.openAiDrawer}},[_c('c-tag',{attrs:{"size":"md","variant-color":"red","mx":"2"}},[_vm._v("AI Generate")])],1):_vm._e(),_c('br')],1)])]):_vm._e()]),(_vm.isTeamDrawerOpen)?_c('TeamDrawer',{attrs:{"title":_vm.title,"type":_vm.type,"context":``,"isOpen":_vm.isTeamDrawerOpen},on:{"close":_vm.closeTeamDrawer}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }