<template>
  <c-box id="revenue">
    <TaskCard
      title="Revenue"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasMounted="hasMounted"
      :hasAssumption="false"
      :disabled="disabled"
      id="revenue"
      :taskData="businessModelData"
      :accessKey="'business_model_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'business_model_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="revenue"
    >
      <template v-slot:description>
        <span> How do you generate revenue? </span>
        <br />
        <span> How does your customer prefer to pay? </span>
        <br />
        <span>
          What percentage of your total revenue does each revenue stream
          represent?
        </span>
      </template>
      <RevenueModelFields
        v-if="!isFetchingData"
        :revenueData="businessModelData"
        :disabled="disabled"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import TaskCard from './../TaskCard';
import RevenueModelFields from './RevenueModelFields';

import businessModelMixin from '@/mixins/business-model.js';

export default {
  mixins: [businessModelMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    RevenueModelFields,
  },
  data() {
    return {
      businessModelType: 'revenue',
      hasMounted: false,
    };
  },
};
</script>

<style></style>
