<template>
  <c-box>
    <c-box
      p="8"
      my="8"
      w="100%"
      bg="#fff"
      borderRadius="8px"
      boxShadow="0 3px 6px rgba(186, 186, 186, 0.16), -2px -1px 6px rgba(186, 186, 186, 0.16)"
      v-if="lastTour"
    >
      <c-text fontSize="lg" fontWeight="500" color="vc-orange.400" mb="2">
        Stage {{ lastTourGroup ? lastTourGroup.stage : lastTour.stage }}
      </c-text>
      <c-flex>
        <c-text fontWeight="600" fontSize="2xl" mr="3">
          {{ lastTourGroup ? lastTourGroup.title : lastTour.title }}
        </c-text>
        <ProgressBar
          :value="
            stageProgress(lastTourGroup ? lastTourGroup.stage : lastTour.stage)
              .totalPercentage
          "
          :valueOutside="true"
          :height="'10px'"
          v-chakra="{
            w: '200px',
          }"
        />
      </c-flex>
      <template v-if="lastTour.type === 'PAGE'">
        <StageStep
          :step="lastTour"
          @goToStep="onGoToStep"
          @goToPage="onGoToPage($event, lastTourGroup)"
          :stepIndex="lastTourIndex"
        />
      </template>
      <template v-else>
        <c-grid
          w="100%"
          :template-columns="{ base: '1fr', lg: 'repeat(2, 1fr)' }"
          gap="16"
        >
          <c-box v-for="(step, stepIndex) in lastTourSteps" :key="step.id">
            <StageStep
              :step="step"
              @goToStep="onGoToStep({ parentTour: lastTour, stepIndex })"
              :stepIndex="stepIndex"
              :progress="step.progress"
            />
          </c-box>
        </c-grid>
      </template>
    </c-box>
  </c-box>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProgressBar from './../../components/ProgressBar';
import StageStep from '@/views/App/GrowthFramework/components/home/StageStep.vue';

import { growthFrameworkTourData } from '@/helpers/data.js';

export default {
  components: {
    ProgressBar,
    StageStep,
  },
  data() {
    return {
      tourData: growthFrameworkTourData,
    };
  },
  computed: {
    ...mapState('tour', {
      lastTour: (state) => state.lastTour,
      lastTourGroup: (state) => state.lastTourGroup,
    }),
    ...mapGetters('company', [
      'companyLevel',
      'companyLevelPercentage',
      'ideaStageLevel',
      'marketPlanLevel',
    ]),
    lastTourIndex() {
      return this.lastTourGroup
        ? this.lastTourGroup.tour_steps.findIndex(
            (step) => step.id === this.lastTour.id
          )
        : undefined;
    },
    lastTourSteps() {
      return this.lastTour.tour_steps
        .map((step) => {
          return {
            ...step,
            progress: this.stageProgress(this.lastTour.stage)[step.key],
          };
        })
        .filter((step) => step.progress !== 'DONE')
        .sort((a, b) => a.position - b.position);
    },
  },
  methods: {
    ...mapActions({
      startTour: 'tour/startTour',
    }),
    stageProgress(stage) {
      switch (stage) {
        case 1:
          return this.ideaStageLevel;
        case 2:
          return { totalPercentage: this.companyLevelPercentage };
        case 3:
          return this.marketPlanLevel;
      }
    },
    onGoToStep({ parentTour, stepIndex = 0 }) {
      this.$router.push('/app/growth-framework').then(() => {
        this.startTour({ tour: parentTour, startIndex: stepIndex });
      });
    },
    onGoToPage(tourPage, tourGroup) {
      this.$router.push('/app/growth-framework').then(() => {
        this.startTour({
          tour: tourPage.page,
          startIndex: 0,
          tourGroup,
        });
      });
    },
  },
};
</script>

<style></style>
