<template>
  <div
    class="problem_cont"
    :style="{
      background: presentation.data.solution.backgroundColor,
    }"
  >
    <div
      :style="{
        background:
          'linear-gradient(to bottom ,' + presentation.data.color + '80, #fff)',
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }"
    >
      <c-flex direction="column" align="center">
        <c-text mt="12" ml="26" color="#000" fontWeight="700" fontSize="5xl"
          >Solution</c-text
        >
        <c-box
          ml="auto"
          mt="5"
          h=".5em"
          w="8em"
          :bg="`${presentation.data.color}80`"
        ></c-box>
      </c-flex>

      <div
        class="draggable-image"
        :style="{
          backgroundImage: 'url(' + imageUrl + ')',
          backgroundSize: 'cover',
          width: '30em',
          height: '20em',
          left: '0',
          top: imagePosition.top + 'px',
          left: imagePosition.left + 'px',
        }"
        @mousedown="startImageDrag"
        @mousemove="handleImageDrag"
        @mouseup="stopDrag"
        @mouseleave="stopDrag"
      ></div>
    </div>

    <c-flex
      :backgroundImage="`linear-gradient(
        to bottom ,
        ${presentation.data.color}80,
        #fff
      )`"
      backgroundSize="cover"
      w="15%"
      ml="auto"
      h="5%"
      justify="center"
    ></c-flex>

    <div class="content_div">
      <div
        class="draggable-div"
        :style="{
          top: position.top + 'px',
          left: position.left + 'px',
          fontSize: presentation.data.solution.fontSize + 'px',
        }"
        @mousedown="startDrag"
        @mousemove="handleDrag"
        @mouseup="stopDrag"
        @mouseleave="stopDrag"
        v-html="selectedItems"
      ></div>

      <c-box ml="auto" mt="auto">
        <WaterMark :direction="'row'" />
      </c-box>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { EventBus } from '@/eventBus.js';
import WaterMark from '@/components/WaterMark.vue';
import { updatePresentation } from '@/services/presentation';

import solutionMixin from '@/mixins/data/solution.js';

export default {
  mixins: [solutionMixin],
  components: { WaterMark },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      startPosition: { x: 0, y: 0 },
      position: { top: 300, left: 850 },
      startImagePosition: { x: 0, y: 0 },
      imagePosition: { top: 300, left: 0 },
      imageUrl:
        'https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/prob_sol_default_1.png',
      content: "''",
    };
  },
  mounted() {
    EventBus.$on('solution', async (configForm) => {
      this.updatePresentation({
        data: {
          ...this.presentation.data,
          solution: configForm,
        },
      });

      try {
        const response = await updatePresentation({
          id: this.presentation.id,
          append: { data: { solution: configForm } },
        });

        const pre = response.data.update_presentation_by_pk;
        this.updatePresentation({
          data: { ...this.presentation.data, solution: pre.data.solution },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, solution: configForm },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
    startDrag(event) {
      this.isDragging = true;
      this.startPosition.x = event.clientX;
      this.startPosition.y = event.clientY;
    },
    handleDrag(event) {
      if (this.isDragging) {
        const offsetX = event.clientX - this.startPosition.x;
        const offsetY = event.clientY - this.startPosition.y;
        this.position.left += offsetX;
        this.position.top += offsetY;
        this.startPosition.x = event.clientX;
        this.startPosition.y = event.clientY;
      }
    },
    startImageDrag(event) {
      this.isDragging = true;
      this.startImagePosition.x = event.clientX;
      this.startImagePosition.y = event.clientY;
    },
    handleImageDrag(event) {
      if (this.isDragging) {
        const offsetX = event.clientX - this.startImagePosition.x;
        const offsetY = event.clientY - this.startImagePosition.y;
        this.imagePosition.left += offsetX;
        this.imagePosition.top += offsetY;
        this.startImagePosition.x = event.clientX;
        this.startImagePosition.y = event.clientY;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
  },
};
</script>

<style>
.problem_cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.content_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.draggable-div {
  position: absolute;
  cursor: move;
}
.draggable-image {
  position: absolute;
  cursor: move;
}
</style>
