<template>
  <c-box my="8" class="customers" v-chakra w="100%" maxW="100%">
    <c-tabs
      v-chakra
      w="100%"
      maxW="100%"
      @change.self="onTabIndexChange"
      variant="enclosed-colored"
      class="customers__tabs"
      :key="customers.length"
    >
      <c-tab-list
        class="customers__tabs__list"
        v-chakra
        w="100%"
        pt="5"
        maxW="100%"
        overflowX="scroll"
      >
        <c-tab v-if="showAdd && !disabled" class="customers__tabs__add">
          <svg width="30" height="30" fill="#ef5323">
            <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
          </svg>
        </c-tab>

        <c-tab
          class="customers__tabs__list__item"
          :class="[
            `customers__tabs__list__item--${customer.category.toLowerCase()}`,
          ]"
          v-for="(customer, ix) in customers"
          :key="customer.name"
        >
          <span class="customers__tabs__list__item__category">{{
            customer.category
          }}</span>

          <svg
            v-if="showAdd"
            class="customers__tabs__list__item__delete"
            @click.stop="onDeleteCustomer(customer.id)"
            v-chakra="{
              w: '4',
              h: '4',
              cursor: 'pointer',
              fill: 'vc-orange.400',
              position: 'absolute',
              top: '-5px',
              right: '-2px',
              display: 'none',
              zIndex: '999999',
            }"
          >
            <use href="@/assets/icons/icon-close-fill.svg#close"></use>
          </svg>
          <c-editable
            v-if="showAdd"
            :isDisabled="tabIndex - 1 !== ix"
            :default-value="customer.name"
            :value="customer.name"
            :selectAllOnFocus="false"
            @change="onFieldChange($event, ix, 'name')"
          >
            <c-editable-preview />
            <c-editable-input @click.stop />
          </c-editable>
          <c-text v-else> {{ customer.name }} </c-text>
        </c-tab>
      </c-tab-list>

      <c-tab-panels
        class="customers__tabs__panels"
        :class="[
          `customers__tabs__panels--${
            showAdd && !disabled
              ? 'default'
              : customers[tabIndex]
              ? customers[tabIndex].category.toLowerCase()
              : 'default'
          }`,
        ]"
      >
        <c-tab-panel v-if="showAdd && !disabled">
          <form class="customers__form" @submit.prevent="onCustomerFormSubmit">
            <c-heading as="h5" fontSize="sm" color="#798892">
              Add Target Market Details
            </c-heading>
            <c-form-control class="customers__form-control">
              <c-input
                type="text"
                size="lg"
                placeholder="Name of Target Market"
                v-model="$v.customerForm.name.$model"
              />
              <c-form-helper-text
                color="vc-orange.400"
                fontSize="xs"
                v-if="
                  $v.customerForm.name.$dirty && !$v.customerForm.name.required
                "
              >
                Customer's name is required
              </c-form-helper-text>

              <c-link my="3" @click="isTeamDrawerOpen = !isTeamDrawerOpen">
                <c-tag size="md" variant-color="red" mx="1" my="3" mb
                  >AI Generate</c-tag
                >
              </c-link>
            </c-form-control>
            <c-form-control as="fieldset" class="customers__form-control">
              <c-form-helper-text my="2">
                Select customer category
              </c-form-helper-text>
              <div class="customers__categories">
                <label for="consumer">
                  <c-visually-hidden
                    name="customerCategory"
                    id="consumer"
                    as="input"
                    type="radio"
                    value="consumer"
                    @change="onCustomerSelect"
                  />
                  <c-control-box
                    border-width="1px"
                    px="10px"
                    :w="{ base: '100%', lg: 'max-content' }"
                    minWidth="120px"
                    h="45px"
                    rounded="md"
                    type="radio"
                    borderColor="#389FB4"
                    fontWeight="500"
                    color="#389FB4"
                    :_checked="{
                      backgroundColor: '#389FB4',
                      color: 'white',
                    }"
                  >
                    Consumer
                  </c-control-box>
                </label>
                <label for="business">
                  <c-visually-hidden
                    name="customerCategory"
                    id="business"
                    as="input"
                    type="radio"
                    value="business"
                    @change="onCustomerSelect"
                  />
                  <c-control-box
                    border-width="1px"
                    px="10px"
                    :w="{ base: '100%', lg: 'max-content' }"
                    minWidth="120px"
                    h="45px"
                    rounded="md"
                    type="radio"
                    borderColor="#14A27B"
                    fontWeight="500"
                    color="#14A27B"
                    :_checked="{
                      backgroundColor: '#14A27B',
                      color: 'white',
                    }"
                  >
                    Business
                  </c-control-box>
                </label>
                <label for="government">
                  <c-visually-hidden
                    name="customerCategory"
                    id="government"
                    as="input"
                    type="radio"
                    value="government"
                    @change="onCustomerSelect"
                  />
                  <c-control-box
                    border-width="1px"
                    px="10px"
                    :w="{ base: '100%', lg: 'max-content' }"
                    minWidth="120px"
                    h="45px"
                    rounded="md"
                    type="radio"
                    borderColor="#3762D5"
                    fontWeight="500"
                    color="#3762D5"
                    :_checked="{
                      backgroundColor: '#3762D5',
                      color: 'white',
                    }"
                  >
                    Government
                  </c-control-box>
                </label>
              </div>
            </c-form-control>
            <c-form-control mb="2rem">
              <v-select
                :clearable="false"
                :searchable="false"
                class="vc-select"
                v-model="$v.customerForm.type.$model"
                :options="customerTypes"
                :placeholder="'Type of Customer'"
              ></v-select>
            </c-form-control>
            <c-form-control mb="2rem">
              <textarea
                placeholder="Description"
                v-chakra="{
                  w: '100%',
                  h: '2rem',
                  outline: 'none',
                  borderRadius: '0.25rem',
                  borderWidth: '1px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  fontSize: '1rem',
                  minHeight: '80px',
                }"
                v-model="$v.customerForm.description.$model"
              ></textarea>
            </c-form-control>
            <c-button
              w="100%"
              type="submit"
              variant-color="vc-orange"
              variant="solid"
              :disabled="$v.customerForm.$invalid || isAddingCustomer"
            >
              <c-spinner v-if="isAddingCustomer" color="#fff" thickness="3px" />
              <c-text v-else color="#fff">Create </c-text>
            </c-button>
          </form>
        </c-tab-panel>
        <c-tab-panel v-for="customer in customers" :key="customer.name">
          <slot v-bind:customer="customer" />
        </c-tab-panel>
      </c-tab-panels>
    </c-tabs>

    <TeamDrawer
      title="Target Market"
      :isOpen="isTeamDrawerOpen"
      @close="closeTeamDrawer"
    />
  </c-box>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import { addCustomer, updateCustomer } from '@/services/business-model';

import TeamDrawer from '../../../components/OpenAiDrawer.vue';

export default {
  components: {
    TeamDrawer,
  },
  props: {
    customerSegment: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    customerForm: {
      name: {
        required,
      },
      category: {
        required,
      },
      type: {
        required,
      },
      description: {
        required,
      },
    },
  },
  data() {
    return {
      isTeamDrawerOpen: false,
      tabIndex: 0,
      customerTypes: ['Primary', 'Secondary', 'Tertiary'],
      customerForm: {
        name: '',
        category: '',
        type: null,
        description: '',
      },
      isAddingCustomer: false,
      isEditingTitle: false,
    };
  },
  computed: {
    ...mapState('company', {
      customers: (state) => state.customers,
    }),
  },
  methods: {
    ...mapActions({
      addNewCustomer: 'company/addCustomer',
      resetCompanyState: 'company/resetCompanyState',
    }),
    ...mapMutations({
      updateCustomer: 'company/updateCustomer',
      deleteCustomer: 'company/deleteCustomer',
      setTargetMargetCustomer: 'company/setTargetMargetCustomer',
    }),
    closeTeamDrawer(results) {
      if (!results) {
        this.isTeamDrawerOpen = false;
        return;
      }

      //  check if resuts is an object
      if (typeof results === 'object') {
        this.customerForm.name = results.label;
        this.customerForm.description = results.description;
      } else {
        this.customerForm.name = results;
      }

      this.isTeamDrawerOpen = false;
    },
    onTabIndexChange(index) {
      this.tabIndex = index;
      const arrIndex = index - 1;
      this.setTargetMargetCustomer(this.customers[arrIndex]);
    },
    onFieldChange(e, index, field) {
      const customer = { ...this.customers[index], [field]: e.target.value };
      this.updateCustomer({ index, customer });

      updateCustomer({
        id: customer.id,
        set: { [field]: customer[field] },
      })
        .then(() => {})
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while editing customer, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    onCustomerSelect(e) {
      if (e.target.checked) {
        this.$v.customerForm.category.$model = e.target.value;
      }
    },
    onCustomerFormSubmit() {
      this.isAddingCustomer = true;
      const customer = {
        ...this.customerForm,
        businessModelId: this.customerSegment.id,
      };
      addCustomer(customer)
        .then((res) => {
          this.isAddingCustomer = false;
          this.addNewCustomer(res.data.insert_customer_one);
          Object.keys(this.customerForm).forEach((field) => {
            this.customerForm[field] = null;
          });
          this.$nextTick(() => {
            this.$v.$reset();
          });
        })
        .catch(() => {
          this.isAddingCustomer = false;
          this.$toast({
            title: 'An error occurred.',
            description: `Error while adding a customer, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    onDeleteCustomer(id) {
      const customerIndex = this.customers.findIndex(
        (customer) => customer.id === id
      );

      const customer = this.customers[customerIndex];

      this.deleteCustomer(customerIndex);

      updateCustomer({ id, set: { isDeleted: true } })
        .then(() => {})
        .catch(() => {
          this.updateCustomer({
            index: customerIndex,
            customer,
            deleteCount: 0,
          });
          this.$toast({
            title: 'An error occurred.',
            description: `Error while deleting a customer, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    protect(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.customers {
  &__form {
    @apply my-8 px-5;
    width: 100%;
    &-control {
      @apply my-5;
      label {
        @apply relative mr-4;
      }
    }
  }
  &__categories {
    @apply flex flex-wrap;
    label {
      @apply mb-3 w-full;
    }
  }

  ::v-deep {
    .customers {
      &__tabs {
        &__add {
          @apply border-none;
          background-color: transparent;
        }
        &__list {
          @apply overflow-x-scroll;
          overflow-x: scroll !important;
          &__item {
            @apply mx-1 border-none px-6 mb-0 font-semibold relative text-base;
            min-height: 35px;
            min-width: 120px;
            background-color: #fff;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            box-shadow: 0 0px 6px rgba(#000000, 0.12);
            &--government {
              color: #3762d5;
              &[aria-selected='true'] {
                color: #fff;
                background-color: #3762d5;
              }
              span.customers__tabs__list__item__category {
                color: #3762d5;
              }
            }
            &--consumer {
              color: #389fb4;
              &[aria-selected='true'] {
                color: #fff;
                background-color: #389fb4;
              }
              span.customers__tabs__list__item__category {
                color: #389fb4;
              }
            }
            &--business {
              color: #14a27b;
              &[aria-selected='true'] {
                color: #fff;
                background-color: #14a27b;
              }
              span.customers__tabs__list__item__category {
                color: #14a27b;
              }
            }
            span.customers__tabs__list__item__category {
              @apply hidden absolute uppercase p-1;
              font-size: 9px;
              border-radius: 4px;
              top: -8px;
              left: 50%;
              transform: translateX(-50%);
              background-color: #fff;
              box-shadow: 0 -2px 6px rgba(#000000, 0.12);
            }
            &[aria-selected='true'] {
              box-shadow: none;
              span {
                @apply block;
              }
            }

            &:hover {
              .customers__tabs__list__item__delete {
                display: block;
              }
            }
          }
        }
        &__panels {
          border: 1px solid $color-grey;
          border-radius: 0 8px 8px 8px;
          &--government {
            border-color: #3762d5;
          }
          &--consumer {
            border-color: #389fb4;
          }
          &--business {
            border-color: #14a27b;
          }
        }
      }
      &__form {
        .vs__dropdown-toggle {
          border-color: #e2e8f0;
        }
        .vs__search {
          height: initial;
          color: #acacac;
          padding-left: 10px;
        }
      }
    }
  }
  @screen lg {
    &__form {
      width: 50%;
    }
    &__categories {
      @apply flex-nowrap;
      label {
        @apply mb-0;
        width: unset;
      }
    }
    ::v-deep {
      .customers {
        &__tabs {
          &__list {
            overflow-x: unset;
            &__item {
              min-height: 55px;
            }
          }
        }
      }
    }
  }
}
</style>
