import { render, staticRenderFns } from "./Milestones.vue?vue&type=template&id=748fb05c"
import script from "./Milestones.vue?vue&type=script&lang=js"
export * from "./Milestones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CLink: require('@chakra-ui/vue').CLink, CFlex: require('@chakra-ui/vue').CFlex, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPopoverArrow: require('@chakra-ui/vue').CPopoverArrow, CText: require('@chakra-ui/vue').CText, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CBox: require('@chakra-ui/vue').CBox})
