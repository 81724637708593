<template>
  <c-text :fontFamily="`'${fontFamily}', ${fontCategory}`">
    {{ fontFamily }}
  </c-text>
</template>

<script>
import WebFont from 'webfontloader';

export default {
  props: {
    fontFamily: {
      type: String,
    },
    fontCategory: {
      type: String,
    },
  },
  created() {
    WebFont.load({
      google: {
        families: [this.fontFamily],
      },
      classes: false,
    });
  },
};
</script>

<style></style>
