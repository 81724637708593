<template>
  <div class="skills">
    <div class="skills__top">
      <div class="skills__count">
        <span>{{ skills.length }}</span>
        Skills
      </div>
      <v-select
        :clearable="false"
        :searchable="false"
        class="vc-select skills__select"
        v-model="selectedSkillType"
        :options="skillTypes"
        placeholder="select"
      >
      </v-select>
    </div>
    <p class="skills__top__description">
      Drag skills into each {{ type === 'team' ? 'team' : 'advisory' }} member
    </p>
    <div class="skills__list">
      <Skill
        v-chakra="{
          mb: '4',
        }"
        v-for="skill in skills"
        :key="skill.id"
        :skill="skill"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Skill from './Skill';

export default {
  props: ['type'],
  components: {
    Skill,
  },
  data() {
    return {
      skillTypes: ['All', 'Assigned', 'Not assigned'],
      selectedSkillType: 'All',
    };
  },
  computed: {
    ...mapState('company', {
      skills: (state) => state.activeCompanySkills,
    }),
  },
};
</script>

<style lang="scss" scoped>
.skills {
  @apply h-full w-full p-6 max-h-full overflow-y-scroll;
  &__top {
    @apply flex items-center;
    &__description {
      @apply text-sm font-medium py-3;
      color: $color-grey;
    }
  }
  &__count {
    @apply flex font-semibold text-lg mr-4 items-end;
    span {
      @apply text-4xl mr-3 font-light;
      color: $color-green;
    }
  }
  &__select {
    ::v-deep {
      .vs__open-indicator {
        @apply block;
        fill: $color-orange;
        width: 10px;
        path {
          d: path(
            'M10.555,13.073l-2.8-2.8.933-.932,1.865,1.865L12.42,9.343l.933.932Z'
          );
          fill: $color-orange;
          transform: translate(-7.757px, -9.343px);
        }
      }
      .vs__selected {
        color: $color-orange;
      }
      .vs__dropdown-toggle {
        height: 25px;
        min-width: 50px;
        font-size: smaller;
        border: none;
      }
      .vs__dropdown-menu {
        min-width: 150px;
      }
      .vs__dropdown-option {
        @apply text-sm;
        height: 25px;
      }
      .vs__actions {
        padding: 8px 6px 0 3px;
      }
    }
  }
}
</style>
