import { render, staticRenderFns } from "./AppSegment.vue?vue&type=template&id=6bee60e1&scoped=true"
import script from "./AppSegment.vue?vue&type=script&lang=js"
export * from "./AppSegment.vue?vue&type=script&lang=js"
import style0 from "./AppSegment.vue?vue&type=style&index=0&id=6bee60e1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bee60e1",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CVisuallyHidden: require('@chakra-ui/vue').CVisuallyHidden, CBox: require('@chakra-ui/vue').CBox, CControlBox: require('@chakra-ui/vue').CControlBox})
