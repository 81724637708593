<template>
  <div>
    <c-alert-dialog-header font-size="lg" font-weight="bold">
      Achieved Milestone
    </c-alert-dialog-header>
    <c-alert-dialog-body>
      Set Date Milestone was acheived
      <AppDatePicker
        format="YYYY-MM-DD"
        type="date"
        v-model="tempAchievedMilestoneDate"
      >
        <template v-slot:input>
          <c-input
            size="lg"
            readonly
            placeholder="Select Date"
            :value="achievedMilestoneDateDisplay"
          ></c-input>
        </template>
      </AppDatePicker>
    </c-alert-dialog-body>
    <c-alert-dialog-footer>
      <c-button ref="cancelRef" @click="$emit('closeModal')"> Cancel </c-button>
      <c-button
        :disabled="!tempAchievedMilestoneDate"
        variantColor="vue"
        @click="saveMilestoneAsAchieved"
        ml="3"
      >
        Save
        <c-spinner
          ml="2"
          v-if="isMarkingAsAchieved"
          color="#fff"
          thickness="2px"
        />
      </c-button>
    </c-alert-dialog-footer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AppDatePicker from '@/views/App/components/AppDatePicker.vue';
import cloneDeep from 'lodash.clonedeep';
import { updateMilestone } from '@/services/timeline';

export default {
  components: {
    AppDatePicker,
  },
  props: {
    milestoneId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('timeline', {
      categories: (state) => state.categories,
      scenarios: (state) => state.scenarios,
    }),

    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    achievedMilestoneDateDisplay() {
      return this.tempAchievedMilestoneDate
        ? this.$moment(this.tempAchievedMilestoneDate).format('YYYY-MM-DD')
        : null;
    },
  },
  data() {
    return {
      isMarkingAsAchieved: false,
      tempAchievedMilestoneDate: null,
      isAddingMilestone: false,
    };
  },
  methods: {
    ...mapMutations({
      addNewMilestone: 'timeline/addMilestone',
      updateMilestoneInStore: 'timeline/updateMilestone',
    }),
    async saveMilestoneAsAchieved() {
      this.isMarkingAsAchieved = true;
      try {
        const res = await updateMilestone({
          id: this.milestoneId,
          set: {
            dateAchieved: this.tempAchievedMilestoneDate,
            status: 'ACHIEVED',
          },
        });
        this.updateMilestoneInStore(cloneDeep(res.data.update_milestone_by_pk));

        this.isMarkingAsAchieved = false;
        this.$emit('onAchievedMilestone');
      } catch (e) {
        console.log({ e });
      }
    },
    clearMilestoneForm() {
      this.milestoneForm = {
        title: null,
        date: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      };
    },
  },
};
</script>
