import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  inject: ["updatePresentation", "updateFoundationType"],
  data() {
    return {
      sdgGoals: [],
      goalsData: {
        belief: "",
        vision: "",
      },
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedItems() {
      return this.sdgGoals.filter((item) => item.selected);
    },
  },
  created() {
    this.setSdgGoals();
  },
  watch: {
    presentation() {
      this.setSdgGoals();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
      updatePresentationData: "presentation/updatePresentationData",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setSdgGoals() {
      this.goalsData.belief = this.presentationData?.belief;
      this.goalsData.vision = this.presentationData?.vision;

      const itemsArray = this.presentationData?.companySdg?.company_sdg_values;
      console.log("stratt", this.goalsData, this.presentationData);
      if (!this.presentation.data.slidesData?.goals) {
        this.sdgGoals = [...itemsArray];
      } else {
        const { goals } = this.presentation.data.slidesData;
        this.sdgGoals = itemsArray?.map((item) => {
          const itemInPresentation = goals?.find(
            (_item) => _item.id === item.id
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async onGoalsInputBlur(val, key) {
      const oldValue = this.presentationData[key];

      console.log("test", this.goalsData[key], this.goalsData, oldValue);
      this.updatePresentationData({
        ...this.presentationData,
        [key]: this.goalsData[key],
      });
      try {
        console.log(key, typeof key);
        await this.updateFoundationType({
          type: key,
          companyId: this.activeCompany.id,
          set: { description: this.goalsData[key] },
        });
      } catch (e) {
        console.log(e);
        this.updatePresentationData({
          ...this.presentationData,
          [key]: oldValue,
        });
        this.goalsData[key] = oldValue;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating ${key} details, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
    async updateGoalsData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                goals: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected goals, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
