<template>
  <c-box
    w="100%"
    h="100%"
    position="relative"
    :background="
      presentation.data.color
        ? `linear-gradient(to right, white 0%, white 80%,${presentation.data.color}80 80%, ${presentation.data.color}80 100%)`
        : `linear-gradient(
        to bottom ,
        #fff,
        #fff
      )`
    "
  >
    <c-flex
      pt="20px"
      ml="20px"
      mb="50px"
      direction="column"
      justify="start"
      align="start"
    >
      <c-text textAlign="start" fontSize="2em" fontWeight="700">
        {{ type === 'team' ? 'Team' : 'Advisory Board' }}
      </c-text>
      <c-box h="5px" w="80px" :bg="`${presentation.data.color}80`"></c-box>
    </c-flex>

    <c-link
      textDecoration="underline"
      position="absolute"
      right="5"
      top="5"
      class="item-selector"
      fontSize="xl"
      v-if="isEdit"
      @click="isDrawerOpen = true"
      >Select members to display</c-link
    >
    <c-grid
      px="50px"
      :template-columns="`repeat(auto-fit, minmax(100px, 1fr))`"
      position="relative"
    >
      <c-box
        zIndex="2"
        textAlign="center"
        position="relative"
        v-for="(member, ix) in selectedMembers"
        :key="ix"
      >
        <c-flex justify="center">
          <c-box
            mb="5px"
            bg="#000"
            :bgImage="`url(${member.user.profilePhoto})`"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            bgSize="cover"
            :name="`${member.user.firstname} ${member.user.lastname}`"
            h="150px"
            w="150px"
            cursor="pointer"
          />
        </c-flex>
        <!-- <c-box
          borderRadius="50%"
          w="150px"
          mx="auto"
          h="150px"
          mb="4"
          borderWidth="10px"
          :borderColor="presentation.data.color"
          overflow="hidden"
        >
          <img
            v-chakra
            w="100%"
            h="100%"
            objectFit="cover"
            objectPosition="center"
            :src="member.user.profilePhoto"
          />
        </c-box>-->
        <c-text fontSize="20px" fontWeight="600"
          >{{ member.user.firstname }} {{ member.user.lastname }}</c-text
        >
        <c-text fontSize="14px" color="#8A8A8A">{{ member.position }}</c-text>
        <c-box mt="40px">
          <div v-for="(teamSkill, idx) in member.team_skills" :key="idx">
            <c-text fontSize="16px" my="2">
              {{ teamSkill.company_skill_value.skillBySkill.name }}
            </c-text>
            <hr
              v-chakra
              w="36px"
              mx="auto"
              border="none"
              h="1px"
              bg="#C5C5C5"
              v-if="idx !== member.team_skills.length - 1"
            />
          </div>
        </c-box>
      </c-box>
    </c-grid>
    <c-box position="absolute" right="60px" bottom="30px">
      <WaterMark :direction="'row'" />
    </c-box>
    <TextListDrawer
      :title="'Select visible members'"
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :items="filteredMembers"
      :updateFn="updateMembersData"
      :isUpdatingData="isUpdatingData"
      :max="5"
    >
      <template #list-item="{ item }">
        <c-text as="span"
          >{{ item.user.firstname }} {{ item.user.lastname }}</c-text
        >
      </template>
    </TextListDrawer>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import WaterMark from '@/components/WaterMark.vue';
import teamMixin from '@/mixins/data/team.js';
import TextListDrawer from '@/components/DataComponents/TextListDrawer.vue';

export default {
  props: {
    type: {
      type: String,
      default: 'team',
    },

    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [teamMixin],
  components: { WaterMark, TextListDrawer },
  computed: {
    ...mapState({
      presentation: (state) => state.presentation.activePresentation,
    }),
  },
};
</script>

<style></style>
