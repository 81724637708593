var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"values"},[(!_vm.disabled)?_c('c-box',{staticClass:"value value--add"},[_c('span',{staticClass:"value__title",on:{"click":_vm.addValue}},[_c('svg',[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})])]),_c('span',{staticClass:"value__description",on:{"click":_vm.addValue}},[_vm._v(" Add value")]),_c('br'),_c('c-link',{on:{"click":_vm.openGenerateDrawer}},[_c('c-tag',{attrs:{"size":"md","variant-color":"red","mx":"2"}},[_vm._v("AI Generate")])],1)],1):_vm._e(),_vm._l((_vm.values),function(value,index){return _c('div',{key:value.id || value.tempId,staticClass:"value"},[(!_vm.disabled)?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        w: '4',
        h: '4',
        cursor: 'pointer',
        fill: 'vc-orange.400',
        position: 'absolute',
        top: '5px',
        right: '5px',
        display: 'none',
      }),expression:"{\n        w: '4',\n        h: '4',\n        cursor: 'pointer',\n        fill: 'vc-orange.400',\n        position: 'absolute',\n        top: '5px',\n        right: '5px',\n        display: 'none',\n      }"}],staticClass:"value__delete",on:{"click":function($event){return _vm.deleteValue(value)}}},[_c('use',{attrs:{"href":require("@/assets/icons/icon-trash.svg") + "#trash"}})]):_vm._e(),_c('c-editable',{staticClass:"value__title",attrs:{"placeholder":"Click to enter label","default-value":value.label,"selectAllOnFocus":false,"value":value.label,"isDisabled":_vm.disabled},on:{"change":function($event){return _vm.onInputChange($event, index, 'label')}}},[_c('c-editable-preview',{attrs:{"maxWidth":"100%","whiteSpace":"normal","wordWrap":"break-word"}}),_c('c-editable-input',{on:{"blur":function($event){return _vm.onLabelBlur(index)}}})],1),(!value.isLabelValid)?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"8px"}},[_vm._v(" Label is required to add value ")]):_vm._e(),_c('EditableText',{attrs:{"type":`values${index}}`,"title":"Values","editabelIndex":index,"disabled":_vm.disabled},on:{"updateDescription":_vm.onUpdateDescription},model:{value:(value.description),callback:function ($$v) {_vm.$set(value, "description", $$v)},expression:"value.description"}})],1)}),(_vm.isTeamDrawerOpen)?_c('TeamDrawer',{attrs:{"title":"Values","context":_vm.valueText,"isOpen":_vm.isTeamDrawerOpen},on:{"close":_vm.closeTeamDrawer}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }