<template>
  <c-box id="costs">
    <TaskCard
      title="Costs"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="false"
      :hasMounte="hasMounted"
      :disabled="disabled"
      id="costs"
      :taskData="businessModelData"
      :accessKey="'business_model_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'business_model_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="costs"
    >
      <template v-slot:description>
        <span> What represents the biggest cost to your business? </span>
        <br />
        <span>
          Do some of your Key activities represent significant expenses?
        </span>
        <br />
        <span>
          Do some of your Key Resources represent significant expenses?
        </span>
      </template>
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
      <CostsFields :disabled="disabled" v-else :costData="businessModelData" />
    </TaskCard>
  </c-box>
</template>

<script>
import TaskCard from '../TaskCard.vue';
import CostsFields from './CostsFields.vue';

import { ContentLoader } from 'vue-content-loader';

import businessModelMixin from '@/mixins/business-model.js';

export default {
  mixins: [businessModelMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TaskCard,
    ContentLoader,
    CostsFields,
  },
  data() {
    return {
      businessModelType: 'costs',
      hasMounted: false,
    };
  },
};
</script>

<style></style>
