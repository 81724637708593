<template>
  <div class="skill">
    <span
      class="skill__state"
      :class="{ 'skill__state--no-member': skill.team_skills.length }"
    >
      <span v-if="!skill.team_skills.length"> Not assigned </span>
      <span v-else>
        <span
          v-chakra="{
            color: '#56d6b3',
          }"
        >
          {{ skill.team_skills.length }}
        </span>
        member{{ skill.team_skills.length > 1 ? 's' : '' }}
      </span>
    </span>
    <div
      class="skill__item"
      :class="{ 'skill__item--assigned': skill.team_skills.length }"
      draggable
      @dragstart="pickupSkill($event)"
      @dragend="onSkillDragEnd($event)"
      @dragover.prevent
      @dragenter.prevent
    >
      <span class="skill__item__handle">
        <span class="skill__item__handle__bar"></span>
        <span class="skill__item__handle__bar"></span>
      </span>
      {{ skill.skillBySkill.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['skill'],
  methods: {
    pickupSkill(event) {
      event.dataTransfer.effectAllowed = 'copy';
      event.dataTransfer.dropEffect = 'copy';
      const skillItem = event.target.cloneNode(true);
      skillItem.style.position = 'absolute';
      skillItem.style.left = '-150px';
      skillItem.style.top = '-150px';
      skillItem.style.zIndex = 1000;
      skillItem.classList.add('skill__item--dragging');
      document.body.appendChild(skillItem);
      event.dataTransfer.setDragImage(skillItem, 100, 30);
      event.dataTransfer.setData('skill', JSON.stringify(this.skill));
    },
    onSkillDragEnd() {
      const items = document.body.getElementsByClassName(
        'skill__item--dragging'
      );
      Array.from(items).forEach((item) => {
        item.remove();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.skill {
  @apply flex flex-col;
  &__state {
    @apply ml-auto;
    font-size: 10px;
    &--no-member {
      color: #495057;
    }
  }
  &__item {
    @apply flex justify-center items-center font-bold relative px-4 text-center;
    min-height: 55px;
    border: 1px solid #56d6b3;
    color: #56d6b3;
    border-radius: 6px;
    &--assigned {
      background-color: #56d6b3;
      color: #fff;
    }
    &--dragging {
      background-color: #fff;
      cursor: grabbing;
      &.skill__item--assigned {
        color: #56d6b3;
      }
    }
    &__handle {
      @apply flex flex-col items-center justify-center absolute;
      width: 22px;
      height: 22px;
      top: 50%;
      left: -11px;
      border-radius: 4px;
      background-color: #f4f6fc;
      border: 1px dashed $color-orange;
      transform: translateY(-50%);
      &__bar {
        width: 12px;
        height: 2px;
        background-color: $color-orange;
        &:first-child {
          margin-bottom: 2px;
        }
      }
    }
  }
}
</style>
