<template>
  <div class="values">
    <c-box v-if="!disabled" class="value value--add">
      <span class="value__title" @click="addValue">
        <svg>
          <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
        </svg>
      </span>
      <span class="value__description" @click="addValue"> Add value</span>
      <br />
      <c-link @click="openGenerateDrawer">
        <c-tag size="md" variant-color="red" mx="2">AI Generate</c-tag>
      </c-link>
    </c-box>
    <div
      v-for="(value, index) in values"
      :key="value.id || value.tempId"
      class="value"
    >
      <svg
        v-if="!disabled"
        class="value__delete"
        @click="deleteValue(value)"
        v-chakra="{
          w: '4',
          h: '4',
          cursor: 'pointer',
          fill: 'vc-orange.400',
          position: 'absolute',
          top: '5px',
          right: '5px',
          display: 'none',
        }"
      >
        <use href="@/assets/icons/icon-trash.svg#trash"></use>
      </svg>
      <c-editable
        class="value__title"
        placeholder="Click to enter label"
        :default-value="value.label"
        :selectAllOnFocus="false"
        :value="value.label"
        @change="onInputChange($event, index, 'label')"
        :isDisabled="disabled"
      >
        <c-editable-preview
          maxWidth="100%"
          whiteSpace="normal"
          wordWrap="break-word"
        />
        <c-editable-input @blur="onLabelBlur(index)" />
      </c-editable>
      <c-text v-if="!value.isLabelValid" color="vc-orange.400" fontSize="8px">
        Label is required to add value
      </c-text>
      <!-- <c-textarea placeholder="Here is a sample placeholder" /> -->
      <EditableText
        :type="`values${index}}`"
        title="Values"
        v-model="value.description"
        @updateDescription="onUpdateDescription"
        :editabelIndex="index"
        :disabled="disabled"
      />
    </div>

    <TeamDrawer
      title="Values"
      :context="valueText"
      :isOpen="isTeamDrawerOpen"
      @close="closeTeamDrawer"
      v-if="isTeamDrawerOpen"
    />
  </div>
</template>

<script>
import { generateID } from '@/helpers/data.js';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';
import TeamDrawer from '@/views/App/components/OpenAiDrawer.vue';

export default {
  components: {
    EditableText,
    TeamDrawer,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTeamDrawerOpen: false,
      values: [],
      valueText: '',
      valueForm: {
        label: null,
        description: null,
      },
    };
  },
  watch: {
    items() {
      this.setValues();
    },
  },
  created() {
    if (this.items && this.items.length) {
      this.setValues();
    }
  },
  methods: {
    closeTeamDrawer(results) {
      if (!results) {
        this.isTeamDrawerOpen = false;
        return;
      }
      // get the last value
      const currentValue = this.values[this.values.length - 1];
      this.values[this.values.length - 1].label = results.label;
      this.values[this.values.length - 1].description = results.description;
      this.isTeamDrawerOpen = false;
      this.saveValue(currentValue);
    },
    openGenerateDrawer() {
      this.addValue();
      this.isTeamDrawerOpen = true;
    },
    setValues() {
      this.values = this.items.map((value) => {
        return {
          ...value,
          isLabelValid: true,
        };
      });
    },
    addValue() {
      this.values.push({
        tempId: generateID(5),
        label: '',
        description: '',
        isLabelValid: true,
      });
    },
    deleteValue(value) {
      if (value.id) {
        this.$emit('deleteValue', value);
      } else {
        const index = this.values.findIndex((v) => v.label === value.label);
        this.values.splice(index, 1);
      }
    },
    onInputChange(e, index, field) {
      if (e) {
        this.values[index][field] = e.target.value;
      }
    },
    onLabelBlur(valueIndex) {
      const value = this.values[valueIndex];
      if (value.label && value.label.trim()) {
        this.saveValue({ ...value });
        value.isLabelValid = true;
      } else {
        value.isLabelValid = false;
      }
    },
    onDescriptionBlur(valueIndex) {
      const value = this.values[valueIndex];
      if (value.label && value.label.trim()) {
        this.saveValue({ ...value });
        value.isLabelValid = true;
      } else {
        value.isLabelValid = false;
      }
    },
    onUpdateDescription(payload, index) {
      if (typeof payload !== 'object') {
        this.values[index].description = payload;
        this.onDescriptionBlur(index);
      } else {
        this.values[index].label = payload.label;
        this.values[index].description = payload.description;
        this.onDescriptionBlur(index);
      }
    },
    saveValue(value) {
      const { label, description, id } = value;
      console.log(label, description, id);
      if (id) {
        this.$emit('updateValue', { id, label, description });
      } else {
        this.$emit('addValue', { label, description });
      }
    },
    onListInput(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.values {
  @apply flex flex-wrap;
  .value {
    @apply flex flex-col justify-center px-5 py-4 mb-6 mr-4 text-center relative;
    min-height: 104px;
    max-width: 250px;
    min-width: 200px;
    border-radius: 8px;
    box-shadow: 3px 3px 10px #bababa5c;
    &__title {
      @apply font-semibold max-w-full;
    }
    &__description {
      @apply text-sm max-w-full;
    }
    &:hover {
      .value__delete {
        display: block;
      }
    }
    &--add {
      @apply items-center cursor-pointer;
      width: 200px;
      svg {
        fill: $color-orange;
        width: 25px;
        height: 25px;
      }
      .value__description {
        @apply font-medium;
        color: $color-orange;
      }
    }
  }
}
</style>
