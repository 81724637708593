<template>
  <c-box id="overview">
    <TaskCard
      :title="'Overview'"
      :description="''"
      :hasVisibility="true"
      :hasImportance="true"
      :hasAssumption="false"
      :hasFooter="true"
      :hasComments="true"
      :disabled="disabled"
      :taskData="marketPotentialData"
      :accessKey="'market_potential_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'market_potential_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="overview"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 200 100"
        primaryColor="#ddd"
      >
        <rect x="0" y="40" rx="3" ry="3" width="50%" height="120" />
        <rect x="60%" y="40" rx="3" ry="3" width="50%" height="130" />
      </ContentLoader>
      <OverviewChart v-else />
    </TaskCard>
  </c-box>
</template>

<script>
import TaskCard from './../TaskCard.vue';
import OverviewChart from './OverviewChart';

import { ContentLoader } from 'vue-content-loader';

import marketPotentialMixin from '@/mixins/market-potential.js';

export default {
  mixins: [marketPotentialMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    ContentLoader,
    OverviewChart,
  },
  data() {
    return {
      marketPotentialType: 'overview',
    };
  },
};
</script>

<style></style>
