var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"mb":"3","p":"5","boxShadow":"0px 3px 6px rgba(186, 186, 186, 0.16)"}},[_c('c-flex',{attrs:{"justify":"space-between"}},[_c('c-heading',{attrs:{"fontSize":"md","fontWeight":"600","as":"h6"}},[_vm._v("Willingness to pay")])],1),_c('c-text',{attrs:{"fontSize":"sm","my":"2","color":"#798892"}},[_vm._v(" How much would customers be willing to pay for your product or service? ")]),_c('c-flex',{attrs:{"my":"3"}},[_c('c-box',{attrs:{"mr":"16"}},[_c('c-heading',{attrs:{"as":"h6","fontSize":"base","fontWeight":"500","mb":"2"}},[_vm._v(" From ")]),_c('c-editable',{attrs:{"default-value":_vm.willingnessValues.from,"color":"gray.500","fontSize":"xs","fontWeight":"500","isDisabled":_vm.disabled},on:{"change":function($event){return _vm.onInputChange($event, 'from')},"submit":_vm.onInputSubmit}},[_c('c-editable-preview'),_c('c-editable-input',{attrs:{"type":"number","w":"80px"}})],1)],1),_c('c-box',{attrs:{"mr":"8"}},[_c('c-heading',{attrs:{"as":"h6","fontSize":"base","fontWeight":"500","mb":"2"}},[_vm._v(" To ")]),_c('c-editable',{attrs:{"default-value":_vm.willingnessValues.to,"color":"gray.500","fontSize":"xs","fontWeight":"500","isDisabled":_vm.disabled},on:{"change":function($event){return _vm.onInputChange($event, 'to')},"submit":_vm.onInputSubmit}},[_c('c-editable-preview'),_c('c-editable-input',{attrs:{"w":"80px"}})],1)],1),_c('AppSelect',{attrs:{"color":"orange","reduce":(currency) => currency.code,"label":"code","options":_vm.currencies,"indicator-fill":"orange","disabled":_vm.disabled},on:{"input":_vm.onInputSubmit},model:{value:(_vm.willingnessValues.currency),callback:function ($$v) {_vm.$set(_vm.willingnessValues, "currency", $$v)},expression:"willingnessValues.currency"}}),_c('AppSelect',{attrs:{"color":"orange","label":"metric","options":[
        'Per Item',
        'Per Service',
        'Per Day',
        'Per Month',
        'Per Year',
      ],"indicator-fill":"orange","disabled":_vm.disabled},on:{"input":_vm.onInputSubmit},model:{value:(_vm.willingnessValues.metric),callback:function ($$v) {_vm.$set(_vm.willingnessValues, "metric", $$v)},expression:"willingnessValues.metric"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }