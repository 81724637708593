<template>
  <c-box>
    <AppSelect
      :bordered="true"
      :size="'large'"
      label="family"
      :placeholder="'Choose Font'"
      :borderColor="'light'"
      :fullWidth="true"
      :options="paginated"
      :filterable="false"
      @open="onOpen"
      @close="onClose"
      @search="(query) => (search = query)"
      v-model="font"
      @input="onFontChange"
    >
      <template v-slot:selected-option="{ option }">
        <c-box px="2">
          <FontPreview
            :fontFamily="option.family"
            :fontCategory="option.category"
          />
        </c-box>
      </template>
      <template v-slot:option="{ option }">
        <FontPreview
          :fontFamily="option.family"
          :fontCategory="option.category"
        />
      </template>
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more fonts...
        </li>
      </template>
    </AppSelect>
  </c-box>
</template>

<script>
import AppSelect from '@/views/App/components/AppSelect.vue';
import FontPreview from './FontPreview.vue';

import { mapState, mapMutations } from 'vuex';
import { updatePresentation } from '@/services/presentation';

export default {
  components: {
    AppSelect,
    FontPreview,
  },
  data() {
    return {
      observer: null,
      limit: 10,
      search: '',
      font: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    this.font = { ...this.presentation.data.font };
  },
  computed: {
    ...mapState('presentation', {
      presentation: (state) => state.activePresentation,
      fontsList: (state) => state.fontsList,
    }),
    filtered() {
      const searchRegex = new RegExp(this.search.trim(), 'i');
      return this.fontsList.filter((font) => searchRegex.test(font.family));
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async onFontChange() {
      const prevFont = { ...this.presentation.data.font };
      this.updatePresentation({
        ...this.presentation,
        data: { ...this.presentation.data, font: { ...this.font } },
      });
      try {
        await updatePresentation({
          id: this.presentation.id,
          append: { data: { font: { ...this.font } } },
        });
      } catch (e) {
        this.updatePresentation({
          ...this.presentation,
          data: { ...this.presentation.data, font: { ...prevFont } },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style></style>
