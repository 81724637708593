var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"p":"8","my":"8","w":"100%","bg":"#fff","borderRadius":"8px","boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16), -2px -1px 6px rgba(186, 186, 186, 0.16)"}},[_c('c-flex',{attrs:{"align":"baseline","mb":"4"}},[_c('c-heading',{attrs:{"fontSize":"2xl","fontWeight":"700","mr":"3"}},[_vm._v(" Milestones ")]),_c('c-link',{attrs:{"as":"router-link","to":"/app/timeline","display":"flex","alignItems":"center"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          w: '6',
          h: '6',
          fill: '#ef5323',
        }),expression:"{\n          w: '6',\n          h: '6',\n          fill: '#ef5323',\n        }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/share-box-fill.svg") + "#share"}})])])],1),_c('c-flex',{attrs:{"mb":"5","align":"center"}},[_c('c-flex',_vm._l((_vm.scenariosData),function(scenario,scenarioIndex){return _c('c-pseudo-box',{key:scenario.id,attrs:{"as":"a","px":"3","py":"2","mr":"3","cursor":"pointer","display":"flex","color":scenario.isActive ? '#fff' : scenario.color,"bg":scenario.isActive ? scenario.color : 'transparent',"fontWeight":"500","fontSize":"xs","borderRadius":"2px"},on:{"click":function($event){return _vm.onScenarioSelect(scenarioIndex)}}},[_vm._v(" "+_vm._s(scenario.title)+" ")])}),1),(_vm.selectedScenario !== 'ALL')?_c('c-link',{attrs:{"color":"red.400","fontSize":"xs","ml":"3"},on:{"click":_vm.clearScenarioFilter}},[_vm._v(" Clear filter ")]):_vm._e()],1),_c('c-flex',{attrs:{"align":"center"}},[_c('c-flex',{attrs:{"flex":"1","flexWrap":"wrap"}},_vm._l((_vm.displayedMilestones),function(milestone){return _c('c-pseudo-box',{key:milestone.id,attrs:{"borderColor":"vc-orange.400","borderWidth":"1px","p":"3","borderRadius":"4px","w":"200px","position":"relative","alignSelf":"center","mr":"8","mb":"8"}},[_c('c-flex',{attrs:{"justify":"space-between","mb":"2"}},[_c('c-heading',{attrs:{"fontSize":"10px","fontWeight":"500"}},[_vm._v(" "+_vm._s(milestone.calender_category ? milestone.calender_category.title : '')+" ")]),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              width: '14px',
              height: '14px',
              transform: 'unset !important',
            }),expression:"{\n              width: '14px',\n              height: '14px',\n              transform: 'unset !important',\n            }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/dots-horizontal.svg") + "#dots"}})])],1),_c('c-popover',{attrs:{"trigger":"hover","placement":"top-end"}},[_c('c-popover-trigger',[_c('c-heading',{attrs:{"maxWidth":"100%","whiteSpace":"nowrap","overflow":"hidden","textOverflow":"ellipsis","fontSize":"sm","fontWeight":"600"}},[_vm._v(" "+_vm._s(milestone.title)+" ")])],1),_c('c-popover-content',[_c('c-popover-arrow'),_c('c-popover-body',{attrs:{"zIndex":"modal"}},[_c('c-text',{attrs:{"fontSize":"sm","overflowWrap":"break-word"}},[_vm._v(" "+_vm._s(milestone.title)+" ")])],1)],1)],1),_c('c-flex',{attrs:{"justify":"space-between","mt":"2"}},[_c('c-text',{attrs:{"color":"gray.500","fontSize":"10px","fontWeight":"500"}},[_vm._v(" "+_vm._s(_vm._f("moment")(milestone.date,'DD, MMMM'))+" ")]),(milestone.status === 'IN_PROGRESS')?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"10px","display":"flex","fontWeight":"500","alignItems":"center"}},[_vm._v(" In Progress "),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                width: '14px',
                height: '14px',
                ml: '2',
              }),expression:"{\n                width: '14px',\n                height: '14px',\n                ml: '2',\n              }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/circle.svg") + "#circle"}})])]):_c('c-text',{attrs:{"color":"#0FC694","fontSize":"10px","display":"flex","fontWeight":"500","alignItems":"center"}},[_vm._v(" Acheived "),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                width: '14px',
                height: '14px',
                ml: '2',
              }),expression:"{\n                width: '14px',\n                height: '14px',\n                ml: '2',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/check-circle.svg") + "#check"}})])])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }