<template>
  <div class="channels">
    <div class="channels__point" v-for="point in points" :key="point.topic">
      <c-pseudo-box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        @click="point.isOpen = !point.isOpen"
      >
        <c-box>
          <h5 class="channels__point__title">{{ point.title }}</h5>
          <p class="channels__point__description">
            {{ point.description }}
          </p>
        </c-box>
        <c-icon
          name="chevron-down"
          v-if="point.isOpen"
          color="blue.500"
          size="24px"
        ></c-icon>
        <c-icon
          name="chevron-right"
          v-else
          color="blue.500"
          size="24px"
        ></c-icon>
      </c-pseudo-box>
      <c-collapse :is-open="point.isOpen">
        <c-text
          my="4"
          ml="3"
          fontSize="sm"
          fontStyle="italic"
          v-if="!customers.length"
        >
          Add a customer in the customer segment card
        </c-text>
        <CustomerTabs showGeneral>
          <template v-slot="{ customer }">
            <ChannelsList
              :channelsData="channelsData"
              :customer="customer"
              :topic="point.topic"
              :disabled="disabled"
            />
          </template>
        </CustomerTabs>
      </c-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ChannelsList from './ChannelsList.vue';
import CustomerTabs from './CustomerTabs.vue';
export default {
  props: {
    channelsData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { CustomerTabs, ChannelsList },
  data() {
    return {
      points: [
        {
          title: 'Awareness',
          description:
            'Where do you make future customers aware of your product?',
          topic: 'awareness',
          isOpen: true,
        },
        {
          title: 'Evaluation',
          description:
            'Where does potential customers gain further knowledge about your product?',
          topic: 'evaluation',
          isOpen: false,
        },
        {
          title: 'Purchase',
          description: 'Where future customers be purchasing your product?',
          topic: 'purchase',
          isOpen: false,
        },
        {
          title: 'Delivery value',
          description:
            'Where do you deliver the promised value to your customer?',
          topic: 'delivery_value',
          isOpen: false,
        },
        {
          title: 'Post Sale Support',
          description:
            'Where will you be providing support for your customers?',
          topic: 'post_sale_support',
          isOpen: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      customers: (state) => state.customers,
    }),
  },
};
</script>

<style lang="scss" scoped>
.channels {
  @apply my-6;
  &__point {
    @apply mb-3;
    &__title {
      @apply text-xl font-semibold;
      color: $color-grey;
    }
    &__description {
      @apply text-sm font-medium;
      color: #798892;
    }
  }
}
</style>
