<template>
  <c-flex my="8" align="center">
    <c-box mr="24">
      <c-pseudo-box :w="chartWidth" :h="chartHeight" position="relative">
        <template v-for="data in marketData">
          <c-pseudo-box
            :key="data.key"
            :bg="data.color"
            :w="`${
              data.key !== 'TAM'
                ? percentageOfTam(data.value) > 100
                  ? 100
                  : percentageOfTam(data.value)
                : '100'
            }%`"
            :h="`${
              data.key !== 'TAM'
                ? percentageOfTam(data.value) > 100
                  ? 100
                  : percentageOfTam(data.value)
                : '100'
            }%`"
            position="absolute"
            bottom="0"
            left="50%"
            transform="translateX(-50%)"
            borderRadius="50%"
            :opacity="data.isHovering ? 0.6 : 1"
            transition="opacity 200ms ease"
            @mouseenter="data.isHovering = true"
            @mouseleave="data.isHovering = false"
          ></c-pseudo-box>
        </template>
      </c-pseudo-box>
    </c-box>
    <c-stack :spacing="6">
      <c-pseudo-box
        display="flex"
        v-for="data in marketData"
        :key="data.key"
        :opacity="data.isHovering ? 0.6 : 1"
        @mouseenter="data.isHovering = true"
        @mouseleave="data.isHovering = false"
      >
        <c-box
          :w="!isSquare ? '22px' : '30px'"
          :h="!isSquare ? '22px' : '10px'"
          :bg="data.color"
          :borderRadius="isSquare ? '0px' : '50%'"
          display="inline-block"
          mr="5"
        ></c-box>
        <c-stack :spacing="3">
          <c-text fontSize="1.8em" fontWeight="600">
            {{ data.title }}
          </c-text>
          <c-flex align="center" flexWrap="wrap">
            <c-text
              color="vc-orange.400"
              fontSize="1.5em"
              fontWeight="700"
              mr="8"
            >
              {{ data.value }} $
            </c-text>
            <c-flex align="baseline">
              <c-text fontSize="1.2em" fontWeight="600" mr="2">
                {{ data.customersCount }}
              </c-text>
              <c-text fontSize="1.2em" color="gray.400" fontWeight="600">
                Customers
              </c-text>
            </c-flex>
            <c-flex
              align="baseline"
              v-if="data.key !== 'TAM'"
              :ml="{ base: 0, lg: 8 }"
            >
              <c-text fontSize="1.2em" fontWeight="600" mr="2">
                {{ percentageOfTam(data.value) }}%
              </c-text>
              <c-text fontSize="1.2em" color="gray.400" fontWeight="600">
                of TAM
              </c-text>
            </c-flex>
          </c-flex>
        </c-stack>
      </c-pseudo-box>
    </c-stack>
  </c-flex>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    template: {
      type: String,
      default: "",
    },
    chartWidth: {
      type: String,
      default: "250px",
    },
    chartHeight: {
      type: String,
      default: "250px",
    },
  },
  data() {
    return {
      marketData: [
        {
          title: "Total Available Market (TAM)",
          key: "TAM",
          value: 0,
          customersCount: 0,
          color: "#A1D3F4",
          isHovering: false,
        },
        {
          title: "Serviceable Available Market (SAM)",
          key: "SAM",
          value: 0,
          customersCount: 0,
          color: "#6DB9ED",
          isHovering: false,
        },
        {
          title: "Serviceable Obtainable Market (SOM)",
          key: "SOM",
          value: 0,
          customersCount: 0,
          color: "#1A86D0",
          isHovering: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("company", {
      tam: (state) => state.tamValue,
      sam: (state) => state.samValue,
      som: (state) => state.somValue,
    }),
    isSquare() {
      return this.template === "default_1";
    },
  },
  watch: {
    tam() {
      this.setValues();
    },
    sam() {
      this.setValues();
    },
    som() {
      this.setValues();
    },
  },
  created() {
    this.setValues();
  },
  methods: {
    percentageOfTam(value) {
      return Math.round((value / this.marketData[0].value || 0) * 100);
    },
    setValues() {
      this.marketData = this.marketData.map((_marketData) => {
        return { ..._marketData, ...this[_marketData.key.toLowerCase()] };
      });
    },
  },
};
</script>

<style></style>
