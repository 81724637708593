<template>
  <div
    v-chakra="{
      padding: '1.5em',
    }"
  >
    <div class="details">
      <h5 class="details__title">Details</h5>
      <c-grid
        w="100%"
        :template-columns="{ base: '1fr', lg: 'repeat(2, 1fr)' }"
        gap="12"
      >
        <c-box class="description__card">
          <c-flex justify="space-between" align="center" mb="6">
            <c-heading as="h5" fontSize="lg" color="#495057" fontWeight="600">
              Description
            </c-heading>
            <!-- <c-link
              @click="onTextInputClick"
              display="flex"
              alignItems="center"
              v-if="!disabled"
            >
              <c-text as="span" color="#ef5323" fontSize="sm">Edit</c-text>
              <svg
                v-chakra="{
                  w: '18px',
                  h: '18px',
                  fill: '#ef5323',
                }"
              >
                <use href="@/assets/icons/edit-fill.svg#edit"></use>
              </svg>
            </c-link> -->
          </c-flex>
          <c-box>
            <c-text as="span" color="#ef5323" fontSize="14px" v-if="saved"
              ><b>Saved successfully 👍</b></c-text
            >
            <EditableText
              v-model="customer.description"
              :disabled="disabled"
              title="Customer Description"
              @updateDescription="updateDescription"
            />
          </c-box>
        </c-box>
        <c-box>
          <div>
            <c-form-control as="fieldset" class="customers__form-control">
              <div class="customers__categories">
                <label :for="`consumer-category-${customer.id}`">
                  <c-visually-hidden
                    :name="`customerCategory-${customer.id}`"
                    :id="`consumer-category-${customer.id}`"
                    as="input"
                    type="radio"
                    value="consumer"
                    :checked="customer.category === 'consumer'"
                    @change="onCustomerSelect"
                  />
                  <c-control-box
                    border-width="1px"
                    px="10px"
                    :w="{ base: '100%', lg: 'max-content' }"
                    minWidth="120px"
                    h="45px"
                    rounded="md"
                    type="radio"
                    borderColor="#389FB4"
                    fontWeight="500"
                    color="#389FB4"
                    :_checked="{
                      backgroundColor: '#389FB4',
                      color: 'white',
                    }"
                  >
                    Consumer
                  </c-control-box>
                </label>
                <label :for="`business-category-${customer.id}`">
                  <c-visually-hidden
                    :name="`customerCategory-${customer.id}`"
                    :id="`business-category-${customer.id}`"
                    as="input"
                    type="radio"
                    value="business"
                    :checked="customer.category === 'business'"
                    @change="onCustomerSelect"
                  />
                  <c-control-box
                    border-width="1px"
                    px="10px"
                    :w="{ base: '100%', lg: 'max-content' }"
                    minWidth="120px"
                    h="45px"
                    rounded="md"
                    type="radio"
                    borderColor="#14A27B"
                    fontWeight="500"
                    color="#14A27B"
                    :_checked="{
                      backgroundColor: '#14A27B',
                      color: 'white',
                    }"
                  >
                    Business
                  </c-control-box>
                </label>
                <label :for="`government-category-${customer.id}`">
                  <c-visually-hidden
                    :name="`customerCategory-${customer.id}`"
                    :id="`government-category-${customer.id}`"
                    as="input"
                    type="radio"
                    value="government"
                    :checked="customer.category === 'government'"
                    @change="onCustomerSelect"
                  />
                  <c-control-box
                    border-width="1px"
                    px="10px"
                    :w="{ base: '100%', lg: 'max-content' }"
                    minWidth="120px"
                    h="45px"
                    rounded="md"
                    type="radio"
                    borderColor="#3762D5"
                    fontWeight="500"
                    color="#3762D5"
                    :_checked="{
                      backgroundColor: '#3762D5',
                      color: 'white',
                    }"
                  >
                    Government
                  </c-control-box>
                </label>
              </div>
              <c-form-helper-text>
                Select customer category
              </c-form-helper-text>
            </c-form-control>
          </div>
          <c-form-control mb="2rem">
            <v-select
              :clearable="false"
              :searchable="false"
              class="vc-select"
              :value="customer.type"
              @input="
                onFieldUpdate({
                  key: 'type',
                  value: $event,
                })
              "
              :options="customerTypes"
              :placeholder="'Type of Customer'"
            ></v-select>
          </c-form-control>
        </c-box>
      </c-grid>
      <!-- <div class="details__list">
        <div
          class="details__list__item details__list__item--add"
          @click="addDetail"
          v-if="!disabled"
        >
          <svg
            fill="#ef5323"
            v-chakra="{
              width: '30px',
              height: '30px',
              mb: '0.8em',
            }"
          >
            <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
          </svg>
          <c-text>Add Details</c-text>
        </div>
        <div
          class="details__list__item"
          v-for="(detail, index) in details"
          :key="detail.id || detail.tempId"
        >
          <svg
            v-if="!disabled"
            class="details__list__item__delete"
            @click="deleteDetail(detail, index)"
            v-chakra="{
              w: '4',
              h: '4',
              cursor: 'pointer',
              fill: 'vc-orange.400',
              position: 'absolute',
              top: '5px',
              right: '5px',
              display: 'none',
            }"
          >
            <use href="@/assets/icons/icon-trash.svg#trash"></use>
          </svg>
          <c-editable
            w="100%"
            :isDisabled="disabled"
            placeholder="Click to enter title"
            :default-value="detail.title"
            mb="0.7em"
            @change="onInputChange($event, index, 'title')"
          >
            <c-editable-preview
              maxWidth="100%"
              whiteSpace="normal"
              wordWrap="break-word"
            />
            <c-editable-input @blur="onTitleBlur(index)" />
          </c-editable>
          <c-text
            v-if="!detail.isLabelValid"
            color="vc-orange.400"
            fontSize="8px"
          >
            Title is required to add detail
          </c-text>
          <ListTextInput
            v-chakra="{ maxHeight: '150px' }"
            :title="'Customer Edit'"
            :showTitle="false"
            v-model="detail.value"
            @blur="onValueBlur(index)"
            :disabled="disabled"
            :placeholder="'Click to enter description'"
          />
        </div>
      </div> -->
    </div>
    <div class="fields">
      <div>
        <FieldCard
          :type="`customerProblem${customer.id}`"
          title="Problem"
          :fieldKey="'problem'"
          :description="`What problem does the target market ( ${customer.name} ) customer have?`"
          :disabled="disabled"
          :value="customer.problem"
          @updateField="onFieldUpdate"
        />
        <FieldCard
          :type="`customerValueProposition${customer.id}`"
          title="Value Proposition"
          description="What is your value proposition to this specific target market?"
          :fieldKey="'valueProposition'"
          :value="customer.valueProposition"
          :disabled="disabled"
          @updateField="onFieldUpdate"
        />
      </div>
      <div>
        <FieldCard
          :type="`customerSolution${customer.id}`"
          title="Solution"
          :fieldKey="'solution'"
          description="What solution do you provide to the specific target market in order to solve their problem?"
          :value="customer.solution"
          :disabled="disabled"
          @updateField="onFieldUpdate"
        />
        <WillingnessToPay
          :value="customer.willingnessToPay"
          :disabled="disabled"
          @updateField="onFieldUpdate"
        />
        <PriceSensitivity
          :value="customer.priceSensitivity"
          :disabled="disabled"
          @updateField="onFieldUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FieldCard from './FieldCard.vue';
import WillingnessToPay from './WillingnessToPay.vue';
import PriceSensitivity from './PriceSensitivity.vue';
import {
  addCustomerDetail,
  updateCustomer,
  updateCustomerDetail,
} from '@/services/business-model.js';
import { generateID } from '@/helpers/data.js';
import cloneDeep from 'lodash.clonedeep';
import EditableText from '../EditableText.vue';

export default {
  // mixins: [businessModelMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  components: {
    FieldCard,
    WillingnessToPay,
    PriceSensitivity,
    EditableText,
  },
  data() {
    return {
      details: [],
      customerTypes: ['Primary', 'Secondary', 'Tertiary'],
      saved: false,
    };
  },
  watch: {
    'customer.customer_details': function () {
      this.setDetails();
    },
  },
  created() {
    if (this.customer.customer_details.length) {
      this.setDetails();
    }
  },
  methods: {
    ...mapActions({
      updateCustomer: 'company/updateCustomer',
      setTargetMargetCustomer: 'company/setTargetMargetCustomer',
    }),
    onCustomerSelect(e) {
      if (e.target.checked) {
        this.updateCustomer({ ...this.customer, category: e.target.value });
        this.onFieldUpdate({ key: 'category', value: e.target.value });
      }
    },
    onTextInputClick() {
      this.$refs['textInputRef'].onEditClick();
    },
    onTextInputBlur(e) {
      this.$emit('update', { key: 'description', value: e.target.innerHTML });
    },
    addDetail() {
      this.details.push({
        tempId: generateID(5),
        title: '',
        value: '',
        isLabelValid: true,
        isDeleted: false,
      });
    },
    async deleteDetail(detail, index) {
      if (detail.id) {
        try {
          this.saveDetail({ ...detail, isDeleted: true }, index);
        } catch (error) {
          // adding it back in the catch for saveDetail

          this.$toast({
            title: 'An error occurred.',
            description: `Error while deleting detail, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        this.details.splice(index, 1);
      }
    },
    updateDescription(newAIcontent) {
      this.onFieldUpdate({ key: 'description', value: newAIcontent });
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },
    onFieldUpdate({ key, value }) {
      const set = { [key]: value };
      updateCustomer({ id: this.customer.id, set })
        .then((res) => {
          this.updateCustomer(res.data.update_customer_by_pk);
          this.setTargetMargetCustomer(res.data.update_customer_by_pk);
        })
        .catch(() => {
          // TODO return old value || No need to return it since the update only happens on a successful call
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating business model, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    setDetails() {
      this.details = [
        ...this.customer.customer_details
          ?.filter((detail) => !detail.isDeleted)
          ?.map((detail) => {
            return {
              ...detail,
              isLabelValid: true,
            };
          }),
        // only add items with tempID If they have no titles. this avoids having duplicate items
        ...this.details.filter(
          (detail) => detail.tempId && !(detail.title && detail.title.trim())
        ),
      ];
    },
    onInputChange(e, index, field) {
      if (typeof e === 'string') {
        this.details[index][field] = e;
      }
    },
    onTitleBlur(valueIndex) {
      const value = this.details[valueIndex];
      console.log(valueIndex, value);
      if (value.title && value.title.trim()) {
        this.saveDetail({ ...value });
        value.isLabelValid = true;
      } else {
        value.isLabelValid = false;
      }
    },
    onValueBlur(valueIndex) {
      const value = this.details[valueIndex];
      if (value.title && value.title.trim()) {
        this.saveDetail({ ...value }, valueIndex);
        value.isLabelValid = true;
      } else {
        value.isLabelValid = false;
      }
    },
    saveDetail(detail, index) {
      const { title, value, id, isDeleted } = detail;

      if (id) {
        updateCustomerDetail({ id, set: { title, value, isDeleted } })
          .then((res) => {
            // console.log('resss', res);
            const customer_details = cloneDeep(this.customer.customer_details);
            const detailIndex = customer_details.findIndex(
              (_detail) => _detail.id === id
            );

            customer_details.splice(detailIndex, 1, {
              ...res.data.update_customer_detail_by_pk,
            });
            const customer = {
              ...cloneDeep(this.customer),
              customer_details: cloneDeep(customer_details),
            };
            this.updateCustomer(customer);
          })
          .catch(() => {
            this.details.splice(index, 0, detail);
            this.$toast({
              title: 'An error occurred.',
              description: `Error while ${
                isDeleted ? 'deleting' : 'updating'
              } detail, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      } else {
        addCustomerDetail({ title, value, customerId: this.customer.id })
          .then((res) => {
            const customer_details = [...this.customer.customer_details];
            customer_details.push({
              ...res.data.insert_customer_detail_one,
            });

            const customer = {
              ...cloneDeep(this.customer),
              customer_details,
            };
            this.updateCustomer(customer);
          })
          .catch(() => {
            this.details.splice(index, 1);
            this.$toast({
              title: 'An error occurred.',
              description: `Error while adding detail, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customers {
  &__form {
    @apply my-8 px-5;
    width: 100%;
    &-control {
      @apply my-5;
      label {
        @apply relative mr-4;
      }
    }
  }
  &__categories {
    // @apply flex flex-row flex-wrap;
    label {
      @apply mb-3 w-full;
    }
  }
}

.details {
  &__title {
    @apply text-lg font-semibold mb-4;
    color: $color-grey;
  }
  &__list {
    @apply flex flex-wrap;
    &__item {
      @apply p-3 flex flex-col items-center justify-center mr-6 mb-3 relative;
      border-radius: 8px;
      min-height: 100px;
      width: 250px;
      background: #fff;
      box-shadow: 3px 3px 10px rgba(#bababa, 0.36);
      & > div {
        &:first-of-type {
          @apply text-center;
        }
      }
      &:hover {
        .details__list__item__delete {
          display: block;
        }
      }
      &--add {
        @apply font-semibold cursor-pointer;
        color: $color-orange;
      }
    }
  }
}
.fields {
  @apply grid my-4;
  @screen lg {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
.description {
  &__card {
    @apply p-5 mb-8;
    width: 100%;
    min-height: 200px;
    box-shadow: 0px 3px 6px rgba(#bababa, 0.16);
    &__header {
      @apply flex justify-between;
    }
    &__title {
      @apply font-semibold;
    }
  }
}
</style>
