<template>
  <div class="channels">
    <h6 class="channels__title">Channels</h6>
    <div class="channels__list">
      <GridTable
        type="channels"
        :title="topic"
        :columns="tableColumns"
        :addRowLabel="'Add new channel'"
        :onAddRow="addNewChannel"
        :disabled="disabled"
      >
        <tr
          v-for="(channel, index) in channels"
          :key="channel.id || channel.tempId"
        >
          <td class="grid__table__body__cell" v-chakra position="relative">
            <c-flex
              h="100%"
              justify="center"
              align-items="start"
              position="relative"
            >
              <c-popover placement="bottom" v-slot="{}">
                <c-popover-trigger cursor="pointer">
                  <c-box
                    class="channels__list__item__name"
                    v-chakra="{
                      height: 'auto',
                    }"
                  >
                    <svg
                      v-chakra="{
                        width: '10px',
                        height: '16px',
                        fill: '#ef5323',
                      }"
                    >
                      <use href="@/assets/icons/vertical-dots.svg#dots" />
                    </svg>
                  </c-box>
                </c-popover-trigger>
                <c-popover-content maxWidth="150px" zIndex="modal">
                  <c-popover-body p="0">
                    <c-list fontSize="sm">
                      <c-list-item v-if="channel.id">
                        <c-box
                          cursor="pointer"
                          w="100%"
                          px="2"
                          py="2"
                          @click="deleteChannel(index)"
                          >Delete</c-box
                        >
                      </c-list-item>
                    </c-list>
                  </c-popover-body>
                </c-popover-content>
              </c-popover>

              <c-flex
                direction="column"
                align="center"
                textAlign="center"
                flex="1"
                ml="3"
                mr="auto"
              >
                <c-editable
                  w="100%"
                  mb="0.7em"
                  class="channels__list__item__name"
                  placeholder="Click to enter channel name"
                  :default-value="channel.name"
                  :isDisabled="disabled"
                  @change="onInputChange($event, index, 'name')"
                >
                  <c-editable-preview
                    maxWidth="100%"
                    whiteSpace="normal"
                    wordWrap="break-word"
                  />
                  <c-editable-input @blur="onFieldBlur(index)" />
                </c-editable>
                <c-text
                  v-if="!channel.isNameValid"
                  color="vc-orange.400"
                  fontSize="8px"
                >
                  Name is required to add channel
                </c-text>
              </c-flex>
            </c-flex>
          </td>
          <td class="grid__table__body__cell">
            <v-select
              :clearable="false"
              :searchable="false"
              class="vc-select channels__list__select"
              v-model="channel.type"
              label="label"
              :reduce="(type) => type.value"
              :options="channelTypes"
              placeholder="select"
              :disabled="disabled"
              @input="
                () => {
                  onFieldBlur(index);
                }
              "
            ></v-select>
          </td>
          <td class="grid__table__body__cell">
            <EfficiencySelect
              v-model="channel.efficiency"
              :placeholder="'Select Efficiency'"
              @input="
                () => {
                  onFieldBlur(index);
                }
              "
            />
          </td>
          <td class="grid__table__body__cell">
            <EditableText
              :type="`${topic}${index}}`"
              title="channels"
              v-model="channel.description"
              @updateDescription="updateDescription"
              :editabelIndex="index"
              :disabled="disabled"
            />
          </td>
          <td class="grid__table__body__cell">
            <c-flex py="3">
              <AppSelect
                :options="teamMembers"
                label="id"
                :reduce="(member) => member.id"
                v-model="channel.employeeResponsible"
                :reset="true"
                :hasShadow="true"
                indicator-fill="orange"
                color="dark"
                size="large"
                :appendToBody="true"
                @input="
                  (e) => {
                    onFieldBlur(index);
                  }
                "
              >
                <template v-slot:option="{ option }">
                  <c-text>
                    {{ option.user.firstname }}
                    {{ option.user.lastname }}
                  </c-text>
                </template>
                <template v-slot:selected-option="{ option }">
                  <c-text>
                    {{ option.user.firstname }}
                    {{ option.user.lastname }}
                  </c-text>
                </template>
              </AppSelect>
            </c-flex>
          </td>
          <td class="grid__table__body__cell">
            <c-flex py="3">
              <c-flex justify="space-between">
                <AppSelect
                  :options="plans"
                  label="label"
                  :reduce="(plan) => plan.value"
                  :value="channel.actionPlan"
                  :reset="true"
                  :hasShadow="true"
                  indicator-fill="orange"
                  color="dark"
                  size="large"
                  :appendToBody="true"
                  @input="onActionPlanChange($event, index)"
                />
                <c-spinner
                  v-if="isUpdatingPlan"
                  thickness="1px"
                  color="blue.500"
                ></c-spinner>
              </c-flex>
            </c-flex>
            <MilestoneCell
              v-if="channel.milestone"
              :milestone="channel.milestone"
              ref="milestoneCell"
              @removeMilestone="onRemoveMilestone(index)"
            />
          </td>
        </tr>
      </GridTable>
      <!-- <c-box
        class="channels__list__item channels__list__item--add"
        v-if="!disabled"
      >
        <c-popover placement="right" v-slot="{ onClose }">
          <c-popover-trigger>
            <c-flex
              direction="column"
              align-items="center"
              justify-content="center"
            >
              <svg
                fill="#ef5323"
                v-chakra="{
                  width: '30px',
                  height: '30px',
                  mb: '0.8em',
                }"
              >
                <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
              </svg>
              <c-text>Add Channel</c-text>
            </c-flex>
          </c-popover-trigger>
          <c-popover-content
            z-index="4"
            width="250px"
            maxHeight="250px"
            overflowY="scroll"
          >
            <c-popover-close-button />
            <c-popover-header fontSize="xs" fontWeight="500"
              >Existing Channels</c-popover-header
            >
            <c-popover-body
              p="4"
              v-chakra="{
                width: '100%',
                d: 'flex',
                textAlign: 'left',
                alignItems: 'start',
                justifyContent: 'start',
              }"
            >
              <c-stack v-if="allChannels.length">
                <c-radio-group
                  v-model="checkedChannel"
                  @change="duplicateChannel(onClose)"
                >
                  <c-radio
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                    >{{ channel.name }}</c-radio
                  >
                </c-radio-group>
              </c-stack>
            </c-popover-body>
            <c-popover-footer>
              <c-text @click.native="addChannel(onClose)">Add New</c-text>
            </c-popover-footer>
          </c-popover-content>
        </c-popover>
      </c-box>

      <c-flex
        direction="column"
        class="channels__list__item"
        v-for="(channel, index) in channels"
        :key="channel.id || channel.tempId"
      >
        <svg
          class="channels__list__item__delete"
          @click="deleteChannel(index)"
          v-if="!disabled"
          v-chakra="{
            w: '4',
            h: '4',
            cursor: 'pointer',
            fill: 'vc-orange.400',
            position: 'absolute',
            top: '5px',
            right: '5px',
            display: 'none',
          }"
        >
          <use href="@/assets/icons/icon-trash.svg#trash"></use>
        </svg>
        <v-select
          :clearable="false"
          :searchable="false"
          class="vc-select channels__list__select"
          v-model="channel.type"
          label="label"
          :reduce="(type) => type.value"
          :options="channelTypes"
          placeholder="select"
          :disabled="disabled"
          @input="
            () => {
              onFieldBlur(index);
            }
          "
        ></v-select>
        <EfficiencySelect
          v-model="channel.efficiency"
          :placeholder="'Select Efficiency'"
          @input="
            () => {
              onFieldBlur(index);
            }
          "
        />

        <c-editable
          w="100%"
          mb="0.7em"
          class="channels__list__item__name"
          placeholder="Click to enter channel name"
          :default-value="channel.name"
          :isDisabled="disabled"
          @change="onInputChange($event, index, 'name')"
        >
          <c-editable-preview
            maxWidth="100%"
            whiteSpace="normal"
            wordWrap="break-word"
          />
          <c-editable-input @blur="onFieldBlur(index)" />
        </c-editable>
        <c-text
          v-if="!channel.isNameValid"
          color="vc-orange.400"
          fontSize="8px"
        >
          Name is required to add channel
        </c-text>

        <ListTextInput
          v-chakra="{ maxHeight: '150px' }"
          :title="''"
          :showTitle="false"
          v-model="channel.description"
          @blur="onFieldBlur(index)"
          :disabled="disabled"
          :placeholder="'Click to enter channel description'"
        />
      </c-flex> -->
    </div>

    <c-modal
      :is-open="isMilestoneModalOpen"
      :on-close="closeMilestoneModal"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            Add
            <c-text textTransform="capitalize" ml="1"> Milestone </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <MilestoneModal @onAddMilestone="onAddMilestone" />
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <MilestoneAchieved
          :milestoneId="milestoneId"
          @closeModal="closeAchievedDialog"
          @onAchievedMilestone="onAchievedMilestone"
        />
      </c-alert-dialog-content>
    </c-alert-dialog>
  </div>
</template>

<script>
import { generateID } from '@/helpers/data.js';
import {
  addChannel,
  getChannels,
  updateChannel,
  deleteChannel,
  getAllChannels,
} from '@/services/business-model.js';

// import ListTextInput from '@/views/App/GrowthFramework/components/ListTextInput.vue';
import { mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import EfficiencySelect from '../EfficiencySelect.vue';
import GridTable from '../GridTable.vue';
import AppSelect from '@/views/App/components/AppSelect.vue';
import EditableText from '../EditableText.vue';
import MilestoneCell from './MilestoneCell.vue';
import MilestoneModal from './MilestoneModal.vue';
import MilestoneAchieved from './MilestoneAchieved.vue';

export default {
  components: {
    EditableText,
    EfficiencySelect,
    GridTable,
    AppSelect,
    MilestoneCell,
    MilestoneModal,
    MilestoneAchieved,
  },
  props: {
    channelsData: {
      type: Object,
      default: () => ({}),
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
    topic: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getChannels();
  },
  data() {
    return {
      isAchievedDialogOpen: false,
      isMilestoneModalOpen: false,
      milestoneId: null,
      isUpdatingPlan: false,

      currentChannelIndex: null,
      channelTypes: [
        { label: 'Indirect', value: 'INDIRECT' },
        { label: 'Direct', value: 'DIRECT' },
      ],
      channels: [],
      allChannels: [],
      checkedChannel: '0',
      customerColorMap: {
        government: '#3762d5',
        business: '#37d5aa',
        consumer: '#6bc1d1',
      },
      channelLevels: [
        { label: 'Most Efficient', value: 'MOST_EFFICIENT', color: '#37d5aa' },
        { label: 'Very Efficient', value: 'VERY_EFFICIENT', color: '#56b5db' },
        { label: ' Efficient', value: 'EFFICIENT', color: '#2d2d2d' },
        { label: 'Less Efficient', value: 'LESS_EFFICIENT', color: '#ef5323' },
        {
          label: 'Least Efficient',
          value: 'LEAST_EFFICIENT',
          color: '#ee2f1f',
        },
      ],
      plans: [
        { label: 'Monitor', value: 'monitor' },
        { label: 'Act', value: 'act' },
        { label: 'Resolved', value: 'resolved' },
      ],
      tableColumns: [
        {
          action: () => null,
          component: null,
          title: 'Name',
          value: 'name',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Type',
          value: 'type',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Efficiency',
          value: 'efficiency',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Description',
          value: 'description',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Employee Responsible',
          value: 'employee',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Action Plan',
          value: 'milestone',
          disabled: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      customers: (state) => state.customers,
      teamMembers: (state) =>
        state.members.filter(
          (member) => member.isAccepted && member.user.isVerified
        ),
    }),
  },
  methods: {
    closeMilestoneModal() {
      this.isMilestoneModalOpen = false;
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
      this.isUpdatingPlan = false;
    },
    updateDescription(val, channelIndex) {
      this.channels[channelIndex].description = val;
      this.onFieldBlur(channelIndex);
    },
    onActionPlanChange(e, channelIndex) {
      switch (e) {
        case 'monitor': {
          this.isUpdatingPlan = true;
          const channel = this.channels[channelIndex];
          this.currentChannelIndex = channelIndex;
          channel.actionPlan = 'monitor';
          channel.milestoneId = null;

          this.saveChannel({ ...channel }, channelIndex);
          // this.isUpdatingPlan = false;
          break;
        }
        case 'act': {
          this.isUpdatingPlan = true;
          this.channels[channelIndex].actionPlan = 'act';
          this.currentChannelIndex = channelIndex;
          this.isMilestoneModalOpen = true;
          break;
        }
        case 'resolved':
          this.isUpdatingPlan = true;
          this.channels[channelIndex].actionPlan = 'resolved';
          this.currentChannelIndex = channelIndex;

          this.milestoneId = this.channels[channelIndex].milestoneId;
          this.isAchievedDialogOpen = true;
          break;
        default:
          break;
      }
    },
    onAddMilestone(milestoneId) {
      let self = this;
      updateChannel({
        id: this.channels[this.currentChannelIndex].id,
        set: {
          milestoneId: milestoneId,
          actionPlan: 'act',
        },
      })
        .then((response) => {
          this.isUpdatingPlan = false;
          this.channels[self.currentChannelIndex] = cloneDeep(
            response.data.update_channel_by_pk
          );

          const milestone = response.data.update_channel_by_pk.milestone;
          self.channels[self.currentPartnerIndex].milestone = { ...milestone };
          this.closeMilestoneModal();
        })
        .catch(() => {
          this.isUpdatingPlan = false;
          this.closeMilestoneModal();
        });
    },
    onAchievedMilestone() {
      updateChannel({
        id: this.channels[this.currentChannelIndex].id,
        set: {
          actionPlan: 'resolved',
        },
      })
        .then((response) => {
          this.isUpdatingPlan = false;
          this.channels[this.currentChannelIndex] = cloneDeep(
            response.data.update_channel_by_pk
          );

          this.closeAchievedDialog();
        })
        .catch(() => {
          this.isUpdatingPlan = false;
          this.closeAchievedDialog();
        });
    },
    getChannelColor(val) {
      return this.channelLevels.find((level) => level.value === val)?.color;
    },
    addNewChannel(results) {
      if (!results) {
        this.channels.push({
          id: null,
          name: '',
          description: '',
          type: 'DIRECT',
          efficiency: '',
          topic: this.topic,
          isNameValid: true,
          tempId: generateID(4),
          employeeResponsible: null,
          customerId: this.customer.id,
          businessModelId: this.channelsData.id,
        });
      } else {
        this.channles[this.channels.length - 1].name = results.label;
        this.channles[this.channels.length - 1].description =
          results.description;
      }
    },
    duplicateChannel(onClose) {
      const checkedChannel = this.channels.find(
        (channel) => channel.id == this.checkedChannel
      );

      const duplicateChannel = {
        ...checkedChannel,
        id: null,
        isNameValid: true,
        customerId: this.customer.id,
        tempId: generateID(4),
      };

      if (checkedChannel.id) {
        this.channels.push(duplicateChannel);

        const channelIndex = this.channels.findIndex(
          (channel) => channel.tempId == duplicateChannel.tempId
        );

        this.saveChannel({ ...duplicateChannel }, channelIndex);
      }

      onClose();
    },
    onInputChange(e, index, field) {
      if (typeof e === 'string') {
        this.channels[index][field] = e;
      }
    },
    onFieldBlur(channelIndex) {
      const channel = this.channels[channelIndex];
      if (channel.name && channel.name.trim()) {
        this.saveChannel({ ...channel }, channelIndex);
        channel.isNameValid = true;
      } else {
        channel.isNameValid = false;
      }
    },
    deleteChannel(index) {
      const channel = this.channels.splice(index, 1)[0];
      if (channel.id) {
        deleteChannel(channel.id)
          .then(() => {})
          .catch(() => {
            this.channels.splice(index, 0, { ...channel });
            this.$toast({
              title: 'An error occurred.',
              description: `Unable to delete channel, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    getChannels() {
      getChannels({
        businessModelId: this.channelsData.id,
        customerId: this.customer.id,
        topic: this.topic,
      }).then((res) => {
        this.channels = res.data.channel.map((channel) => {
          return {
            ...channel,
            isNameValid: true,
          };
        });
      });

      getAllChannels({
        businessModelId: this.channelsData.id,
        topic: this.topic,
      }).then((res) => {
        this.allChannels = res.data.channel.map((channel) => {
          return {
            ...channel,
            isNameValid: true,
          };
        });
      });
    },
    saveChannel(channel, index) {
      // eslint-disable-next-line no-unused-vars
      const { id, tempId, isNameValid, __typename, milestone, ...rest } =
        channel;
      if (id) {
        return updateChannel({ id, set: rest })
          .then((response) => {
            this.isUpdatingPlan = false;
            this.channels[index] = cloneDeep(
              response.data.update_channel_by_pk
            );
          })
          .catch(() => {});
      } else {
        return addChannel({ ...rest })
          .then((res) => {
            this.channels[index].id = res.data.insert_channel_one.id;
          })
          .catch(() => {
            this.channels.splice(index);
            this.$toast({
              title: 'An error occurred.',
              description: `Error while adding channel, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    onCheckListChange() {},
    onRemoveMilestone(index) {
      this.saveChannel({
        ...this.channels[index],
        milestoneId: null,
      }).then(() => {
        this.channels[index].milestone = null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.channels {
  @apply p-5;
  &__title {
    @apply font-semibold text-lg mb-4;
    color: $color-grey;
  }
  &__list {
    @apply flex flex-wrap;
    &__item {
      @apply flex flex-col items-center justify-center mr-6 mb-3 px-3 py-5 text-center relative;
      border-radius: 8px;
      min-height: 100px;
      width: 250px;
      background: #fff;
      box-shadow: 3px 3px 10px rgba(#bababa, 0.36);
      // &:hover {
      //   .channels__list__item__delete {
      //     display: block;
      //   }
      // }
      &--add {
        @apply font-semibold cursor-pointer;
        color: $color-orange;
      }
      &__name,
      &__description {
        @apply font-semibold mt-3;
      }
    }
    &__select {
      &.vs--open {
        ::v-deep {
          .vs__selected {
            margin-top: 4px;
            opacity: 1;
          }
          .vs__open-indicator {
            transform: rotate(180deg) scale(1) translateY(5px);
          }
        }
      }
      ::v-deep {
        .vs__open-indicator {
          @apply block;
          fill: $color-orange;
          width: 10px;
          transform: scale(1) translateY(0);
          path {
            d: path(
              'M10.555,13.073l-2.8-2.8.933-.932,1.865,1.865L12.42,9.343l.933.932Z'
            );
            fill: red;
            transform: translate(-7.757px, -9.343px);
          }
        }
        .vs__dropdown-toggle {
          height: 25px;
          width: max-content;
          min-width: 80px;
          font-size: smaller;
          border: none;
          padding: 0;
        }
        .vs__selected {
          color: $color-orange;
          font-weight: bold;
        }
        .vs__dropdown-menu {
          min-width: 150px;
        }
        .vs__dropdown-option {
          height: 25px;
          padding: 0 15px;
        }
        .vs__actions {
          padding: 8px 6px 0 3px;
        }
      }
      &__option {
        @apply w-full px-4;
        color: $color-green;
        &:hover {
          color: #fff;
          background-color: $color-green;
        }
      }
    }
  }
}
</style>
