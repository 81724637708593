<template>
  <c-box>
    <!--       :isLocked="!getCurrentPlan.access.values" -->
    <TaskCard
      :title="'Values'"
      :description="'What behaviour is key for you to reach yours goals '"
      :hasVisibility="true"
      :hasNotification="true"
      :hasImportance="true"
      :hasAssumption="false"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :taskData="foundationData"
      :accessKey="'foundation_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'foundation_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      explanationKey="values"
      :hasAccess="hasAccess"
      :disabled="disabled"
    >
      <ContentLoader
        v-if="isFetchingData"
        height="60"
        viewBox="0 0 200 60"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="50" />
        <rect x="110" y="0" rx="3" ry="3" width="100" height="50" />
      </ContentLoader>
      <ValuesList
        v-else
        @addValue="onAddValue"
        @updateValue="onUpdateValue"
        @deleteValue="onDeleteValue"
        :items="foundationData.foundation_items"
        :disabled="disabled"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { ContentLoader } from 'vue-content-loader';

import {
  createFoundationItem,
  updateFoundationItem,
  deleteFoundationItem,
} from '@/services/foundation.js';

import TaskCard from './../TaskCard.vue';
import ValuesList from './ValuesList.vue';

import foundationMixin from '@/mixins/foundation';
import cloneDeep from 'lodash.clonedeep';

export default {
  mixins: [foundationMixin],
  components: {
    TaskCard,
    ValuesList,
    ContentLoader,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      foundationType: 'values',
      hasMounted: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    onAddValue({ label, description }) {
      createFoundationItem({
        label,
        description,
        foundationId: this.foundationData.id,
      })
        .then((res) => {
          this.foundationData.foundation_items.push(
            res.data.insert_foundation_item_one
          );
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while creating value, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    onUpdateValue({ id, label, description }) {
      updateFoundationItem({ id, set: { label, description } })
        .then((res) => {
          const foundation_items = cloneDeep(
            this.foundationData.foundation_items
          );
          const foundationItemIndex = foundation_items.findIndex(
            (_item) => _item.id === id
          );
          this.foundationData.foundation_items.splice(foundationItemIndex, 1, {
            ...res.data.update_foundation_item_by_pk,
          });
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating value, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    onDeleteValue({ id, label, description }) {
      const itemIndex = this.foundationData.foundation_items.findIndex(
        (item) => item.id === id
      );
      deleteFoundationItem(id)
        .then((response) => {
          const checkPk = response.data.delete_foundation_item_by_pk;
          if (checkPk) {
            this.foundationData.foundation_items.splice(itemIndex, 1);
            this.$toast({
              title: 'Success',
              description: `Value deleted successfully.`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          } else {
            this.$toast({
              title: 'Access Denied.',
              description: `Error while deleting value, check if you have permission to delete.`,
              status: 'warning',
              position: 'top',
              duration: 3000,
            });
          }
        })
        .catch((err) => {
          const error = Object.assign({}, err);
          this.foundationData.foundation_items.splice(itemIndex, 1, {
            id,
            label,
            description,
          });
          if (error.message.includes('permission')) {
            this.$toast({
              title: 'Access Denied.',
              description: `You don't have permission to delete values.`,
              status: 'warning',
              position: 'top',
              duration: 4000,
            });
          } else {
            this.$toast({
              title: 'An error occurred.',
              description: `Error while deleting values, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          }
        });
    },
  },
};
</script>
