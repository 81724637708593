import { render, staticRenderFns } from "./OverviewChart.vue?vue&type=template&id=1ff0469e"
import script from "./OverviewChart.vue?vue&type=script&lang=js"
export * from "./OverviewChart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CBox: require('@chakra-ui/vue').CBox, CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CStack: require('@chakra-ui/vue').CStack})
