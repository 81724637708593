import { render, staticRenderFns } from "./ValidationDrawer.vue?vue&type=template&id=3575a2f4"
import script from "./ValidationDrawer.vue?vue&type=script&lang=js"
export * from "./ValidationDrawer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CSpinner: require('@chakra-ui/vue').CSpinner, CStatLabel: require('@chakra-ui/vue').CStatLabel, CStatNumber: require('@chakra-ui/vue').CStatNumber, CStatArrow: require('@chakra-ui/vue').CStatArrow, CStatHelperText: require('@chakra-ui/vue').CStatHelperText, CStat: require('@chakra-ui/vue').CStat, CStatGroup: require('@chakra-ui/vue').CStatGroup, CBox: require('@chakra-ui/vue').CBox, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
