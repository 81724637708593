<template>
  <div class="costs">
    <c-flex :direction="{ lg: 'row', xl: 'row', xs: 'column' }">
      <c-box
        mb="2"
        class="costs__header"
        v-for="(costYear, idx) in costYears"
        :key="idx"
      >
        <c-text fontSize="sm" color="#2a2a3199"
          >Total Cost - {{ costYear.label }}</c-text
        >
        <c-flex align="center">
          <c-text color="vc-orange.500" fontSize="3xl" fontWeight="600" mr="2">
            {{ costYear.total }}
          </c-text>
          <AppSelect
            color="grey"
            :reduce="(currency) => currency.code"
            label="code"
            :options="currencies"
            v-model="currency"
            indicator-fill="orange"
            size="large"
            @input="updateCostsCurrency"
            :disabled="disabled"
          />
        </c-flex>
      </c-box>
    </c-flex>
    <GridTable
      title="Costs"
      :columns="tableColumns"
      :addRowLabel="'Add new revenue'"
      :onAddRow="addCosts"
      :disabled="disabled"
    >
      <tr v-for="(cost, index) in costs" :key="cost.id || cost.tempId">
        <td class="grid__table__body__cell">
          <c-flex h="100%" justify="center" align-items="start">
            <c-popover placement="bottom" v-slot="{}">
              <c-popover-trigger cursor="pointer">
                <c-box
                  my="3"
                  v-chakra="{
                    height: 'auto',
                  }"
                >
                  <svg
                    v-chakra="{
                      width: '10px',
                      height: '16px',
                      fill: '#ef5323',
                    }"
                  >
                    <use href="@/assets/icons/vertical-dots.svg#dots" />
                  </svg>
                </c-box>
              </c-popover-trigger>
              <c-popover-content maxWidth="150px" zIndex="modal">
                <c-popover-body p="0">
                  <c-list fontSize="sm">
                    <c-list-item v-if="cost.id">
                      <c-box
                        cursor="pointer"
                        w="100%"
                        px="2"
                        py="2"
                        @click="deleteCost(index)"
                        >Delete</c-box
                      >
                    </c-list-item>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>

            <!-- <c-flex
                direction="column"
                align="center"
                textAlign="center"
                flex="1"
                ml="3"
                mr="auto"
              >
                <c-editable
                  w="100%"
                  mb="0.7em"
                  class="channels__list__item__name"
                  placeholder="Click to enter channel name"
                  :default-value="channel.name"
                  :isDisabled="disabled"
                  @change="onInputChange($event, index, 'name')"
                >
                  <c-editable-preview
                    maxWidth="100%"
                    whiteSpace="normal"
                    wordWrap="break-word"
                  />
                  <c-editable-input @blur="onFieldBlur(index)" />
                </c-editable>
                <c-text
                  v-if="!channel.isNameValid"
                  color="vc-orange.400"
                  fontSize="8px"
                >
                  Name is required to add channel
                </c-text>
              </c-flex> -->
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-box>
            <AppSelect
              :reduce="(type) => type.value"
              label="label"
              :options="costTypes"
              v-model="cost.type"
              indicator-fill="orange"
              color="orange"
              size="large"
              @input="onFieldBlur(index)"
              :disabled="disabled"
            />
          </c-box>
        </td>
        <td class="grid__table__body__cell">
          <c-flex width="100%" justify-content="space-between">
            <c-box mr="2" v-for="(costYear, ix) in costYears" :key="ix">
              <c-flex direction="column" mt="2" w="max-content" align="center">
                <c-text fontWeight="bold" mr="3">Year {{ ix + 1 }}</c-text>
                <c-flex align="center">
                  <c-editable
                    fontWeight="600"
                    fontSize="xl"
                    mr="1"
                    my="2"
                    :isDisabled="disabled"
                    :default-value="cost.valueArray[ix].value + ''"
                    @change="onValueInputChange($event, index, ix)"
                  >
                    <c-editable-preview overflowWrap="break-word" />
                    <c-editable-input
                      w="100px"
                      type="number"
                      @blur="onFieldBlur(index)"
                    />
                  </c-editable>
                  <c-text fontSize="sm" color="gray.400">{{ currency }}</c-text>
                </c-flex>
              </c-flex>
              <c-text
                v-if="!cost.isValueValid"
                color="vc-orange.400"
                fontSize="8px"
              >
                cost value is required to add cost
              </c-text>
            </c-box>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <EditableText
            title="Cost"
            :type="`cost${index}}`"
            v-model="cost.description"
            :editabelIndex="index"
            @updateDescription="updateDescription"
            :disabled="disabled"
          />
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <AppSelect
              :options="teamMembers"
              label="id"
              placeholder="Select team member"
              :reduce="(member) => member.id"
              v-model="cost.employeeResponsible"
              :reset="true"
              :hasShadow="true"
              color="dark"
              size="large"
              :appendToBody="true"
              indicator-fill="orange"
              @input="onFieldBlur(index)"
            >
              <template v-slot:option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
              <template v-slot:selected-option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
            </AppSelect>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <c-flex justify="space-between">
              <AppSelect
                :options="plans"
                label="label"
                :reduce="(plan) => plan.value"
                :value="cost.actionPlan"
                :reset="true"
                :hasShadow="true"
                indicator-fill="orange"
                color="dark"
                size="large"
                :appendToBody="true"
                @input="onActionPlanChange($event, index)"
              />
              <c-spinner
                v-if="isUpdatingPlan"
                thickness="1px"
                color="blue.500"
              ></c-spinner>
            </c-flex>
          </c-flex>
          <MilestoneCell
            v-if="cost.milestone"
            :milestone="cost.milestone"
            ref="milestoneCell"
            @removeMilestone="onRemoveMilestone(index)"
          />
        </td>
      </tr>
    </GridTable>
    <!-- <c-grid
      w="100%"
      :template-columns="{ base: '1fr', lg: 'repeat(4, 1fr)' }"
      gap="8"
      my="4"
    >
      <c-flex
        h="350px"
        mb="1.5em"
        cursor="pointer"
        borderColor="#2d2d2d4d"
        borderRadius="4px"
        borderWidth="1px"
        direction="column"
        justify="center"
        align="center"
        tabindex="-1"
        v-if="!disabled"
      >
        <c-pseudo-box
          as="a"
          display="flex"
          h="100%"
          :_hover="{ textDecoration: 'none' }"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          @click="addCosts"
        >
          <svg
            v-chakra="{
              width: '30px',
              height: '30px',
              fill: '#ef5323',
            }"
          >
            <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
          </svg>
          <c-text color="vc-orange.400" fontWeight="600" fontSize="lg">
            Add Costs
          </c-text>
        </c-pseudo-box>
      </c-flex>
      <c-flex
        h="350px"
        mb="1.5em"
        borderColor="#2d2d2d"
        borderRadius="4px"
        borderWidth="1px"
        direction="column"
        align="center"
        v-for="(cost, index) in costs"
        :key="cost.id || cost.tempId"
        paddingTop="25px"
        paddingInline="20px"
        position="relative"
        class="revenue"
      >
        <c-popover v-if="!disabled" placement="bottom">
          <c-popover-trigger
            position="absolute"
            right="15px"
            top="15px"
            cursor="pointer"
          >
            <c-box>
              <svg
                v-chakra="{
                  width: '10px',
                  height: '16px',
                  fill: '#ef5323',
                }"
              >
                <use href="@/assets/icons/vertical-dots.svg#dots"></use>
              </svg>
            </c-box>
          </c-popover-trigger>
          <c-popover-content maxWidth="150px">
            <c-popover-body>
              <c-list>
                <c-list-item>
                  <c-box cursor="pointer" w="100%" @click="deleteCost(index)">
                    Delete
                  </c-box>
                </c-list-item>
              </c-list>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
        <c-box>
          <AppSelect
            :reduce="(type) => type.value"
            label="label"
            :options="costTypes"
            v-model="cost.type"
            indicator-fill="orange"
            color="orange"
            size="large"
            @input="onFieldBlur(index)"
            :disabled="disabled"
          />
        </c-box>
        <c-flex width="100%" justify-content="space-between">
          <c-box mr="2" v-for="(costYear, ix) in costYears" :key="ix">
            <c-flex direction="column" mt="2" w="max-content" align="center">
              <c-text fontWeight="bold" mr="3">Year {{ ix + 1 }}</c-text>
              <c-flex align="center">
                <c-editable
                  fontWeight="600"
                  fontSize="xl"
                  mr="1"
                  my="2"
                  :isDisabled="disabled"
                  :default-value="cost.valueArray[ix].value + ''"
                  @change="onValueInputChange($event, index, ix)"
                >
                  <c-editable-preview overflowWrap="break-word" />
                  <c-editable-input
                    w="100px"
                    type="number"
                    @blur="onFieldBlur(index)"
                  />
                </c-editable>
                <c-text fontSize="sm" color="gray.400">{{ currency }}</c-text>
              </c-flex>
            </c-flex>
            <c-text
              v-if="!cost.isValueValid"
              color="vc-orange.400"
              fontSize="8px"
            >
              cost value is required to add cost
            </c-text>
          </c-box>
        </c-flex>

        <EditableText
          v-model="cost.description"
          v-chakra="{
            my: '2',
            h: 'calc(100% - 100px)',
          }"
          @blur="onFieldBlur(index)"
          :disabled="disabled"
          :placeholder="'click here to add description'"
        />
      </c-flex>
    </c-grid> -->

    <c-modal
      :is-open="isMilestoneModalOpen"
      :on-close="closeMilestoneModal"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            Add
            <c-text textTransform="capitalize" ml="1"> Milestone </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <MilestoneModal @onAddMilestone="onAddMilestone" />
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <MilestoneAchieved
          :milestoneId="milestoneId"
          @closeModal="closeAchievedDialog"
          @onAchievedMilestone="onAchievedMilestone"
        />
      </c-alert-dialog-content>
    </c-alert-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppSelect from '../../../components/AppSelect.vue';
import currenciesArray from '@/helpers/currencies.js';
import EditableText from './../EditableText.vue';
import { generateID } from '@/helpers/data';
import {
  addCost,
  deleteCost,
  getCosts,
  updateCost,
  updateMultipleCosts,
} from '@/services/business-model.js';
import cloneDeep from 'lodash.clonedeep';
import GridTable from '../GridTable.vue';
import MilestoneCell from './MilestoneCell.vue';
import MilestoneModal from './MilestoneModal.vue';
import MilestoneAchieved from './MilestoneAchieved.vue';

export default {
  props: {
    costData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppSelect,
    EditableText,
    GridTable,
    MilestoneCell,
    MilestoneModal,
    MilestoneAchieved,
  },
  data() {
    return {
      isAchievedDialogOpen: false,
      isMilestoneModalOpen: false,
      milestoneId: null,
      isUpdatingPlan: false,
      currentCostIndex: null,
      currencies: currenciesArray,
      currency: 'USD',
      costs: [],
      costTypes: [
        { label: 'Cost of goods', value: 'Cost of goods' },
        { label: 'Operating expenses', value: 'Operating expenses' },
        { label: 'Financial expenses', value: 'Financial expenses' },
        { label: 'Extraordinary expenses', value: 'Extraordinary expenses' },
        { label: 'Non-operating expenses', value: 'Non-operating expenses' },
        { label: 'Direct materials', value: 'Direct materials' },
        { label: 'Equipment', value: 'Equipment' },
        { label: 'Incorporation fees', value: 'Incorporation fees' },
        { label: 'Office space', value: 'Office space' },
        { label: 'Rent space', value: 'Rent space' },
        { label: 'Inventory', value: 'Inventory' },
        { label: 'Marketing', value: 'Marketing' },
        { label: 'Sales', value: 'Sales' },
        { label: 'Website', value: 'Website' },
        {
          label: 'Office furniture and supplies',
          value: 'Office furniture and supplies',
        },
        { label: 'Utilities', value: 'Utilities' },
        { label: 'Payroll', value: 'Payroll' },
        {
          label: 'Professional consultants',
          value: 'Professional consultants',
        },
        { label: 'Insurance', value: 'Insurance' },
        { label: 'Taxes', value: 'Taxes' },
        { label: 'Shipping', value: 'Shipping' },
        { label: 'Staffing and employment', value: 'Staffing and employment' },
        { label: 'Stock', value: 'Stock' },
        { label: 'Technology cost', value: 'Technology cost' },
        { label: 'Software licenses', value: 'Software licenses' },
        { label: 'IT support', value: 'IT support' },
        { label: 'Website hosting', value: 'Website hosting' },
        { label: 'Data storage', value: 'Data storage' },
        { label: 'Email accounts', value: 'Email accounts' },
        { label: 'Mobile phone contracts', value: 'Mobile phone contracts' },
        { label: 'Payment gateways', value: 'Payment gateways' },
        {
          label: 'Third-party integrations or services',
          value: 'Third-party integrations or services',
        },
        { label: 'Accounting expenses', value: 'Accounting expenses' },
        { label: 'License fees', value: 'License fees' },
        { label: 'Maintenance and repairs', value: 'Maintenance and repairs' },
        { label: 'Attorney legal fees', value: 'Attorney legal fees' },
        { label: 'Property taxes', value: 'Property taxes' },
        { label: 'Commissions', value: 'Commissions' },
      ],
      tableColumns: [
        {
          action: () => null,
          component: null,
          title: '',
          value: '',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Type',
          value: 'type',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Costs by Year',
          value: 'Costs',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Description',
          value: 'description',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Employee Responsible',
          value: 'employee',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Milestone',
          value: 'milestone',
          disabled: false,
        },
      ],
      plans: [
        { label: 'Monitor', value: 'monitor' },
        { label: 'Act', value: 'act' },
        { label: 'Resolved', value: 'resolved' },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      teamMembers: (state) =>
        state.members.filter(
          (member) => member.isAccepted && member.user.isVerified
        ),
    }),
    totalCost() {
      return this.costs.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.value,
        0
      );
    },
    costYears() {
      return [
        {
          key: 'YEAR_1',
          label: 'Year 1',
          total: this.totalCostForYear('YEAR_1'),
        },
        {
          key: 'YEAR_2',
          label: 'Year 2',
          total: this.totalCostForYear('YEAR_2'),
        },
        {
          key: 'YEAR_3',
          label: 'Year 3',
          total: this.totalCostForYear('YEAR_3'),
        },
      ];
    },
  },
  created() {
    this.getCosts();
  },
  methods: {
    totalCostForYear(key) {
      return this.costs.reduce(
        (accumulator, currentValue) =>
          accumulator +
          +currentValue.valueArray.find((_cost) => _cost.key === key)?.value,
        0
      );
    },
    getCosts() {
      getCosts(this.costData.id).then((res) => {
        this.costs = res.data.cost.map((cost) => {
          return {
            ...cost,
            isValueValid: true,
            valueArray: this.costYears.map((costYear) => ({
              ...costYear,
              value:
                cost.valueArray?.find((_cost) => _cost.key === costYear.key)
                  ?.value || 0,
            })),
          };
        });
        if (this.costs.length) {
          this.currency = this.costs[0].currency;
        }
      });
    },
    addCosts() {
      this.costs.push({
        id: null,
        value: 0,
        tempId: generateID(4),
        type: 'DIRECT',
        description: '',
        currency: this.currency,
        isValueValid: true,
        valueArray: this.costYears.map((costYear) => ({
          ...costYear,
          value: 0,
        })),
        employeeResponsible: null,
      });
    },
    onValueInputChange(e, index, valueIndex) {
      if (typeof e === 'string') {
        this.costs[index].valueArray[valueIndex] = {
          ...this.costYears[valueIndex],
          value: +e,
        };
      }
    },
    closeMilestoneModal() {
      this.isMilestoneModalOpen = false;
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
    },
    updateDescription(value, currentIndex) {
      this.costs[currentIndex].description = value;
      this.onFieldBlur(currentIndex);
    },
    onActionPlanChange(e, costIndex) {
      switch (e) {
        case 'monitor': {
          this.isUpdatingPlan = true;
          this.currentCostIndex = costIndex;
          this.costs[costIndex].actionPlan = 'monitor';
          this.costs[costIndex].milestoneId = null;
          this.onFieldBlur(costIndex);
          break;
        }
        case 'act': {
          this.isUpdatingPlan = true;
          this.costs[costIndex].actionPlan = 'act';
          this.currentCostIndex = costIndex;
          this.isMilestoneModalOpen = true;
          break;
        }
        case 'resolved':
          this.isUpdatingPlan = true;
          this.costs[costIndex].actionPlan = 'resolved';
          this.currentCostIndex = costIndex;

          this.milestoneId = this.costs[costIndex].milestoneId;
          this.isAchievedDialogOpen = true;
          break;
        default:
          break;
      }
    },
    onAddMilestone(milestoneId) {
      updateCost({
        id: this.costs[this.currentCostIndex].id,
        set: {
          milestoneId: milestoneId,
          actionPlan: 'act',
        },
      })
        .then((response) => {
          this.costs[this.currentCostIndex] = response.data.update_cost_by_pk;

          const milestone = response.data.update_cost_by_pk.milestone;
          this.costs[this.currentCostIndex].milestone = {
            ...milestone,
          };

          this.isUpdatingPlan = false;
          this.closeMilestoneModal();
        })
        .catch(() => {
          this.isUpdatingPlan = false;
          this.closeMilestoneModal();
        });
    },
    onAchievedMilestone() {
      updateCost({
        id: this.costs[this.currentCostIndex].id,
        set: {
          actionPlan: 'resolved',
        },
      })
        .then((response) => {
          this.isUpdatingPlan = false;
          this.costs[this.currentCostIndex] = cloneDeep(
            response.data.update_cost_by_pk
          );
          this.closeAchievedDialog();
        })
        .catch(() => {
          this.isUpdatingPlan = false;
          this.closeAchievedDialog();
        });
    },
    onFieldBlur(index) {
      const cost = this.costs[index];
      cost.isValueValid = cost.valueArray?.some(
        (_cost) => _cost !== null && _cost.value !== 0
      );
      if (cost.isValueValid) {
        this.saveCost({ ...cost }, index);
      }
    },
    saveCost(cost, index) {
      const {
        id,
        type,
        value,
        currency,
        valueArray,
        description,
        actionPlan,
        milestoneId,
        employeeResponsible,
      } = cost;
      if (id) {
        return updateCost({
          id,
          set: {
            type,
            value,
            currency,
            valueArray,
            description,
            actionPlan,
            milestoneId,
            employeeResponsible,
          },
        })
          .then((response) => {
            this.isUpdatingPlan = false;
            this.costs[index] = response.data.update_cost_by_pk;
          })
          .catch(() => {
            this.isUpdatingPlan = false;
          });
      } else {
        return addCost({
          value,
          valueArray,
          type,
          description,
          currency,
          businessModelId: this.costData.id,
          employeeResponsible,
        })
          .then((res) => {
            this.costs[index].id = res.data.insert_cost_one.id;
          })
          .catch(() => {
            this.costs.splice(index, 1);
            this.$toast({
              title: 'An error occurred.',
              description: `Error while adding cost, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    deleteCost(index) {
      const cost = this.costs.splice(index, 1)[0];
      if (cost.id) {
        deleteCost(cost.id)
          .then(() => {})
          .catch(() => {
            this.costs.splice(index, 0, { ...cost });
            this.$toast({
              title: 'An error occurred.',
              description: `Error while deleting cost, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    updateCostsCurrency() {
      const costsWithId = this.costs.filter((cost) => cost.id);
      if (costsWithId.length) {
        const ids = costsWithId.map((cost) => cost.id);
        updateMultipleCosts({ ids, set: { currency: this.currency } })
          .then(() => {
            this.costs.forEach((cost) => {
              cost.currency = this.currency;
            });
          })
          .catch(() => {
            this.currency = costsWithId[0].currency;
            this.$toast({
              title: 'An error occurred.',
              description: `Error while update costs currency, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    onRemoveMilestone(index) {
      this.saveCost({
        ...this.costs[index],
        milestoneId: null,
      }).then(() => {
        this.costs[index].milestone = null;
      });
    },
  },
};
</script>

<style lang="scss">
.costs {
  @apply w-full;
  &__header {
    @apply flex flex-col items-start justify-center mx-auto;
    width: max-content;
  }
  table {
    th,
    td {
      &:first-of-type {
        width: 50px;
        min-width: 50px;
      }
    }
  }
}
</style>
