<template>
  <c-flex justify="center" w="100%">
    <c-popover placement="bottom" v-slot="{ onClose }">
      <c-popover-trigger>
        <c-flex
          h="45px"
          w="100%"
          px="3"
          fontSize="md"
          align="center"
          justify="space-between"
          borderRadius="4px"
          :backgroundColor="selectedLevel ? selectedLevel.colorRgba : '#bbb'"
          :color="selectedLevel ? selectedLevel.color : '#fff'"
        >
          {{ selectedLevel ? selectedLevel.title : placeholder }}
          <svg
            fill="#495057"
            v-chakra="{
              width: '12px',
              height: '12px',
              ml: '2',
            }"
          >
            <use href="@/assets/icons/chevron-down.svg#chevron"></use>
          </svg>
        </c-flex>
      </c-popover-trigger>
      <c-popover-content w="150px" v-if="!disabled" zIndex="modal">
        <c-popover-body p="0">
          <c-list>
            <c-list-item
              v-for="level in levels"
              :key="level.value"
              :color="level.color"
            >
              <c-box
                px="3"
                cursor="pointer"
                py="2"
                @click="onLevelClick(level.value, onClose)"
              >
                {{ level.title }}
              </c-box>
            </c-list-item>
          </c-list>
        </c-popover-body>
      </c-popover-content>
    </c-popover>
  </c-flex>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Set Importance',
    },
  },
  data() {
    return {
      selectedLevel: null,
      levels: [
        {
          title: 'Severe',
          color: '#ee2f1f',
          colorRgba: 'rgba(238, 48, 31, 0.1)',
          value: 'severe',
        },
        {
          title: 'High',
          color: '#ef7923',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
          value: 'high',
        },
        {
          title: 'Moderate',
          color: '#37d5aa',
          colorRgba: 'rgba(55, 213, 170, 0.1)',
          value: 'moderate',
        },
        {
          title: 'Low',
          color: '#56b5db',
          colorRgba: 'rgba(86, 181, 219, 0.1)',
          value: 'low',
        },
      ],
    };
  },
  watch: {
    value() {
      this.setSelectedLevel();
    },
  },
  created() {
    if (this.value) {
      this.setSelectedLevel();
    }
  },
  methods: {
    setSelectedLevel() {
      this.selectedLevel = this.levels.find(
        (level) => level.value === this.value
      );
    },
    onLevelClick(value, close) {
      this.$emit('input', value);
      close();
    },
  },
};
</script>

<style></style>
