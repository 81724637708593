<template>
  <c-box id="pestle" class="pestle">
    <!--     :isLocked="!getCurrentPlan.access.swot" -->
    <TaskCard
      :title="'PESTLE'"
      :canAssign="true"
      :hasProgress="true"
      :hasAssumption="false"
      :hasImportance="true"
      :hasFooter="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="riskAssessmentData"
      :accessKey="'risk_assessment_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'risk_assessment_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="pestle"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 300 200"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="50" />
        <rect x="120" y="0" rx="3" ry="3" width="100" height="50" />
        <rect x="240" y="0" rx="3" ry="3" width="100" height="50" />
      </ContentLoader>
      <c-box v-else mb="4" overflowX="auto">
        <table class="pestle__table">
          <thead class="pestle__table__header">
            <tr class="pestle__table__header__row">
              <th
                class="pestle__table__header__cell"
                v-for="(area, areaIndex) in pestle"
                :key="area.key + areaIndex"
              >
                <c-flex justify="center" align="center" py="2">
                  <c-text
                    color="grey.300"
                    fontWeight="300"
                    fontSize="lg"
                    textAlign="center"
                    mb="2"
                  >
                    {{ area.key.charAt(0) }}
                  </c-text>
                </c-flex>
              </th>
            </tr>
            <tr class="pestle__table__header__row">
              <th
                class="pestle__table__header__cell"
                v-for="(area, areaIndex) in pestle"
                :key="area.key + areaIndex"
              >
                <c-flex align="center" justify="center" pb="3">
                  <c-heading color="#495057" fontSize="lg" fontWeight="600">
                    {{ area.title }}
                  </c-heading>
                  <c-link v-if="!disabled" @click="addFactor(areaIndex)">
                    <svg
                      fill="#ef5323"
                      v-chakra="{
                        width: '20px',
                        height: '20px',
                        ml: '0.8em',
                      }"
                    >
                      <use
                        href="@/assets/icons/add-circle-line.svg#add-circle"
                      ></use>
                    </svg>
                  </c-link>
                </c-flex>
              </th>
            </tr>
          </thead>
          <tbody class="pestle__table__body__cell">
            <tr>
              <td
                class="pestle__table__body__cell"
                v-for="(area, areaIndex) in pestle"
                :key="area.key + areaIndex"
                @drop="moveFactor($event, areaIndex)"
                @dragover.prevent
                @dragenter.prevent
              >
                <c-flex maxWidth="100%" w="100%" direction="column" p="4">
                  <c-box
                    h="180px"
                    w="100%"
                    maxWidth="100%"
                    p="6"
                    mb="7"
                    borderRadius="8px"
                    boxShadow="3px 3px 10px rgba(186, 186, 186, 0.36)"
                    class="point-card"
                    v-for="(factor, index) in area.factors"
                    :key="factor.id || factor.tempId"
                    :draggable="!disabled"
                    @dragstart="pickupFactor($event, index, areaIndex)"
                    @drop.stop="moveFactor($event, areaIndex, index)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <svg
                      class="point-card__delete"
                      @click.stop="
                        deleteFactor({ factor, index, areaIndex, area })
                      "
                      v-chakra="{
                        w: '4',
                        h: '4',
                        cursor: 'pointer',
                        fill: 'vc-orange.400',
                        position: 'absolute',
                        top: '-5px',
                        right: '-2px',
                        display: 'none',
                      }"
                    >
                      <use
                        href="@/assets/icons/icon-close-fill.svg#close"
                      ></use>
                    </svg>
                    <c-editable
                      :default-value="factor.title || 'Title'"
                      font-size="lg"
                      w="100%"
                      fontWeight="600"
                      :isDisabled="disabled"
                      mb="2"
                      @submit="onTitleSubmit($event, areaIndex, index)"
                    >
                      <c-editable-preview textAlign="center" w="100%" />
                      <c-editable-input textAlign="center" />
                    </c-editable>

                    <EditableText
                      :type="`pestle${areaIndex}${index}`"
                      :disabled="disabled"
                      v-model="factor.description"
                      :editabelIndex="{ areaIndex, index }"
                      @updateDescription="updateDescription"
                    />
                  </c-box>
                </c-flex>
              </td>
            </tr>
          </tbody>
        </table>
      </c-box>
    </TaskCard>
  </c-box>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

import { ContentLoader } from 'vue-content-loader';

import {
  addRiskAssessmentValue,
  updateRiskAssessmentValue,
} from '@/services/risk-assessment';

import EditableText from '../EditableText.vue';
import TaskCard from '../TaskCard.vue';

import { generateID } from '@/helpers/data';
import cloneDeep from 'lodash.clonedeep';

import riskAssessmentMixin from '@/mixins/risk-assessment.js';

export default {
  mixins: [riskAssessmentMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TaskCard,
    ContentLoader,
    EditableText,
  },
  data() {
    return {
      pestle: [
        {
          title: 'Political',
          key: 'P',
          factors: [],
        },
        {
          title: 'Economic',
          key: 'E',
          factors: [],
        },
        {
          title: 'Sociological',
          key: 'S',
          factors: [],
        },
        {
          title: 'Technological',
          key: 'T',
          factors: [],
        },
        {
          title: 'Legal',
          key: 'L',
          factors: [],
        },
        {
          title: 'Environmental',
          key: 'En',
          factors: [],
        },
      ],
      riskAssessmentType: 'pestle',
      hasMounted: false,
    };
  },
  watch: {
    'riskAssessmentData.risk_assessment_values': function () {
      this.setPestleFactors(this.riskAssessmentData.risk_assessment_values);
    },
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    ...mapMutations({
      setPestleFactors: 'company/setPestleFactors',
    }),
    onDataFetch() {
      this.setFactors();
    },
    setFactors() {
      this.pestle.forEach((type) => {
        type.factors = this.riskAssessmentData.risk_assessment_values.filter(
          (value) => value.type === type.key
        );
      });
    },
    addFactor(areaIndex) {
      this.pestle[areaIndex].factors.push({
        tempId: generateID(4),
        title: '',
        type: this.pestle[areaIndex].key,
        description: '',
      });
    },
    pickupFactor(event, factorIndex, fromAreaIndex) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';

      event.dataTransfer.setData('factor-index', factorIndex);
      event.dataTransfer.setData('from-area-index', fromAreaIndex);
    },
    async moveFactor(event, toAreaIndex, toFactorIndex) {
      const factorIndex = event.dataTransfer.getData('factor-index');
      const fromAreaIndex = event.dataTransfer.getData('from-area-index');
      const fromArea = this.pestle[fromAreaIndex];
      const toArea = this.pestle[toAreaIndex];
      const factor = {
        ...fromArea.factors.splice(factorIndex, 1)[0],
        type: toArea.key,
      };
      toArea.factors.splice(
        toFactorIndex !== undefined ? toFactorIndex : toArea.factors.length,
        0,
        factor
      );
      if (factor.id) {
        try {
          await updateRiskAssessmentValue({
            id: factor.id,
            set: { type: factor.type },
          });
        } catch (e) {
          this.pestle[fromAreaIndex].splice(factorIndex, 0, {
            ...factor,
            type: this.pestle[fromAreaIndex].key,
          });
          this.$toast({
            title: 'Error!!!',
            description: `An error occurred while updating factor`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    onTitleChange(event, areaIndex, factorIndex) {
      if (typeof event === 'string') {
        this.pestle[areaIndex].factors[factorIndex].title = event;
      }
    },
    onTitleSubmit(e, areaIndex, factorIndex) {
      if (e && e.trim()) {
        this.pestle[areaIndex].factors[factorIndex].title = e;
        this.saveFactor({ areaIndex, factorIndex }, 'title');
      } else {
        this.$toast({
          title: 'Input Error',
          description: `Title is Required to add a risk factor`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    updateDescription(value, idx) {
      this.onDescriptionBlur(value, idx.areaIndex, idx.index);
    },
    onDescriptionBlur(e, areaIndex, factorIndex) {
      this.pestle[areaIndex].factors[factorIndex].description = e;
      const title = this.pestle[areaIndex].factors[factorIndex].title;
      if (title && title.trim()) {
        this.saveFactor({ areaIndex, factorIndex }, 'description');
      } else {
        this.$toast({
          title: 'Input Error',
          description: `Title is Required to add a risk factor`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    saveFactor({ areaIndex, factorIndex }, updateKey = null) {
      const { type, title, description, id } =
        this.pestle[areaIndex].factors[factorIndex];
      if (id) {
        this.updateFactor({
          areaIndex,
          type,
          title,
          description,
          id,
          updateKey,
        });
      } else {
        this.addNewFactor({ type, title, description, areaIndex, factorIndex });
      }
    },
    async addNewFactor({ type, title, description, areaIndex, factorIndex }) {
      try {
        const res = await addRiskAssessmentValue({
          type,
          title,
          description,
          riskAssessmentId: this.riskAssessmentData.id,
        });
        this.pestle[areaIndex].factors[factorIndex].id =
          res.data.insert_risk_assessment_value_one.id;
        this.riskAssessmentData.risk_assessment_values.push(
          res.data.insert_risk_assessment_value_one
        );
        this.prevData.risk_assessment_values = cloneDeep(
          this.riskAssessmentData.risk_assessment_values
        );
      } catch (e) {
        this.pestle[areaIndex].factors.splice(factorIndex, 1);
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while adding the factor, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateFactor({
      id,
      type,
      title,
      description,
      areaIndex,
      factorIndex,
      updateKey,
      isDeleted = false,
    }) {
      const index = this.riskAssessmentData.risk_assessment_values.findIndex(
        (value) => value.id === id
      );

      try {
        if (isDeleted) {
          this.pestle[areaIndex].factors.splice(factorIndex, 1);
        }
        await updateRiskAssessmentValue({
          id,
          set: { type, title, description, isDeleted },
        });
        // this.riskAssessmentData.risk_assessment_values[index][updateKey] =
      } catch (e) {
        if (!isDeleted) {
          this.pestle[areaIndex][factorIndex][updateKey] =
            this.riskAssessmentData.risk_assessment_values[index][updateKey];
        } else {
          this.pestle[areaIndex].factors.splice(factorIndex, 1, {
            id,
            type,
            title,
            description,
          });
        }
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while ${
            isDeleted ? 'deleting' : 'updating'
          } the factor, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    deleteFactor({ index, factor, areaIndex, updateKey = null }) {
      if (!factor.title || !factor.description) {
        this.pestle[areaIndex].factors.splice(index, 1);
      } else {
        this.updateFactor({
          areaIndex,
          factorIndex: index,
          ...factor,
          isDeleted: true,
          updateKey,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pestle {
  &__table {
    @apply w-full;
    &__header {
      &__row {
        &:nth-child(2) {
          .pestle__table__header__cell {
            border-width: 0 1px 0 0;
            border-color: rgba(112, 112, 112, 0.2);
          }
        }
      }
      &__cell {
        @apply p-0;
        width: 280px;
      }
    }
    &__body {
      &__cell {
        @apply align-top;
        border-color: rgba(112, 112, 112, 0.2);
        border-width: 1px 1px 1px 0;
        height: 200px;
        min-width: 280px;
        width: 280px;
        max-width: 280px;
        .point-card {
          position: relative;
          ::v-deep {
            .editable__input {
              @apply text-xs;
              min-height: 80px;
              max-height: 80px;
            }
          }

          &:hover {
            .point-card__delete {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
