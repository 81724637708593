<template>
  <div class="customers">
    <h4 class="customers__title">Who is your target market customer ?</h4>
    <CustomerTabs
      :disabled="disabled"
      :customerSegment="customerSegment"
      :show-add="true"
    >
      <template v-slot="{ customer }">
        <CustomerDetails :disabled="disabled" :customer="customer" />
      </template>
    </CustomerTabs>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CustomerTabs from './CustomerTabs.vue';
import CustomerDetails from './CustomerDetails.vue';
import { getCustomers } from '@/services/business-model';

export default {
  props: {
    customerSegment: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { CustomerTabs, CustomerDetails },
  data() {
    return {
      customers: [],
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  created() {
    this.getCustomers();
  },
  methods: {
    ...mapActions({
      setCustomers: 'company/setCustomers',
    }),
    getCustomers() {
      getCustomers(this.activeCompany.id).then((res) => {
        this.setCustomers(res.data.customer);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customers {
  @apply w-full;
  &__title {
    @apply font-semibold text-xl;
    color: #495057;
  }
}
</style>
