var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onMilestoneFormSubmit.apply(null, arguments)}}},[_c('c-modal-body',[_c('c-box',{attrs:{"p":"3"}},[_c('c-box',{attrs:{"mb":"5"}},[_c('c-input',{attrs:{"size":"lg","placeholder":"Milestone Name"},model:{value:(_vm.$v.milestoneForm.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.milestoneForm.title, "$model", $$v)},expression:"$v.milestoneForm.title.$model"}})],1),_c('c-box',{attrs:{"mb":"5"}},[_c('AppDatePicker',{attrs:{"format":"YYYY-MM-DD","type":"date"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('c-input',{attrs:{"size":"lg","placeholder":"Select Date"},model:{value:(_vm.milestoneDateDisplay),callback:function ($$v) {_vm.milestoneDateDisplay=$$v},expression:"milestoneDateDisplay"}})]},proxy:true}]),model:{value:(_vm.$v.milestoneForm.date.$model),callback:function ($$v) {_vm.$set(_vm.$v.milestoneForm.date, "$model", $$v)},expression:"$v.milestoneForm.date.$model"}})],1),_c('c-box',{attrs:{"mb":"5"}},[_c('AppSelect',{attrs:{"label":"title","reduce":(scenario) => scenario.id,"options":_vm.scenarios,"reset":true,"fullWidth":true,"bordered":true,"indicator-fill":"orange","color":"dark","borderColor":'light',"size":"large","placeholder":"Add Scenario (optional)"},model:{value:(_vm.$v.milestoneForm.scenarioId.$model),callback:function ($$v) {_vm.$set(_vm.$v.milestoneForm.scenarioId, "$model", $$v)},expression:"$v.milestoneForm.scenarioId.$model"}})],1),_c('c-box',{attrs:{"mb":"5"}},[_c('CategorySelect',{model:{value:(_vm.$v.milestoneForm.categoryId.$model),callback:function ($$v) {_vm.$set(_vm.$v.milestoneForm.categoryId, "$model", $$v)},expression:"$v.milestoneForm.categoryId.$model"}})],1),_c('c-box',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.milestoneForm.description.$model),expression:"$v.milestoneForm.description.$model"},{name:"chakra",rawName:"v-chakra",value:({
              w: '100%',
              h: '2rem',
              outline: 'none',
              borderRadius: '0.25rem',
              borderWidth: '1px',
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              fontSize: '1rem',
              minHeight: '80px',
            }),expression:"{\n              w: '100%',\n              h: '2rem',\n              outline: 'none',\n              borderRadius: '0.25rem',\n              borderWidth: '1px',\n              paddingTop: '8px',\n              paddingBottom: '8px',\n              paddingLeft: '1rem',\n              paddingRight: '1rem',\n              fontSize: '1rem',\n              minHeight: '80px',\n            }"}],attrs:{"placeholder":"Description"},domProps:{"value":(_vm.$v.milestoneForm.description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.milestoneForm.description, "$model", $event.target.value)}}})])],1)],1),_c('c-modal-footer',[_c('c-button',{attrs:{"disabled":_vm.$v.milestoneForm.$invalid || _vm.isAddingMilestone,"variant-color":"vc-orange","type":"submit","mr":"3"}},[_c('c-text',{attrs:{"color":"#fff"}},[_vm._v(" "+_vm._s('Create Milestone')+" ")]),(_vm.isAddingMilestone)?_c('c-spinner',{attrs:{"ml":"2","color":"#fff","thickness":"2px"}}):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }