import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      tractions: [],
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedTractions() {
      return this.filteredTractions.filter((traction) => traction.selected);
    },
    filteredTractions() {
      return this.tractions.filter((traction) =>
        this.type === "market_goal"
          ? traction.status === "IN_PROGRESS"
          : traction.status === "ACHIEVED"
      );
    },
  },
  watch: {
    presentation() {
      this.setTractions();
    },
  },
  created() {
    this.setTractions();
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setTractions() {
      const tractions = this.presentationData.tractions;

      if (!this.presentation.data.slidesData?.tractions) {
        this.tractions = tractions.map((traction, index) => ({
          ...traction,
          selected: index <= 6,
        }));
      } else {
        const { tractions: tractionsInSlide } =
          this.presentation.data.slidesData;
        console.log(tractionsInSlide, " rere");
        this.tractions = tractions.map((item) => {
          const itemInPresentation = tractionsInSlide?.find(
            (_item) => _item.id === item.id
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async updateTractionData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                tractions: [
                  ...data,
                  ...this.presentation.data.slidesData?.tractions?.filter(
                    (traction) =>
                      this.type !== "market_goal"
                        ? traction.status === "IN_PROGRESS"
                        : traction.status === "ACHIEVED"
                  ),
                ],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected ${
            this.type === "traction" ? "Tractions" : "Market Goals"
          }, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
