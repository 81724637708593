import { mapState, mapGetters } from 'vuex';

import {
  getMarketPotentialComponent,
  createMarketPotentialComponent,
  updateMarketPotentialComponent,
  addMarketPotentialComponentAccess,
  removeMarketPotentialComponentAccess,
  updateMarketPotentialComponentVisibility,
  deleteMarketPotentialCustomer,
  subscribeToMarketPotential,
} from '@/services/market-potential';

import { generateID } from '@/helpers/data';
import cloneDeep from 'lodash.clonedeep';
import { titlecase } from '@/helpers/utils';

export default {
  data() {
    return {
      marketPotentialData: {},
      isFetchingData: false,
      prevData: {},
      hasAccess: true,
      isUpdatingMetadata: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
  },
  created() {
    this.getMarketPotentialData();
  },
  mounted() {
    this.subscribeToMarketPotentialData();
  },
  methods: {
    titlecase,
    subscribeToMarketPotentialData() {
      subscribeToMarketPotential({
        type: this.marketPotentialType,
        companyId: this.activeCompany.id,
      }).subscribe({
        next: (res) => {
          this.marketPotentialData = cloneDeep(res.data.market_potential[0]);
          this.prevData = cloneDeep(res.data.market_potential[0]);
        },
        error(error) {
          console.error(error);
        },
      });
    },
    getMarketPotentialData() {
      this.isFetchingData = true;
      getMarketPotentialComponent({
        type: this.marketPotentialType,
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          if (res.data.market_potential.length) {
            this.marketPotentialData = cloneDeep(res.data.market_potential[0]);
            this.prevData = cloneDeep(res.data.market_potential[0]);
            this.isFetchingData = false;
            if (this.onDataFetch) {
              this.onDataFetch();
            }
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.isFetchingData = false;
            this.setMarketPotentialSeedData();
          } else {
            this.hasAccess = false;
          }
        })
        .finally(() => {
          this.hasMounted = true;
        });
    },
    setMarketPotentialSeedData() {
      this.isFetchingData = true;
      const data = {
        type: this.marketPotentialType,
        description: '',
        otherDescription: '',
        companyId: this.activeCompany.id,
      };
      createMarketPotentialComponent(data).then((res) => {
        this.marketPotentialData = cloneDeep(
          res.data.insert_market_potential_one
        );
        this.prevData = cloneDeep(res.data.insert_market_potential_one);
        this.isFetchingData = false;
        if (this.onDataCreate) {
          this.onDataCreate();
        }
      });
    },
    updateData({ key, value }) {
      const set = { [key]: value };
      this.marketPotentialData[key] = value;
      updateMarketPotentialComponent({
        id: this.marketPotentialData.id,
        set,
      })
        .then((res) => {
          if (!res.data.update_market_potential_by_pk) {
            throw { error: 'unauthorized' };
          }
        })
        .catch((e) => {
          this.marketPotentialData[key] = this.prevData[key];
          if (e.error === 'unauthorized') {
            this.$toast({
              title: 'Unauthorized',
              description: `You are not allowed to edit this information`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
            return;
          }
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating ${titlecase(
              this.marketPotentialType.split('_').join(' ')
            )}, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    updateAccess(user) {
      const accessIndex =
        this.marketPotentialData.market_potential_assignments.findIndex(
          (access) => access.user.id == user.id
        );
      if (accessIndex === -1) {
        const tempId = generateID(4);
        this.marketPotentialData.market_potential_assignments.push({
          id: tempId,
          user,
        });
        this.addAccess(user, tempId);
      } else {
        this.removeAccess(accessIndex);
      }
    },
    async addAccess(user, tempId) {
      const data = {
        marketPotentialId: this.marketPotentialData.id,
        userId: user.id,
      };
      const accessIndex =
        this.marketPotentialData.market_potential_assignments.findIndex(
          (access) => access.id == tempId
        );
      try {
        const res = await addMarketPotentialComponentAccess(data);
        this.marketPotentialData.market_potential_assignments[accessIndex] =
          res.data.insert_market_potential_assignment_one;
        this.prevData.market_potential_assignments = cloneDeep(
          this.marketPotentialData.market_potential_assignments
        );
      } catch (e) {
        this.marketPotentialData.market_potential_assignments = cloneDeep(
          this.prevData.market_potential_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while assigning user, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async removeAccess(accessIndex) {
      const access =
        this.marketPotentialData.market_potential_assignments.splice(
          accessIndex,
          1
        )[0];
      try {
        await removeMarketPotentialComponentAccess(access.id);
        this.prevData.market_potential_assignments = cloneDeep(
          this.marketPotentialData.market_potential_assignments
        );
      } catch (e) {
        this.marketPotentialData.market_potential_assignments = cloneDeep(
          this.prevData.market_potential_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing member access, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateVisibility(users) {
      this.isUpdatingMetadata = true;
      const newUsers = users.filter(
        (user) =>
          !this.marketPotentialData.market_potential_visibilities.find(
            (visibility) => visibility.userId === user.id
          )
      );
      const removedVisibilities =
        this.marketPotentialData.market_potential_visibilities.filter(
          (visibility) => !users.find((user) => visibility.userId === user.id)
        );
      const data = {
        addVisibility: newUsers.map((user) => ({
          userId: user.id,
          marketPotentialId: this.marketPotentialData.id,
        })),
        removedVisibilities: removedVisibilities.map(
          (visibility) => visibility.id
        ),
      };
      const visibilityData = cloneDeep(
        this.marketPotentialData.market_potential_visibilities
      );
      try {
        const res = await updateMarketPotentialComponentVisibility(data);
        res.data.delete_market_potential_visibility.returning.forEach(
          (deletedVisibility) => {
            const deletedVisibilityIndex = visibilityData.find(
              (visibility) => visibility.id === deletedVisibility.id
            );
            visibilityData.splice(deletedVisibilityIndex, 1);
          }
        );
        visibilityData.push(
          ...res.data.insert_market_potential_visibility.returning
        );
        this.marketPotentialData.market_potential_visibilities =
          cloneDeep(visibilityData);
        this.prevData.market_potential_visibilities = cloneDeep(visibilityData);
        this.isUpdatingMetadata = false;
      } catch (e) {
        console.log(e);
      }
    },
    async onCustomerDelete({ customer, index }) {
      this.customers.splice(index, 1);
      try {
        await deleteMarketPotentialCustomer(customer.id);
      } catch (e) {
        this.customers.splice(index, 0, customer);
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while deleting market potential customer, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
