import { render, staticRenderFns } from "./CustomerTabs.vue?vue&type=template&id=6ceaf143&scoped=true"
import script from "./CustomerTabs.vue?vue&type=script&lang=js"
export * from "./CustomerTabs.vue?vue&type=script&lang=js"
import style0 from "./CustomerTabs.vue?vue&type=style&index=0&id=6ceaf143&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ceaf143",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CTab: require('@chakra-ui/vue').CTab, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CText: require('@chakra-ui/vue').CText, CTabList: require('@chakra-ui/vue').CTabList, CHeading: require('@chakra-ui/vue').CHeading, CInput: require('@chakra-ui/vue').CInput, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CTag: require('@chakra-ui/vue').CTag, CLink: require('@chakra-ui/vue').CLink, CFormControl: require('@chakra-ui/vue').CFormControl, CVisuallyHidden: require('@chakra-ui/vue').CVisuallyHidden, CControlBox: require('@chakra-ui/vue').CControlBox, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton, CTabPanel: require('@chakra-ui/vue').CTabPanel, CTabPanels: require('@chakra-ui/vue').CTabPanels, CTabs: require('@chakra-ui/vue').CTabs, CBox: require('@chakra-ui/vue').CBox})
