<template>
  <c-box w="100%" overflow-x="auto" my="8">
    <table class="table">
      <thead class="table__header">
        <tr>
          <th class="table__header__cell">Customer</th>
          <th class="table__header__cell">Price</th>
          <th class="table__header__cell">Customer Number</th>
          <th class="table__header__cell">Description</th>
          <th class="table__header__cell">Documents</th>
          <!-- <th class="table__header__cell">Projected Growth</th> -->
        </tr>
      </thead>
      <tbody class="table__body">
        <tr v-if="!marketPotentialCustomers.length">
          <td colspan="100" class="table__body__cell">
            <c-flex align="center" h="100%" justify="center" w="100%"
              >Click on "+ New customer"</c-flex
            >
          </td>
        </tr>
        <tr
          v-for="(customer, index) in marketPotentialCustomers"
          :key="customer.id"
        >
          <td class="table__body__cell">
            <c-flex h="100%" w="100%" align="center">
              <c-popover placement="bottom" v-slot="{}">
                <c-popover-trigger cursor="pointer">
                  <c-box
                    my="2"
                    mr="3"
                    v-chakra="{
                      height: 'auto',
                    }"
                  >
                    <svg
                      v-chakra="{
                        width: '10px',
                        height: '16px',
                        fill: '#ef5323',
                      }"
                    >
                      <use href="@/assets/icons/vertical-dots.svg#dots" />
                    </svg>
                  </c-box>
                </c-popover-trigger>
                <c-popover-content maxWidth="150px" zIndex="modal">
                  <c-popover-body p="0">
                    <c-list fontSize="sm">
                      <c-list-item v-if="customer.id">
                        <c-box
                          cursor="pointer"
                          w="100%"
                          px="2"
                          py="2"
                          @click="
                            removeMarketPotentialCustomer({ customer, index })
                          "
                          >Delete</c-box
                        >
                      </c-list-item>
                    </c-list>
                  </c-popover-body>
                </c-popover-content>
              </c-popover>

              <c-flex
                direction="column"
                justify="center"
                align="center"
                minHeight="120px"
                h="100%"
                w="100%"
                :backgroundColor="customerColorMap[customer.category]"
                color="#fff"
                p="3"
                textAlign="center"
                overflow="auto"
              >
                <c-flex
                  h="20px"
                  fontWeight="600"
                  fontSize="11px"
                  align="center"
                  px="2"
                  borderRadius="4px"
                  background="#fff"
                  textTransform="uppercase"
                  :color="customerColorMap[customer.category]"
                  marginBottom="auto"
                  >{{ customer.category }}</c-flex
                >
                <c-text
                  fontSize="sm"
                  fontWeight="600"
                  maxWidth="70%"
                  marginBottom="auto"
                  >{{ customer.name }}</c-text
                >
              </c-flex>
            </c-flex>
          </td>
          <td class="table__body__cell">
            <c-popover placement="bottom" v-slot="{ onClose }">
              <c-popover-trigger>
                <c-link
                  display="flex"
                  cursor="pointer"
                  alignItems="center"
                  fontSize="sm"
                  justifyContent="center"
                  w="100%"
                  mt="5"
                >
                  <svg
                    fill="#798892"
                    v-chakra="{
                      width: '12px',
                      height: '12px',
                      mr: '2',
                    }"
                  >
                    <use href="@/assets/icons/icon-add.svg#add"></use>
                  </svg>
                  <span> Add price </span>
                </c-link>
              </c-popover-trigger>
              <c-popover-content width="200px">
                <c-popover-body p="0" width="200px">
                  <c-list>
                    <template
                      v-for="(customerRevenue, ix) in customer.customer
                        .revenue_customers"
                    >
                      <c-list-item fontWeight="600" :key="ix">
                        <c-pseudo-box
                          width="200px"
                          @click="
                            $refs.priceInput[index].value =
                              customerRevenue.revenue.value;
                            onValueInput(customerRevenue.revenue.value, {
                              key: 'price',
                              index,
                            });
                            onClose();
                          "
                          px="4"
                          py="3"
                          cursor="pointer"
                        >
                          <c-text
                            fontSize="sm"
                            fontWeight="600"
                            maxWidth="100%"
                            marginBottom="auto"
                          >
                            {{ customerRevenue.revenue.value }}
                            {{ customerRevenue.revenue.currency }} -
                            {{
                              getFrequency(customerRevenue.revenue.frequency)
                            }}
                          </c-text>
                          <c-text
                            fontSize="sm"
                            mt="2"
                            fontWeight="600"
                            maxWidth="100%"
                            color="orange.400"
                            marginBottom="auto"
                          >
                            {{ getRevenueType(customerRevenue.revenue.type) }}
                          </c-text>
                        </c-pseudo-box>
                      </c-list-item>
                    </template>
                    <c-list-item fontWeight="600">
                      <c-pseudo-box
                        width="200px"
                        @click="
                          $router.push({
                            name: 'BusinessModel',
                            query: {
                              to_component: 'revenue',
                            },
                          });
                          onClose();
                        "
                        px="4"
                        py="3"
                        cursor="pointer"
                      >
                        <c-text
                          fontSize="sm"
                          fontWeight="600"
                          maxWidth="70%"
                          marginBottom="auto"
                        >
                          Add New
                        </c-text>
                      </c-pseudo-box>
                    </c-list-item>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>

            <c-flex p="3">
              <c-editable
                ref="priceInput"
                :default-value="customer.price + ''"
                fontSize="sm"
                fontWeight="600"
                mr="2"
                :isDisabled="isShareView"
                w="max-content"
                @submit="onValueInput($event, { key: 'price', index })"
              >
                <c-editable-preview maxWidth="100%" />
                <c-editable-input type="number" w="60px" />
              </c-editable>
            </c-flex>
          </td>
          <td class="table__body__cell">
            <c-flex p="3">
              <c-editable
                :default-value="customer.number + ''"
                fontSize="sm"
                fontWeight="600"
                mr="2"
                :isDisabled="isShareView"
                w="max-content"
                @submit="onValueInput($event, { key: 'number', index })"
              >
                <c-editable-preview maxWidth="100%" />
                <c-editable-input type="number" w="60px" />
              </c-editable>
            </c-flex>
          </td>
          <td class="table__body__cell">
            <c-flex p="3">
              <EditableText
                title="Markets"
                :type="`markets${index}`"
                v-model="customer.description"
                @updateDescription="updateDescription"
                :editabelIndex="index"
                :disabled="isShareView"
              />
            </c-flex>
          </td>
          <td class="table__body__cell">
            <c-flex p="3">
              <EditableText
                title="Markets"
                :type="`links${index}`"
                v-model="customer.links"
                @updateDescription="updateLinks"
                :editabelIndex="index"
                :disabled="isShareView"
              />
            </c-flex>
          </td>
          <!-- <td class="table__body__cell">
            <c-flex p="3">
              <ProjectedGrowthCell
                :value="customer.projectedGrowth"
                :disabled="isShareView"
                @input="onValueInput($event, { key: 'projectedGrowth', index })"
              />
            </c-flex>
          </td> -->
        </tr>
      </tbody>
      <tfoot class="table__footer">
        <tr>
          <td colspan="100" class="table__footer__cell">
            <c-popover
              v-if="!isShareView"
              placement="bottom"
              v-slot="{ onClose }"
            >
              <c-popover-trigger>
                <c-link
                  display="flex"
                  alignItems="center"
                  color="#798892"
                  fontWeight="500"
                  width="max-content"
                  fontSize="sm"
                >
                  <svg
                    fill="#798892"
                    v-chakra="{
                      width: '12px',
                      height: '12px',
                      mr: '2',
                    }"
                  >
                    <use href="@/assets/icons/icon-add.svg#add" />
                  </svg>
                  <span>New Customer</span>
                </c-link>
              </c-popover-trigger>
              <c-popover-content width="max-content">
                <c-popover-body p="0" width="max-content">
                  <c-list>
                    <template v-for="customer in companyCustomers">
                      <c-list-item
                        fontWeight="600"
                        :key="customer.customerId"
                        :color="customerColorMap[customer.category]"
                      >
                        <c-pseudo-box
                          as="a"
                          :_hover="{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            bg: customerColorMap[customer.category],
                            color: 'white',
                          }"
                          role="group"
                          px="3"
                          py="2"
                          display="flex"
                          alignItems="center"
                          @click="onCustomerSelect(customer, onClose)"
                        >
                          <c-text mr="2">{{ customer.name }}</c-text>
                        </c-pseudo-box>
                      </c-list-item>
                    </template>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>
          </td>
        </tr>
      </tfoot>
    </table>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';

// import Prices from './../competition/Prices';
import EditableText from './../EditableText';
// import ProjectedGrowthCell from './ProjectedGrowthCell';
import currencies from '@/helpers/currencies';

import { customerColorMap } from '@/helpers/utils';
import { revenueTypes } from '@/helpers/data';

export default {
  components: {
    // Prices,
    EditableText,
  },
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('company', {
      companyCustomers: (state) => state.customers,
      isShareView: (state) => state.isShareView,
    }),
  },
  data() {
    return {
      customerColorMap,
      marketPotentialCustomers: [],
      currencies,
      revenueTypes,
      frequencies: [
        { label: 'Per Item', value: 'PER_ITEM' },
        { label: 'Per Service', value: 'PER_SERVICE' },
        { label: 'Yearly', value: 'YEARLY' },
        { label: 'Monthly', value: 'MONTHLY' },
        { label: 'Weekly', value: 'WEEKLY' },
        { label: 'Daily', value: 'DAILY' },
      ],
    };
  },
  created() {
    if (this.customers) {
      this.setMarketPotentialCustomers();
    }
  },
  methods: {
    getFrequency(frequency) {
      return this.frequencies.find((val) => val.value === frequency).label;
    },
    getRevenueType(type) {
      return this.revenueTypes.find((val) => val.value === type).label;
    },
    onCustomerSelect(customer, closePopover) {
      const newCustomer = {
        customerId: customer.id,
        price: 0,
        number: 0,
        description: null,
        links: null,
        projectedGrowth: [],
      };
      this.marketPotentialCustomers.push({
        name: customer.name,
        type: customer.type,
        category: customer.category,
        ...newCustomer,
      });
      closePopover();
      this.$emit('addCustomer', newCustomer);
    },
    isAttachedCustomer(id) {
      return this.marketPotentialCustomers.find(
        (customer) => customer.customerId === id
      );
    },
    onValueInput(e, { key, index }) {
      this.$emit('updateCustomer', { key, index, value: e });
    },
    updateDescription(value, currentIndex) {
      this.onValueInput(value, { key: 'description', index: currentIndex });
    },
    updateLinks(value, currentIndex) {
      this.onValueInput(value, { key: 'links', index: currentIndex });
    },
    setMarketPotentialCustomers() {
      this.marketPotentialCustomers = this.customers.map((customer) => {
        return {
          ...customer,
          name: customer.customer.name,
          type: customer.customer.type,
          category: customer.customer.category,
        };
      });
    },
    removeMarketPotentialCustomer({ customer, index }) {
      this.$emit('deleteCustomer', { customer, index });
    },
  },
  watch: {
    customers() {
      this.setMarketPotentialCustomers();
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  @apply w-full;
  &__header {
    &__cell {
      @apply font-medium text-xs;
      height: 35px;
      border: 1px solid rgba(112, 112, 112, 0.2);
      border-width: 1px 1px 1px 0;
    }
  }
  &__body {
    &__cell {
      @apply p-0 align-top;
      border: 1px solid rgba(112, 112, 112, 0.2);
      border-width: 1px 1px 1px 0;
      min-width: 150px;
      max-width: 250px;
      height: 150px;
      ::v-deep {
        .editable__input {
          @apply text-sm max-h-full;
        }
      }
    }
  }
  &__footer {
    &__cell {
      height: 50px;
      border: 1px solid rgba(112, 112, 112, 0.2);
      border-width: 1px 0 1px 0;
      color: $color-grey;
    }
  }
}
</style>
