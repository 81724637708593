<template>
  <c-box
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
    backgroundColor="#fff"
    height="750px"
    display="flex"
  >
    <c-box flex="1" p="8" h="100%">
      <c-box
        display="flex"
        justifyContent="space-between"
        alignCenter="center"
        mb="4"
      >
        <!--   :isDisabled="members.length >= getCurrentPlan.access.maxTeam" -->
        <c-heading fontSize="3xl" as="h4">Advisory board</c-heading>
        <c-link
          v-if="!disabled && isCompanyOwner && isCompanyAdmin"
          fontWeight="600"
          fontSize="sm"
          color="vc-orange.500"
          @click="
            members.length >= getCurrentPlan.access.maxTeam
              ? null
              : (isInviteModalOpen = true)
          "
        >
          Invite advisory members
        </c-link>
      </c-box>
      <c-box h="calc(100% - 60px)">
        <MembersList
          @fetchedMembers="members = $event"
          :type="'board'"
          :disabled="disabled"
          ref="listComponent"
        />
      </c-box>
    </c-box>
    <c-box
      v-if="!disabled"
      backgroundColor="#f4f6fc"
      w="25%"
      :display="{ base: 'none', md: 'none', lg: 'flex' }"
      borderRadius="inherit"
    >
      <SkillsList :type="'board'" />
    </c-box>
    <InviteModal
      :type="'board'"
      :isOpen="isInviteModalOpen"
      :onClose="closeModal"
    />
  </c-box>
</template>

<script>
import { mapGetters } from 'vuex';

import SkillsList from './SkillsList';
import MembersList from './MembersList';
import InviteModal from './InviteModal.vue';

export default {
  props: ['disabled'],
  components: {
    SkillsList,
    MembersList,
    InviteModal,
  },
  data() {
    return {
      isInviteModalOpen: false,
      members: [],
    };
  },
  computed: {
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    closeModal(hasInvited) {
      this.isInviteModalOpen = false;
      if (hasInvited) {
        this.$refs.listComponent.getMembers();
      }
    },
  },
};
</script>

<style></style>
