import { mapState, mapMutations } from 'vuex';

export default {
  inject: ['updateCompany'],
  data() {
    return {
      elevatorPitch: null,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
  },
  mounted() {
    this.elevatorPitch = this.presentationData.elevatorPitch;
  },
  methods: {
    ...mapMutations({
      updatePresentationData: 'presentation/updatePresentationData',
    }),
    async onElevatorPitchInputBlur() {
      const oldValue = this.presentationData.elevatorPitch;
      this.updatePresentationData({
        ...this.presentationData,
        elevatorPitch: this.elevatorPitch,
      });
      try {
        await this.updateCompany({
          id: this.presentation.companyId,
          set: { elevatorPitch: this.elevatorPitch },
        });
      } catch (e) {
        this.updatePresentationData({
          ...this.presentationData,
          elevatorPitch: oldValue,
        });
        this.elevatorPitch = oldValue;
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while updating elevator pitch, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
