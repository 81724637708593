<template>
  <div class="overview">
    <GrowthSectionHeader :criteria="criteria" :showShare="false" />
    <c-flex v-if="!isShareView" justify="center" align="center">
      <AppSegment
        :segments="segments"
        name="view"
        @input="handleChange"
        v-model="currentView"
      />
      <c-link
        color="vc-orange.400"
        fontSize="sm"
        @click="onShareClick"
        ml="3"
        display="flex"
        alignItems="center"
      >
        Share
        <svg v-chakra fill="#ef5323" w="4" h="4" ml="1">
          <use href="@/assets/icons/share-box-fill.svg#share"></use>
        </svg>
      </c-link>
      <share-content
        :isShareOpen="isShareOpen"
        @close="onShareClick"
        type="overview"
        :url="currentView"
        :shareTitle="
          currentView === 'market-plan'
            ? ' Share Market Plan'
            : 'Share Business Plan'
        "
        :postTitle="
          currentView === 'market-plan'
            ? 'Hi, view my Market Plan on VibrantCreator'
            : 'Hi, view my Business Plan on VibrantCreator'
        "
        :postDescription="
          currentView === 'market-plan'
            ? 'Hi, view my Market Plan on VibrantCreator'
            : 'Hi, view my Business Plan on VibrantCreator'
        "
      />
    </c-flex>
    <Dashboard v-if="currentView === 'status'" />
    <div v-else>
      <!-- <c-box
        d="flex"
        justify-content="flex-end"
        v-if="currentView === 'business-plan'"
      >
        <c-button
          size="md"
          px="8"
          variant-color="vc-orange"
          variant="outline"
          @click="openPdf"
        >
          Download PDF
        </c-button>
      </c-box> -->
      <CompanyPhotoCard :disabled="isShareView" />
      <OverviewDetailsCard :disabled="isShareView" :view="currentView" />
      <c-box my="8">
        <ElevatorPitchCard :disabled="isShareView" />
      </c-box>
      <c-stack :spacing="10" v-if="currentView !== 'market-plan'">
        <c-grid
          w="100%"
          :template-columns="{ base: '1fr', lg: 'repeat(3, 1fr)' }"
          gap="12"
        >
          <c-box>
            <OverallProblem :disabled="isShareView" />
          </c-box>
          <c-box>
            <OverallSolution :disabled="isShareView" />
          </c-box>
          <c-box>
            <OverallValueProposition :disabled="isShareView" />
          </c-box>
        </c-grid>
        <c-box>
          <CustomerSegment :disabled="isShareView" />
        </c-box>
        <c-box>
          <RevenueModelCard :disabled="isShareView" />
        </c-box>
        <c-box>
          <MarketPotentialTabs :disabled="isShareView" />
        </c-box>
        <c-box>
          <CompetitionTabs :disabled="isShareView" />
        </c-box>
        <c-box>
          <MarketStrategyCard :disabled="isShareView" />
        </c-box>
        <c-box>
          <CardTabs
            :segments="goalsSegments"
            :name="'goals'"
            :disabled="isShareView"
          />
        </c-box>
        <c-box>
          <CardTabs
            :segments="risksSegments"
            :name="'risks'"
            :disabled="isShareView"
          />
        </c-box>
        <c-box>
          <MembersTabs :disabled="isShareView" />
        </c-box>
        <c-box>
          <CardTabs
            :segments="visionSegments"
            :name="'vision'"
            :disabled="isShareView"
          />
        </c-box>
        <!-- <c-box>
          <TractionCard />
        </c-box> -->
      </c-stack>
      <c-stack v-else :spacing="10" pb="3">
        <c-box>
          <MarketGoalsCard isMarketPlan :disabled="isShareView" />
        </c-box>
        <c-box>
          <CustomerSegment :title="'Target Audience'" :disabled="isShareView" />
        </c-box>
        <c-box>
          <CompetitionTabs :disabled="isShareView" />
        </c-box>
        <c-box>
          <MarketStrategyCard :disabled="isShareView" />
        </c-box>
        <c-box>
          <ChannelsCard :disabled="isShareView" />
        </c-box>
        <c-box>
          <CostModelCard :disabled="isShareView" />
        </c-box>
      </c-stack>
    </div>

    <c-modal
      :is-open="showPDFModal"
      :on-close="closePdf"
      size="xl"
      scroll-behavior="inside"
      id="pdfModal"
    >
      <c-modal-content ref="content">
        <c-modal-header>Business Plan PDF</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <DownloadPdf v-if="showPDFModal" />
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

import GrowthSectionHeader from './components/GrowthSectionHeader';
import AppSegment from './components/AppSegment.vue';
import Dashboard from './../Dashboard/Index.vue';
import OverallProblem from './components/business-model/OverallProblem.vue';
import OverallSolution from './components/business-model/OverallSolution.vue';
import OverallValueProposition from './components/business-model/OverallValueProposition.vue';
import CompetitionTabs from './components/overview/CompetitionTabs.vue';
import MarketPotentialTabs from './components/overview/MarketPotentialTabs.vue';
import MarketStrategyCard from './components/market-potential/MarketStrategyCard.vue';
import RevenueModelCard from './components/business-model/RevenueModelCard.vue';
import MembersTabs from './components/overview/MembersTabs.vue';
import VisionCard from './components/foundation/VisionCard';
import BeliefCard from './components/foundation/BeliefCard';
import SDGCard from './components/foundation/SDGCard';
import OverviewDetailsCard from './components/overview/OverviewDetailsCard.vue';
import CustomerSegment from './components/overview/CustomerSegment.vue';
import ElevatorPitchCard from './components/overview/ElevatorPitchCard.vue';
import ChannelsCard from './components/business-model/ChannelsCard.vue';
import KeyActivitiesCard from './components/business-model/KeyActivitiesCard.vue';
import KeyPartnersCard from './components/business-model/KeyPartnersCard.vue';
import KeyResourcesCard from './components/business-model/KeyResourcesCard.vue';
// import TractionCard from './components/overview/TractionCard.vue';
import CompanyPhotoCard from './components/overview/CompanyPhotoCard.vue';
import MarketGoalsCard from './components/market-potential/MarketGoalsCard.vue';
import TractionCard from './components/market-potential/TractionCard.vue';
import CostModelCard from './components/business-model/CostModelCard.vue';
import CardTabs from './components/overview/CardTabs.vue';
import RiskAnalysis from './components/risk-assesstment/RiskAnalysis.vue';
import SWOT from './components/risk-assesstment/SWOT.vue';
import PESTLE from './components/risk-assesstment/PESTLE.vue';
import { getCompany } from '../../../services/company';
import ShareContent from './../components/ShareContent.vue';
import DownloadPdf from './../components/DownloadPdf.vue';

export default {
  props: {
    activeView: {
      type: String,
      default: '',
    },
  },
  components: {
    GrowthSectionHeader,
    AppSegment,
    OverallProblem,
    OverallSolution,
    CompetitionTabs,
    MarketStrategyCard,
    RevenueModelCard,
    MarketPotentialTabs,
    MembersTabs,
    OverviewDetailsCard,
    CustomerSegment,
    ChannelsCard,
    // TractionCard,
    ElevatorPitchCard,
    CompanyPhotoCard,
    MarketGoalsCard,
    CostModelCard,
    OverallValueProposition,
    CardTabs,
    ShareContent,
    Dashboard,
    DownloadPdf,
  },
  data() {
    return {
      showPDFModal: false,
      isShareOpen: false,
      criteria: {
        title: 'Overview',
        description: `The overview provides you with a final view of your business plan and market plan. Your business plan summarizes all the core aspects of your business and serves as an external document to your potential investors and partners. The Market plan provides you with a clear roadmap on how you plan to enter the market and serves as a foundation for all your marketing activities. With the share button, you are one click away from sharing externally.
                    `,
      },
      segments: [
        { title: 'Status', value: 'status' },
        { title: 'Business Plan', value: 'business-plan' },
        { title: 'Market Plan', value: 'market-plan' },
      ],
      goalsSegments: [
        {
          title: 'Market Goals',
          value: 'market-goals',
          component: MarketGoalsCard,
        },
        { title: 'Traction', value: 'traction', component: TractionCard },
        { title: 'Channels', value: 'channels', component: ChannelsCard },
        {
          title: 'Key Activities',
          value: 'key-activities',
          component: KeyActivitiesCard,
        },
        {
          title: 'Key Partners',
          value: 'key-partners',
          component: KeyPartnersCard,
        },
        {
          title: 'Key Resources',
          value: 'key-resources',
          component: KeyResourcesCard,
        },
      ],
      risksSegments: [
        {
          title: 'Risk Analysis',
          value: 'risk-analysis',
          component: RiskAnalysis,
        },
        {
          title: 'SWOT',
          value: 'SWOT',
          component: SWOT,
        },
        {
          title: 'PESTLE',
          value: 'PESTLE',
          component: PESTLE,
        },
      ],
      visionSegments: [
        {
          title: 'Vision',
          value: 'vision',
          component: VisionCard,
        },
        {
          title: 'Belief',
          value: 'belief',
          component: BeliefCard,
        },
        {
          title: 'Impact Profile',
          value: 'impact-profile',
          component: SDGCard,
          props: {
            title: 'Impact Profile',
          },
        },
      ],
      currentView: 'business-plan',
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      isShareView: (state) => state.isShareView,
    }),
  },
  created() {
    if (this.activeView) {
      this.currentView = this.activeView;
    }
  },
  mounted() {
    if (this.isShareView) {
      this.setCompany();
    }
  },
  methods: {
    ...mapActions({
      setActiveCompany: 'company/setActiveCompany',
    }),
    onShareClick() {
      this.isShareOpen = !this.isShareOpen;
    },
    handleChange(s) {
      this.currentView = s;
    },
    async setCompany() {
      await getCompany(this.$route.query.company_id).then((res) => {
        this.setActiveCompany(cloneDeep(res.data.company_by_pk));
      });
    },
    openPdf() {
      this.showPDFModal = true;
    },
    closePdf() {
      this.showPDFModal = false;
    },
  },
};
</script>

<style lang="scss">
#modal-pdfModal {
  max-width: 52rem !important;
  height: 100vh !important;
}
</style>
