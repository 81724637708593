<template>
  <c-pseudo-box
    :bg="!activeCompany.coverImage ? '#2D2D2D' : ''"
    :style="{ backgroundImage: `url('${activeCompany.coverImage}')` }"
    borderWidth="1px"
    backgroundSize="cover"
    backgroundPosition="top center"
    justifyContent="center"
    alignItems="center"
    w="100%"
    h="450px"
    my="8"
    borderRadius="8px"
    position="relative"
    display="flex"
    cursor="pointer"
    @click="onCoverClick"
  >
    <AppLoadingOverlay
      v-if="images.coverImage.isUploading"
      :failed="images.coverImage.failed"
      :canReload="true"
      @reload="onReload(images.coverImage.file, 'coverImage')"
    />
    <c-text
      v-if="!activeCompany.coverImage"
      color="#fff"
      fontWeight="600"
      fontSize="2xl"
      >Picture</c-text
    >
    <c-pseudo-box
      :bg="!activeCompany.logo ? '#37D5AA' : ''"
      :style="{ backgroundImage: `url('${activeCompany.logo}')` }"
      :borderWidth="activeCompany.logo ? '2px' : '0px'"
      :borderColor="activeCompany.logo ? '#fff' : ''"
      justifyContent="center"
      alignItems="center"
      backgroundSize="cover"
      backgroundPosition="top center"
      w="150px"
      h="150px"
      position="absolute"
      left="0"
      bottom="0"
      display="flex"
      borderRadius="0 0 0 8px"
      cursor="pointer"
      @click="onLogoClick"
    >
      <AppLoadingOverlay
        v-if="images.logo.isUploading"
        :failed="images.logo.failed"
        :canReload="true"
        @reload="onReload(images.logo.file, 'logo')"
      />
      <c-text
        v-if="!activeCompany.logo"
        color="#2A2A31"
        fontWeight="600"
        fontSize="2xl"
      >
        Logo
      </c-text>
    </c-pseudo-box>
    <input
      @click="onFileInputClick"
      @change="onFileInputChange"
      type="file"
      hidden
      ref="fileInput"
    />
  </c-pseudo-box>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { uploadFile } from '@/services/common';
import { updateCompany } from '@/services/company';
import AppLoadingOverlay from '../../../components/AppLoadingOverlay.vue';

export default {
  components: { AppLoadingOverlay },
  data() {
    return {
      uploadType: null,
      isUploadingImage: false,
      images: {
        coverImage: {
          file: null,
          isUploading: false,
          failed: false,
        },
        logo: {
          file: null,
          isUploading: false,
          failed: false,
        },
      },
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  methods: {
    ...mapMutations({
      updateActiveCompany: 'company/updateActiveCompany',
    }),
    onCoverClick() {
      this.uploadType = 'coverImage';
      this.$refs.fileInput.click();
    },
    onLogoClick(e) {
      e.stopPropagation();
      this.uploadType = 'logo';
      this.$refs.fileInput.click();
    },
    onFileInputClick(e) {
      e.stopPropagation();
    },
    onReload(file, uploadType) {
      this.uploadType = uploadType;
      this.doUpload(file);
    },
    onFileInputChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.doUpload(file);
      }
    },
    async doUpload(file) {
      this.images[this.uploadType].isUploading = true;
      this.images[this.uploadType].failed = false;
      try {
        const res = await uploadFile({ file, type: 'image' });
        await updateCompany({
          id: this.activeCompany.id,
          set: { [this.uploadType]: res.data.url },
        });
        this.updateActiveCompany({ [this.uploadType]: res.data.url });
        this.$nextTick(() => {
          this.images[this.uploadType].isUploading = false;
        });
      } catch (e) {
        this.images[this.uploadType].file = file;
        this.images[this.uploadType].failed = true;
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss"></style>
