<template>
  <div class="business-analysis">
    <AppSegment
      :segments="segments"
      name="business-analysis"
      v-model="selectedAnalysis"
    />
    <c-box>
      <transition-group name="fade" mode="out-in">
        <SWOT
          :disabled="disabled"
          v-show="this.selectedAnalysis === 'swot'"
          :key="'swot'"
        />
        <PESTLE
          :disabled="disabled"
          v-show="this.selectedAnalysis === 'pestle'"
          :key="'pestle'"
        />
        <!-- <keep-alive>
          <component :is="selectedView" />
        </keep-alive> -->
      </transition-group>
    </c-box>
  </div>
</template>

<script>
import AppSegment from '../AppSegment.vue';
import PESTLE from './PESTLE.vue';
import SWOT from './SWOT.vue';

export default {
  props: ['disabled'],
  components: { AppSegment, SWOT, PESTLE },
  data() {
    return {
      segments: [
        { title: 'SWOT', value: 'swot' },
        { title: 'PESTLE', value: 'pestle' },
      ],
      selectedAnalysis: null,
    };
  },
  computed: {
    selectedView() {
      return this.selectedAnalysis === 'swot' ? SWOT : PESTLE;
    },
  },
};
</script>

<style></style>
