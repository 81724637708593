<template>
  <c-box id="risk-analysis">
    <!-- :isLocked="!getCurrentPlan.access.riskAnalysis" -->
    <TaskCard
      :title="'Risk Analysis'"
      :hasImportance="true"
      :hasProgress="true"
      :hasNotification="true"
      :hasVisibility="true"
      :canAssign="true"
      :hasAssumption="false"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="riskAssessmentData"
      :accessKey="'risk_assessment_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'risk_assessment_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="risk_analysis"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 300 200"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="50" />
        <rect x="120" y="0" rx="3" ry="3" width="100" height="50" />
        <rect x="240" y="0" rx="3" ry="3" width="100" height="50" />
      </ContentLoader>
      <c-box v-else class="risk-grid">
        <table class="risk-grid__table">
          <thead class="risk-grid__table__header">
            <tr>
              <th class="risk-grid__table__header__cell">Risk</th>
              <th class="risk-grid__table__header__cell">Analysis</th>
              <th class="risk-grid__table__header__cell">Description</th>
              <template v-if="!disabled">
                <th class="risk-grid__table__header__cell">
                  Employee Responsible
                </th>
                <th class="risk-grid__table__header__cell">Action Plan</th>
              </template>
            </tr>
          </thead>
          <tbody class="risk-grid__table__body">
            <tr
              v-for="(analysis, analysisIndex) of analyses"
              :key="analysis.id || analysis.tempId"
            >
              <td class="risk-grid__table__body__cell">
                <c-flex h="100%" justify="center">
                  <c-popover placement="bottom" v-slot="{}">
                    <c-popover-trigger cursor="pointer">
                      <c-box
                        my="2"
                        mr="3"
                        v-chakra="{
                          height: 'auto',
                        }"
                      >
                        <svg
                          v-chakra="{
                            width: '10px',
                            height: '16px',
                            fill: '#ef5323',
                          }"
                        >
                          <use href="@/assets/icons/vertical-dots.svg#dots" />
                        </svg>
                      </c-box>
                    </c-popover-trigger>
                    <c-popover-content maxWidth="150px" zIndex="modal">
                      <c-popover-body p="0">
                        <c-list fontSize="sm">
                          <c-list-item v-if="analysis.id">
                            <c-box
                              cursor="pointer"
                              w="100%"
                              px="2"
                              py="2"
                              @click="
                                removeAnalysis({ analysis, analysisIndex })
                              "
                              >Delete</c-box
                            >
                          </c-list-item>
                        </c-list>
                      </c-popover-body>
                    </c-popover-content>
                  </c-popover>

                  <c-flex
                    h="100%"
                    p="3"
                    direction="column"
                    align="center"
                    justify="center"
                    textAlign="center"
                    v-if="analysis.riskAssessmentValueId"
                    mr="auto"
                  >
                    <c-text
                      color="vc-orange.400"
                      fontWeight="500"
                      fontSize="sm"
                      mb="auto"
                    >
                      {{
                        analysis.risk_assessment_value
                          ? analysis.risk_assessment_value.risk_assessment.type.toUpperCase()
                          : ''
                      }}
                    </c-text>
                    <c-text fontWeight="600" fontSize="sm" mb="auto">
                      {{
                        analysis.risk_assessment_value
                          ? analysis.risk_assessment_value.title
                          : ''
                      }}
                    </c-text>
                  </c-flex>
                  <c-flex
                    h="100%"
                    align="center"
                    justify="center"
                    textAlign="center"
                    mr="auto"
                    v-else
                  >
                    <c-editable
                      :default-value="analysis.title"
                      mb="2"
                      fontWeight="600"
                      fontSize="sm"
                      @submit="
                        onFieldInput($event, { analysisIndex, key: 'title' })
                      "
                    >
                      <c-editable-preview minWidth="120px" />
                      <c-editable-input />
                    </c-editable>
                  </c-flex>
                </c-flex>
              </td>
              <td class="risk-grid__table__body__cell">
                <c-flex py="3">
                  <RiskForceCell
                    :value="analysis.analysis"
                    :disabled="disabled"
                    @input="
                      onFieldInput($event, { analysisIndex, key: 'analysis' })
                    "
                  />
                </c-flex>
              </td>
              <td class="risk-grid__table__body__cell">
                <c-flex py="3">
                  <EditableText
                    :type="`risk${analysisIndex}`"
                    v-model="analysis.description"
                    :disabled="disabled"
                    :editabelIndex="analysisIndex"
                    @updateDescription="updateDescription"
                  />
                </c-flex>
              </td>
              <template v-if="!disabled">
                <td class="risk-grid__table__body__cell">
                  <c-flex py="3">
                    <AppSelect
                      :options="teamMembers"
                      label="id"
                      :reduce="(member) => member.id"
                      :value="analysis.employeeResponsible"
                      :reset="true"
                      :hasShadow="true"
                      indicator-fill="orange"
                      color="dark"
                      size="large"
                      :appendToBody="true"
                      @input="
                        onFieldInput($event, {
                          analysisIndex,
                          key: 'employeeResponsible',
                        })
                      "
                    >
                      <template v-slot:option="{ option }">
                        <c-text>
                          {{ option.user.firstname }}
                          {{ option.user.lastname }}
                        </c-text>
                      </template>
                      <template v-slot:selected-option="{ option }">
                        <c-text>
                          {{ option.user.firstname }}
                          {{ option.user.lastname }}
                        </c-text>
                      </template>
                    </AppSelect>
                  </c-flex>
                </td>
                <td class="risk-grid__table__body__cell">
                  <c-flex py="3">
                    <c-flex justify="space-between">
                      <AppSelect
                        :options="plans"
                        label="label"
                        :reduce="(plan) => plan.value"
                        :value="analysis.actionPlan"
                        :reset="true"
                        :hasShadow="true"
                        indicator-fill="orange"
                        color="dark"
                        size="large"
                        :appendToBody="true"
                        @input="
                          onFieldInput($event, {
                            analysisIndex,
                            key: 'actionPlan',
                          })
                        "
                      />
                      <c-spinner
                        v-if="isUpdatingAnalysis"
                        thickness="1px"
                        color="blue.500"
                      ></c-spinner>
                    </c-flex>
                  </c-flex>
                  <c-pseudo-box
                    v-if="analysis.milestone"
                    borderColor="vc-orange.400"
                    borderWidth="1px"
                    p="3"
                    borderRadius="4px"
                    w="200px"
                    position="relative"
                    alignSelf="center"
                    mr="8"
                    mb="8"
                  >
                    <c-flex justify="space-between" mb="2">
                      <c-heading fontSize="10px" fontWeight="500">
                        {{
                          analysis.milestone.calender_category
                            ? analysis.milestone.calender_category.title
                            : ''
                        }}
                      </c-heading>
                    </c-flex>
                    <c-popover trigger="hover" placement="top-end">
                      <c-popover-trigger>
                        <c-heading
                          maxWidth="100%"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          {{ analysis.milestone.title }}
                        </c-heading>
                      </c-popover-trigger>
                      <c-popover-content>
                        <c-popover-arrow />
                        <c-popover-body zIndex="modal">
                          <c-text fontSize="sm" overflowWrap="break-word">
                            {{ analysis.milestone.title }}
                          </c-text>
                        </c-popover-body>
                      </c-popover-content>
                    </c-popover>
                    <c-flex justify="space-between" mt="2">
                      <c-text color="gray.500" fontSize="10px" fontWeight="500">
                        {{ analysis.milestone.date | moment('DD, MMMM') }}
                      </c-text>
                      <c-text
                        color="vc-orange.400"
                        fontSize="10px"
                        display="flex"
                        fontWeight="500"
                        alignItems="center"
                        v-if="analysis.milestone.status === 'IN_PROGRESS'"
                      >
                        In Progress
                        <svg
                          fill="#ef5323"
                          v-chakra="{
                            width: '14px',
                            height: '14px',
                            ml: '2',
                          }"
                        >
                          <use href="@/assets/icons/circle.svg#circle"></use>
                        </svg>
                      </c-text>
                      <c-text
                        color="#0FC694"
                        fontSize="10px"
                        display="flex"
                        fontWeight="500"
                        alignItems="center"
                        v-else
                      >
                        Acheived
                        <svg
                          v-chakra="{
                            width: '14px',
                            height: '14px',
                            ml: '2',
                          }"
                        >
                          <use
                            href="@/assets/icons/check-circle.svg#check"
                          ></use>
                        </svg>
                      </c-text>
                    </c-flex>
                  </c-pseudo-box>
                </td>
              </template>
            </tr>
          </tbody>
          <tfoot class="risk-grid__table__footer" v-if="!disabled">
            <tr>
              <td colspan="100" class="risk-grid__table__footer__cell">
                <c-popover placement="bottom" v-slot="parentPopoverSlotProps">
                  <c-popover-trigger>
                    <c-link
                      display="flex"
                      alignItems="center"
                      color="#798892"
                      fontWeight="500"
                      fontSize="sm"
                      width="max-content"
                    >
                      <svg
                        fill="#798892"
                        v-chakra="{
                          width: '12px',
                          height: '12px',
                          mr: '2',
                        }"
                      >
                        <use href="@/assets/icons/icon-add.svg#add"></use>
                      </svg>
                      <span> New Risk</span>
                    </c-link>
                  </c-popover-trigger>
                  <c-popover-content w="150px">
                    <c-popover-body p="0">
                      <c-list>
                        <c-list-item fontSize="sm">
                          <c-popover
                            trigger="hover"
                            v-slot="{ onClose }"
                            :placement="'right-end'"
                          >
                            <c-popover-trigger>
                              <c-pseudo-box
                                as="a"
                                display="flex"
                                py="2"
                                px="4"
                                alignItems="center"
                                :_hover="{
                                  textDecoration: 'none',
                                  cursor: 'pointer',
                                  bg: 'vc-orange.50',
                                }"
                                justifyContent="space-between"
                                fontSize="sm"
                                cursor="pointer"
                              >
                                <span> SWOT </span>
                                <svg
                                  v-chakra="{
                                    width: '10px',
                                    height: '10px',
                                    fill: '#ef5323',
                                    ml: '2',
                                  }"
                                >
                                  <use
                                    href="@/assets/icons/icon-caret-right.svg#caret"
                                  ></use>
                                </svg>
                              </c-pseudo-box>
                            </c-popover-trigger>
                            <c-popover-content width="max-content">
                              <c-popover-body width="max-content">
                                <c-list>
                                  <c-list-item
                                    v-for="factor of swotFactors"
                                    :key="factor.id"
                                  >
                                    <c-box
                                      fontWeight="600"
                                      px="2"
                                      py="2"
                                      cursor="pointer"
                                      @click="
                                        onFactorClick(
                                          factor,
                                          onClose,
                                          parentPopoverSlotProps.onClose
                                        )
                                      "
                                    >
                                      {{ factor.title }}
                                    </c-box>
                                  </c-list-item>
                                </c-list>
                              </c-popover-body>
                            </c-popover-content>
                          </c-popover>
                        </c-list-item>
                        <c-list-item fontSize="sm">
                          <c-popover
                            trigger="hover"
                            v-slot="{ onClose }"
                            :placement="'right-end'"
                          >
                            <c-popover-trigger>
                              <c-pseudo-box
                                as="a"
                                display="flex"
                                py="2"
                                px="4"
                                alignItems="center"
                                :_hover="{
                                  textDecoration: 'none',
                                  cursor: 'pointer',
                                  bg: 'vc-orange.50',
                                }"
                                justifyContent="space-between"
                                fontSize="sm"
                                cursor="pointer"
                              >
                                <span> PESTLE </span>
                                <svg
                                  v-chakra="{
                                    width: '10px',
                                    height: '10px',
                                    fill: '#ef5323',
                                    ml: '2',
                                  }"
                                >
                                  <use
                                    href="@/assets/icons/icon-caret-right.svg#caret"
                                  ></use>
                                </svg>
                              </c-pseudo-box>
                            </c-popover-trigger>
                            <c-popover-content width="max-content">
                              <c-popover-body width="max-content">
                                <c-list>
                                  <c-list-item
                                    v-for="factor of pestleFactors"
                                    :key="factor.id"
                                  >
                                    <c-box
                                      fontWeight="600"
                                      px="2"
                                      py="2"
                                      cursor="pointer"
                                      @click="
                                        onFactorClick(
                                          factor,
                                          onClose,
                                          parentPopoverSlotProps.onClose
                                        )
                                      "
                                    >
                                      {{ factor.title }}
                                    </c-box>
                                  </c-list-item>
                                </c-list>
                              </c-popover-body>
                            </c-popover-content>
                          </c-popover>
                        </c-list-item>
                        <c-list-item fontSize="sm" borderTopWidth="1px">
                          <c-pseudo-box
                            cursor="pointer"
                            @click="
                              onFactorClick(
                                null,
                                () => {},
                                parentPopoverSlotProps.onClose
                              )
                            "
                          >
                            <c-text
                              px="4"
                              py="2"
                              fontSize="sm"
                              fontWeight="600"
                              color="vc-orange.400"
                            >
                              Create Risk
                            </c-text>
                          </c-pseudo-box>
                        </c-list-item>
                      </c-list>
                    </c-popover-body>
                  </c-popover-content>
                </c-popover>
              </td>
            </tr>
          </tfoot>
        </table>
      </c-box>
    </TaskCard>
    <c-modal
      :is-open="isMilestoneModalOpen"
      :on-close="closeMilestoneModal"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            Add
            <c-text textTransform="capitalize" ml="1"> Milestone </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <form @submit.prevent="onMilestoneFormSubmit">
          <c-modal-body>
            <c-box p="3">
              <c-box mb="5">
                <c-input
                  size="lg"
                  placeholder="Milestone Name"
                  v-model="$v.milestoneForm.title.$model"
                ></c-input>
              </c-box>
              <c-box mb="5">
                <AppDatePicker
                  format="YYYY-MM-DD"
                  type="date"
                  v-model="$v.milestoneForm.date.$model"
                >
                  <template v-slot:input>
                    <c-input
                      size="lg"
                      placeholder="Select Date"
                      v-model="milestoneDateDisplay"
                    ></c-input>
                  </template>
                </AppDatePicker>
              </c-box>
              <c-box mb="5">
                <AppSelect
                  label="title"
                  :reduce="(scenario) => scenario.id"
                  :options="scenarios"
                  :reset="true"
                  :fullWidth="true"
                  :bordered="true"
                  indicator-fill="orange"
                  color="dark"
                  :borderColor="'light'"
                  size="large"
                  v-model="$v.milestoneForm.scenarioId.$model"
                  placeholder="Add Scenario (optional)"
                />
              </c-box>
              <c-box mb="5">
                <CategorySelect v-model="$v.milestoneForm.categoryId.$model" />
                <!-- <AppSelect
                  label="title"
                  :reduce="(category) => category.id"
                  :options="categories"
                  :reset="true"
                  :fullWidth="true"
                  :bordered="true"
                  indicator-fill="orange"
                  color="dark"
                  v-model="$v.milestoneForm.categoryId.$model"
                  :borderColor="'light'"
                  size="large"
                  placeholder="Add Category"
                /> -->
              </c-box>
              <c-box>
                <textarea
                  v-model="$v.milestoneForm.description.$model"
                  placeholder="Description"
                  v-chakra="{
                    w: '100%',
                    h: '2rem',
                    outline: 'none',
                    borderRadius: '0.25rem',
                    borderWidth: '1px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    fontSize: '1rem',
                    minHeight: '80px',
                  }"
                ></textarea>
              </c-box>
            </c-box>
          </c-modal-body>
          <c-modal-footer>
            <c-button
              :disabled="$v.milestoneForm.$invalid || isAddingMilestone"
              variant-color="vc-orange"
              type="submit"
              mr="3"
            >
              <c-text color="#fff">
                {{ 'Create Milestone' }}
              </c-text>
              <c-spinner
                v-if="isAddingMilestone"
                ml="2"
                color="#fff"
                thickness="2px"
              />
            </c-button>
          </c-modal-footer>
        </form>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Achieved Milestone
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          Set Date Milestone was acheived
          <AppDatePicker
            format="YYYY-MM-DD"
            type="date"
            v-model="tempAchievedMilestoneDate"
          >
            <template v-slot:input>
              <c-input
                size="lg"
                readonly
                placeholder="Select Date"
                :value="achievedMilestoneDateDisplay"
              ></c-input>
            </template>
          </AppDatePicker>
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeAchievedDialog">
            Cancel
          </c-button>
          <c-button
            :disabled="!tempAchievedMilestoneDate"
            variantColor="vue"
            @click="saveMilestoneAsAchieved"
            ml="3"
          >
            Save
            <c-spinner
              ml="2"
              v-if="isMarkingAsAchieved"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import { ContentLoader } from 'vue-content-loader';

import { required } from 'vuelidate/lib/validators';

import {
  addRiskAnalysis,
  deleteRiskAnalysis,
  updateRiskAnalysis,
} from '@/services/risk-assessment';

import { addMilestone, updateMilestone } from '@/services/timeline';

import TaskCard from '../TaskCard.vue';
import EditableText from './../EditableText.vue';
import AppSelect from './../../../components/AppSelect.vue';
import RiskForceCell from './RiskForceCell.vue';
import cloneDeep from 'lodash.clonedeep';
import { generateID } from '@/helpers/data';

import riskAssessmentMixin from '@/mixins/risk-assessment.js';
import AppDatePicker from '@/views/App/components/AppDatePicker.vue';
import CategorySelect from '@/views/App/Timeline/components/CategorySelect.vue';

export default {
  mixins: [riskAssessmentMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    EditableText,
    AppSelect,
    RiskForceCell,
    ContentLoader,
    AppDatePicker,
    CategorySelect,
  },
  validations: {
    milestoneForm: {
      title: {
        required,
      },
      date: {
        required,
      },
      description: {},
      categoryId: {},
      scenarioId: {},
    },
  },
  data() {
    return {
      analyses: [],
      riskAssessmentType: 'risk_analysis',
      plans: [
        { label: 'Monitor', value: 'monitor' },
        { label: 'Act', value: 'act' },
        { label: 'Resolved', value: 'resolved' },
      ],
      isMilestoneModalOpen: false,
      milestoneForm: {
        title: null,
        date: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      },
      updatingAnalysis: null,
      isAddingMilestone: false,
      tempAchievedMilestone: null,
      tempAchievedMilestoneDate: null,
      isAchievedDialogOpen: false,
      isMarkingAsAchieved: false,
      isUpdatingAnalysis: false,
      hasMounted: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      swotFactors: (state) => state.swotFactors,
      pestleFactors: (state) => state.pestleFactors,
    }),
    ...mapGetters('subscription', ['getCurrentPlan']),

    ...mapState('timeline', {
      categories: (state) => state.categories,
      scenarios: (state) => state.scenarios,
    }),
    ...mapState({
      teamMembers: (state) =>
        state.company.members.filter(
          (member) => member.isAccepted && member.user.isVerified
        ),
    }),
    milestoneDateDisplay() {
      return this.milestoneForm.date
        ? this.$moment(this.milestoneForm.date).format('YYYY-MM-DD')
        : null;
    },
    achievedMilestoneDateDisplay() {
      return this.tempAchievedMilestoneDate
        ? this.$moment(this.tempAchievedMilestoneDate).format('YYYY-MM-DD')
        : null;
    },
  },
  methods: {
    onSubmit(e) {
      console.log(e);
    },
    ...mapActions({
      updateMilestoneInStore: 'timeline/updateMilestone',
    }),
    ...mapMutations({
      addNewMilestone: 'timeline/addMilestone',
    }),
    onDataFetch() {
      this.setAnalyses();
    },
    setAnalyses() {
      this.analyses = cloneDeep(this.riskAssessmentData.risk_analyses);
    },
    onFactorClick(factor, popoverClose, parentPopoverClose) {
      this.addRisk({ ...factor });
      popoverClose();
      parentPopoverClose();
    },
    async addRisk(factor) {
      const riskData = {
        tempId: generateID(3),
        riskAssessmentId: this.riskAssessmentData.id,
        riskAssessmentValueId: factor ? factor.id : null,
      };
      this.analyses.push(riskData);
      try {
        const res = await addRiskAnalysis({
          riskAssessmentId: this.riskAssessmentData.id,
          riskAssessmentValueId: factor ? factor.id : null,
          analysis: { impact: 'Moderate', likelihood: 'Very Unlikely' },
        });
        const index = this.analyses.findIndex(
          (analysis) => analysis.tempId === riskData.tempId
        );
        this.analyses.splice(index, 1, res.data.insert_risk_analysis_one);
      } catch (e) {
        console.log({ e });
        const index = this.analyses.findIndex(
          (analysis) => analysis.tempId === riskData.tempId
        );
        this.analyses.splice(index, 1);
        this.$toast({
          title: 'An error occurred.',
          description: `Error while adding Risk, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    updateDescription(value, analysisIndex) {
      this.onFieldInput(value, { analysisIndex, key: 'description' });
    },
    onFieldInput(e, { analysisIndex, key }) {
      const oldValue = cloneDeep(this.analyses[analysisIndex][key]);
      if (key === 'actionPlan') {
        this.onActionPlanChange(e, analysisIndex);
        return;
      }
      console.log(e);
      this.analyses[analysisIndex][key] = e;
      console.log(this.analyses);
      this.updateRisk({ key, value: e, index: analysisIndex, oldValue });
    },
    async updateRisk({ key, value, index, oldValue }) {
      const { id } = this.analyses[index];
      const set = { [key]: value };
      try {
        await updateRiskAnalysis({ id, set });
      } catch (e) {
        this.analyses[index][key] = oldValue;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating Risk analysis, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async removeAnalysis({ analysis, analysisIndex }) {
      this.analyses.splice(analysisIndex, 1);
      try {
        await deleteRiskAnalysis(analysis.id);
      } catch (e) {
        this.analyses.splice(analysisIndex, 1, { ...analysis });
        this.$toast({
          title: 'An error occurred.',
          description: `Error while deleting Risk analysis, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    closeMilestoneModal() {
      this.isMilestoneModalOpen = false;
      this.updatingAnalysis = false;
    },
    clearMilestoneForm() {
      this.milestoneForm = {
        title: null,
        date: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      };
    },
    async onMilestoneFormSubmit() {
      this.isAddingMilestone = true;
      const { title, date, description, categoryId, scenarioId } =
        this.milestoneForm;
      const data = {
        title,
        categoryId,
        description,
        scenarioId,
        date: date.toISOString(),
        companyId: this.activeCompany.id,
      };
      try {
        const res = await addMilestone(data);
        this.addNewMilestone(res.data.insert_milestone_one);
        const analysisRes = await updateRiskAnalysis({
          id: this.updatingAnalysis.id,
          set: {
            milestoneId: res.data.insert_milestone_one.id,
            actionPlan: this.updatingAnalysis.newActionPlan,
          },
        });
        this.analyses[this.updatingAnalysis.index] = cloneDeep(
          analysisRes.data.update_risk_analysis_by_pk
        );
        this.clearMilestoneForm();
        this.isAddingMilestone = false;
        this.closeMilestoneModal();
      } catch (e) {
        this.isAddingMilestone = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while creating milestone, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
      this.tempAchievedMilestone = null;
      this.tempAchievedMilestoneDate = null;
      this.updatingAnalysis = null;
    },
    async saveMilestoneAsAchieved() {
      this.isMarkingAsAchieved = true;
      try {
        const res = await updateMilestone({
          id: this.tempAchievedMilestone.id,
          set: {
            dateAchieved: this.tempAchievedMilestoneDate,
            status: 'ACHIEVED',
          },
        });
        this.updateMilestoneInStore(cloneDeep(res.data.update_milestone_by_pk));
        const analysisRes = await updateRiskAnalysis({
          id: this.updatingAnalysis.id,
          set: {
            actionPlan: this.updatingAnalysis.newActionPlan,
          },
        });
        this.analyses[this.updatingAnalysis.index] = cloneDeep(
          analysisRes.data.update_risk_analysis_by_pk
        );
        this.isMarkingAsAchieved = false;
        this.closeAchievedDialog();
      } catch (e) {
        console.log({ e });
      }
    },
    async onActionPlanChange(e, analysisIndex) {
      switch (e) {
        case 'monitor': {
          this.isUpdatingAnalysis = true;
          try {
            const analysisRes = await updateRiskAnalysis({
              id: this.analyses[analysisIndex].id,
              set: {
                milestoneId: null,
                actionPlan: 'monitor',
              },
            });
            this.analyses[analysisIndex] = cloneDeep(
              analysisRes.data.update_risk_analysis_by_pk
            );
            this.isUpdatingAnalysis = false;
          } catch (e) {
            console.log({ e });
            this.isUpdatingAnalysis = false;
          }
          break;
        }
        case 'act': {
          this.isMilestoneModalOpen = true;
          this.updatingAnalysis = {
            ...this.analyses[analysisIndex],
            index: analysisIndex,
            newActionPlan: e,
          };
          break;
        }
        case 'resolved':
          this.isAchievedDialogOpen = true;
          this.updatingAnalysis = {
            ...this.analyses[analysisIndex],
            index: analysisIndex,
            newActionPlan: e,
          };
          this.tempAchievedMilestone = {
            ...this.analyses[analysisIndex].milestone,
          };
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.risk-grid {
  @apply w-full max-w-full my-5 overflow-x-scroll;
  &__table {
    @apply w-full;
    &__header {
      &__cell {
        @apply text-sm font-medium text-left px-4;
        color: #2d2d2d;
        min-width: 180px;
        max-width: 250px;
        height: 40px;
        border-width: 1px 1px 0 0;
        border-color: rgba(112, 112, 112, 0.2);
      }
    }
    &__body {
      &__cell {
        @apply text-sm text-left px-4 align-top;
        height: 160px;
        border-width: 1px 1px 0 0;
        border-color: rgba(112, 112, 112, 0.2);
      }
    }
    &__footer {
      &__cell {
        height: 50px;
        border-width: 1px 1px 1px 0;
        border-color: rgba(112, 112, 112, 0.2);
      }
    }
  }
}
</style>
