var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"w":"100%","h":"100%","px":"100px","py":"80px","position":"relative","bg":_vm.presentation.data.how_it_works.backgroundColor}},[_c('c-flex',[_c('c-text',{attrs:{"ml":"0px","mr":"10","as":"h3","mb":"60px","fontSize":"4xl","fontWeight":"700"}},[_vm._v("How It Works")])],1),_c('c-flex',{attrs:{"px":"5","h":"90%","position":"relative","borderWidth":".5em","borderColor":_vm.presentation.data.color,"backgroundImage":`linear-gradient(
      to bottom ,
      ${_vm.presentation.data.color}20,
      #fff
    )`}},[_c('c-flex',{attrs:{"w":"100%","align":"center","justify":"space-between"}},[_c('c-box',{attrs:{"w":"70%"}},[_c('c-box',{attrs:{"mb":"8","textAlign":"left"}},[_c('c-text',{attrs:{"fontSize":"25px","fontWeight":"600"}},[_vm._v("Description")]),_c('EditableText',{attrs:{"noHeight":true,"showOptions":false,"disabled":!_vm.isEdit,"value":_vm.howItWorksData.description},on:{"updateDescription":_vm.updateDescription}})],1),_c('c-box',{attrs:{"mb":"8","textAlign":"left"}},[_c('c-text',{attrs:{"fontSize":"25px","fontWeight":"600"}},[_vm._v("Link")]),_c('EditableText',{attrs:{"noHeight":true,"showOptions":false,"disabled":!_vm.isEdit,"value":_vm.howItWorksData.link},on:{"updateDescription":_vm.updateLink}})],1)],1),_c('c-box',{attrs:{"maxW":"50%","w":"20em","h":"15em"}},[(_vm.isVideo(_vm.howItWorksData.link))?_c('video',[_c('source',{attrs:{"src":_vm.howItWorksData.link,"type":"video/mp4"}})]):(
            !_vm.isVideo(_vm.howItWorksData.link) &&
            !_vm.isYoutubeVideo(_vm.howItWorksData.link)
          )?_c('img',{attrs:{"src":_vm.howItWorksData.link}}):_vm._e()])],1)],1),_c('c-box',{attrs:{"position":"absolute","right":"5","bottom":"5"}},[_c('WaterMark',{attrs:{"direction":'row'}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }