<template>
  <c-box mt="auto" w="100%">
    <carousel
      ref="carousel"
      @pageChange="handleSlide"
      :paginationEnabled="false"
      :navigationEnabled="true"
      :perPage="3"
      :navigationPrevLabel="'◀◀'"
      :navigationNextLabel="'▶▶'"
    >
      <template v-for="(slide, slideIndex) in slides">
        <slide :key="slide.label">
          <c-pseudo-box
            cursor="pointer"
            h="100px"
            w="150px"
            position="relative"
            overflow="hidden"
            borderRadius="lg"
            @click="handleSlide(slideIndex, slide)"
          >
            <c-flex
              position="absolute"
              top="0"
              left="0"
              bottom="0"
              right="0"
              :backgroundImage="
                currentPage === slideIndex
                  ? `linear-gradient(
                                to right top,
                               rgba(15, 198, 148, 1),
                                rgba(15, 198, 148, 1)
                            )`
                  : `linear-gradient(
                                to right top,
                                rgba(0, 0, 0, 0.7),
                                rgba(0, 0, 0, 0.7)
                            )`
              "
              justify="center"
              align="center"
            >
              <c-text color="#fff" text-align="center" fontWeight="600">{{
                slide.label
              }}</c-text>
            </c-flex>
          </c-pseudo-box>
        </slide>
      </template>
    </carousel>
  </c-box>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  props: {
    goToSlide: {
      type: Function,
    },
    currentTIndex: {
      type: [Number, String],
      default: 0,
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      currentPage: 0,
      // slides: [
      //   'Elevator Pitch',
      //   'Problem',
      //   'Solution',
      //   'How It Works',
      //   'Value Proposition',
      //   'Target Customers',
      //   'Business model',
      //   'Market Size',
      //   'Competition',
      //   'Point Of Differentiation',
      //   'Go to market strategy',
      //   'Market goals',
      //   'Traction',
      //   'Team',
      //   'Advisory Board',
      //   'Capital',
      //   'Financials',
      //   'Goals',
      // ],
    };
  },

  methods: {
    handleSlide(index, slideName) {
      this.currentPage = index;
      this.$emit('changeSlide', index, slideName);
      this.goToSlide(index);
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      // console.log(`page changed to ${currentPage}`);
    },
  },
};
</script>

<style></style>
