import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";
import { getItems } from "@/helpers/utils.js";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      pointsOfDifferentiation: [],
      pointOfDifferentiationData: null,
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedItems() {
      return this.pointsOfDifferentiation.filter((item) => item.selected);
    },
  },
  created() {
    this.setPointsOfDifferentiation();
  },
  watch: {
    presentation() {
      this.setPointsOfDifferentiation();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setPointsOfDifferentiation() {
      this.pointOfDifferentiationData =
        this.presentationData?.competition?.find(
          (data) => data.type === "point_of_differentiation"
        );

      const itemsArray = getItems(this.pointOfDifferentiationData.description);
      if (!this.presentation.data.slidesData?.pointsOfDifferentiation) {
        this.pointsOfDifferentiation = [...itemsArray];
      } else {
        const { pointsOfDifferentiation } = this.presentation.data.slidesData;
        this.pointsOfDifferentiation = itemsArray?.map((item) => {
          const itemInPresentation = pointsOfDifferentiation?.find(
            (_item) => _item.text === item.text
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async updatePointOfDifferentiationData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                pointsOfDifferentiation: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected Problems, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
