<template>
  <c-box w="100%">
    <c-flex maxWidth="100%" overflowX="scroll" py="5">
      <c-pseudo-box
        v-for="customer in customers"
        :key="customer.name"
        :bg="customerColorMap[customer.category]"
        minWidth="150px"
        height="60px"
        px="4"
        mr="7"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="4px"
        cursor="pointer"
        @click="onCustomerClick(customer)"
        position="relative"
      >
        <c-flex
          as="span"
          textTransform="uppercase"
          bg="#fff"
          color="customerColorMap[customer.category]"
          position="absolute"
          top="-10px"
          left="50%"
          transform="translateX(-50%)"
          fontSize="10px"
          px="4"
          py="1"
          fontWeight="bold"
          borderRadius="4px"
          align="center"
          justify="center"
        >
          {{ customer.category }}
        </c-flex>
        <c-text fontWeight="600" color="#fff">{{ customer.name }}</c-text>
      </c-pseudo-box>
    </c-flex>
    <c-box
      v-if="selectedCustomer"
      borderWidth="1px"
      my="4"
      borderRadius="4px"
      :borderColor="customerColorMap[selectedCustomer.category]"
    >
      <CustomerDetails :customer="selectedCustomer" :disabled="disabled" />
    </c-box>
  </c-box>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { customerColorMap } from '@/helpers/utils.js';
import { getCustomers } from '@/services/business-model';
import CustomerDetails from '../business-model/CustomerDetails.vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    customerSegment: {
      type: Object,
      required: true,
    },
  },
  components: { CustomerDetails },
  data() {
    return {
      customerColorMap,
      selectedCustomer: null,
    };
  },
  computed: {
    ...mapState('company', {
      customers: (state) => state.customers,
    }),
  },
  created() {
    if (!this.customers.length) {
      this.getCustomers();
    }
  },
  methods: {
    ...mapActions({
      setCustomers: 'company/setCustomers',
    }),
    getCustomers() {
      getCustomers(this.customerSegment.id).then((res) => {
        this.setCustomers(res.data.customer);
      });
    },
    onCustomerClick(customer) {
      if (
        customer &&
        this.selectedCustomer &&
        this.selectedCustomer.name === customer.name
      ) {
        this.selectedCustomer = null;
      } else {
        this.selectedCustomer = customer;
      }
    },
  },
};
</script>

<style></style>
