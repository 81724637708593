<template>
  <c-box>
    <c-drawer
      :isOpen="true"
      placement="right"
      :on-close="
        () => {
          $emit('close');
        }
      "
    >
      <c-drawer-overlay />
      <c-drawer-content>
        <c-drawer-header>Validation for {{ title }}</c-drawer-header>

        <c-drawer-body
          display="flex"
          overflowY="scroll"
          flexDirection="column"
          pb="8"
        >
          <div v-if="!isFetchingPosts && !posts.length">
            <p>You have no validation for {{ title }}</p>
          </div>
          <div
            style="text-align: center"
            v-if="isFetchingPosts && !posts.length"
          >
            <c-spinner ml="2" color="orange" thickness="2px" />
          </div>

          <div v-else>
            <c-box v-for="(post, index) in posts" :key="index">
              <c-stat-group>
                <c-stat>
                  <c-stat-label>Suggestions</c-stat-label>
                  <c-stat-number v-if="post.post_comments.length > 0">{{
                    post.post_comments.length
                  }}</c-stat-number>
                  <c-stat-helper-text>
                    <c-stat-arrow type="increase" />
                    30.60%
                  </c-stat-helper-text>
                </c-stat>
                <c-stat>
                  <c-stat-label>Impressions</c-stat-label>
                  <c-stat-number v-if="post.post_likes.length > 0">{{
                    post.post_likes.length
                  }}</c-stat-number>
                  <c-stat-helper-text>
                    <c-stat-arrow type="decrease" />
                    -5.20%
                  </c-stat-helper-text>
                </c-stat>
              </c-stat-group>

              <PostCard :post="post" :hideActions="true" />
            </c-box>
          </div>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import PostCard from '@/views/App/Explore/components/PostCard.vue';
import { getValidationPost } from '@/services/insight';

export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  components: {
    PostCard,
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      posts: [],
      isFetchingPosts: false,
    };
  },
  created() {
    this.getValidationPosts();
  },
  methods: {
    getValidationPosts() {
      this.isFetchingPosts = true;

      const payload = {
        userId: this.user.id,
        validation_field: this.title,
      };

      getValidationPost(payload).then((res) => {
        this.posts = res.data.post;
        this.isFetchingPosts = false;
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
