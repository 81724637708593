<template>
  <div class="risk-assessment">
    <GrowthSectionHeader
      :criteria="criteria"
      :completion="getRiskAssessmentLevel"
    />
    <c-stack :spacing="10" my="8">
      <c-box>
        <BusinessAnalysis :disabled="isShareView" />
      </c-box>
      <c-box>
        <RiskAnalysis :disabled="isShareView" />
      </c-box>
    </c-stack>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import GrowthSectionHeader from './components/GrowthSectionHeader.vue';
import BusinessAnalysis from './components/risk-assesstment/BusinessAnalysis.vue';
import RiskAnalysis from './components/risk-assesstment/RiskAnalysis.vue';
import scrollToComponent from '@/mixins/scroll-to-component.js';
import pageVisits from '@/mixins/page-visits.js';

export default {
  mixins: [scrollToComponent, pageVisits],
  components: {
    GrowthSectionHeader,
    BusinessAnalysis,
    RiskAnalysis,
  },
  data() {
    return {
      criteria: {
        title: 'Risk Assessment',
        description: `Risk assessment provides you and your team a process of identifying and assessing factors that could negatively affect the success of your business. This process is essential as it helps you and your team to determine the risks that your business faces or may face and helps you to mitigate those risks and decide whether or not a specific decision should be taken.
                    `,
        tasks: [
          { title: 'SWOT', value: 'swot' },
          { title: 'PESTLE', value: 'pestle' },
          { title: 'Risk Analysis', value: 'risk_analysis' },
        ],
      },
    };
  },
  computed: {
    ...mapState('company', {
      isShareView: (state) => state.isShareView,
    }),
    ...mapGetters('company', ['getRiskAssessmentLevel']),
  },
};
</script>

<style></style>
