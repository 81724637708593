<template>
  <div v-chakra overflowX="scroll" w="100%" maxWidth="100%">
    <table class="grid__table">
      <thead v-if="hasHeader" class="grid__table__header">
        <tr>
          <th
            v-for="title in columns
              .filter((elm) => !elm.disabled)
              .map(({ title }) => title)"
            :key="title"
            class="grid__table__header__cell"
          >
            {{ title }}
          </th>
        </tr>
      </thead>
      <tbody class="grid__table__body">
        <slot></slot>
        <!-- <tr
        v-for="(cellData, cellIndex) of data"
        :key="cellData.id || cellData.tempId"
      >
        <td
          :key="columnIdx"
          v-for="(column, columnIdx) in columns.filter((elm) => !elm.disabled)"
          class="grid__table__body__cell"
        >
          <c-flex h="100%" justify="center" v-if="componentIdx === 0">
            <c-link
              v-if="cellData.id"
              mr="auto"
              h="max-content"
              @click="onRemoveRow({ cellData, cellIndex })"
              mt="3"
            >
              <svg
                fill="vc-orange.300"
                v-chakra="{
                  cursor: 'pointer',
                  width: '12px',
                  height: '12px',
                }"
              >
                <use href="@/assets/icons/minus-box-fill.svg#minus"></use>
              </svg>
            </c-link>
            <component
              :is="column.component"
              :componentData="cellData"
              :cellIndex="cellIndex"
              :key="componentIdx"
              :action="column.action"
            />
          </c-flex>
          <c-flex py="3" v-else>
            <component
              :is="column.component"
              :componentData="cellData"
              :cellIndex="cellIndex"
              :key="componentIdx"
              :action="column.action"
            />
          </c-flex>
        </td>
      </tr> -->
      </tbody>
      <tfoot class="grid__table__footer" v-if="!disabled">
        <tr>
          <td colspan="100" class="grid__table__footer__cell">
            <c-link
              display="flex"
              alignItems="center"
              color="#798892"
              fontWeight="500"
              fontSize="sm"
              width="max-content"
              mb="3"
              @click="onAddRow()"
            >
              <svg
                fill="#798892"
                v-chakra="{
                  width: '12px',
                  height: '12px',
                  mr: '2',
                }"
              >
                <use href="@/assets/icons/icon-add.svg#add"></use>
              </svg>
              <span> {{ addRowLabel }}</span>
            </c-link>

            <c-link @click="openAiDrawer" v-if="!hideAIGenerate">
              <c-tag size="md" variant-color="red" mx="2">AI Generate</c-tag>
            </c-link>
            <br />
          </td>
        </tr>
      </tfoot>
    </table>
    <TeamDrawer
      :title="title"
      :type="type"
      :context="``"
      :isOpen="isTeamDrawerOpen"
      @close="closeTeamDrawer"
      v-if="isTeamDrawerOpen"
    />
  </div>
</template>

<script>
import TeamDrawer from '@/views/App/components/OpenAiDrawer.vue';
export default {
  // props: [
  //   'columns',
  //   'hasHeader',
  //   'onAddRow',
  //   'onRemoveRow',
  //   'addRowLabel',
  //   'disabled',
  // ],
  components: {
    TeamDrawer,
  },
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    onAddRow: {
      type: Function,
    },
    onRemoveRow: {
      type: Function,
    },
    addRowLabel: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    hideAIGenerate() {
      return this.title === 'Costs' || this.title === 'Revenue';
    },
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
  },
  data() {
    return {
      isTeamDrawerOpen: false,
      testcolumns: [
        {
          action: () => null,
          component: null,
          title: 'Employee Responsible',
          value: 'employeeResponsible',
          disabled: true,
        },
        {
          action: () => null,
          component: null,
          title: 'Action Plan',
          value: 'actionPlan',
          disabled: false,
        },
      ],
      tableData: [
        { actionPlan: 'monitor', employeeResponsible: 'emmanuel' },
        { actionPlan: 'monitor', employeeResponsible: 'emmanuel' },
      ],
    };
  },
  methods: {
    closeTeamDrawer(results) {
      this.onAddRow(results);
      this.isTeamDrawerOpen = false;
    },
    openAiDrawer() {
      this.onAddRow();
      this.isTeamDrawerOpen = true;
    },
  },
  // computed: {
  //   selectedSegmentComponent() {
  //     return this.segments.find(
  //       (segment) => segment.value === this.selectedSegment
  //     ).component;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.grid__table {
  @apply w-full;
  &__header {
    &__cell {
      @apply text-sm font-medium text-left px-4;
      color: #2d2d2d;
      min-width: 180px;
      max-width: 250px;
      height: 40px;
      border-width: 1px 1px 0 0;
      border-color: rgba(112, 112, 112, 0.2);
    }
  }
  &__body {
    &__cell {
      @apply text-sm text-left px-4 align-top;
      height: 160px;
      border-width: 1px 1px 0 0;
      border-color: rgba(112, 112, 112, 0.2);
    }
  }
  &__footer {
    &__cell {
      height: 65px;
      border-width: 1px 1px 1px 0;
      border-color: rgba(112, 112, 112, 0.2);
    }
  }
}
</style>
