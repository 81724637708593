<template>
  <c-box
    w="100%"
    h="100%"
    position="relative"
    :_before="{
      content: '',
      bg: '#fff',
      position: 'absolute',
      right: '0px',
      w: '75%',
      top: '0',
      bottom: '0',
      zIndex: 1,
    }"
  >
    <c-flex h="100%">
      <c-box
        :bgImage="`linear-gradient(to bottom ,${presentation.data.color}80, #fff)`"
        w="35%"
        pl="50px"
        pt="50px"
        bg="#fff"
      >
        <c-flex
          mt="20px"
          ml="20px"
          mb="50px"
          direction="column"
          justify="start"
          align="start"
        >
          <c-text textAlign="start" fontSize="2em" fontWeight="700"
            >Capital raised</c-text
          >
          <c-box h="5px" w="80px" :bg="`${presentation.data.color}80`"></c-box>
        </c-flex>

        <c-box pl="10px" textAlign="left">
          <c-box mb="30px">
            <c-text fontSize="16px" color="#8A8A8A"
              >Value of the Company</c-text
            >
            <EditableText
              v-model="capitalData.value"
              @blur="onCapitalInputBlur"
              type="capital"
            />
            <!-- <c-text fontSize="20px" fontWeight="600">300.000</c-text> -->
          </c-box>
          <c-box mb="30px">
            <c-text fontSize="16px" color="#8A8A8A">Funding</c-text>
            <EditableText
              v-model="capitalData.funding"
              @blur="onCapitalInputBlur"
            />
            <!-- <c-text fontSize="20px" fontWeight="600">400.000</c-text> -->
          </c-box>
          <c-box mb="30px">
            <c-text fontSize="16px" color="#8A8A8A" mb="2">Runaway</c-text>
            <c-input-group v-chakra w="40%" bg="transparent">
              <c-input
                v-chakra
                bg="transparent"
                type="date"
                roundedLeft="0"
                placeholder="Enter here"
              />
            </c-input-group>

            <!-- <c-text fontSize="20px" fontWeight="600"> 30 April 1995 </c-text> -->
          </c-box>
          <c-box mb="30px">
            <c-text fontSize="16px" color="#8A8A8A">Highest Cost(s)</c-text>
            <c-text fontSize="20px" fontWeight="600" v-if="largestCosts[0]">
              {{ largestCosts[0].type }}
            </c-text>
            <c-text fontSize="20px" fontWeight="600" v-if="largestCosts[1]">
              {{ largestCosts[1].type }}
            </c-text>
            <c-text fontSize="20px" fontWeight="600" v-if="largestCosts[2]">
              {{ largestCosts[2].type }}
            </c-text>
          </c-box>
        </c-box>
      </c-box>
      <c-flex flex="1" bg="#F6F6F6" justify="center">
        <c-box pl="10px" d="flex" justify-content="center">
          <c-flex direction="column" mt="7">
            <highcharts :options="chartOptions"></highcharts>
            <c-box mt="10px">
              <c-text py="2" px="4" color="#000">Capital</c-text>

              <c-flex align="center" mt="5">
                <c-box
                  h="20px"
                  w="20px"
                  bg="#EF5323"
                  borderRadius="50%"
                ></c-box>
                <c-text py="2" px="4">Current Value</c-text>
              </c-flex>

              <c-flex align="center" mt="5">
                <c-box
                  h="20px"
                  w="20px"
                  bg="#0FC694"
                  borderRadius="50%"
                ></c-box>
                <c-text py="2" px="4">Funding</c-text>
              </c-flex>
            </c-box>
          </c-flex>

          <c-flex direction="column" mt="7">
            <highcharts :options="spendingsChartOptions"></highcharts>
            <c-box mt="10px">
              <c-text py="2" px="4" color="#000">Spendings</c-text>

              <c-flex align="center" mt="5" v-if="largestCosts[0]">
                <c-box
                  h="20px"
                  w="20px"
                  bg="#EF5323"
                  borderRadius="50%"
                ></c-box>
                <c-text py="2" px="4">{{ largestCosts[0].type }}</c-text>
              </c-flex>

              <c-flex align="center" mt="5" v-if="largestCosts[1]">
                <c-box
                  h="20px"
                  w="20px"
                  bg="#0FC694"
                  borderRadius="50%"
                ></c-box>
                <c-text py="2" px="4">{{ largestCosts[1].type }}</c-text>
              </c-flex>

              <c-flex align="center" mt="5" v-if="largestCosts[2]">
                <c-box
                  h="20px"
                  w="20px"
                  bg="#0ADCE4"
                  borderRadius="50%"
                ></c-box>
                <c-text py="2" px="4">{{ largestCosts[2].type }}</c-text>
              </c-flex>
              <c-flex align="center" mt="5">
                <c-box
                  h="20px"
                  w="20px"
                  bg="#3250fe"
                  borderRadius="50%"
                ></c-box>
                <c-text py="2" px="4">OTHER COSTS</c-text>
              </c-flex>
            </c-box>
          </c-flex>
        </c-box>
      </c-flex>
    </c-flex>
    <c-box position="absolute" right="100px" bottom="10px">
      <WaterMark :direction="'row'" />
    </c-box>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import WaterMark from '@/components/WaterMark.vue';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';
import capitalMixin from '@/mixins/data/capital.js';
import costsMixin from '@/mixins/data/costs.js';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import variablePieInit from 'highcharts/modules/variable-pie';

variablePieInit(Highcharts);
export default {
  components: { WaterMark, EditableText, highcharts: Chart },
  mixins: [capitalMixin, costsMixin],
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          height: 200,
          width: 300,
          background: 'transparent',
          backgroundColor: 'transparent',
        },
        title: {
          text: null,
        },
        // tooltip: { enabled: false },
        plotOptions: {
          variablepie: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: '0%',
            zMin: 0,
            name: 'Capital Raise',
            size: 200,
            data: [
              {
                name: 'Current Value',
                y: 0,
                z: 0,
                color: '#EF5323',
              },
              {
                name: 'Funding',
                y: 0,
                z: 0,
                color: '#0FC694',
              },
            ],
          },
        ],
        exporting: { enabled: false },
      },
      spendingsChartOptions: {
        chart: {
          type: 'pie',
          height: 200,
          width: 300,
          background: 'transparent',
          backgroundColor: 'transparent',
        },
        title: {
          text: null,
        },
        // tooltip: { enabled: false },
        plotOptions: {
          variablepie: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: '0%',
            zMin: 0,
            name: 'Spendings',
            size: 200,
            data: [
              {
                name: 'Cost 1',
                y: 0,
                z: 0,
                color: '#EF5323',
              },
              {
                name: 'Cost 2',
                y: 0,
                z: 0,
                color: '#0FC694',
              },
              {
                name: 'Cost 3',
                y: 0,
                z: 0,
                color: '#0ADCE4',
              },
              {
                name: 'Other Costs',
                y: 0,
                z: 0,
                color: '#3250fe',
              },
            ],
          },
        ],
        exporting: { enabled: false },
      },
    };
  },
  computed: {
    ...mapState({
      presentation: (state) => state.presentation.activePresentation,
    }),
    sortedCosts() {
      const val = this.costs.map((cost) => {
        const maxVal = Math.max.apply(
          Math,
          cost?.valueArray?.map((o) => Number(o.value))
        );

        return {
          ...cost,
          maxVal,
        };
      });

      return val.sort((a, b) => a.maxVal - b.maxVal);
    },
    largestCosts() {
      return this.sortedCosts?.slice(0, 3);
    },
    otherCosts() {
      let val =
        this.sortedCosts.reduce(
          (accumulator, currentValue) => accumulator + +currentValue.maxVal,
          0
        ) -
        +this.largestCosts.reduce(
          (accumulator, currentValue) => accumulator + +currentValue.maxVal,
          0
        );

      return Math.abs(val);
    },
  },
  methods: {
    setChartValues() {
      this.chartOptions.series[0].data[0].y = Number(this.capitalData.value);
      this.chartOptions.series[0].data[0].z = Number(this.capitalData.value);
      this.chartOptions.series[0].data[1].y = Number(this.capitalData.funding);
      this.chartOptions.series[0].data[1].z = Number(this.capitalData.funding);
    },
    setSpendingChartValue() {
      this.spendingsChartOptions.series[0].data[0].y = Math.max.apply(
        Math,
        this.largestCosts[0]?.valueArray?.map((o) => Number(o.value))
      );
      this.spendingsChartOptions.series[0].data[0].z = Math.max.apply(
        Math,
        this.largestCosts[0]?.valueArray?.map((o) => Number(o.value))
      );
      this.spendingsChartOptions.series[0].data[1].y = Math.max.apply(
        Math,
        this.largestCosts[1]?.valueArray?.map((o) => Number(o.value))
      );
      this.spendingsChartOptions.series[0].data[1].z = Math.max.apply(
        Math,
        this.largestCosts[1]?.valueArray?.map((o) => Number(o.value))
      );
      this.spendingsChartOptions.series[0].data[2].y = Math.max.apply(
        Math,
        this.largestCosts[2]?.valueArray?.map((o) => Number(o.value))
      );
      this.spendingsChartOptions.series[0].data[2].z = Math.max.apply(
        Math,
        this.largestCosts[2]?.valueArray?.map((o) => Number(o.value))
      );

      this.spendingsChartOptions.series[0].data[3].y = this.otherCosts;
      this.spendingsChartOptions.series[0].data[3].z = this.otherCosts;
    },
  },

  mounted() {
    this.setChartValues();
    this.setSpendingChartValue();
  },
  watch: {
    capitalData: {
      deep: true,
      handler() {
        this.setChartValues();
      },
    },
    largestCosts() {
      this.setSpendingChartValue();
    },
  },
};
</script>

<style></style>
