import { mapState, mapGetters } from 'vuex';

import {
  addCompetitionComponentAccess,
  createCompetitionComponent,
  getCompetitionComponent,
  removeCompetitionComponentAccess,
  updateCompetitionComponent,
  updateCompetitionComponentVisibility,
  subscribeToCompetition,
} from '@/services/competition.js';

import { titlecase, getError } from '@/helpers/utils';
import { cloneDeep } from 'lodash';
import { generateID } from '../helpers/data';

export default {
  data() {
    return {
      competitionData: {},
      prevData: {},
      isFetchingData: false,
      hasAccess: true,
      isUpdatingMetadata: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
  },
  created() {
    this.getCompetitionData();
  },
  mounted() {
    this.subscribeToCompetitionData();
  },
  methods: {
    subscribeToCompetitionData() {
      subscribeToCompetition({
        type: this.competitionType,
        companyId: this.activeCompany.id,
      }).subscribe({
        next: (res) => {
          if (res.data.competition.length) {
            this.competitionData = res.data.competition[0];
            this.prevData = res.data.competition[0];
            if (this.onDataFetch) {
              this.onDataFetch();
            }
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.setCompetitionSeedData();
          } else {
            this.hasAccess = false;
          }
        },
        error(error) {
          console.error(error);
        },
      });
    },
    getCompetitionData() {
      this.isFetchingData = true;
      getCompetitionComponent({
        type: this.competitionType,
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          this.isFetchingData = false;
          if (res.data.competition.length) {
            this.competitionData = res.data.competition[0];
            this.prevData = res.data.competition[0];
            if (this.onDataFetch) {
              this.onDataFetch();
            }
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.setCompetitionSeedData();
          } else {
            this.hasAccess = false;
          }
        })
        .finally(() => {
          this.hasMounted = true;
        });
    },
    setCompetitionSeedData() {
      this.isFetchingData = true;
      const data = {
        type: this.competitionType,
        description: '',
        companyId: this.activeCompany.id,
      };
      if (this.competitionType === 'comparison') {
        data['hiddenFields'] = [
          'website',
          'keyTakeaway',
          'weaknesses',
          'strengths',
          'socialMediaEngagement',
          'customerExperience',
          'revenueStreams',
        ];
      }
      createCompetitionComponent(data).then((res) => {
        this.competitionData = res.data.insert_competition_one;
        this.prevData = res.data.insert_competition_one;
        this.isFetchingData = false;
        if (this.onDataCreate) {
          this.onDataCreate();
        }
      });
    },
    updateData({ key, value }) {
      const set = { [key]: value };
      this.competitionData[key] = value;
      updateCompetitionComponent({
        type: this.competitionType,
        companyId: this.activeCompany.id,
        set,
      })
        .then((res) => {
          if (!res.data.update_competition.returning.length) {
            throw { error: 'unauthorized' };
          }
        })
        .catch((e) => {
          this.competitionData[key] = this.prevData[key];
          if (e.error === 'unauthorized') {
            this.$toast({
              title: 'Unauthorized',
              description: `You are not allowed to edit this information`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
            return;
          }
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating ${titlecase(
              this.competitionType.split('_').join(' ')
            )}, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    updateAccess(user) {
      const accessIndex =
        this.competitionData.competition_assignments.findIndex(
          (access) => access.user.id == user.id
        );
      if (accessIndex === -1) {
        const tempId = generateID(4);
        this.competitionData.competition_assignments.push({
          id: tempId,
          user,
        });
        this.addAccess(user, tempId);
      } else {
        this.removeAccess(accessIndex);
      }
    },
    async addAccess(user, tempId) {
      const data = {
        competitionId: this.competitionData.id,
        userId: user.id,
      };
      const accessIndex =
        this.competitionData.competition_assignments.findIndex(
          (access) => access.id == tempId
        );
      try {
        const res = await addCompetitionComponentAccess(data);
        this.competitionData.competition_assignments[accessIndex] =
          res.data.insert_competition_assignment_one;
        this.prevData.competition_assignments = cloneDeep(
          this.competitionData.competition_assignments
        );
      } catch (err) {
        const error = Object.assign({}, err);
        this.competitionData.competition_assignments = cloneDeep(
          this.prevData.competition_assignments
        );
        getError(error.message, 'assigning user');
      }
    },
    async removeAccess(accessIndex) {
      const access = this.competitionData.competition_assignments.splice(
        accessIndex,
        1
      )[0];
      try {
        await removeCompetitionComponentAccess(access.id);
        this.prevData.competition_assignments = cloneDeep(
          this.competitionData.competition_assignments
        );
      } catch (e) {
        this.competitionData.competition_assignments = cloneDeep(
          this.prevData.competition_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing member access, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateVisibility(users) {
      this.isUpdatingMetadata = true;
      const newUsers = users.filter(
        (user) =>
          !this.competitionData.competition_visibilities.find(
            (visibility) => visibility.userId === user.id
          )
      );
      const removedVisibilities =
        this.competitionData.competition_visibilities.filter(
          (visibility) => !users.find((user) => visibility.userId === user.id)
        );
      const data = {
        addVisibility: newUsers.map((user) => ({
          userId: user.id,
          competitionId: this.competitionData.id,
        })),
        removedVisibilities: removedVisibilities.map(
          (visibility) => visibility.id
        ),
      };
      const visibilityData = cloneDeep(
        this.competitionData.competition_visibilities
      );
      try {
        const res = await updateCompetitionComponentVisibility(data);
        res.data.delete_competition_visibility.returning.forEach(
          (deletedVisibility) => {
            const deletedVisibilityIndex = visibilityData.find(
              (visibility) => visibility.id === deletedVisibility.id
            );
            visibilityData.splice(deletedVisibilityIndex, 1);
          }
        );
        visibilityData.push(
          ...res.data.insert_competition_visibility.returning
        );
        this.competitionData.competition_visibilities =
          cloneDeep(visibilityData);
        this.prevData.competition_visibilities = cloneDeep(visibilityData);
        this.isUpdatingMetadata = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
