import { render, staticRenderFns } from "./GrowthSectionHeader.vue?vue&type=template&id=fc72c3a6"
import script from "./GrowthSectionHeader.vue?vue&type=script&lang=js"
export * from "./GrowthSectionHeader.vue?vue&type=script&lang=js"
import style0 from "./GrowthSectionHeader.vue?vue&type=style&index=0&id=fc72c3a6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CLink: require('@chakra-ui/vue').CLink, CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList})
