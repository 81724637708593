import { render, staticRenderFns } from "./MembersList.vue?vue&type=template&id=1fa7282d&scoped=true"
import script from "./MembersList.vue?vue&type=script&lang=js"
export * from "./MembersList.vue?vue&type=script&lang=js"
import style0 from "./MembersList.vue?vue&type=style&index=0&id=1fa7282d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa7282d",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex})
