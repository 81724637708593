var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comparison"},[_c('table',{staticClass:"comparison__table"},[_c('thead',{staticClass:"comparison__table__header"},[_c('tr',[_c('th',{staticClass:"comparison__table__header__cell"},[_c('c-text',{attrs:{"fontSize":"xs","fontWeight":"500"}},[_vm._v(" Categories ")])],1),_c('th',{staticClass:"comparison__table__header__cell"},[_c('c-heading',{attrs:{"fontSize":"xl","fontWeight":"600"}},[_vm._v(" You ")])],1),_vm._l((_vm.competitors),function(competitor){return [(_vm.selectedCompetitors.includes(competitor.id + ''))?_c('th',{key:competitor.name,staticClass:"comparison__table__header__cell"},[_c('c-heading',{attrs:{"fontSize":"xl","fontWeight":"600","maxWidth":"60%","marginInline":"auto"}},[_vm._v(" "+_vm._s(competitor.name)+" ")])],1):_vm._e()]}),_c('th',{staticClass:"comparison__table__header__cell"},[_c('c-popover',{attrs:{"placement":"bottom"}},[_c('c-popover-trigger',[_c('c-link',{attrs:{"display":"flex","alignItems":"center","fontSize":"xs","fontWeight":"500"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    width: '12px',
                    height: '12px',
                    mr: '2',
                  }),expression:"{\n                    width: '12px',\n                    height: '12px',\n                    mr: '2',\n                  }"}],attrs:{"fill":"#495057"}},[_c('use',{attrs:{"href":require("@/assets/icons/icon-add.svg") + "#add"}})]),_vm._v(" Select Competitor to compare ")])],1),_c('c-popover-content',{attrs:{"w":"180px"}},[_c('c-popover-body',{attrs:{"p":"0","w":"max-content"}},[_c('c-checkbox-group',{attrs:{"default-value":_vm.selectedCompetitors},on:{"change":function($event){return _vm.onCompetitorSelect($event)}}},_vm._l((_vm.competitors),function(competitor){return _c('c-checkbox',{key:competitor.id,attrs:{"value":competitor.id + '',"alignItems":""}},[_c('c-text',{attrs:{"fontSize":"sm"}},[_vm._v(" "+_vm._s(competitor.name)+" ")])],1)}),1)],1)],1)],1)],1)],2)]),_c('tbody',{staticClass:"comparison__table__body"},_vm._l((_vm.visibleFieldsCategories),function(category){return _c('tr',{key:category.key},[_c('td',{staticClass:"comparison__table__body__cell"},[_c('c-flex',{attrs:{"align":"center","justify":"space-between"}},[_c('c-popover',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('c-popover-trigger',{attrs:{"cursor":"pointer"}},[_c('c-box',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    height: 'auto',
                  }),expression:"{\n                    height: 'auto',\n                  }"}],attrs:{"my":"2","mr":"3"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                      width: '10px',
                      height: '16px',
                      fill: '#ef5323',
                    }),expression:"{\n                      width: '10px',\n                      height: '16px',\n                      fill: '#ef5323',\n                    }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/vertical-dots.svg") + "#dots"}})])])],1),_c('c-popover-content',{attrs:{"maxWidth":"150px","zIndex":"modal"}},[_c('c-popover-body',{attrs:{"p":"0"}},[_c('c-list',{attrs:{"fontSize":"sm"}},[_c('c-list-item',[_c('c-box',{attrs:{"cursor":"pointer","w":"100%","px":"2","py":"2"},on:{"click":function($event){return _vm.removeCategory(category)}}},[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)}),_c('c-text',{attrs:{"mx":"auto","textAlign":"center"}},[_vm._v(" "+_vm._s(category.title)+" ")])],1)],1),_c('td',{staticClass:"comparison__table__body__cell"},[(category.type === 'text')?_c('c-editable',{attrs:{"default-value":_vm.selfCompetitor ? _vm.selfCompetitor[category.key] : null,"value":_vm.selfCompetitor ? _vm.selfCompetitor[category.key] : null,"isDisabled":_vm.disabled,"font-size":"14px","textAlign":"center"},on:{"input":(e) => (_vm.editableValue = e.target.value),"submit":function($event){return _vm.onEditableValueInput(null, category.key, _vm.selfCompetitor)}}},[_c('c-editable-preview'),_c('c-editable-input')],1):_c(category.component,_vm._b({tag:"component",attrs:{"value":_vm.selfCompetitor ? _vm.selfCompetitor[category.key] : null,"disabled":_vm.disabled},on:{"input":function($event){return _vm.onCompetitorValueInput(
                $event,
                null,
                category.key,
                _vm.selfCompetitor
              )}}},'component',category.props,false))],1),_vm._l((_vm.competitors),function(competitor,competitorIndex){return [(_vm.selectedCompetitors.includes(competitor.id + ''))?_c('td',{key:competitor.name,staticClass:"comparison__table__body__cell"},[(category.type === 'text')?_c('c-editable',{attrs:{"default-value":competitor[category.key],"value":competitor[category.key],"isDisabled":_vm.disabled,"font-size":"14px","textAlign":"center"},on:{"input":(e) => (_vm.editableValue = e.target.value),"submit":function($event){return _vm.onEditableValueInput(competitorIndex, category.key, null)}}},[_c('c-editable-preview'),_c('c-editable-input')],1):_c(category.component,_vm._b({tag:"component",attrs:{"disabled":_vm.disabled,"value":competitor[category.key]},on:{"input":function($event){return _vm.onCompetitorValueInput($event, competitorIndex, category.key)}}},'component',category.props,false))],1):_vm._e()]}),_c('td',{staticClass:"comparison__table__body__cell"})],2)}),0),_c('tfoot',{staticClass:"comparison__table__footer"},[_c('tr',{staticClass:"comparison__table__footer__row"},[_c('td',{staticClass:"comparison__table__footer__cell",attrs:{"colspan":"100"}},[_c('c-popover',{attrs:{"placement":"bottom"}},[_c('c-popover-trigger',[_c('c-link',{attrs:{"display":"flex","alignItems":"center","color":"#798892","fontSize":"sm","fontWeight":"500","width":"max-content"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    width: '12px',
                    height: '12px',
                    mr: '2',
                  }),expression:"{\n                    width: '12px',\n                    height: '12px',\n                    mr: '2',\n                  }"}],attrs:{"fill":"#798892"}},[_c('use',{attrs:{"href":require("@/assets/icons/icon-add.svg") + "#add"}})]),_c('span',[_vm._v(" Select Category")])])],1),_c('c-popover-content',{attrs:{"w":"max-content"}},[_c('c-popover-body',{attrs:{"p":"0"}},[_c('c-list',[_c('c-list-item',{attrs:{"fontSize":"sm"}},[_c('c-popover',{attrs:{"trigger":"hover","placement":'right'}},[_c('c-popover-trigger',[_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","py":"2","px":"4","alignItems":"center","_hover":{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            bg: 'vc-orange.50',
                          },"justifyContent":"space-between","fontSize":"sm","cursor":"pointer"}},[_c('span',[_vm._v(" Analysis ")]),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                              width: '10px',
                              height: '10px',
                              fill: '#ef5323',
                              ml: '2',
                            }),expression:"{\n                              width: '10px',\n                              height: '10px',\n                              fill: '#ef5323',\n                              ml: '2',\n                            }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-caret-right.svg") + "#caret"}})])])],1),_c('c-popover-content',{attrs:{"width":"max-content"}},[_c('c-popover-body',{attrs:{"p":"3","width":"max-content"}},[_c('c-checkbox-group',{attrs:{"default-value":_vm.analysisVisibleFields},on:{"change":function($event){return _vm.onFieldsChange($event, 'analysis')}}},_vm._l((_vm.analysisFields),function(field,index){return _c('c-checkbox',{key:'analysis-select' + field.title + index,attrs:{"value":field.key}},[_c('c-text',{attrs:{"fontSize":"sm"}},[_vm._v(" "+_vm._s(field.title)+" ")])],1)}),1)],1)],1)],1)],1),_c('c-list-item',{attrs:{"fontSize":"sm"}},[_c('c-popover',{attrs:{"trigger":"hover","placement":'right'}},[_c('c-popover-trigger',[_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","py":"2","px":"4","alignItems":"center","_hover":{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            bg: 'vc-orange.50',
                          },"justifyContent":"space-between","fontSize":"sm","cursor":"pointer"}},[_c('span',[_vm._v(" Conclusion ")]),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                              width: '10px',
                              height: '10px',
                              fill: '#ef5323',
                              ml: '2',
                            }),expression:"{\n                              width: '10px',\n                              height: '10px',\n                              fill: '#ef5323',\n                              ml: '2',\n                            }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-caret-right.svg") + "#caret"}})])])],1),_c('c-popover-content',{attrs:{"width":"max-content"}},[_c('c-popover-body',{attrs:{"p":"3","width":"max-content"}},[_c('c-checkbox-group',{attrs:{"default-value":_vm.conclusionVisibleFields},on:{"change":function($event){return _vm.onFieldsChange($event, 'conclusion')}}},_vm._l((_vm.conclusionFields),function(field,index){return _c('c-checkbox',{key:'cat-select' + field.title + index,attrs:{"value":field.key}},[_c('c-text',{attrs:{"fontSize":"sm"}},[_vm._v(" "+_vm._s(field.title)+" ")])],1)}),1)],1)],1)],1)],1)],1)],1)],1)],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }