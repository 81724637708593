<template>
  <c-box w="100%">
    <OptionSelectField
      v-if="selectedStage"
      :field="{ options: stages, label: 'business-stage' }"
      :has-placeholder="false"
      v-model="selectedStage"
      valueKey="id"
      label="name"
      :disabled="isShareView || !isCompanyAdmin"
    />
  </c-box>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { updateCompany } from '@/services/company';

import OptionSelectField from './../../../Onboarding/components/OptionSelectField.vue';
import cloneDeep from 'lodash.clonedeep';

export default {
  components: {
    OptionSelectField,
  },
  data() {
    return {
      selectedStage: null,
    };
  },
  created() {
    this.selectedStage = this.activeCompany.business_stage.id;
    this.$watch('selectedStage', this.updateCompanyStage);
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      isShareView: (state) => state.isShareView,
    }),
    ...mapState('config', {
      stages: (state) => state.businessStages,
    }),
    ...mapGetters('company', ['isCompanyAdmin']),
  },
  methods: {
    ...mapActions({
      setActiveCompany: 'company/setActiveCompany',
    }),
    updateCompanyStage() {
      updateCompany({
        id: this.activeCompany.id,
        set: { stageId: this.selectedStage },
      })
        .then((res) => {
          this.setActiveCompany(
            cloneDeep({
              ...this.activeCompany,
              ...res.data.update_company_by_pk,
            })
          );
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating business stage, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style></style>
