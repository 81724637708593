var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-flex',{staticClass:"prices",attrs:{"w":"100%","align":"center","direction":"column","justify":"center","maxHeight":"100%"}},[_c('c-flex',{attrs:{"h":"calc(100% - 15px)","overflowY":"scroll","align":"center","direction":"column"}},_vm._l((_vm.prices),function(price,priceIndex){return _c('c-flex',{key:price.id,attrs:{"align":"center","justify":"center","alignSelf":"stretch"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          w: '3',
          h: '3',
          mr: '2',
          cursor: 'pointer',
          fill: 'vc-orange.400',
        }),expression:"{\n          w: '3',\n          h: '3',\n          mr: '2',\n          cursor: 'pointer',\n          fill: 'vc-orange.400',\n        }"}],on:{"click":function($event){return _vm.deletePrice(priceIndex)}}},[_c('use',{attrs:{"href":require("@/assets/icons/icon-trash.svg") + "#trash"}})]),_c('c-editable',{attrs:{"default-value":price.value + '',"fontSize":"sm","fontWeight":"600","mr":"3","w":"max-content","minWidth":"20px","maxWidth":"100px","isDisabled":_vm.disabled},on:{"submit":function($event){return _vm.onValueSubmit($event, priceIndex)}}},[_c('c-editable-preview',{attrs:{"overflowX":"hidden","textOverflow":"ellipsis","maxWidth":"80px","verticalAlign":"middle"}}),_c('c-editable-input',{attrs:{"type":"number","w":"60px"}})],1),_c('AppSelect',{attrs:{"options":_vm.currencies,"indicator-fill":"orange","color":"grey","reduce":(currency) => currency.code,"label":"code","disabled":_vm.disabled,"appendToBody":true,"searchable":true},on:{"input":function($event){return _vm.onCurrencyChange($event, priceIndex)}},model:{value:(price.currency),callback:function ($$v) {_vm.$set(price, "currency", $$v)},expression:"price.currency"}})],1)}),1),((!_vm.isPreview || !_vm.disabled) && !(_vm.isSingle && _vm.prices.length > 0))?_c('c-pseudo-box',{attrs:{"as":"a","mt":"2","display":"flex","alignItems":"center","_hover":{ textDecoration: 'none', cursor: 'pointer' }},on:{"click":_vm.addPrice}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        width: '18px',
        height: '18px',
        fill: '#ef5323',
        mr: '1',
      }),expression:"{\n        width: '18px',\n        height: '18px',\n        fill: '#ef5323',\n        mr: '1',\n      }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})]),_c('c-text',{attrs:{"color":"#ef5323","fontWeight":"600","fontSize":"sm"}},[_vm._v(" Add Price ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }