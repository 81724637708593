import { render, staticRenderFns } from "./Prices.vue?vue&type=template&id=7eb8b1fd&scoped=true"
import script from "./Prices.vue?vue&type=script&lang=js"
export * from "./Prices.vue?vue&type=script&lang=js"
import style0 from "./Prices.vue?vue&type=style&index=0&id=7eb8b1fd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eb8b1fd",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CPseudoBox: require('@chakra-ui/vue').CPseudoBox})
