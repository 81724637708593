<template>
  <c-box>
    <TaskCard
      :title="'Sustainable Development Goals'"
      :description="''"
      :hasVisibility="true"
      :hasNotification="true"
      :hasImportance="true"
      :hasAssumption="false"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      @update="updateMetaData"
      :taskData="cardData"
      :accessKey="'company_sdg_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'company_sdg_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetaData"
      explanationKey="sdg"
      :hasAccess="hasAccess"
    >
      <SDGFields
        v-if="!isFetchingData"
        :sdgValues="sdgData.company_sdg_values"
        :contribution="sdgData.contribution"
        :evaluation="sdgData.evaluation"
        :disabled="disabled"
        @updateSdgs="onUpdateSdgs"
        @updateField="updateMetaData"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import cloneDeep from 'lodash.clonedeep';

import {
  createCompanySdg,
  getCompanySdg,
  addCompanySdgValues,
  removeCompanySdgValues,
  updateCompanySdg,
  addCompanySDGAccess,
  removeCompanySDGAccess,
  updateSDGVisibility,
  subscribeToCompanySdg,
} from '@/services/foundation.js';

import TaskCard from './../TaskCard.vue';
import SDGFields from './SDGFields.vue';
import { debounce } from '@/helpers/utils.js';
import { generateID } from '@/helpers/data';

export default {
  props: ['disabled'],
  components: {
    TaskCard,
    SDGFields,
  },
  data() {
    return {
      sdgData: {},
      prevData: {},
      hasAccess: true,
      isFetchingData: false,
      isUpdatingMetaData: false,
      hasMounted: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
    cardData() {
      return { ...this.sdgData, type: 'sdgs' };
    },
  },
  created() {
    this.getSdgData();
  },
  mounted() {
    this.subscribeToCompanySdgData();
  },
  methods: {
    subscribeToCompanySdgData() {
      subscribeToCompanySdg(this.activeCompany.id).subscribe({
        next: (res) => {
          const data = res.data?.company_sdg[0];
          if (data) {
            this.sdgData = cloneDeep(data);
            this.prevData = cloneDeep(data);
          }
        },
      });
    },
    getSdgData() {
      this.isFetchingData = true;
      getCompanySdg(this.activeCompany.id)
        .then((res) => {
          this.isFetchingData = false;
          if (res.data.company_sdg.length) {
            this.sdgData = cloneDeep(res.data.company_sdg[0]);
            this.prevData = cloneDeep(res.data.company_sdg[0]);
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.createSdgSeedData();
          } else {
            this.hasAccess = false;
          }
        })
        .finally(() => {
          this.hasMounted = true;
        });
    },
    createSdgSeedData() {
      this.isFetchingData = true;
      const data = {
        evaluation: '',
        contribution: '',
        companyId: this.activeCompany.id,
      };
      createCompanySdg(data).then((res) => {
        this.sdgData = cloneDeep(res.data.insert_company_sdg_one);
        this.prevData = cloneDeep(res.data.insert_company_sdg_one);
        this.isFetchingData = false;
      });
    },
    onUpdateSdgs: debounce(async function (goals) {
      const newGoals = goals.filter(
        (goal) =>
          !this.sdgData.company_sdg_values.find(
            (value) => value.SDGId == goal.id
          )
      );
      const removedGoals = this.sdgData.company_sdg_values.filter(
        (value) => !goals.find((goal) => value.SDGId == goal.id)
      );
      await this.addNewGoals(newGoals);
      this.removeGoals(removedGoals);
    }, 600),
    addNewGoals(goals) {
      if (goals.length) {
        const data = goals.map((goal) => {
          return {
            SDGId: goal.id,
            companySDGId: this.sdgData.id,
          };
        });
        return addCompanySdgValues(data)
          .then((res) => {
            this.sdgData.company_sdg_values.push(
              ...cloneDeep(res.data.insert_company_sdg_value.returning)
            );
            this.prevData.company_sdg_values.push(
              ...cloneDeep(res.data.insert_company_sdg_value.returning)
            );
          })
          .catch(() => {
            this.sdgData.company_sdg_values = cloneDeep(
              this.prevData.company_sdg_values
            );
          });
      }
    },
    removeGoals(values) {
      if (values.length) {
        const data = values.map((value) => value.id);
        removeCompanySdgValues(data)
          .then(() => {
            const values = [...this.sdgData.company_sdg_values];
            data.forEach((id) => {
              const index = values.findIndex((value) => value.id == id);
              values.splice(index, 1);
            });
            this.sdgData.company_sdg_values = cloneDeep(values);
            this.prevData.company_sdg_values = cloneDeep(values);
          })
          .catch(() => {
            this.sdgData.company_sdg_values = cloneDeep(
              this.prevData.company_sdg_values
            );
          });
      }
    },
    updateMetaData({ key, value }) {
      const set = { [key]: value };
      updateCompanySdg({
        id: this.sdgData.id,
        set,
      })
        .then(() => {
          this.sdgData[key] = value;
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating SDG data, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
          this.sdgData[key] = this.prevData[key];
        });
    },
    updateAccess(user) {
      const accessIndex = this.sdgData.company_sdg_assignments.findIndex(
        (access) => access.user.id == user.id
      );
      if (accessIndex === -1) {
        const tempId = generateID(4);
        this.sdgData.company_sdg_assignments.push({
          id: tempId,
          user,
        });
        this.addAccess(user, tempId);
      } else {
        this.removeAccess(accessIndex);
      }
    },
    async addAccess(user, tempId) {
      const data = {
        companySDGId: this.sdgData.id,
        userId: user.id,
      };
      const accessIndex = this.sdgData.company_sdg_assignments.findIndex(
        (access) => access.id == tempId
      );
      try {
        const res = await addCompanySDGAccess(data);
        this.sdgData.company_sdg_assignments[accessIndex] =
          res.data.insert_company_sdg_assignment_one;
        this.prevData.company_sdg_assignments = cloneDeep(
          this.sdgData.company_sdg_assignments
        );
      } catch (e) {
        this.sdgData.company_sdg_assignments = cloneDeep(
          this.prevData.company_sdg_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while assigning user, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async removeAccess(accessIndex) {
      const access = this.sdgData.company_sdg_assignments.splice(
        accessIndex,
        1
      )[0];
      try {
        await removeCompanySDGAccess(access.id);
        this.prevData.company_sdg_assignments = cloneDeep(
          this.sdgData.company_sdg_assignments
        );
      } catch (e) {
        this.sdgData.company_sdg_assignments = cloneDeep(
          this.prevData.company_sdg_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing member access, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateVisibility(users) {
      this.isUpdatingMetaData = true;
      const newUsers = users.filter(
        (user) =>
          !this.sdgData.company_sdg_visibilities.find(
            (visibility) => visibility.userId === user.id
          )
      );
      const removedVisibilities = this.sdgData.company_sdg_visibilities.filter(
        (visibility) => !users.find((user) => visibility.userId === user.id)
      );
      const data = {
        addVisibility: newUsers.map((user) => ({
          userId: user.id,
          companySDGId: this.sdgData.id,
        })),
        removedVisibilities: removedVisibilities.map(
          (visibility) => visibility.id
        ),
      };
      const visibilityData = cloneDeep(this.sdgData.company_sdg_visibilities);
      try {
        const res = await updateSDGVisibility(data);
        res.data.delete_company_sdg_visibility.returning.forEach(
          (deletedVisibility) => {
            const deletedVisibilityIndex = visibilityData.find(
              (visibility) => visibility.id === deletedVisibility.id
            );
            visibilityData.splice(deletedVisibilityIndex, 1);
          }
        );
        visibilityData.push(
          ...res.data.insert_company_sdg_visibility.returning
        );
        this.sdgData.company_sdg_visibilities = cloneDeep(visibilityData);
        this.prevData.company_sdg_visibilities = cloneDeep(visibilityData);
        this.isUpdatingMetaData = false;
      } catch (e) {
        this.isUpdatingMetaData = false;
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
