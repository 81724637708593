import { render, staticRenderFns } from "./ElevatorPitchCard.vue?vue&type=template&id=708f6b6a&scoped=true"
import script from "./ElevatorPitchCard.vue?vue&type=script&lang=js"
export * from "./ElevatorPitchCard.vue?vue&type=script&lang=js"
import style0 from "./ElevatorPitchCard.vue?vue&type=style&index=0&id=708f6b6a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "708f6b6a",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox})
