<template>
  <div class="skills-select">
    <div class="skills-select__header">
      <c-box>
        <div class="skills-select__count">
          <span>{{ selectedSkills.length }}</span>
          Selected skills
        </div>
      </c-box>
    </div>
    <c-flex justify="center" mb="5">
      <c-box
        position="relative"
        tabindex="-1"
        @focusin="onSearchInputFocus"
        @focusout="onSearchInputBlur"
      >
        <c-flex align="center">
          <svg
            v-chakra="{
              w: '15px',
              h: '15px',
              fill: '#ef5323',
            }"
          >
            <use href="@/assets/icons/icon-search.svg#icon-search"></use>
          </svg>
          <input
            ml="3"
            v-chakra
            borderTopWidth="0"
            borderRightWidth="0"
            borderLeftWidth="0"
            borderRadius="0"
            outline="none"
            type="text"
            h="40px"
            w="300px"
            v-model="searchString"
            @keyup="onSearchInputKeyUp"
            placeholder="Select skill"
          />
        </c-flex>
        <c-list
          w="300px"
          bg="#fff"
          top="100%"
          zIndex="modal"
          maxHeight="300px"
          overflowY="scroll"
          position="absolute"
          left="15px"
          v-show="isSkillsListVisible"
          borderBottomLeftRadius="6px"
          borderBottomRightRadius="6px"
          shadow="0px 4px 4px rgba(0, 0, 0, 0.08), -2px 0px 4px rgba(0, 0, 0, 0.08)"
        >
          <c-list-item v-for="skill in filteredSkills" :key="skill.id">
            <c-pseudo-box
              display="flex"
              justifyContent="space-between"
              px="2"
              py="2"
              cursor="pointer"
              @click="onSkillSelect(skill)"
            >
              {{ skill.name }}
              <c-icon
                ml="2"
                v-if="isSelected(skill.id)"
                name="check"
                color="vue.400"
              ></c-icon>
            </c-pseudo-box>
          </c-list-item>
          <c-list-item
            v-if="filteredSkills.length === 0 && searchString.length > 0"
          >
            <c-pseudo-box
              display="flex"
              justifyContent="space-between"
              px="2"
              py="2"
              cursor="pointer"
              @click="onSkillAdd(searchString)"
            >
              Add '{{ searchString }}'
            </c-pseudo-box>
          </c-list-item>
        </c-list>
      </c-box>
    </c-flex>
    <c-flex my="3" flexWrap="wrap">
      <c-flex
        p="2"
        mr="2"
        mb="2"
        h="35px"
        bg="#37d5aa"
        align="center"
        w="max-content"
        :key="skill.id"
        minWidth="120px"
        justify="center"
        borderRadius="4px"
        v-for="skill in selectedSkills"
      >
        <c-text fontSize="sm" fontWeight="500" color="#fff">
          {{ skill.name }}
        </c-text>
      </c-flex>
    </c-flex>
    <!-- <div class="skills-select__options">
      <template v-for="(option, index) in filteredSkills">
        <label
          class="skills-select__option"
          :for="'skill' + option.id"
          :key="option.id"
          v-if="index + 1 <= 10 || showAllSkills"
          :disabled="disabled"
        >
          <c-visually-hidden
            :name="option.name"
            :id="'skill' + option.id"
            as="input"
            type="checkbox"
            :value="option.id"
            @change="onInputChanged"
            :disabled="disabled"
            :checked="selectedSkills.includes(option.id)"
          />
          <c-control-box
            border-width="1px"
            px="10px"
            w="max-content"
            minWidth="50px"
            h="35px"
            rounded="md"
            type="checkbox"
            borderColor="#37d5aa"
            fontWeight="500"
            :disabled="disabled"
            :_checked="{
              backgroundColor: '#37d5aa',
              color: 'white',
            }"
          >
            {{ option.name }}
          </c-control-box>
        </label>
      </template>
    </div> -->
    <!-- <c-link
      class="skills-select__toggle"
      @click="showAllSkills = !showAllSkills"
    >
      Show {{ showAllSkills ? 'less' : 'more' }}
    </c-link> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createSkill } from '../../../../../services/foundation';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSkills: [],
      showAllSkills: false,
      filteredSkills: [],
      isSkillsListVisible: false,
      searchString: '',
    };
  },
  computed: {
    ...mapState('config', {
      skills: (state) => state.skills,
    }),
  },
  created() {
    this.filteredSkills = [...this.skills];
    if (this.value.length) {
      this.setSelectedSkills();
    }
  },
  watch: {
    value() {
      this.setSelectedSkills();
    },
  },
  methods: {
    onSkillSelect(skill) {
      if (!this.isSelected(skill.id)) {
        this.selectedSkills.push({ ...skill });
      } else {
        const index = this.selectedSkills.findIndex(
          (_skill) => _skill.id === skill.id
        );
        this.selectedSkills.splice(index, 1);
      }
      this.$emit(
        'updateSkills',
        this.selectedSkills.map((_skill) => _skill.id)
      );
    },
    onSkillAdd(skill) {
      // this.$emit('addSkill', skill);
      createSkill({
        name: skill,
        isUserGenerated: true,
      })
        .then((res) => {
          this.onSkillSelect(res.data.insert_skill_one);
          // console.log(res);
        })
        .finally(() => {
          this.onSearchInputBlur();
        });
      // console.log(skill);
    },
    setSelectedSkills() {
      this.selectedSkills = this.value.map((skill) => skill.skillBySkill);
    },
    onSearchInputFocus() {
      this.isSkillsListVisible = true;
    },
    onSearchInputBlur() {
      this.isSkillsListVisible = false;
    },
    isSelected(id) {
      return !!this.selectedSkills.find((skill) => skill.id === id);
    },
    onSearchInputKeyUp() {
      const searchRegex = new RegExp(this.searchString.trim(), 'i');
      this.filteredSkills = this.skills.filter((skill) =>
        searchRegex.test(skill.name)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.skills-select {
  @apply my-5;
  &__header {
    @apply flex justify-between;
    ::v-deep {
      input {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  &__count {
    span {
      @apply text-6xl;
      color: $color-green;
    }
  }
  &__options {
    @apply flex flex-wrap my-8;
  }
  &__option {
    @apply mr-5 mt-3 cursor-pointer text-sm relative;
  }
  &__toggle {
    @apply flex font-semibold text-sm;
    margin: 0 auto;
    width: max-content;
    color: $color-orange;
  }
}
</style>
