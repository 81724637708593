import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";
import { getItems } from "@/helpers/utils.js";

export default {
  inject: ["updatePresentation", "updateMarketPotentialComponent"],
  data() {
    return {
      keyItems: [],
      marketStrategyData: null,
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedItems() {
      return this.keyItems.filter((item) => item.selected);
    },
  },
  created() {
    this.setKeyItems();
  },
  watch: {
    presentation() {
      this.setKeyItems();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
      updatePresentationData: "presentation/updatePresentationData",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setKeyItems() {
      this.marketStrategyData = this.presentationData?.marketPotential?.find(
        (data) => data.type === "go_to_market_strategy"
      );

      console.log("stratt", this.marketStrategyData);
      const itemsArray = getItems(this.marketStrategyData.otherDescription);
      if (!this.presentation.data.slidesData?.marketStrategyKeyItems) {
        this.keyItems = [...itemsArray];
      } else {
        const { marketStrategyKeyItems } = this.presentation.data.slidesData;
        this.keyItems = itemsArray?.map((item) => {
          const itemInPresentation = marketStrategyKeyItems?.find(
            (_item) => _item.text === item.text
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async onMarketStragetyInputBlur() {
      const oldValue = this.presentationData?.marketStrategy;
      console.log("test", this.marketStrategyData, oldValue);
      this.updatePresentationData({
        ...this.presentationData,
        marketStrategy: this.marketStrategyData?.description,
      });
      try {
        await this.updateMarketPotentialComponent({
          id: this.marketStrategyData?.id,
          set: { description: this.marketStrategyData?.description },
        });
      } catch (e) {
        console.log(e);
        this.updatePresentationData({
          ...this.presentationData,
          marketStrategy: oldValue,
        });
        this.marketStrategyData.description = oldValue;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating market strategy details, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
    async updateMarketStrategyData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                marketStrategyKeyItems: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected Problems, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
