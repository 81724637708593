<template>
  <c-flex
    class="prices"
    w="100%"
    align="center"
    direction="column"
    justify="center"
    maxHeight="100%"
  >
    <c-flex
      h="calc(100% - 15px)"
      overflowY="scroll"
      align="center"
      direction="column"
    >
      <c-flex
        v-for="(price, priceIndex) in prices"
        :key="price.id"
        align="center"
        justify="center"
        alignSelf="stretch"
      >
        <svg
          @click="deletePrice(priceIndex)"
          v-chakra="{
            w: '3',
            h: '3',
            mr: '2',
            cursor: 'pointer',
            fill: 'vc-orange.400',
          }"
        >
          <use href="@/assets/icons/icon-trash.svg#trash"></use>
        </svg>
        <c-editable
          :default-value="price.value + ''"
          fontSize="sm"
          fontWeight="600"
          mr="3"
          w="max-content"
          minWidth="20px"
          maxWidth="100px"
          :isDisabled="disabled"
          @submit="onValueSubmit($event, priceIndex)"
        >
          <c-editable-preview
            overflowX="hidden"
            textOverflow="ellipsis"
            maxWidth="80px"
            verticalAlign="middle"
          />
          <c-editable-input type="number" w="60px" />
        </c-editable>
        <AppSelect
          v-model="price.currency"
          :options="currencies"
          indicator-fill="orange"
          color="grey"
          :reduce="(currency) => currency.code"
          label="code"
          :disabled="disabled"
          :appendToBody="true"
          :searchable="true"
          @input="onCurrencyChange($event, priceIndex)"
        />
      </c-flex>
    </c-flex>
    <c-pseudo-box
      as="a"
      mt="2"
      display="flex"
      alignItems="center"
      :_hover="{ textDecoration: 'none', cursor: 'pointer' }"
      @click="addPrice"
      v-if="(!isPreview || !disabled) && !(isSingle && prices.length > 0)"
    >
      <svg
        v-chakra="{
          width: '18px',
          height: '18px',
          fill: '#ef5323',
          mr: '1',
        }"
      >
        <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
      </svg>
      <c-text color="#ef5323" fontWeight="600" fontSize="sm">
        Add Price
      </c-text>
    </c-pseudo-box>
  </c-flex>
</template>

<script>
import AppSelect from './../../../components/AppSelect';
import currenciesArray from '@/helpers/currencies.js';
import { generateID } from '@/helpers/data';
import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppSelect,
  },
  data() {
    return {
      prices: [],
      currencies: currenciesArray,
    };
  },
  created() {
    if (this.value) {
      this.prices = cloneDeep(this.value);
    }
  },
  watch: {
    value() {
      this.prices = cloneDeep(this.value);
    },
  },
  methods: {
    addPrice() {
      this.prices.push({ id: generateID(4), value: 0, currency: 'USD' });
    },
    onValueSubmit(e, priceIndex) {
      this.prices[priceIndex].value = e;
      this.updateData();
    },
    onCurrencyChange(e, priceIndex) {
      const price = this.prices[priceIndex];
      if (price.value && price.value.trim()) {
        this.updateData();
      }
    },
    updateData() {
      this.$emit('input', cloneDeep(this.prices));
    },
    deletePrice(index) {
      this.prices.splice(index, 1);
      this.$emit('input', cloneDeep(this.prices));
    },
  },
};
</script>

<style lang="scss" scoped>
.prices {
  ::v-deep {
    .vc-select {
      .vs__dropdown-toggle {
        min-width: max-content;
      }
      .vs__actions {
        padding-top: 3px;
      }
      .vs__selected-options {
        flex-basis: 50%;
        flex-grow: 0;
      }
    }
  }
}
</style>
