<template>
  <c-stack mr="3em" :spacing="2">
    <c-heading fontSize="sm" color="#495057" as="h6"> Visibility </c-heading>
    <c-popover :placement="'bottom'" v-slot="{}">
      <c-popover-trigger>
        <c-link display="flex" fontWeight="500" color="#798892" fontSize="xs">
          <c-text as="span" mr="0.3rem">
            <!-- <template v-if="!members.length">Only you</template>
            <template v-else-if="members.length === teamMembers.length">
              Entire Team
            </template>
            <template v-else>
              {{ members.length }} member{{ members.length > 1 ? 's' : '' }}
            </template> -->
            Custom Access
          </c-text>
          <svg
            v-chakra="{
              w: '15px',
              h: '15px',
              fill: '#ef5323',
            }"
          >
            <use href="@/assets/icons/edit-fill.svg#edit"></use>
          </svg>
        </c-link>
      </c-popover-trigger>
      <c-popover-content
        z-index="4"
        w="max-content"
        ref="visibilityOptionsContent"
      >
        <c-popover-body paddingTop="5px" paddingBottom="5px" paddingInline="2">
          <!-- <c-list maxWidth="170px">
            <c-list-item
              fontSize="xs"
              paddingTop="10px"
              paddingBottom="10px"
              paddingLeft="10px"
              paddingRight="5px"
              color="#ef5323"
              fontWeight="600"
            >
              <c-popover
                @close="onMembersListClose"
                placement="right"
                trigger="hover"
              >
                <c-popover-trigger>
                  <c-text as="a">
                    Give custom access
                    <c-icon
                      name="chevron-right"
                      color="vc-orange.400"
                      size="12px"
                    />
                  </c-text>
                </c-popover-trigger>
                <c-popover-content>
                  <c-popover-body>
                    <c-checkbox-group
                      variant-color="green"
                      v-model="selectedMembers"
                      @change="onCheckListChange"
                    >
                      <c-checkbox
                        v-for="member of teamMembers"
                        :key="member.user.id"
                        :value="member.user.id + ''"
                      >
                        <c-text fontSize="xs" as="span">
                          {{ member.user.firstname }} {{ member.user.lastname }}
                        </c-text>
                      </c-checkbox>
                    </c-checkbox-group>
                  </c-popover-body>
                </c-popover-content>
              </c-popover>
            </c-list-item>
            <c-list-item
              fontSize="xs"
              paddingTop="10px"
              paddingBottom="10px"
              paddingLeft="10px"
              paddingRight="10px"
              color="#ee2f1f"
              fontWeight="600"
              borderTopWidth="1px"
              borderTopColor="#7070702c"
            >
              <c-pseudo-box
                cursor="pointer"
                as="a"
                @click="onEntireTeamClick(onClose)"
              >
                Select entire team
              </c-pseudo-box>
            </c-list-item>
            <c-list-item
              fontSize="xs"
              paddingTop="10px"
              paddingBottom="10px"
              paddingLeft="10px"
              paddingRight="10px"
              color="#ee2f1f"
              fontWeight="600"
              borderTopWidth="1px"
              borderTopColor="#7070702c"
            >
              <c-pseudo-box
                cursor="pointer"
                as="a"
                @click="onOnlyYouClick(onClose)"
              >
                Only you
              </c-pseudo-box>
            </c-list-item>
          </c-list> -->
          <c-checkbox-group
            variant-color="green"
            v-model="selectedMembers"
            @change="onCheckListChange"
          >
            <c-checkbox
              v-for="member of teamMembers"
              :key="member.user.id"
              :value="member.user.id + ''"
            >
              <c-text fontSize="xs" as="span">
                {{ member.user.firstname }} {{ member.user.lastname }}
              </c-text>
            </c-checkbox>
          </c-checkbox-group>
        </c-popover-body>
      </c-popover-content>
    </c-popover>
  </c-stack>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from '@/helpers/utils.js';

export default {
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedMembers: [],
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState({
      teamMembers: (state) =>
        state.company.members.filter(
          (member) =>
            member.user.isVerified &&
            member.user.id !== state.auth.user.id &&
            member.accessType === 'custom' &&
            member.role !== 'ADMIN'
        ),
    }),
  },
  watch: {
    members() {
      this.setSelectedMembers();
    },
  },
  created() {
    this.setSelectedMembers();
  },
  methods: {
    onCheckListChange: debounce(function () {
      const members = this.selectedMembers
        .map((id) => {
          const member = this.teamMembers.find(
            (member) => member.user.id === +id
          );
          if (member) {
            return member.user;
          }
          return null;
        })
        .filter((member) => member !== null);
      this.$emit('selected', members);
    }, 800),
    onMembersListClose() {
      this.$refs.visibilityOptionsContent.$el.focus();
    },
    onEntireTeamClick(closePopover) {
      closePopover();
      this.$emit(
        'selected',
        this.teamMembers.map((member) => member.user)
      );
    },
    onOnlyYouClick(closePopover) {
      this.$emit('selected', []);
      closePopover();
    },
    setSelectedMembers() {
      this.selectedMembers = this.members.map((member) => member.id + '');
    },
  },
};
</script>

<style></style>
