<template>
  <c-box w="100%">
    <c-stack :spacing="2">
      <c-flex align="center">
        <c-text fontSize="sm" mr="2" fontWeight="500" color="gray.500">
          Impact:
        </c-text>
        <AppSelect
          v-model="impact"
          :options="[
            'Negligible',
            'Minor',
            'Moderate',
            'Significant',
            'Severe',
          ]"
          :disabled="disabled"
          :color="'dark'"
          :appendToBody="true"
          :indicatorFill="'orange'"
        />
      </c-flex>
      <c-flex align="center">
        <c-text fontSize="sm" mr="2" fontWeight="500" color="gray.500">
          Likelihood:
        </c-text>
        <AppSelect
          :disabled="disabled"
          v-model="likelihood"
          :options="[
            'Very Likely',
            'Likely',
            'Possible',
            'Unlikely',
            'Very Unlikely',
          ]"
          :color="'dark'"
          :appendToBody="true"
          :indicatorFill="'orange'"
        />
      </c-flex>
      <c-box w="100%">
        <c-text fontSize="sm" fontWeight="500" color="gray.500" mb="4">
          Risk Force:
          <c-text
            as="span"
            textTransform="uppercase"
            fontWeight="600"
            color="#000"
            ml="2"
          >
            {{ matrixValues[riskForce].title }}
          </c-text>
        </c-text>
        <c-progress
          :color="matrixValues[riskForce].color"
          size="sm"
          :value="matrixValues[riskForce].value"
        />
      </c-box>
    </c-stack>
  </c-box>
</template>

<script>
import AppSelect from '../../../components/AppSelect.vue';
export default {
  components: { AppSelect },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      impact: 'Moderate',
      likelihood: 'Very Unlikely',
      riskForce: 'low',
      riskMatrix: {
        'very likely-negligible': 'lowMedium',
        'very likely-minor': 'medium',
        'very likely-moderate': 'mediumHigh',
        'very likely-significant': 'high',
        'very likely-severe': 'high',
        'likely-negligible': 'low',
        'likely-minor': 'lowMedium',
        'likely-moderate': 'medium',
        'likely-significant': 'mediumHigh',
        'likely-severe': 'high',
        'possible-negligible': 'low',
        'possible-minor': 'lowMedium',
        'possible-moderate': 'medium',
        'possible-significant': 'mediumHigh',
        'possible-severe': 'mediumHigh',
        'unlikely-negligible': 'low',
        'unlikely-minor': 'lowMedium',
        'unlikely-moderate': 'lowMedium',
        'unlikely-significant': 'medium',
        'unlikely-severe': 'mediumHigh',
        'very unlikely-negligible': 'low',
        'very unlikely-minor': 'low',
        'very unlikely-moderate': 'lowMedium',
        'very unlikely-significant': 'medium',
        'very unlikely-severe': 'medium',
      },
      matrixValues: {
        low: {
          title: 'Low',
          value: 16,
          color: 'blue',
        },
        lowMedium: {
          title: 'Low Medium',
          value: 32,
          color: 'vue',
        },
        medium: {
          title: 'Medium',
          value: 64,
          color: 'yellow',
        },
        mediumHigh: {
          title: 'Medium High',
          value: 80,
          color: 'orange',
        },
        high: {
          title: 'High',
          value: 100,
          color: 'red',
        },
      },
    };
  },
  created() {
    if (this.value) {
      this.setInnerValues();
    }
  },
  watch: {
    value() {
      this.setInnerValues();
    },
    likelihood() {
      this.setRiskForce();
      if (this.value.likelihood !== this.likelihood) {
        this.$emit('input', {
          likelihood: this.likelihood,
          impact: this.impact,
        });
      }
    },
    impact() {
      this.setRiskForce();
      if (this.value.impact !== this.impact) {
        this.$emit('input', {
          likelihood: this.likelihood,
          impact: this.impact,
        });
      }
    },
  },
  methods: {
    setInnerValues() {
      this.likelihood = this.value.likelihood;
      this.impact = this.value.impact;
    },
    setRiskForce() {
      this.riskForce = this.riskMatrix[
        `${this.likelihood.toLowerCase()}-${this.impact.toLowerCase()}`
      ];
    },
  },
};
</script>

<style></style>
