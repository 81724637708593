var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member"},[_c('div',{staticClass:"member__header"},[_c('div',{staticClass:"member__details"},[(!_vm.disabled)?_c('c-popover',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"default",fn:function({ onClose }){return [_c('c-popover-trigger',{attrs:{"position":"absolute","right":"15px","top":"15px","cursor":"pointer"}},[_c('c-box',[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                width: '10px',
                height: '16px',
                fill: '#ef5323',
              }),expression:"{\n                width: '10px',\n                height: '16px',\n                fill: '#ef5323',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/vertical-dots.svg") + "#dots"}})])])],1),_c('c-popover-content',{attrs:{"maxWidth":"150px","zIndex":"modal"}},[_c('c-popover-body',{attrs:{"p":"0"}},[_c('c-list',{attrs:{"fontSize":"sm"}},[(_vm.isCompanyOwner || _vm.isCompanyAdmin)?_c('c-list-item',[_c('c-box',{attrs:{"cursor":"pointer","w":"100%","px":"2","py":"2"},on:{"click":function($event){return _vm.deleteMember(_vm.member, onClose)}}},[_vm._v("Delete")])],1):_vm._e()],1)],1)],1)]}}],null,false,1486060713)}):_vm._e(),_c('c-avatar',{staticClass:"member__avatar",attrs:{"size":"lg","src":_vm.member.user.profilePhoto,"name":_vm.member.user.firstname
            ? _vm.member.user.firstname + ' ' + _vm.member.user.lastname
            : _vm.member.user.email,"mr":"2"}}),_c('c-box',[(_vm.member.user.firstname)?_c('h5',{staticClass:"member__name"},[_vm._v(" "+_vm._s(_vm.member.user.firstname)+" "+_vm._s(_vm.member.user.lastname)+" ")]):_c('h5',{staticClass:"member__name"},[_vm._v(" "+_vm._s(_vm.member.user.email)+" ")]),_c('p',{staticClass:"member__position"},[_c('span',[_vm._v(_vm._s(_vm.member.position)+" ")])])])],1)]),(!_vm.disabled)?_c('div',{staticClass:"member__content",on:{"drop":_vm.onSkillDrop,"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"member__skills"},_vm._l((_vm.skills),function(skill,index){return _c('div',{key:skill.id,staticClass:"member__skill"},[_c('span',{staticClass:"member__skill__text"},[_vm._v(" "+_vm._s(skill.company_skill_value.skillBySkill.name)+" ")]),_c('span',{staticClass:"member__skill__remove",on:{"click":function($event){return _vm.removeSkill(index, skill)}}},[_c('svg',[_c('use',{attrs:{"href":require("@/assets/icons/icon-close.svg") + "#close"}})])])])}),0),_c('div',{staticClass:"member__skills__drop",class:{ 'member__skills__drop--dropped': _vm.skills.length }},[_c('span',[_vm._v("Drag and drop skill")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }