import { render, staticRenderFns } from "./OverviewDetailsCard.vue?vue&type=template&id=37e1c250"
import script from "./OverviewDetailsCard.vue?vue&type=script&lang=js"
export * from "./OverviewDetailsCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CInput: require('@chakra-ui/vue').CInput, CText: require('@chakra-ui/vue').CText, CStack: require('@chakra-ui/vue').CStack, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex})
