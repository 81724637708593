<template>
  <c-box
    p="8"
    mr="8"
    bg="#fff"
    borderRadius="8px"
    justify="space-between"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
  >
    <c-flex justify="space-between" mb="4">
      <c-text fontSize="xl" fontWeight="600">Responsibilities</c-text>
      <!-- <AppSelect
        color="orange"
        :options="['All']"
        :value="'All'"
        indicator-fill="orange"
      /> -->
    </c-flex>
    <c-stack maxHeight="300px" overflowY="scroll" :spacing="2">
      <c-text v-if="!moduleAsssignments.length">
        You have not being assigned to any task
      </c-text>
      <c-pseudo-box
        boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
        w="100%"
        p="6"
        bg="#fff"
        mr="8"
        justify="space-between"
        v-for="(assignment, index) in moduleAsssignments"
        :key="assignment.id + '-' + index"
        @click="goToAssignment(assignment)"
      >
        <c-flex align="center" justify="space-between">
          <c-flex align="center">
            <c-flex
              px="3"
              py="2"
              mr="3"
              w="max-content"
              borderRadius="4px"
              align="center"
              :color="importanceLevels[assignment.priority].color"
              :backgroundColor="importanceLevels[assignment.priority].colorRgba"
            >
              <svg
                v-chakra="{
                  fill: importanceLevels[assignment.priority].color,
                  w: '4',
                  h: '4',
                  mr: '2',
                }"
              >
                <use href="@/assets/icons/flag-line.svg#flag"></use>
              </svg>
              <c-text fontWeight="500">
                {{ importanceLevels[assignment.priority].name }}
              </c-text>
            </c-flex>
            <c-text fontWeight="600">
              {{
                titlecase(
                  (assignment.type || assignment.__typename)
                    .split('_')
                    .join(' ')
                )
              }}
            </c-text>
          </c-flex>
          <c-link>
            <svg
              v-chakra="{
                w: '5',
                h: '5',
                fill: '#ef5323',
              }"
            >
              <use href="@/assets/icons/share-box-fill.svg#share"></use>
            </svg>
          </c-link>
        </c-flex>
        <c-text
          color="gray.400"
          fontSize="sm"
          fontWeight="500"
          my="2"
          v-if="assignment.description"
          v-html="assignment.description"
          class="description"
        ></c-text>
      </c-pseudo-box>
    </c-stack>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import { flatten } from 'lodash';
import { titlecase } from '@/helpers/utils';

export default {
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    moduleAsssignments() {
      return flatten(
        this.modules.map((_module) =>
          this.user[_module.assignmentKey].map(
            (assignment) => assignment[_module.moduleKey]
          )
        )
      ).filter((module) => module.companyId === this.activeCompany.id);
    },
  },
  data() {
    return {
      importanceLevels: {
        urgent: {
          name: 'Urgent',
          value: 'urgent',
          color: '#ee2f1f',
          colorRgba: 'rgba(238, 48, 31, 0.1)',
        },
        high: {
          name: 'High',
          value: 'high',
          color: '#ef5323',
          colorRgba: 'rgba(239, 83, 35, 0.1)',
        },
        normal: {
          name: 'Normal',
          color: '#ef7923',
          value: 'normal',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        low: {
          name: 'Low',
          value: 'low',
          color: '#37d5aa',
          colorRgba: 'rgba(55, 213, 170, 0.1)',
        },
        clear: {
          name: 'Clear',
          value: 'clear',
          color: '#56b5db',
          colorRgba: 'rgba(86, 181, 219, 0.1)',
        },
      },
      modules: [
        {
          assignmentKey: 'business_model_assignments',
          moduleKey: 'business_model',
        },
        { assignmentKey: 'company_sdg_assignments', moduleKey: 'company_sdg' },
        {
          assignmentKey: 'company_skill_assignments',
          moduleKey: 'company_skill',
        },
        {
          assignmentKey: 'competition_assignments',
          moduleKey: 'competition',
        },
        {
          assignmentKey: 'foundation_assignments',
          moduleKey: 'foundation',
        },
        {
          assignmentKey: 'market_potential_assignments',
          moduleKey: 'market_potential',
        },
        {
          assignmentKey: 'risk_assessment_assignments',
          moduleKey: 'risk_assessment',
        },
      ],
    };
  },
  methods: {
    titlecase,
    goToAssignment(assignment) {
      let route = assignment.__typename.split('_').join('-');
      if (!assignment.type) {
        route = 'foundation';
      }
      this.$router.push({
        path: `/app/growth-framework/${route}`,
        query: {
          to_component: (assignment.type || assignment.__typename)
            .split('_')
            .join('-'),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.description {
  ul {
    @apply pl-4;
  }
}
</style>
