var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"py":"8","px":{ base: '4', lg: '0' }}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between","mb":"8"}},[_c('c-flex',{attrs:{"align":"baseline"}},[_c('c-heading',{attrs:{"fontSize":"3xl","fontWeight":"700","mr":"3"}},[_vm._v(" Status ")])],1),_c('Notification')],1),_c('c-flex',{attrs:{"boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)","w":"100%","p":"8","bg":"#fff","borderRadius":"8px","justify":"space-between"}},[_c('c-box',{attrs:{"w":{ base: '100%', lg: '60%', sm: '100%', xs: '100%' },"mr":{ base: '0', lg: '6', sm: '0', xs: '0' }}},[_c('c-heading',{attrs:{"as":"h3","display":"flex","fontSize":"xl","mb":"3"}},[_c('c-text',{attrs:{"fontWeight":"normal","mr":"2"}},[_vm._v(" Level of Business: ")]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v(_vm._s(_vm.companyLevel))])],1),_c('c-flex',{attrs:{"align":"center","h":"80px"}},[_c('ProgressBar',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            flex: 1,
          }),expression:"{\n            flex: 1,\n          }"}],attrs:{"value":_vm.companyLevelPercentage,"height":'20px'}}),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            w: '10',
            h: '10',
            m: '-5px',
            zIndex: '1',
          }),expression:"{\n            w: '10',\n            h: '10',\n            m: '-5px',\n            zIndex: '1',\n          }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-target.svg") + "#target"}})])],1)],1),_c('c-box',{staticClass:"stat-tabs"},[_c('c-tabs',{attrs:{"variant":"enclosed"}},[_c('c-tab-list',[_c('c-tab',[_vm._v("Team Activity")]),_c('c-tab',[_vm._v("Framework of Growth")]),_c('c-tab',[_vm._v("Communication")])],1),_c('c-tab-panels',{attrs:{"borderWidth":"1px","h":"90px","bg":"#fff"}},[_c('c-tab-panel',[_c('c-flex',{attrs:{"px":"6","py":"3"}},[_c('c-stack',{attrs:{"spacing":1}},[_c('c-text',{attrs:{"color":"gray.500","fontWeight":"500","fontSize":"sm"}},[_vm._v(" Activity Level (Monthly) ")]),_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{attrs:{"fontSize":"lg","fontWeight":"600","mr":"2"}},[_vm._v("5710")]),_c('c-flex',{attrs:{"align":"center"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                        w: '2',
                        h: '2',
                        fill: 'vc-orange.400',
                      }),expression:"{\n                        w: '2',\n                        h: '2',\n                        fill: 'vc-orange.400',\n                      }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/arrow-down.svg") + "#arrow"}})]),_c('c-text',{attrs:{"fontSize":"sm","color":"vc-orange.400"}},[_vm._v(" 15% ")])],1)],1)],1)],1)],1),_c('c-tab-panel',[_c('c-flex',{attrs:{"px":"6","py":"2","justify":"space-between"}},[_c('c-stack',{attrs:{"spacing":1}},[_c('c-text',{attrs:{"color":"gray.400","fontWeight":"500","fontSize":"xs"}},[_vm._v(" Least Visited ")]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v(" Business Model ")]),_c('c-text',{attrs:{"color":"vc-orange.400","fontWeight":"600","fontSize":"xs"}},[_vm._v(" < 1minute ")])],1),_c('c-stack',{attrs:{"spacing":1}},[_c('c-text',{attrs:{"color":"gray.400","fontWeight":"500","fontSize":"xs"}},[_vm._v(" Most Visited ")]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v(" Risk Assessment ")]),_c('c-text',{attrs:{"color":"vc-orange.400","fontWeight":"600","fontSize":"xs"}},[_vm._v(" 2 hours ")])],1)],1)],1),_c('c-tab-panel',{attrs:{"h":"100%"}},[_c('c-flex',{attrs:{"h":"100%","px":"6","py":"2","align":"center","justify":"space-between"}},[_c('c-stack',{attrs:{"spacing":1}},[_c('c-text',{attrs:{"color":"gray.400","fontWeight":"500","fontSize":"xs"}},[_vm._v(" Messages Sent (Monthly) ")]),_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{attrs:{"fontSize":"lg","fontWeight":"600","mr":"2"}},[_vm._v("526")]),_c('c-flex',{attrs:{"align":"center"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                        w: '2',
                        h: '2',
                        fill: 'green.400',
                      }),expression:"{\n                        w: '2',\n                        h: '2',\n                        fill: 'green.400',\n                      }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/arrow-up.svg") + "#arrow"}})]),_c('c-text',{attrs:{"fontSize":"sm","color":"green.400"}},[_vm._v(" 15% ")])],1)],1)],1),_c('c-stack',{attrs:{"spacing":1}},[_c('c-text',{attrs:{"color":"gray.400","fontWeight":"500","fontSize":"xs"}},[_vm._v(" Responsiveness (Average) ")]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v(" 1h 15mins ")])],1)],1)],1)],1)],1)],1)],1),_c('c-box',{attrs:{"boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16), -2px -1px 6px rgba(186, 186, 186, 0.16)","w":"100%","p":"8","my":"8","bg":"#fff","borderRadius":"8px"}},[_c('c-flex',{attrs:{"justify":"space-between","align":"center","mb":"8"}},[_c('c-heading',{attrs:{"fontSize":"xl","fontWeight":"600"}},[_vm._v("Upcoming Meetings")]),_c('c-link',{attrs:{"as":"router-link","to":"/app/calendar","fontWeight":"700","color":"vc-orange.400"}},[_vm._v(" Go to Calendar ")])],1),_c('WeekCalendar')],1),_c('c-flex',{attrs:{"direction":{ lg: 'row', xs: 'column', xl: 'row' },"w":"100%","my":"8","justify":"space-between"}},[_c('c-box',{attrs:{"w":{ lg: '55%', xs: '100%' }}},[_c('Responsibilities')],1),_c('c-box',{attrs:{"w":{ lg: '45%', xs: '100%' }}},[_c('Feed')],1)],1),_c('LastTour'),_c('Milestones')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }