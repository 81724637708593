<template>
  <div class="members">
    <div class="members__top">
      <c-flex align="center">
        <div class="members__count">
          <span>{{ members.length }}</span>
          <c-text> Members </c-text>
        </div>
        <v-select
          :clearable="false"
          :searchable="false"
          class="vc-select members__select"
          v-model="selectedMemberType"
          :options="memberTypes"
          placeholder="select"
        >
        </v-select>
      </c-flex>
      <!-- <c-input-group>
        <c-input-left-element>
          <svg
            v-chakra="{
              w: '15px',
              h: '15px',
              fill: '#ef5323',
            }"
          >
            <use href="@/assets/icons/icon-search.svg#icon-search"></use>
          </svg>
        </c-input-left-element>
        <c-input
          borderColor="#798892"
          borderTopWidth="0"
          borderRightWidth="0"
          borderLeftWidth="0"
          borderRadius="0"
          type="text"
          :placeholder="`Search ${
            type === 'team' ? 'team' : 'advisory'
          } member`"
        />
      </c-input-group> -->
    </div>
    <div class="members__list" :class="{ 'members__list--disabled': disabled }">
      <Member
        v-for="member in members"
        :key="member.id"
        :member="member"
        :disabled="disabled"
        @skillAdded="onSkillAdded"
        @skillRemoved="onSkillRemoved"
        @memberDeleted="onMemberDeleted"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  addSkillToMember,
  getCompanyMembers,
  getCompanySkillValues,
  removeSkillFromMember,
  removeCompanyMember,
} from '@/services/foundation';

import Member from './Member.vue';
import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Member,
  },
  data() {
    return {
      memberTypes: ['All'],
      selectedMemberType: 'All',
      members: [],
    };
  },
  created() {
    this.getMembers();
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      skills: (state) => state.activeCompanySkills,
    }),
  },
  watch: {
    skills() {
      this.getMembers();
    },
  },
  methods: {
    ...mapActions({
      setCompanySkills: 'company/setActiveCompanySkills',
    }),
    onSkillAdded(data) {
      const memberIndex = this.members.findIndex(
        (member) => member.id === data.teamId
      );
      addSkillToMember(data)
        .then(async (res) => {
          this.members[memberIndex].team_skills.push(
            res.data.insert_team_skill_one
          );
          this.updateSkillValuesInStore();
        })
        .catch(() => {
          this.showErrorToast();
          this.members[memberIndex].team_skills = cloneDeep(
            this.members[memberIndex].team_skills
          );
        });
    },
    onSkillRemoved(skill) {
      const memberIndex = this.members.findIndex(
        (member) => member.id === skill.teamId
      );
      removeSkillFromMember(skill.id)
        .then(async () => {
          const teamSkillIndex = this.members[
            memberIndex
          ].team_skills.findIndex((_skill) => _skill.id === skill.id);
          this.members[memberIndex].team_skills.splice(teamSkillIndex, 1);
          this.updateSkillValuesInStore();
        })
        .catch(() => {
          this.showErrorToast();
          this.members[memberIndex].team_skills = cloneDeep(
            this.members[memberIndex].team_skills
          );
        });
    },
    onMemberDeleted(member) {
      removeCompanyMember(member.id)
        .then((res) => {
          console.log(res);
          // remove from list
          const memberIndex = this.members.findIndex(
            (_member) => _member.id === member.id
          );
          this.members.splice(memberIndex, 1);
          this.getMembers();
        })
        .catch((e) => {
          const err = Object.assign({}, e);

          if (err.message.includes('permission')) {
            this.$toast({
              title: 'Access Denied.',
              description: `You don't have permission to add category.`,
              status: 'warning',
              position: 'top',
              duration: 4000,
            });
          } else {
            this.$toast({
              title: 'An error occurred.',
              description: `Error while deleting team member, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          }
        });
    },
    getMembers() {
      getCompanyMembers({
        companyId: this.activeCompany.id,
        isAdvisory: this.type === 'board',
      })
        .then((res) => {
          this.members = res.data.team;
          this.$emit('fetchedMembers', this.members);
        })
        .catch((e) => {
          console.log({ e });
        });
    },
    showErrorToast() {
      this.$toast({
        title: 'An error occurred.',
        description: `Error while updating member skils, please try again.`,
        status: 'error',
        position: 'top',
        duration: 3000,
      });
    },
    updateSkillValuesInStore() {
      getCompanySkillValues(this.activeCompany.id).then((res) => {
        this.setCompanySkills(res.data.company_skill_values);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.members {
  @apply h-full;
  &__top {
    @apply flex items-center justify-between;
    ::v-deep {
      input {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  &__count {
    @apply flex font-semibold text-lg mr-4 items-baseline;
    span {
      @apply text-5xl mr-1 font-light;
      color: $color-green;
    }
  }
  &__select {
    ::v-deep {
      .vs__open-indicator {
        @apply block;
        fill: $color-orange;
        width: 10px;
        path {
          d: path(
            'M10.555,13.073l-2.8-2.8.933-.932,1.865,1.865L12.42,9.343l.933.932Z'
          );
          fill: $color-orange;
          transform: translate(-7.757px, -9.343px);
        }
      }
      .vs__selected {
        color: $color-orange;
      }
      .vs__dropdown-toggle {
        @apply text-base;
        height: 25px;
        min-width: 50px;
        border: none;
      }
      .vs__dropdown-menu {
        min-width: 150px;
      }
      .vs__dropdown-option {
        @apply text-sm;
        height: 25px;
      }
      .vs__actions {
        padding: 8px 6px 0 3px;
      }
    }
  }
  &__list {
    @apply grid my-9 overflow-y-scroll;
    grid-template-columns: 1fr;
    gap: 30px;
    height: calc(100% - 60px);
  }
  @screen md {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @screen lg {
    &__list {
      &--disabled {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
</style>
