import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      members: [],
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedMembers() {
      return this.filteredMembers.filter((member) => member.selected);
    },
    filteredMembers() {
      return this.members.filter((member) =>
        this.type === "team"
          ? member.isAdvisory === false
          : member.isAdvisory === true
      );
    },
  },
  watch: {
    presentation() {
      this.setMembers();
    },
  },
  created() {
    this.setMembers();
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setMembers() {
      const members = this.presentationData.members;

      if (!this.presentation.data.slidesData?.members) {
        this.members = members.map((member, index) => ({
          ...member,
          selected: index <= 5,
        }));
      } else {
        const { members: membersInSlide } = this.presentation.data.slidesData;
        this.members = members.map((item) => {
          const itemInPresentation = membersInSlide?.find(
            (_item) => _item.id === item.id
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async updateMembersData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                members: [
                  ...data,
                  ...this.presentation.data.slidesData?.members?.filter(
                    (member) =>
                      this.type !== "team"
                        ? member.isAdvisory === false
                        : member.isAdvisory === true
                  ),
                ],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected ${
            this.type === "team" ? "team members" : "advisory board"
          }, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
