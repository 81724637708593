var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-pseudo-box',{attrs:{"w":"100%","h":"100%","bgImage":_vm.presentation.data.color
      ? `linear-gradient(
      to bottom ,
      ${_vm.presentation.data.color}80,
      #fff
    )`
      : `linear-gradient(
      to bottom ,
      #fff,
      #fff
    )`,"display":"flex","position":"relative","_before":{
    content: _vm.content,
    bg: '#fff',
    position: 'absolute',
    right: '0px',
    w: '75%',
    top: '0',
    bottom: '0',
    zIndex: 1,
  }}},[_c('div',{staticClass:"draggable-image",style:({
      backgroundImage: 'url(' + _vm.imageUrl + ')',
      backgroundSize: 'cover',
      width: '20em',
      height: '30em',
      top: _vm.imagePosition.top + 'px',
      left: _vm.imagePosition.left + 'px',
    }),on:{"mousedown":_vm.startImageDrag,"mousemove":_vm.handleImageDrag,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}}),_c('c-flex',{attrs:{"direction":"column","h":"100%","align":"center"}},[_c('c-text',{attrs:{"mt":"10","ml":"5","mr":"10","color":"#000","fontWeight":"700","fontSize":"4xl","zIndex":"2"}},[_vm._v("Market Size")]),_c('c-box',{attrs:{"mt":"5","h":".5em","w":"10em","bg":`${_vm.presentation.data.color}80`,"zIndex":"2"}})],1),_c('c-flex',{attrs:{"direction":"column","flex":"1","zIndex":"2","py":"10","h":"100%","justify":"center","align":"center","pr":"4","position":"relative","bg":_vm.presentation.data.market_size.backgroundColor}},[_c('c-box',{attrs:{"my":"auto","d":"flex","justify":"center","align":"center"}},[_c('OverviewChart',{attrs:{"chartWidth":"20em","chartHeight":"20em","template":"default_1"}})],1),_c('c-box',{attrs:{"ml":"auto","mt":"auto"}},[_c('WaterMark',{attrs:{"direction":'row'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }