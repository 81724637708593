<template>
  <c-box h="100%">
    <TaskCard
      title="Overall Problem"
      :description="'What problem is your idea/business trying to solve ? '"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :hasMounted="hasMounted"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="true"
      :disabled="disabled"
      size="small"
      id="overall-problem"
      :taskData="businessModelData"
      :accessKey="'business_model_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'business_model_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="overall_problem"
      :assumptionId="'problem-interview'"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>

      <div v-else>
        <c-text
          as="span"
          color="#ef5323"
          fontSize="14px"
          v-if="showChangesModal"
          ><b>Saved successfully 👍</b></c-text
        >
        <div
          style="display: flex; justify-content: flex-end"
          v-if="showChangesModal"
        >
          <c-popover
            :isOpen="showChangesModal"
            placement="top"
            :closeOnBlur="false"
          >
            <c-popover-trigger>
              <c-button
                right-icon="arrow-forward"
                variant-color="white"
                size="xs"
              ></c-button>
            </c-popover-trigger>
            <c-popover-content zIndex="4">
              <c-popover-arrow />
              <c-popover-close-button @click="closeAlert" />
              <c-popover-header
                font-weight="bold"
                background="#ee5224"
                color="white"
                >Updates!</c-popover-header
              >
              <c-popover-body p="2">
                <c-text fontSize="13px">
                  Looks like you changed your Overall Problem. Maybe you should
                  consider changing your overal solution also.
                </c-text>
              </c-popover-body>
            </c-popover-content>
          </c-popover>
        </div>
        <EditableText
          title="Overall Problem"
          type="overallproblem"
          v-model="businessModelData.description"
          @updateDescription="updateDescription"
          :disabled="disabled"
        />
      </div>
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

import TaskCard from './../TaskCard.vue';
import EditableText from './../EditableText';
import businessModelMixin from '@/mixins/business-model.js';

export default {
  mixins: [businessModelMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    EditableText,
    ContentLoader,
  },
  data() {
    return {
      businessModelType: 'overall_problem',
      hasMounted: false,
      showChangesModal: false,
    };
  },
  methods: {
    updateDescription(newAIcontent) {
      this.businessModelData.description = newAIcontent;

      this.updateData({
        key: 'description',
        value: this.businessModelData.description,
      });
      this.showChangesModal = true;
    },
    closeAlert() {
      this.showChangesModal = false;
    },
  },
};
</script>
