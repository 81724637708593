<template>
  <c-box
    borderRadius="8px"
    boxShadow="0px 3px 6px rgba(186, 186, 186, 0.16)"
    backgroundColor="#fff"
    h="700px"
    display="flex"
  >
    <c-box flex="1" p="8" h="100%">
      <c-box
        display="flex"
        justifyContent="space-between"
        alignCenter="center"
        mb="4"
      >
        <c-heading fontSize="3xl" as="h4">Team</c-heading>
        <!--           :isDisabled="members.length >= getCurrentPlan.access.maxTeam" -->
        <c-link
          fontWeight="600"
          fontSize="sm"
          color="vc-orange.500"
          v-if="!disabled && isCompanyOwner && isCompanyAdmin"
          @click="
            members.length >= getCurrentPlan.access.maxTeam
              ? showInfo
              : (isInviteModalOpen = true)
          "
        >
          Invite team members
        </c-link>
      </c-box>
      <c-box h="calc(100% - 60px)">
        <MembersList
          @fetchedMembers="members = $event"
          :type="'team'"
          :disabled="disabled"
          ref="listComponent"
        />
      </c-box>
    </c-box>
    <c-box
      v-if="!disabled"
      backgroundColor="#f4f6fc"
      :display="{ base: 'none', md: 'none', lg: 'flex' }"
      w="25%"
      borderRadius="inherit"
    >
      <SkillsList :type="'team'" />
    </c-box>
    <InviteModal :isOpen="isInviteModalOpen" :onClose="closeModal" />
  </c-box>
</template>

<script>
import { mapGetters } from 'vuex';

import SkillsList from './SkillsList';
import MembersList from './MembersList.vue';
import InviteModal from './InviteModal.vue';

export default {
  props: ['disabled'],
  components: {
    SkillsList,
    MembersList,
    InviteModal,
  },
  data() {
    return {
      isInviteModalOpen: false,
      members: [],
    };
  },
  computed: {
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    closeModal(hasInvited) {
      this.isInviteModalOpen = false;
      if (hasInvited) {
        this.$refs.listComponent.getMembers();
      }
    },
    showInfo() {
      this.$toast({
        title: 'An error occurred.',
        description: `You can only have a maximum of ${
          this.getCurrentPlan.access.maxTeam
        } people on your current plan. Kindly ${
          this.isCompanyAdmin || this.isCompanyOwner
            ? 'upgrade'
            : 'contact your company admin to upgrade'
        }`,
        status: 'error',
        position: 'top',
        duration: 3000,
      });
    },
  },
};
</script>
