import { render, staticRenderFns } from "./SOM.vue?vue&type=template&id=fbfc76e2"
import script from "./SOM.vue?vue&type=script&lang=js"
export * from "./SOM.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CInput: require('@chakra-ui/vue').CInput, CInputRightAddon: require('@chakra-ui/vue').CInputRightAddon, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CStack: require('@chakra-ui/vue').CStack, CBox: require('@chakra-ui/vue').CBox})
