var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sector-select",class:[`sector-select--${_vm.size}`]},[_c('v-select',{staticClass:"vc-select",attrs:{"label":"name","options":_vm.options || _vm.sectors,"reduce":(option) => option.id,"clearable":false,"multiple":true,"disabled":_vm.disabled,"searchable":_vm.searchable},scopedSlots:_vm._u([(_vm.showHeader)?{key:"list-header",fn:function({ filteredOptions }){return [_c('li',{staticClass:"options-header",staticStyle:{"font-size":"small"}},[_c('c-text',{attrs:{"as":"span","color":"#ef5323"}},[_vm._v(" "+_vm._s(filteredOptions.length || _vm.sectors.length)+" ")]),_vm._v(" industries ")],1)]}}:null],null,true),model:{value:(_vm.selectedSectors),callback:function ($$v) {_vm.selectedSectors=$$v},expression:"selectedSectors"}}),(_vm.searchable)?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
      w: '18px',
      h: '18px',
      top: '50%',
      right: '20px',
      fill: '#ef5323',
      position: 'absolute',
      transform: 'translateY(-50%)',
    }),expression:"{\n      w: '18px',\n      h: '18px',\n      top: '50%',\n      right: '20px',\n      fill: '#ef5323',\n      position: 'absolute',\n      transform: 'translateY(-50%)',\n    }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-search.svg") + "#icon-search"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }