<template>
  <c-flex :direction="direction" w="max-content" align="center">
    <c-box maxWidth="100px" h="30px">
      <img
        v-chakra="{
          w: '100%',
          h: '100%',
          objectFit: 'contain',
        }"
        :src="
          activeCompany.logo ||
          'https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/company-logo.webp'
        "
        alt=""
      />
    </c-box>
    <c-flex :mt="direction == 'column' ? '5px' : ''">
      <c-text fontSize="10px" mr="2">Powered by</c-text>
      <img
        v-chakra="{
          w: '80px',
          h: '20px',
          objectFit: 'contain',
        }"
        src="
            https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/vibrantcreator-logo-orange.png
          "
        alt=""
      />
    </c-flex>
  </c-flex>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    direction: {
      type: String,
      default: "row",
    },
  },
  computed: {
    ...mapState("company", {
      activeCompany: (state) => state.activeCompany,
    }),
  },
};
</script>

<style></style>
