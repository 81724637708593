<template>
  <c-box id="comparison">
    <!--       :isLocked="!getCurrentPlan.access.comparison" -->
    <TaskCard
      title="Comparison"
      :description="''"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="false"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="competitionData"
      :accessKey="'competition_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'competition_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="comparison"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
      <ComparisonGrid
        v-else
        :disabled="disabled"
        @update="updateData"
        :competitionData="competitionData"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

import TaskCard from './../TaskCard';
import ComparisonGrid from './../competition/ComparisonGrid';

import competitionMixin from '@/mixins/competition.js';

import { mapState, mapMutations, mapGetters } from 'vuex';
import { addCompetitor } from '@/services/competition';
import cloneDeep from 'lodash.clonedeep';

export default {
  mixins: [competitionMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    ContentLoader,
    ComparisonGrid,
  },
  data() {
    return {
      competitionType: 'comparison',
      hasMounted: false,
    };
  },
  computed: {
    ...mapState('company', {
      competitors: (state) => state.competitors,
      hasFetchedCompetitors: (state) => state.hasFetchedCompetitors,
    }),
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    ...mapMutations({
      addCompetitorToStore: 'company/addCompetitor',
    }),
    onDataFetch() {
      if (this.competitors.length) {
        this.checkForSelf();
      } else {
        this.watchCompetitorsOnce();
      }
    },
    watchCompetitorsOnce() {
      if (this.hasFetchedCompetitors) {
        this.checkForSelf();
      } else {
        const unwatchCompetitors = this.$watch('competitors', () => {
          unwatchCompetitors();
          this.checkForSelf();
        });
      }
    },
    checkForSelf() {
      const hasSelf = this.competitors.some((competitor) => competitor.isSelf);
      if (!hasSelf) {
        this.createSelfCompetitor();
      }
    },
    createSelfCompetitor() {
      this.isFetchingData = true;
      const data = {
        type: 'DIRECT',
        name: this.activeCompany.name,
        competitionId: this.competitionData.id,
        customerExperience: '',
        details: '',
        revenueStreams: [],
        importanceLevel: 'moderate',
        pointOfDifferentiation: '',
        price: [],
        valueProposition: { delivery: 0, description: '' },
        website: '',
        isSelf: true,
      };
      addCompetitor(data)
        .then((res) => {
          this.addCompetitorToStore(cloneDeep(res.data.insert_competitor_one));
          this.isFetchingData = false;
        })
        .catch((e) => {
          console.log({ e });
          this.createSelfCompetitor();
          this.isFetchingData = false;
        });
    },
  },
};
</script>

<style></style>
