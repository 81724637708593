import { render, staticRenderFns } from "./CompetitionGrid.vue?vue&type=template&id=3f5cac32&scoped=true"
import script from "./CompetitionGrid.vue?vue&type=script&lang=js"
export * from "./CompetitionGrid.vue?vue&type=script&lang=js"
import style0 from "./CompetitionGrid.vue?vue&type=style&index=0&id=3f5cac32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5cac32",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CVisuallyHidden: require('@chakra-ui/vue').CVisuallyHidden, CIcon: require('@chakra-ui/vue').CIcon, CControlBox: require('@chakra-ui/vue').CControlBox, CBox: require('@chakra-ui/vue').CBox, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CText: require('@chakra-ui/vue').CText, CCheckbox: require('@chakra-ui/vue').CCheckbox, CCheckboxGroup: require('@chakra-ui/vue').CCheckboxGroup, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CFlex: require('@chakra-ui/vue').CFlex, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CLink: require('@chakra-ui/vue').CLink})
