import { render, staticRenderFns } from "./Responsibilities.vue?vue&type=template&id=0537f3ae"
import script from "./Responsibilities.vue?vue&type=script&lang=js"
export * from "./Responsibilities.vue?vue&type=script&lang=js"
import style0 from "./Responsibilities.vue?vue&type=style&index=0&id=0537f3ae&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CLink: require('@chakra-ui/vue').CLink, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CStack: require('@chakra-ui/vue').CStack, CBox: require('@chakra-ui/vue').CBox})
