<template>
  <c-box w="100%" h="100%" px="5" py="5" position="relative" background="red">
    <c-flex mb="5" direction="column" justify="center" align="center">
      <c-text as="h3" mb="1" fontSize="4xl" fontWeight="700"
        >Competition</c-text
      >
      <c-box h=".5em" w="8em" :bg="`${presentation.data.color}80`"></c-box>
    </c-flex>
    <c-link
      textDecoration="underline"
      position="absolute"
      right="5"
      top="2"
      fontSize="xl"
      v-if="isEdit"
      @click="isDrawerOpen = true"
      >Select competitors to display</c-link
    >
    <table v-chakra w="100%" my="5" fontSize="1.4em">
      <thead v-chakra px="5" :bg="presentation.data.color">
        <tr>
          <th
            v-chakra
            w="15%"
            pb="5"
            textAlign="start"
            borderBottomWidth=".1em"
            fontWeight="normal"
            color="#fff"
          >
            Categories
          </th>
          <th
            v-chakra
            pb="5"
            textAlign="start"
            borderBottomWidth=".1em"
            color="#fff"
          >
            You
          </th>
          <th
            v-chakra
            pb="5"
            textAlign="start"
            borderBottomWidth=".1em"
            v-for="(competitor, ix) in selectedCategories"
            :key="ix"
            color="#fff"
          >
            {{ competitor.name }}
          </th>
        </tr>
      </thead>
      <tbody v-chakra fontSize="1em" textAlign="left">
        <tr>
          <td v-chakra py="10px" w="25%" borderBottomWidth=".1em">Details</td>
          <td v-chakra py="10px" borderBottomWidth=".1em">
            {{ selfCompetitor.details }}
          </td>
          <td
            v-chakra
            py="10px"
            borderBottomWidth=".1em"
            v-for="(competitor, ix) in selectedCategories"
            :key="ix"
          >
            <c-text v-html="competitor.details" />
          </td>
        </tr>
        <tr v-chakra :bg="`${presentation.data.color}20`">
          <td v-chakra py="10px" w="25%" borderBottomWidth=".1em">
            Customer Segment
          </td>
          <td v-chakra py="10px" borderBottomWidth=".1em">
            <c-text
              v-for="(segment, idx) in selfCompetitor.competitor_customers"
              :key="idx"
            >
              {{ segment.customer.name
              }}{{
                idx !== selfCompetitor.competitor_customers.length - 1
                  ? ','
                  : ''
              }}
            </c-text>
          </td>
          <td
            v-chakra
            py="10px"
            borderBottomWidth=".1em"
            v-for="(competitor, ix) in selectedCategories"
            :key="ix"
          >
            <c-text
              v-for="(segment, idx) in competitor.competitor_customers"
              :key="idx"
            >
              {{ segment.customer.name
              }}{{
                idx !== competitor.competitor_customers.length - 1 ? ',' : ''
              }}
            </c-text>
          </td>
        </tr>
        <tr>
          <td v-chakra py="10px" w="25%" borderBottomWidth=".1em">
            Point of Differentiation
          </td>
          <td v-chakra py="10px" borderBottomWidth=".1em">
            {{ selfCompetitor.pointOfDifferentiation }}
          </td>
          <td
            v-chakra
            py="10px"
            borderBottomWidth=".1em"
            v-for="(competitor, ix) in selectedCategories"
            :key="ix"
          >
            {{ competitor.pointOfDifferentiation }}
          </td>
        </tr>
        <tr v-chakra :bg="`${presentation.data.color}20`">
          <td v-chakra py="5" w="25%" borderBottomWidth=".1em">
            Importance Level
          </td>
          <td v-chakra py="5" borderBottomWidth=".1em">
            {{ selfCompetitor.importanceLevel }}
          </td>
          <td
            v-chakra
            py="5"
            borderBottomWidth=".1em"
            v-for="(competitor, ix) in selectedCategories"
            :key="ix"
          >
            {{ competitor.importanceLevel }}
          </td>
        </tr>
      </tbody>
    </table>
    <c-box position="absolute" right="5" bottom="5">
      <WaterMark :direction="'row'" />
    </c-box>
    <TextListDrawer
      :title="'Select visible competitors'"
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :items="categories"
      :updateFn="updateCompetitionData"
      :isUpdatingData="isUpdatingData"
    >
      <template #list-item="{ item }">
        <c-text as="span">{{ item.name }}</c-text>
      </template>
    </TextListDrawer>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import WaterMark from '@/components/WaterMark.vue';
import competitionMixin from '@/mixins/data/competition.js';
import TextListDrawer from '@/components/DataComponents/TextListDrawer.vue';

export default {
  components: { WaterMark, TextListDrawer },
  mixins: [competitionMixin],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      competitionType: 'comparison',
    };
  },
  computed: {
    ...mapState({
      presentation: (state) => state.presentation.activePresentation,
    }),
    ...mapState('company', {
      selfCompetitor: (state) =>
        state.competitors.find((competitor) => competitor.isSelf),
      competitors: (state) =>
        state.competitors?.filter((competitor) => !competitor.isSelf),
    }),
  },
  // mounted() {
  //   console.log("rahh", this.competitors);
  // },
};
</script>

<style></style>
