<template>
  <div class="competition-grid">
    <div class="competition-grid__selector">
      <label>
        <c-visually-hidden
          as="input"
          type="checkbox"
          name="analysis"
          :checked="selectedViews.includes('analysis')"
          @change="onCheckChange"
        />

        <c-control-box
          border-width="2px"
          borderColor="vc-orange.400"
          border-radius="4px"
          size="24px"
          rounded="sm"
          :_checked="{
            bg: 'transparent',
            color: 'white',
            borderColor: 'vc-orange.400',
          }"
          :_focus="{
            boxShadow: '0 1px 5px rgb(196, 70, 31, 0.44)',
          }"
          :_checkedAndChild="{ display: 'block' }"
        >
          <c-icon
            name="check"
            color="vc-orange.400"
            v-chakra="{
              display: 'none',
            }"
            size="14px"
          />
        </c-control-box>

        <c-box as="span" vertical-align="top" ml="2" fontWeight="600">
          Analysis
        </c-box>
      </label>
      <label>
        <c-visually-hidden
          as="input"
          type="checkbox"
          name="conclusion"
          :checked="selectedViews.includes('conclusion')"
          @change="onCheckChange"
        />

        <c-control-box
          border-width="2px"
          borderColor="vc-orange.400"
          border-radius="4px"
          size="24px"
          rounded="sm"
          :_checked="{
            bg: 'transparent',
            color: 'white',
            borderColor: 'vc-orange.400',
          }"
          :_focus="{
            boxShadow: '0 1px 5px rgb(196, 70, 31, 0.44)',
          }"
          :_checkedAndChild="{ display: 'block' }"
        >
          <c-icon
            name="check"
            color="vc-orange.400"
            v-chakra="{
              display: 'none',
            }"
            size="14px"
          />
        </c-control-box>

        <c-box as="span" vertical-align="top" ml="2" fontWeight="600">
          Conclusion
        </c-box>
      </label>
    </div>
    <div class="competition-grid__wrapper">
      <table class="competition-grid__table">
        <thead class="competition-grid__table__header">
          <tr class="competition-grid__table__header__row">
            <th class="competition-grid__table__header__cell"></th>
            <template v-if="selectedViews.includes('analysis')">
              <th class="competition-grid__table__header__cell">
                <c-flex>
                  <c-popover
                    placement="bottom"
                    v-slot="analysisPopoverSlotProps"
                  >
                    <c-popover-trigger>
                      <c-pseudo-box
                        as="a"
                        display="flex"
                        :_hover="{
                          textDecoration: 'none',
                          cursor: 'pointer',
                        }"
                        :_focus="{
                          bg: 'grey.200',
                        }"
                      >
                        <span> Analysis </span>
                        <svg
                          fill="#ef5323"
                          v-chakra="{
                            width: '20px',
                            height: '20px',
                            ml: '2',
                          }"
                        >
                          <use
                            href="@/assets/icons/add-circle-line.svg#add-circle"
                          ></use>
                        </svg>
                      </c-pseudo-box>
                    </c-popover-trigger>
                    <c-popover-content zIndex="4" width="max-content">
                      <c-popover-body px="0">
                        <c-list>
                          <c-list-item fontSize="sm">
                            <c-popover
                              trigger="hover"
                              @close="
                                onFieldSelectPopoverClose(
                                  $event,
                                  analysisPopoverSlotProps.onClose
                                )
                              "
                              :placement="'right'"
                            >
                              <c-popover-trigger>
                                <c-pseudo-box
                                  as="a"
                                  display="flex"
                                  p="2"
                                  alignItems="center"
                                  :_hover="{
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    bg: 'vc-orange.50',
                                  }"
                                  justifyContent="space-between"
                                  fontSize="sm"
                                  cursor="pointer"
                                >
                                  <span> Default </span>
                                  <svg
                                    v-chakra="{
                                      width: '10px',
                                      height: '10px',
                                      fill: '#ef5323',
                                      ml: '2',
                                    }"
                                  >
                                    <use
                                      href="@/assets/icons/icon-caret-right.svg#caret"
                                    ></use>
                                  </svg>
                                </c-pseudo-box>
                              </c-popover-trigger>
                              <c-popover-content width="max-content">
                                <c-popover-body p="3" width="max-content">
                                  <c-checkbox-group
                                    :default-value="analysisVisibleFields"
                                    @change="onFieldsChange($event, 'analysis')"
                                  >
                                    <c-checkbox
                                      v-for="(field, index) in analysisFields"
                                      :key="
                                        'analysis-select' + field.title + index
                                      "
                                      :value="field.key"
                                    >
                                      <c-text fontSize="sm">
                                        {{ field.title }}
                                      </c-text>
                                    </c-checkbox>
                                  </c-checkbox-group>
                                </c-popover-body>
                              </c-popover-content>
                            </c-popover>
                          </c-list-item>
                          <!-- <c-list-item
                            color="vc-orange.400"
                            p="2"
                            fontSize="sm"
                            fontWeight="600"
                          >
                            Your Category
                          </c-list-item> -->
                        </c-list>
                      </c-popover-body>
                    </c-popover-content>
                  </c-popover>
                </c-flex>
              </th>
              <th
                class="competition-grid__table__header__cell"
                v-for="spaceValue in analysisFields.length - 1"
                :key="spaceValue"
              ></th>
            </template>
            <template v-if="selectedViews.includes('conclusion')">
              <th class="competition-grid__table__header__cell">
                <c-flex>
                  <c-popover
                    placement="bottom"
                    v-slot="conclusionPopoverSlotProps"
                  >
                    <c-popover-trigger>
                      <c-pseudo-box
                        as="a"
                        display="flex"
                        :_hover="{
                          textDecoration: 'none',
                          cursor: 'pointer',
                        }"
                        :_focus="{
                          bg: 'grey.200',
                        }"
                      >
                        <span> Conclusion </span>
                        <svg
                          fill="#ef5323"
                          v-chakra="{
                            width: '20px',
                            height: '20px',
                            ml: '2',
                          }"
                        >
                          <use
                            href="@/assets/icons/add-circle-line.svg#add-circle"
                          ></use>
                        </svg>
                      </c-pseudo-box>
                    </c-popover-trigger>
                    <c-popover-content zIndex="4" width="max-content">
                      <c-popover-body px="0">
                        <c-list>
                          <c-list-item fontSize="sm">
                            <c-popover
                              trigger="hover"
                              :placement="'right'"
                              @close="
                                onFieldSelectPopoverClose(
                                  $event,
                                  conclusionPopoverSlotProps.onClose
                                )
                              "
                            >
                              <c-popover-trigger>
                                <c-pseudo-box
                                  as="a"
                                  display="flex"
                                  p="2"
                                  alignItems="center"
                                  :_hover="{
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    bg: 'vc-orange.50',
                                  }"
                                  justifyContent="space-between"
                                  fontSize="sm"
                                  cursor="pointer"
                                >
                                  <span> Default </span>
                                  <svg
                                    v-chakra="{
                                      width: '10px',
                                      height: '10px',
                                      fill: '#ef5323',
                                      ml: '2',
                                    }"
                                  >
                                    <use
                                      href="@/assets/icons/icon-caret-right.svg#caret"
                                    ></use>
                                  </svg>
                                </c-pseudo-box>
                              </c-popover-trigger>
                              <c-popover-content width="max-content">
                                <c-popover-body p="3" width="max-content">
                                  <c-checkbox-group
                                    :default-value="conclusionVisibleFields"
                                    @change="
                                      onFieldsChange($event, 'conclusion')
                                    "
                                  >
                                    <c-checkbox
                                      v-for="(field, index) in conclusionFields"
                                      :key="'cat-select' + field.title + index"
                                      :value="field.key"
                                    >
                                      <c-text fontSize="sm">
                                        {{ field.title }}
                                      </c-text>
                                    </c-checkbox>
                                  </c-checkbox-group>
                                </c-popover-body>
                              </c-popover-content>
                            </c-popover>
                          </c-list-item>
                          <!-- <c-list-item
                            color="vc-orange.400"
                            p="2"
                            fontSize="sm"
                            fontWeight="600"
                          >
                            Your Category
                          </c-list-item> -->
                        </c-list>
                      </c-popover-body>
                    </c-popover-content>
                  </c-popover>
                </c-flex>
              </th>
            </template>
          </tr>
          <tr class="competition-grid__table__header__row">
            <th class="competition-grid__table__header__cell">Competitor</th>
            <template v-if="selectedViews.includes('analysis')">
              <template v-for="field in analysisFields">
                <th
                  class="competition-grid__table__header__cell"
                  v-if="analysisVisibleFields.includes(field.key)"
                  :key="field.title"
                >
                  <c-flex align="center" w="max-content">
                    <svg v-if="field.type === 'text'">
                      <use href="@/assets/icons/icon-text.svg#text"></use>
                    </svg>
                    <svg v-if="field.type === 'multi-select'">
                      <use href="@/assets/icons/icon-list.svg#list"></use>
                    </svg>
                    <svg v-if="field.type === 'number'">
                      <use href="@/assets/icons/icon-number.svg#number"></use>
                    </svg>
                    <span> {{ field.title }} </span>
                  </c-flex>
                </th>
              </template>
            </template>

            <template v-if="selectedViews.includes('conclusion')">
              <template v-for="field in conclusionFields">
                <th
                  class="competition-grid__table__header__cell"
                  v-if="conclusionVisibleFields.includes(field.key)"
                  :key="field.title"
                >
                  <c-flex align="center" w="max-content">
                    <svg v-if="field.type === 'text'">
                      <use href="@/assets/icons/icon-text.svg#text"></use>
                    </svg>
                    <svg v-if="field.type === 'multi-select'">
                      <use href="@/assets/icons/icon-list.svg#list"></use>
                    </svg>
                    <span> {{ field.title }} </span>
                  </c-flex>
                </th>
              </template>
            </template>
          </tr>
        </thead>
        <tbody class="competition-grid__table__body" v-if="competitors.length">
          <tr
            class="competition-grid__table__body__row"
            v-for="(competitor, competitorIndex) in competitors"
            :key="competitorIndex"
          >
            <td class="competition-grid__table__body__cell">
              <c-flex h="100%" justify="start" align-items="start">
                <c-popover placement="bottom" v-slot="{}">
                  <c-popover-trigger cursor="pointer">
                    <c-box
                      my="2"
                      mr="3"
                      v-chakra="{
                        height: 'auto',
                      }"
                    >
                      <svg
                        v-chakra="{
                          width: '10px',
                          height: '16px',
                          fill: '#ef5323',
                        }"
                      >
                        <use href="@/assets/icons/vertical-dots.svg#dots" />
                      </svg>
                    </c-box>
                  </c-popover-trigger>
                  <c-popover-content maxWidth="150px" zIndex="modal">
                    <c-popover-body p="0">
                      <c-list fontSize="sm">
                        <c-list-item v-if="competitor.id">
                          <c-box
                            cursor="pointer"
                            w="100%"
                            px="2"
                            py="2"
                            @click="onDeleteCompetitor(competitor)"
                            >Delete</c-box
                          >
                        </c-list-item>
                      </c-list>
                    </c-popover-body>
                  </c-popover-content>
                </c-popover>

                <c-flex direction="column" align="center" justify="center">
                  <AppSelect
                    :options="competitorTypes"
                    indicator-fill="orange"
                    color="dark"
                    :value="competitor.type"
                    label="label"
                    :disabled="disabled"
                    :reduce="(competitor) => competitor.value"
                    @input="
                      onCompetitorValueInput($event, competitorIndex, 'type')
                    "
                  />
                  <c-editable
                    :default-value="competitor.name"
                    :value="competitor.name"
                    placeholder="Click to type competitor name"
                    :isDisabled="disabled"
                    @input="(e) => (competitorTempName = e.target.value)"
                    @submit="onCompetitorNameSubmit($event, competitorIndex)"
                    textAlign="center"
                  >
                    <c-editable-preview />
                    <c-editable-input />
                  </c-editable>
                </c-flex>
              </c-flex>
            </td>
            <template v-if="selectedViews.includes('analysis')">
              <template v-for="(field, index) in analysisFields">
                <td
                  class="competition-grid__table__body__cell"
                  v-if="analysisVisibleFields.includes(field.key)"
                  :key="field.title + index"
                >
                  <c-flex maxHeight="170px">
                    <c-editable
                      :default-value="competitor[field.key]"
                      v-if="field.type === 'text'"
                      :value="competitor[field.key]"
                      :isDisabled="disabled"
                      font-size="14px"
                      textAlign="center"
                      @input="(e) => (editableValue = e.target.value)"
                      @submit="onEditableValueInput(competitorIndex, field.key)"
                    >
                      <c-editable-preview />
                      <c-editable-input />
                    </c-editable>
                    <component
                      v-else
                      :is="field.component"
                      v-bind="field.props"
                      :disabled="disabled"
                      :value="competitor[field.key]"
                      @input="
                        onCompetitorValueInput(
                          $event,
                          competitorIndex,
                          field.key
                        )
                      "
                    />
                  </c-flex>
                </td>
              </template>
            </template>

            <template v-if="selectedViews.includes('conclusion')">
              <template v-for="(field, index) in conclusionFields">
                <td
                  class="competition-grid__table__body__cell"
                  v-if="conclusionVisibleFields.includes(field.key)"
                  :key="field.title + index"
                >
                  <c-flex maxHeight="170px">
                    <c-editable
                      :default-value="competitor[field.key]"
                      v-if="field.type === 'text'"
                      :value="competitor[field.key]"
                      :isDisabled="disabled"
                      font-size="14px"
                      textAlign="center"
                      @input="(e) => (editableValue = e.target.value)"
                      @submit="onEditableValueInput(competitorIndex, field.key)"
                    >
                      <c-editable-preview />
                      <c-editable-input />
                    </c-editable>
                    <component
                      v-else
                      :is="field.component"
                      v-bind="field.props"
                      :disabled="disabled"
                      :isPreview="disabled"
                      :value="competitor[field.key]"
                      @input="
                        onCompetitorValueInput(
                          $event,
                          competitorIndex,
                          field.key
                        )
                      "
                    />
                  </c-flex>
                </td>
              </template>
            </template>
          </tr>
        </tbody>
        <tfoot class="competition-grid__table__footer" v-if="!disabled">
          <tr class="competition-grid__table__footer__row">
            <td class="competition-grid__table__footer__cell" colspan="100">
              <c-link
                display="flex"
                alignItems="center"
                color="#798892"
                fontSize="sm"
                fontWeight="500"
                @click="addCompetitor"
                w="max-content"
              >
                <svg
                  fill="#798892"
                  v-chakra="{
                    width: '12px',
                    height: '12px',
                    mr: '2',
                  }"
                >
                  <use href="@/assets/icons/icon-add.svg#add"></use>
                </svg>
                <span> New Competitor </span>
              </c-link>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import competitorMixin from '@/mixins/competitor.js';

import AppSelect from './../../../components/AppSelect';
import { generateID } from '@/helpers/data';

export default {
  mixins: [competitorMixin],
  components: {
    AppSelect,
  },
  data() {
    return {
      selectedViews: ['analysis', 'conclusion'],
      competitorTempName: '',
      editableValue: '',
      competitorTypes: [
        { label: 'Indirect', value: 'INDIRECT' },
        { label: 'Direct', value: 'DIRECT' },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      competitors: (state) =>
        state.competitors.filter((competitor) => !competitor.isSelf),
      allCompetitors: (state) => state.competitors,
    }),
  },
  methods: {
    onEditableValueInput(competitorIndex, fieldKey) {
      this.onCompetitorValueInput(
        this.editableValue,
        competitorIndex,
        fieldKey
      );
    },
    onCheckChange(e) {
      if (e.target.checked) {
        this.selectedViews.push(e.target.name);
      } else {
        this.selectedViews.splice(this.selectedViews.indexOf(e.target.name), 1);
      }
      this.setAllVisibleFields(e.target.name);
    },
    addCompetitor() {
      this.addCompetitorToStore({
        tempId: generateID(5),
        type: 'INDIRECT',
        name: '',
        competitionId: this.competitionData.id,
        customerExperience: '',
        details: '',
        revenueStreams: [],
        importanceLevel: 'moderate',
        pointOfDifferentiation: '',
        price: [],
        valueProposition: { delivery: 0, description: '' },
        website: '',
      });
    },
    setAllVisibleFields(name) {
      switch (name) {
        case 'analysis':
          if (this.selectedViews.includes('analysis')) {
            this.analysisVisibleFields = this.analysisFields.map(
              (field) => field.key
            );
          } else {
            this.analysisVisibleFields = [];
          }
          break;
        case 'conclusion':
          if (this.selectedViews.includes('conclusion')) {
            this.conclusionVisibleFields = this.conclusionFields.map(
              (field) => field.key
            );
          } else {
            this.conclusionVisibleFields = [];
          }
          break;
        default:
          break;
      }
      this.updateCompetitionHiddenFields();
    },
    onDeleteCompetitor(competitor) {
      // recomputing this because competitors is filtered by isSelf, but to retrieve the correct index, we need all the competitors in the store
      const competitorIndex = this.allCompetitors.findIndex((_competitor) =>
        competitor.id
          ? _competitor.id === competitor.id
          : _competitor.tempId === competitor.tempId
      );

      this.deleteCompetitorInStore(competitorIndex);

      // deletes competitor with an Id not just tempId
      if (competitor.id) {
        this.deleteCompetitor(competitor, competitorIndex);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.competition-grid {
  &__selector {
    @apply flex justify-center mb-8;
    label {
      @apply relative mr-8;
    }
  }
  &__table {
    @apply w-full max-w-full my-4;
    &__header {
      &__cell {
        @apply font-medium text-sm text-left;
        height: 35px;
      }
      &__row {
        &:nth-child(2) {
          .competition-grid__table__header__cell {
            @apply px-3;
            border: 1px solid rgba(112, 112, 112, 0.2);
            color: $color-grey;
            width: max-content;
            &:first-child {
              border-left: none;
            }
            svg {
              @apply mr-2;
              fill: #798892;
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
    &__body {
      &__row {
      }
      &__cell {
        @apply px-3 align-top;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid rgba(112, 112, 112, 0.2);
        min-width: 150px;
        max-width: 250px;
        min-height: 80px;
        max-height: 150px;
        &:first-child {
          @apply font-semibold text-center;
          border-left: none;
        }
        ::v-deep {
          .editable__input {
            @apply text-xs max-h-full;
          }
          .list-input {
            &__placeholder {
              @apply text-xs my-0;
            }
            &__editor {
              .ql-container ul {
                @apply my-0;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    &__footer {
      &__cell {
        @apply relative;
        height: 40px;
        border: 1px solid rgba(112, 112, 112, 0.2);
        border-width: 1px 0 1px 0;
        a {
          position: sticky;
          left: 0;
        }
      }
    }
  }
  &__wrapper {
    @apply w-full max-w-full overflow-x-auto;
  }
}

#editable-Svr {
  height: 70px;
}
</style>
