import { render, staticRenderFns } from "./RiskForceCell.vue?vue&type=template&id=27138162"
import script from "./RiskForceCell.vue?vue&type=script&lang=js"
export * from "./RiskForceCell.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CProgress: require('@chakra-ui/vue').CProgress, CBox: require('@chakra-ui/vue').CBox, CStack: require('@chakra-ui/vue').CStack})
