import { render, staticRenderFns } from "./SkillsSelect.vue?vue&type=template&id=d7ba8d18&scoped=true"
import script from "./SkillsSelect.vue?vue&type=script&lang=js"
export * from "./SkillsSelect.vue?vue&type=script&lang=js"
import style0 from "./SkillsSelect.vue?vue&type=style&index=0&id=d7ba8d18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7ba8d18",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CIcon: require('@chakra-ui/vue').CIcon, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CText: require('@chakra-ui/vue').CText})
