<template>
  <c-box
    bg="#fff"
    borderRadius="8px"
    py="12"
    px="7"
    minHeight="180px"
    w="100%"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
    class="overview__pitch"
    id="elevator-pitch"
  >
    <c-heading fontSize="lg" fontWeight="600" mb="4">
      Elevator Pitch
    </c-heading>

    <EditableText
      title="Pitch"
      type="pitch"
      :placeholder="'An elevator pitch is a brief, persuasive speech that you use to spark interest in what your idea/organization/company does. Click to edit'"
      :disabled="disabled"
      v-model="elevatorPitch"
      @updateDescription="onInput"
    />
  </c-box>
</template>

<script>
import { mapState } from 'vuex';

import { updateCompany } from '@/services/company';
import EditableText from './../EditableText.vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditableText,
  },
  data() {
    return {
      elevatorPitch: null,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  created() {
    this.elevatorPitch = this.activeCompany.elevatorPitch;
  },
  methods: {
    async onInput(e) {
      const oldValue = this.elevatorPitch;
      this.elevatorPitch = e;
      try {
        const res = await updateCompany({
          id: this.activeCompany.id,
          set: { elevatorPitch: this.elevatorPitch },
        });
        if (!res.data.update_company_by_pk) {
          this.elevatorPitch = oldValue;
          this.$toast({
            title: 'An error occurred.',
            description: `You need ADMIN or edit access to update this information`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } catch (e) {
        this.elevatorPitch = oldValue;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating elevator pitch, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  @apply pb-3;
  &__pitch {
    ::v-deep {
      .editable__input {
        min-height: 100px;
      }
    }
  }
}
</style>
