<template>
  <c-box py="8" :px="{ base: '4', lg: '0' }">
    <c-flex align="center" justify="space-between" mb="8">
      <c-flex align="baseline">
        <c-heading fontSize="3xl" fontWeight="700" mr="3"> Status </c-heading>
        <!-- <c-flex as="a" align="center">
          <c-text
            as="span"
            color="vc-orange.400"
            fontSize="md"
            mr="2"
            fontWeight="600"
          >
            Share
          </c-text>
          <svg
            v-chakra="{
              w: '4',
              h: '4',
              fill: '#ef5323',
            }"
          >
            <use href="@/assets/icons/share-box-fill.svg#share"></use>
          </svg>
        </c-flex> -->
      </c-flex>
      <Notification />
    </c-flex>
    <c-flex
      boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
      w="100%"
      p="8"
      bg="#fff"
      borderRadius="8px"
      justify="space-between"
    >
      <c-box
        :w="{ base: '100%', lg: '60%', sm: '100%', xs: '100%' }"
        :mr="{ base: '0', lg: '6', sm: '0', xs: '0' }"
      >
        <c-heading as="h3" display="flex" fontSize="xl" mb="3">
          <c-text fontWeight="normal" mr="2"> Level of Business: </c-text>
          <c-text fontWeight="600">{{ companyLevel }}</c-text>
        </c-heading>
        <c-flex align="center" h="80px">
          <ProgressBar
            :value="companyLevelPercentage"
            :height="'20px'"
            v-chakra="{
              flex: 1,
            }"
          />
          <svg
            v-chakra="{
              w: '10',
              h: '10',
              m: '-5px',
              zIndex: '1',
            }"
          >
            <use href="@/assets/icons/icon-target.svg#target"></use>
          </svg>
        </c-flex>
      </c-box>
      <c-box class="stat-tabs">
        <c-tabs variant="enclosed">
          <c-tab-list>
            <c-tab>Team Activity</c-tab>
            <c-tab>Framework of Growth</c-tab>
            <c-tab>Communication</c-tab>
          </c-tab-list>

          <c-tab-panels borderWidth="1px" h="90px" bg="#fff">
            <c-tab-panel>
              <c-flex px="6" py="3">
                <c-stack :spacing="1">
                  <c-text color="gray.500" fontWeight="500" fontSize="sm">
                    Activity Level (Monthly)
                  </c-text>
                  <c-flex align="center">
                    <c-text fontSize="lg" fontWeight="600" mr="2">5710</c-text>
                    <c-flex align="center">
                      <svg
                        v-chakra="{
                          w: '2',
                          h: '2',
                          fill: 'vc-orange.400',
                        }"
                      >
                        <use href="@/assets/icons/arrow-down.svg#arrow"></use>
                      </svg>
                      <c-text fontSize="sm" color="vc-orange.400"> 15% </c-text>
                    </c-flex>
                  </c-flex>
                </c-stack>
              </c-flex>
            </c-tab-panel>
            <c-tab-panel>
              <c-flex px="6" py="2" justify="space-between">
                <c-stack :spacing="1">
                  <c-text color="gray.400" fontWeight="500" fontSize="xs">
                    Least Visited
                  </c-text>
                  <c-text fontWeight="600"> Business Model </c-text>
                  <c-text color="vc-orange.400" fontWeight="600" fontSize="xs">
                    &lt; 1minute
                  </c-text>
                </c-stack>
                <c-stack :spacing="1">
                  <c-text color="gray.400" fontWeight="500" fontSize="xs">
                    Most Visited
                  </c-text>
                  <c-text fontWeight="600"> Risk Assessment </c-text>
                  <c-text color="vc-orange.400" fontWeight="600" fontSize="xs">
                    2 hours
                  </c-text>
                </c-stack>
              </c-flex>
            </c-tab-panel>
            <c-tab-panel h="100%">
              <c-flex
                h="100%"
                px="6"
                py="2"
                align="center"
                justify="space-between"
              >
                <c-stack :spacing="1">
                  <c-text color="gray.400" fontWeight="500" fontSize="xs">
                    Messages Sent (Monthly)
                  </c-text>
                  <c-flex align="center">
                    <c-text fontSize="lg" fontWeight="600" mr="2">526</c-text>
                    <c-flex align="center">
                      <svg
                        v-chakra="{
                          w: '2',
                          h: '2',
                          fill: 'green.400',
                        }"
                      >
                        <use href="@/assets/icons/arrow-up.svg#arrow"></use>
                      </svg>
                      <c-text fontSize="sm" color="green.400"> 15% </c-text>
                    </c-flex>
                  </c-flex>
                </c-stack>
                <c-stack :spacing="1">
                  <c-text color="gray.400" fontWeight="500" fontSize="xs">
                    Responsiveness (Average)
                  </c-text>
                  <c-text fontWeight="600"> 1h 15mins </c-text>
                </c-stack>
              </c-flex>
            </c-tab-panel>
          </c-tab-panels>
        </c-tabs>
      </c-box>
    </c-flex>
    <c-box
      boxShadow="0 3px 6px rgba(186, 186, 186, 0.16), -2px -1px 6px rgba(186, 186, 186, 0.16)"
      w="100%"
      p="8"
      my="8"
      bg="#fff"
      borderRadius="8px"
    >
      <c-flex justify="space-between" align="center" mb="8">
        <c-heading fontSize="xl" fontWeight="600">Upcoming Meetings</c-heading>
        <c-link
          as="router-link"
          to="/app/calendar"
          fontWeight="700"
          color="vc-orange.400"
        >
          Go to Calendar
        </c-link>
      </c-flex>
      <WeekCalendar />
    </c-box>
    <c-flex
      :direction="{ lg: 'row', xs: 'column', xl: 'row' }"
      w="100%"
      my="8"
      justify="space-between"
    >
      <c-box :w="{ lg: '55%', xs: '100%' }">
        <Responsibilities />
      </c-box>
      <c-box :w="{ lg: '45%', xs: '100%' }">
        <Feed />
      </c-box>
    </c-flex>
    <LastTour />
    <!-- <NoteList :isCardView="true" /> -->
    <Milestones />
  </c-box>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import ProgressBar from './../components/ProgressBar';
import { growthFrameworkTourData } from './../../../helpers/data.js';
import Responsibilities from './components/Responsibilities.vue';
import Feed from './components/Feed.vue';
import LastTour from './components/LastTour.vue';
import Milestones from './components/Milestones.vue';
import Notification from '../components/Notification.vue';
// import WeekCalendar from './components/WeekCalendar.vue';
// import NoteList from '../components/notes/NoteList.vue';

export default {
  components: {
    ProgressBar,
    Responsibilities,
    Feed,
    LastTour,
    Milestones,
    Notification,
    // WeekCalendar,
    // NoteList,
  },
  data() {
    return {
      tourData: growthFrameworkTourData,
      aiCreditPercentage: 30,
    };
  },
  computed: {
    ...mapGetters('company', ['companyLevel', 'companyLevelPercentage']),
    ...mapGetters('subscription', ['getCurrentPlan']),
    ...mapState('company', ['activeCompany']),
  },
  methods: {
    ...mapActions({
      startTour: 'tour/startTour',
    }),
    onStage2StepStartClick(pageIndex, stepIndex = 0) {
      this.startTour({
        tour: this.tourData.stage2.steps[pageIndex],
        startIndex: stepIndex,
      });
    },
    buyCredits() {
      this.$router.push({
        name: 'Credits',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stat-tabs {
  ::v-deep {
    div[data-chakra-component='CTabList'] {
      background-color: #eaf0f6;
      align-items: stretch;
    }
    button[data-chakra-component='CTab'] {
      background-color: transparent;
      height: unset;
      color: rgba(42, 42, 49, 0.8);
      &[aria-selected='true'] {
        background-color: #fff;
        box-shadow: 1px 1px 4px #4b66ab33;
        color: #2a2a31;
      }
    }
  }
}
</style>
