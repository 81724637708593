var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"chakra",rawName:"v-chakra",value:({
    padding: '1.5em',
  }),expression:"{\n    padding: '1.5em',\n  }"}]},[_c('div',{staticClass:"details"},[_c('h5',{staticClass:"details__title"},[_vm._v("Details")]),_c('c-grid',{attrs:{"w":"100%","template-columns":{ base: '1fr', lg: 'repeat(2, 1fr)' },"gap":"12"}},[_c('c-box',{staticClass:"description__card"},[_c('c-flex',{attrs:{"justify":"space-between","align":"center","mb":"6"}},[_c('c-heading',{attrs:{"as":"h5","fontSize":"lg","color":"#495057","fontWeight":"600"}},[_vm._v(" Description ")])],1),_c('c-box',[(_vm.saved)?_c('c-text',{attrs:{"as":"span","color":"#ef5323","fontSize":"14px"}},[_c('b',[_vm._v("Saved successfully 👍")])]):_vm._e(),_c('EditableText',{attrs:{"disabled":_vm.disabled,"title":"Customer Description"},on:{"updateDescription":_vm.updateDescription},model:{value:(_vm.customer.description),callback:function ($$v) {_vm.$set(_vm.customer, "description", $$v)},expression:"customer.description"}})],1)],1),_c('c-box',[_c('div',[_c('c-form-control',{staticClass:"customers__form-control",attrs:{"as":"fieldset"}},[_c('div',{staticClass:"customers__categories"},[_c('label',{attrs:{"for":`consumer-category-${_vm.customer.id}`}},[_c('c-visually-hidden',{attrs:{"name":`customerCategory-${_vm.customer.id}`,"id":`consumer-category-${_vm.customer.id}`,"as":"input","type":"radio","value":"consumer","checked":_vm.customer.category === 'consumer'},on:{"change":_vm.onCustomerSelect}}),_c('c-control-box',{attrs:{"border-width":"1px","px":"10px","w":{ base: '100%', lg: 'max-content' },"minWidth":"120px","h":"45px","rounded":"md","type":"radio","borderColor":"#389FB4","fontWeight":"500","color":"#389FB4","_checked":{
                    backgroundColor: '#389FB4',
                    color: 'white',
                  }}},[_vm._v(" Consumer ")])],1),_c('label',{attrs:{"for":`business-category-${_vm.customer.id}`}},[_c('c-visually-hidden',{attrs:{"name":`customerCategory-${_vm.customer.id}`,"id":`business-category-${_vm.customer.id}`,"as":"input","type":"radio","value":"business","checked":_vm.customer.category === 'business'},on:{"change":_vm.onCustomerSelect}}),_c('c-control-box',{attrs:{"border-width":"1px","px":"10px","w":{ base: '100%', lg: 'max-content' },"minWidth":"120px","h":"45px","rounded":"md","type":"radio","borderColor":"#14A27B","fontWeight":"500","color":"#14A27B","_checked":{
                    backgroundColor: '#14A27B',
                    color: 'white',
                  }}},[_vm._v(" Business ")])],1),_c('label',{attrs:{"for":`government-category-${_vm.customer.id}`}},[_c('c-visually-hidden',{attrs:{"name":`customerCategory-${_vm.customer.id}`,"id":`government-category-${_vm.customer.id}`,"as":"input","type":"radio","value":"government","checked":_vm.customer.category === 'government'},on:{"change":_vm.onCustomerSelect}}),_c('c-control-box',{attrs:{"border-width":"1px","px":"10px","w":{ base: '100%', lg: 'max-content' },"minWidth":"120px","h":"45px","rounded":"md","type":"radio","borderColor":"#3762D5","fontWeight":"500","color":"#3762D5","_checked":{
                    backgroundColor: '#3762D5',
                    color: 'white',
                  }}},[_vm._v(" Government ")])],1)]),_c('c-form-helper-text',[_vm._v(" Select customer category ")])],1)],1),_c('c-form-control',{attrs:{"mb":"2rem"}},[_c('v-select',{staticClass:"vc-select",attrs:{"clearable":false,"searchable":false,"value":_vm.customer.type,"options":_vm.customerTypes,"placeholder":'Type of Customer'},on:{"input":function($event){return _vm.onFieldUpdate({
                key: 'type',
                value: $event,
              })}}})],1)],1)],1)],1),_c('div',{staticClass:"fields"},[_c('div',[_c('FieldCard',{attrs:{"type":`customerProblem${_vm.customer.id}`,"title":"Problem","fieldKey":'problem',"description":`What problem does the target market ( ${_vm.customer.name} ) customer have?`,"disabled":_vm.disabled,"value":_vm.customer.problem},on:{"updateField":_vm.onFieldUpdate}}),_c('FieldCard',{attrs:{"type":`customerValueProposition${_vm.customer.id}`,"title":"Value Proposition","description":"What is your value proposition to this specific target market?","fieldKey":'valueProposition',"value":_vm.customer.valueProposition,"disabled":_vm.disabled},on:{"updateField":_vm.onFieldUpdate}})],1),_c('div',[_c('FieldCard',{attrs:{"type":`customerSolution${_vm.customer.id}`,"title":"Solution","fieldKey":'solution',"description":"What solution do you provide to the specific target market in order to solve their problem?","value":_vm.customer.solution,"disabled":_vm.disabled},on:{"updateField":_vm.onFieldUpdate}}),_c('WillingnessToPay',{attrs:{"value":_vm.customer.willingnessToPay,"disabled":_vm.disabled},on:{"updateField":_vm.onFieldUpdate}}),_c('PriceSensitivity',{attrs:{"value":_vm.customer.priceSensitivity,"disabled":_vm.disabled},on:{"updateField":_vm.onFieldUpdate}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }