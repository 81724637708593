<template>
  <c-pseudo-box
    w="100%"
    h="100%"
    :bgImage="
      presentation.data.color
        ? `linear-gradient(
        to bottom ,
        ${presentation.data.color}80,
        #fff
      )`
        : `linear-gradient(
        to bottom ,
        #fff,
        #fff
      )`
    "
    display="flex"
    position="relative"
    :_before="{
      content: content,
      bg: '#fff',
      position: 'absolute',
      right: '0px',
      w: '75%',
      top: '0',
      bottom: '0',
      zIndex: 1,
    }"
  >
    <div
      class="draggable-image"
      :style="{
        backgroundImage: 'url(' + imageUrl + ')',
        backgroundSize: 'cover',
        width: '20em',
        height: '30em',
        top: imagePosition.top + 'px',
        left: imagePosition.left + 'px',
      }"
      @mousedown="startImageDrag"
      @mousemove="handleImageDrag"
      @mouseup="stopDrag"
      @mouseleave="stopDrag"
    ></div>

    <c-flex direction="column" h="100%" align="center">
      <c-text
        mt="10"
        ml="5"
        mr="10"
        color="#000"
        fontWeight="700"
        fontSize="4xl"
        zIndex="2"
        >Market Size</c-text
      >
      <c-box
        mt="5"
        h=".5em"
        w="10em"
        :bg="`${presentation.data.color}80`"
        zIndex="2"
      ></c-box>
    </c-flex>

    <c-flex
      direction="column"
      flex="1"
      zIndex="2"
      py="10"
      h="100%"
      justify="center"
      align="center"
      pr="4"
      position="relative"
      :bg="presentation.data.market_size.backgroundColor"
    >
      <c-box my="auto" d="flex" justify="center" align="center">
        <OverviewChart
          chartWidth="20em"
          chartHeight="20em"
          template="default_1"
        />
      </c-box>
      <c-box ml="auto" mt="auto">
        <WaterMark :direction="'row'" />
      </c-box>
    </c-flex>
  </c-pseudo-box>
</template>

<script>
import { EventBus } from '@/eventBus.js';
import { mapState, mapMutations } from 'vuex';
import OverviewChart from '@/components/OverviewChart.vue';
import WaterMark from '@/components/WaterMark.vue';
import { updatePresentation } from '@/services/presentation';

export default {
  components: { OverviewChart, WaterMark },
  computed: {
    ...mapState({
      presentation: (state) => state.presentation.activePresentation,
    }),
  },
  data() {
    return {
      imageUrl:
        'https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/market_size.png',
      content: "''",
      startImagePosition: { x: 0, y: 0 },
      imagePosition: { top: 300, left: 0 },
      isDragging: false,
    };
  },
  mounted() {
    EventBus.$on('market_size', async (configForm) => {
      this.updatePresentation({
        data: {
          ...this.presentation.data,
          market_size: configForm,
        },
      });

      try {
        const response = await updatePresentation({
          id: this.presentation.id,
          append: { data: { market_size: configForm } },
        });
        const pre = response.data.update_presentation_by_pk;
        this.updatePresentation({
          data: {
            ...this.presentation.data,
            market_size: pre.data.market_size,
          },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, market_size: configForm },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
    startImageDrag(event) {
      this.isDragging = true;
      this.startImagePosition.x = event.clientX;
      this.startImagePosition.y = event.clientY;
    },
    handleImageDrag(event) {
      if (this.isDragging) {
        const offsetX = event.clientX - this.startImagePosition.x;
        const offsetY = event.clientY - this.startImagePosition.y;
        this.imagePosition.left += offsetX;
        this.imagePosition.top += offsetY;
        this.startImagePosition.x = event.clientX;
        this.startImagePosition.y = event.clientY;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
  },
};
</script>

<style></style>
