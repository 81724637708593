var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customers",style:({
    background: _vm.presentation.data.target_customers.backgroundColor,
  })},[_c('div',[_c('div',{staticStyle:{"margin-top":"20px","margin-left":"20px"}},[_c('c-text',{attrs:{"as":"h3","mb":"20px","fontSize":"4xl","fontWeight":"700","textAlign":"left"}},[_vm._v("Target Customers")]),_c('c-box',{attrs:{"mr":"auto","h":".5em","w":"8em","bg":`${_vm.presentation.data.color}80`}})],1),_c('div',{staticClass:"customers_item"},[_vm._l((_vm.selectedCustomers),function(customer,idx){return [_c('c-box',{key:customer.id,attrs:{"textAlign":"left","bg":idx % 2 === 0 ? `${_vm.presentation.data.color}20` : '#fff',"w":"200px","p":"5"}},[_c('c-text',{attrs:{"fontSize":"1.5em","fontWeight":"600"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(customer.name))+" ")]),_c('hr',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"h":".3em","maxWidth":"80%","bg":_vm.presentation.data.color,"my":"5"}}),_c('c-box',{attrs:{"mb":"5"}},[_c('c-text',{attrs:{"fontSize":"1em","color":"#8A8A8A"}},[_vm._v("Customer type")]),_c('c-text',{attrs:{"fontSize":"1.2em","color":"#000000"}},[_vm._v(" "+_vm._s(customer.type)+" ")])],1),_c('c-box',[_c('c-text',{attrs:{"fontSize":"1em","color":"#8A8A8A"}},[_vm._v("Category")]),_c('c-text',{attrs:{"fontSize":"1.2em","color":"#000000"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(customer.category))+" ")])],1)],1)]})],2)]),_c('div',{staticClass:"customers_background",style:({
      background: _vm.presentation.data.color
        ? `linear-gradient(to right, white 0%, white 80%,${_vm.presentation.data.color}80 80%, ${_vm.presentation.data.color}80 100%)`
        : `linear-gradient(
      to bottom ,
      #fff,
      #fff
    )`,
    })},[(_vm.isEdit)?_c('c-link',{staticClass:"item-selector",attrs:{"textDecoration":"underline","right":"5","top":"-10","fontSize":"xl"},on:{"click":function($event){_vm.isDrawerOpen = true}}},[_vm._v("Select Target customers to display")]):_vm._e(),_c('br'),_c('div',[_c('WaterMark',{attrs:{"direction":'row'}})],1)],1),_c('ListDrawer',{attrs:{"isDrawerOpen":_vm.isDrawerOpen,"closeDrawer":_vm.closeDrawer,"updateFn":_vm.updateCustomersData,"isUpdatingData":_vm.isUpdatingData,"items":_vm.customers,"itemLabel":'name',"maximumItemsVisible":8}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }