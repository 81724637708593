<template>
  <c-box h="100%" id="overall-value-proposition">
    <TaskCard
      title="Overall Value Proposition"
      :description="'What value are you creating to your customers?'"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :hasMounted="hasMounted"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="true"
      :disabled="disabled"
      size="small"
      :taskData="businessModelData"
      :accessKey="'business_model_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'business_model_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="overall_value_proposition"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>

      <div v-else>
        <c-text as="span" color="#ef5323" fontSize="14px" v-if="saved"
          ><b>Saved successfully 👍</b></c-text
        >
        <EditableText
          title="Overall Value Proposition"
          type="overall_value_proposition"
          v-model="businessModelData.description"
          @updateDescription="updateDescription"
          :disabled="disabled"
        />
      </div>
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';
import TaskCard from '@/views/App/GrowthFramework/components/TaskCard.vue';
import businessModelMixin from '@/mixins/business-model.js';

export default {
  mixins: [businessModelMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    EditableText,
    ContentLoader,
  },
  data() {
    return {
      businessModelType: 'overall_value_proposition',
      hasMounted: false,
      saved: false,
    };
  },
  methods: {
    onTextBlur() {
      this.updateData({
        key: 'description',
        value: this.businessModelData.description,
      });
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },
    updateDescription(newAIcontent) {
      this.businessModelData.description = newAIcontent;
      this.onTextBlur();
    },
  },
};
</script>

<style></style>
