<template>
  <c-box w="100%" h="100%" p="30px" position="relative" bg="#fff">
    <c-text fontWeight="700" mb="30px" fontSize="2em">Financials</c-text>
    <c-link
      textDecoration="underline"
      position="absolute"
      right="5"
      top="5"
      class="item-selector"
      fontSize="xl"
      v-if="isEdit"
      @click="isDrawerOpen = true"
      >Select costs to display</c-link
    >
    <table v-chakra w="100%" my="30px" fontSize="1.4em">
      <thead>
        <tr>
          <th
            v-chakra
            w="25%"
            pb="20px"
            textAlign="start"
            borderBottomWidth="1px"
            fontWeight="normal"
          >
            Cost/Year
          </th>
          <th
            v-for="(costYear, ix) in costYears"
            :key="ix"
            v-chakra
            pb="30px"
            textAlign="start"
            borderBottomWidth="1px"
          >
            {{ costYear.label }}
          </th>
        </tr>
      </thead>
      <tbody v-chakra fontSize="1.3em" textAlign="left">
        <tr v-for="(cost, ix) in selectedItems" :key="ix">
          <td v-chakra py="10px" w="25%" borderBottomWidth="1px">
            {{ cost.type }}
          </td>

          <td
            v-for="(costItem, idx) in cost.valueArray"
            :key="idx"
            v-chakra
            py="10px"
            borderBottomWidth="1px"
          >
            {{ costItem.value }}
          </td>
        </tr>
      </tbody>
      <tbody v-chakra fontSize="1.3em" textAlign="left">
        <tr>
          <td v-chakra py="10px" w="25%" borderBottomWidth="1px">TOTAL</td>
          <td
            v-chakra
            py="10px"
            w="25%"
            borderBottomWidth="1px"
            v-for="(costYear, i) in selectedCostYears"
            :key="i"
          >
            {{ costYear.total }}
          </td>
        </tr>
      </tbody>
      <tbody v-chakra fontSize="1.3em" textAlign="left">
        <tr>
          <td
            v-chakra
            py="10px"
            w="25%"
            borderBottomWidth="1px"
            fontWeight="bold"
          >
            GRAND TOTAL
          </td>
          <td
            v-chakra
            py="10px"
            w="25%"
            borderBottomWidth="1px"
            v-for="i in 2"
            :key="i"
          />
          <td
            v-chakra
            py="10px"
            w="25%"
            borderBottomWidth="1px"
            fontWeight="bold"
          >
            {{
              selectedCostYears.reduce(
                (accumulator, currentValue) =>
                  accumulator + +currentValue.total,
                0
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <c-box position="absolute" right="5" bottom="5">
      <WaterMark :direction="'column'" />
    </c-box>
    <TextListDrawer
      :title="'Select visible costs'"
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :items="costs"
      :updateFn="updateCostsData"
      :isUpdatingData="isUpdatingData"
      :max="50"
    >
      <template #list-item="{ item }">
        <c-text as="span">{{ item.type }}</c-text>
      </template>
    </TextListDrawer>
  </c-box>
</template>

<script>
import WaterMark from '@/components/WaterMark.vue';
import costsMixin from '@/mixins/data/costs.js';
import TextListDrawer from '@/components/DataComponents/TextListDrawer.vue';

export default {
  components: { WaterMark, TextListDrawer },
  mixins: [costsMixin],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
