<template>
  <div class="value-proposition">
    <c-heading as="h6" fontSize="10px">Grade delivery</c-heading>
    <div class="value-proposition__progress-bar">
      <c-text fontSize="sm" fontWeight="500" color="gray.400">
        {{ innerValue.delivery }}%
      </c-text>
      <c-box>
        <c-slider
          :isDisabled="disabled"
          color="vue"
          v-model="innerValue.delivery"
        >
          <c-slider-track h="8px" />
          <c-slider-filled-track h="8px" />
          <c-slider-thumb @mouseup="onSliderBlur" />
        </c-slider>
      </c-box>
    </div>
    <EditableText
      v-model="innerValue.description"
      :disabled="disabled"
      title="Competition Value"
      type="competition_value"
      @updateDescription="updateDescription"
    />
  </div>
</template>

<script>
import EditableText from './../EditableText';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    EditableText,
  },
  data() {
    return {
      progress: 0,
      innerValue: { delivery: 0, description: '' },
    };
  },

  watch: {
    value() {
      this.innerValue = { ...this.value };
    },
  },
  created() {
    if (this.value) {
      this.innerValue = { ...this.value };
    }
  },
  methods: {
    onTextInputBlur() {
      this.$emit('input', { ...this.innerValue });
    },
    updateDescription(value) {
      this.innerValue.description = value;
      this.$emit('input', { ...this.innerValue });
    },
    onSliderBlur() {
      this.$emit('input', { ...this.innerValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.value-proposition {
  @apply w-full;
  &__progress-bar {
    @apply mt-1 mb-2 w-full;
  }
  ::v-deep {
    .editable {
      height: calc(100% - 80px);
      &__input {
        min-height: 80px;
        height: 100%;
      }
    }
  }
}
</style>
