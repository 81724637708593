<template>
  <c-box
    p="8"
    w="100%"
    bg="#f4f6fc"
    height="400px"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16), -2px -1px 6px rgba(186, 186, 186, 0.16)"
  >
    <c-flex align="center" mb="4">
      <c-heading fontSize="2xl" fontWeight="700" mr="3">
        Activity Log
      </c-heading>
      <!-- <c-flex as="a" align="baseline">
        <svg
          v-chakra="{
            w: '6',
            h: '6',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/share-box-fill.svg#share"></use>
        </svg>
      </c-flex> -->
    </c-flex>
    <c-stack
      maxHeight="calc(100% - 45px)"
      overflowY="scroll"
      :spacing="4"
      v-if="feed.length"
    >
      <template v-for="(action, index) in feed">
        <c-box v-if="index < 16" :key="action.id">
          <c-flex fontSize="sm" fontWeight="600" mb="2">
            <div class="user_name">
              <span>{{ getFirstLetter(action.user.firstname) }}</span>
            </div>
            <c-text mr="1">
              {{ titlecase(action.type.split('_').join(' ')) }}
            </c-text>
            <c-text color="vc-orange.400">/</c-text>
            <c-text ml="1">{{
              titlecase(action.component.split('_').join(' '))
            }}</c-text>
          </c-flex>
          <c-flex fontSize="xs" justify="space-between">
            <template v-if="action.data.set && action.data.set.description">
              <span>
                {{ action.user.firstname }}
                {{ action.user.lastname }} edited
                {{ titlecase(action.component.split('_').join(' ')) }}
              </span>
            </template>
            <template v-if="action.data.set && action.data.set.progress">
              {{ action.user.firstname }}
              {{ action.user.lastname }} set
              {{ titlecase(action.component.split('_').join(' ')) }}
              to
              {{
                titlecase(
                  action.data.set.progress.toLowerCase().split('_').join(' ')
                )
              }}
            </template>
            <template v-if="action.data.set && action.data.set.priority">
              <span
                v-chakra="{
                  display: 'flex',
                }"
              >
                {{ action.user.firstname }}
                {{ action.user.lastname }} changed Priority to:
                <c-text
                  ml="2"
                  :color="importanceLevels[action.data.set.priority].color"
                  fontWeight="600"
                >
                  {{ importanceLevels[action.data.set.priority].name }}
                </c-text>
              </span>
            </template>
            <template v-if="action.data.object">
              <span
                v-chakra="{
                  display: 'flex',
                }"
              >
                {{ action.user.firstname }}
                {{ action.user.lastname }} created
                {{ titlecase(action.component.split('_').join(' ')) }}
              </span>
            </template>
            <span>
              {{ action.createdAt | moment('DD MMM. YYYY') }} at
              {{ action.createdAt | moment('hh:mm a') }}
            </span>
          </c-flex>
        </c-box>
      </template>
    </c-stack>
    <!-- <c-grid template-columns="repeat(2, 1fr)" h="calc(100% - 15px)">
      <c-stack :spacing="4" pl="8">
        <c-box>
          <c-flex fontSize="sm" fontWeight="600" mb="2">
            <c-text mr="1">Business Model</c-text>
            <c-text color="vc-orange.400">/</c-text>
            <c-text ml="1">Customer Segment</c-text>
          </c-flex>
          <c-flex fontSize="xs" justify="space-between">
            <c-text color="gray.500">Mark Alonso edited Vision</c-text>
            <c-text color="gray.400">Feb 20 2020 at 6:01 pm</c-text>
          </c-flex>
        </c-box>
        <c-box>
          <c-flex fontSize="sm" fontWeight="600" mb="2">
            <c-text mr="1">Business Model</c-text>
            <c-text color="vc-orange.400">/</c-text>
            <c-text ml="1">Customer Segment</c-text>
          </c-flex>
          <c-flex fontSize="xs" justify="space-between">
            <c-text color="gray.500">Mark Alonso edited Vision</c-text>
            <c-text color="gray.400">Feb 20 2020 at 6:01 pm</c-text>
          </c-flex>
        </c-box>
        <c-box>
          <c-flex fontSize="sm" fontWeight="600" mb="2">
            <c-text mr="1">Business Model</c-text>
            <c-text color="vc-orange.400">/</c-text>
            <c-text ml="1">Customer Segment</c-text>
          </c-flex>
          <c-flex fontSize="xs" justify="space-between">
            <c-text color="gray.500">Mark Alonso edited Vision</c-text>
            <c-text color="gray.400">Feb 20 2020 at 6:01 pm</c-text>
          </c-flex>
        </c-box>
        <c-box>
          <c-flex fontSize="sm" fontWeight="600" mb="2">
            <c-text mr="1">Business Model</c-text>
            <c-text color="vc-orange.400">/</c-text>
            <c-text ml="1">Customer Segment</c-text>
          </c-flex>
          <c-flex fontSize="xs" justify="space-between">
            <c-text color="gray.500">Mark Alonso edited Vision</c-text>
            <c-text color="gray.400">Feb 20 2020 at 6:01 pm</c-text>
          </c-flex>
        </c-box>
        <c-box>
          <c-flex fontSize="sm" fontWeight="600" mb="2">
            <c-text mr="1">Business Model</c-text>
            <c-text color="vc-orange.400">/</c-text>
            <c-text ml="1">Customer Segment</c-text>
          </c-flex>
          <c-flex fontSize="xs" justify="space-between">
            <c-text color="gray.500">Mark Alonso edited Vision</c-text>
            <c-text color="gray.400">Feb 20 2020 at 6:01 pm</c-text>
          </c-flex>
        </c-box>
      </c-stack>
    </c-grid> -->
  </c-box>
</template>

<script>
import { getComponentActivity } from '@/services/company';
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';
import { titlecase } from '@/helpers/utils';

export default {
  data() {
    return {
      feed: [],
      importanceLevels: {
        urgent: {
          name: 'Urgent',
          value: 'urgent',
          color: '#ee2f1f',
          colorRgba: 'rgba(238, 48, 31, 0.1)',
        },
        high: {
          name: 'High',
          value: 'high',
          color: '#ef5323',
          colorRgba: 'rgba(239, 83, 35, 0.1)',
        },
        normal: {
          name: 'Normal',
          color: '#ef7923',
          value: 'normal',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        low: {
          name: 'Low',
          value: 'low',
          color: '#37d5aa',
          colorRgba: 'rgba(55, 213, 170, 0.1)',
        },
        clear: {
          name: 'Clear',
          value: 'clear',
          color: '#56b5db',
          colorRgba: 'rgba(86, 181, 219, 0.1)',
        },
      },
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  created() {
    this.getFeed();
  },
  methods: {
    titlecase,
    getFeed() {
      getComponentActivity({
        companyId: { _eq: this.activeCompany.id },
      }).then((res) => {
        this.feed = cloneDeep(res.data.activity_logs);
      });
    },
    getFirstLetter(string) {
      return string.charAt(0).toUpperCase();
    },
  },
};
</script>

<style>
.user_name {
  /* float: right; */
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: #e2e8f0;
  border-radius: 100%;
}
</style>
