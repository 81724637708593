<template>
  <div
    class="customers"
    :style="{
      background: presentation.data.target_customers.backgroundColor,
    }"
  >
    <div>
      <div style="margin-top: 20px; margin-left: 20px">
        <c-text
          as="h3"
          mb="20px"
          fontSize="4xl"
          fontWeight="700"
          textAlign="left"
          >Target Customers</c-text
        >
        <c-box
          mr="auto"
          h=".5em"
          w="8em"
          :bg="`${presentation.data.color}80`"
        ></c-box>
      </div>

      <div class="customers_item">
        <template v-for="(customer, idx) in selectedCustomers">
          <c-box
            textAlign="left"
            :bg="idx % 2 === 0 ? `${presentation.data.color}20` : '#fff'"
            w="200px"
            p="5"
            :key="customer.id"
          >
            <c-text fontSize="1.5em" fontWeight="600">
              {{ customer.name | capitalize }}
            </c-text>
            <hr
              v-chakra
              h=".3em"
              maxWidth="80%"
              :bg="presentation.data.color"
              my="5"
            />
            <c-box mb="5">
              <c-text fontSize="1em" color="#8A8A8A">Customer type</c-text>
              <c-text fontSize="1.2em" color="#000000">
                {{ customer.type }}
              </c-text>
            </c-box>
            <c-box>
              <c-text fontSize="1em" color="#8A8A8A">Category</c-text>
              <c-text fontSize="1.2em" color="#000000">
                {{ customer.category | capitalize }}
              </c-text>
            </c-box>
          </c-box>
        </template>
      </div>
    </div>

    <div
      :style="{
        background: presentation.data.color
          ? `linear-gradient(to right, white 0%, white 80%,${presentation.data.color}80 80%, ${presentation.data.color}80 100%)`
          : `linear-gradient(
        to bottom ,
        #fff,
        #fff
      )`,
      }"
      class="customers_background"
    >
      <c-link
        v-if="isEdit"
        textDecoration="underline"
        right="5"
        top="-10"
        class="item-selector"
        fontSize="xl"
        @click="isDrawerOpen = true"
        >Select Target customers to display</c-link
      >
      <br />
      <div>
        <WaterMark :direction="'row'" />
      </div>
    </div>
    <ListDrawer
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :updateFn="updateCustomersData"
      :isUpdatingData="isUpdatingData"
      :items="customers"
      :itemLabel="'name'"
      :maximumItemsVisible="8"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { EventBus } from '@/eventBus.js';
import WaterMark from '@/components/WaterMark.vue';
import { updatePresentation } from '@/services/presentation';

import targetCustomersMixin from '@/mixins/data/targetCustomers.js';
import ListDrawer from '@/components/DataComponents/ListDrawer.vue';

import { capitalize } from '@/helpers/filters.js';

export default {
  mixins: [targetCustomersMixin],
  components: { WaterMark, ListDrawer },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    capitalize,
  },
  mounted() {
    EventBus.$on('target_customers', async (configForm) => {
      this.updatePresentation({
        data: {
          ...this.presentation.data,
          target_customers: configForm,
        },
      });

      try {
        const response = await updatePresentation({
          id: this.presentation.id,
          append: { data: { target_customers: configForm } },
        });
        const pre = response.data.update_presentation_by_pk;
        this.updatePresentation({
          data: {
            ...this.presentation.data,
            target_customers: pre.data.target_customers,
          },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, target_customers: configForm },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
  },
};
</script>

<style scoped>
.customers {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.customers_item {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}

.customers_background {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
</style>
