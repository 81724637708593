<template>
  <c-box id="sam">
    <!--    :isLocked="!getCurrentPlan.access.sam" -->
    <TaskCard
      :title="'SAM'"
      :description="''"
      :hasVisibility="true"
      :hasImportance="true"
      :hasAssumption="false"
      :hasNotification="true"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="marketPotentialData"
      :accessKey="'market_potential_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'market_potential_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="sam"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 200 100"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
        <rect x="200" y="0" rx="3" ry="3" width="80" height="20" />
        <rect x="300" y="0" rx="3" ry="3" width="80" height="20" />
        <rect x="0" y="40" rx="3" ry="3" width="100%" height="80" />
      </ContentLoader>
      <c-box v-else>
        <c-flex
          justify="space-between"
          align="center"
          mb="4"
          :flexWrap="{ base: 'wrap', lg: 'nowrap' }"
        >
          <c-flex align="center" :flexWrap="{ base: 'wrap', lg: 'nowrap' }">
            <c-flex :mb="{ base: '4', lg: '0' }" align="center">
              <c-text fontSize="lg" fontWeight="500" mr="3">SAM</c-text>
              <c-text
                fontSize="3xl"
                fontWeight="600"
                mr="7"
                color="vc-orange.400"
              >
                {{ calculatedSam }} $
              </c-text>
            </c-flex>
            <c-stack :spacing="1" mr="10">
              <c-text fontSize="sm" fontWeight="500" color="gray.600"
                >Customers</c-text
              >
              <c-text fontWeight="500">{{ totalCustomersCount }}</c-text>
            </c-stack>
            <c-stack :spacing="1" mr="10">
              <c-text fontSize="sm" fontWeight="500" color="gray.600">
                Average Price
              </c-text>
              <c-text fontWeight="600">{{ averagePrice }} USD</c-text>
            </c-stack>
            <c-flex
              v-if="percentageOfTam"
              align="center"
              :mt="{ base: '6', lg: '0' }"
            >
              <c-text fontWeight="600" mr="1">{{ percentageOfTam }}%</c-text>
              <c-text fontSize="sm" fontWeight="600" color="#798892">
                of TAM
              </c-text>
            </c-flex>
          </c-flex>
        </c-flex>
        <MarketPotentialGrid
          :customers="customers"
          @addCustomer="onCustomerAdd"
          @updateCustomer="onCustomerUpdate"
          @deleteCustomer="onCustomerDelete"
        />
      </c-box>
    </TaskCard>
  </c-box>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import { ContentLoader } from 'vue-content-loader';

import TaskCard from './../TaskCard';
import MarketPotentialGrid from './MarketPotentialGrid';

import marketPotentialMixin from '@/mixins/market-potential.js';

import {
  getMarketPotentialCustomers,
  addMarketPotentialCustomer,
  updateMarketPotentialCustomer,
} from '@/services/market-potential';
import cloneDeep from 'lodash.clonedeep';

export default {
  mixins: [marketPotentialMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    ContentLoader,
    MarketPotentialGrid,
  },
  data() {
    return {
      marketPotentialType: 'sam',
      customers: [],
      businessModelType: 'revenue',
      hasMounted: false,
    };
  },
  computed: {
    ...mapState('company', {
      tamValue: (state) => state.tamValue,
    }),
    ...mapGetters('subscription', ['getCurrentPlan']),
    totalPrice() {
      return this.customers.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.price,
        0
      );
    },
    averagePrice() {
      return this.totalPrice
        ? this.totalPrice /
            this.customers?.filter(
              (customer) => customer.price > 0 && customer.number > 0
            )?.length
        : 0;
    },
    totalCustomersCount() {
      return this.customers.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.number,
        0
      );
    },
    calculatedSam() {
      return this.averagePrice * this.totalCustomersCount || 0;
    },
    percentageOfTam() {
      return this.tamValue.value
        ? ((this.calculatedSam / this.tamValue.value) * 100).toFixed(2)
        : 0;
    },
  },
  watch: {
    calculatedSam() {
      this.setSam({
        customersCount: this.totalCustomersCount,
        value: this.calculatedSam,
      });
    },
  },
  methods: {
    ...mapMutations({
      setSam: 'company/setSam',
    }),
    onDataFetch() {
      this.getCustomers();
    },
    onDataCreate() {
      this.setSam({ customersCount: 0, value: 0 });
    },
    async getCustomers() {
      this.isFetchingData = true;
      const res = await getMarketPotentialCustomers(
        this.marketPotentialData.id
      );
      this.customers = cloneDeep(res.data.market_potential_customer);
      this.isFetchingData = false;
      this.setSam({
        customersCount: this.totalCustomersCount,
        value: this.calculatedSam,
      });
    },
    async onCustomerAdd(customer) {
      try {
        const res = await addMarketPotentialCustomer({
          marketPotentialId: this.marketPotentialData.id,
          ...customer,
        });
        this.customers.push(
          cloneDeep(res.data.insert_market_potential_customer_one)
        );
        await this.getCustomers();
      } catch (e) {
        console.log(e);
      }
    },
    async onCustomerUpdate({ key, value, index }) {
      try {
        const set = { [key]: value };
        const id = this.customers[index].id;
        const res = await updateMarketPotentialCustomer({ id, set });
        this.customers[index][key] =
          res.data.update_market_potential_customer_by_pk[key];
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
