import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      customers: [],
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedCustomers() {
      return this.customers.filter((customer) => customer.selected);
    },
  },
  watch: {
    presentation() {
      this.setCustomers();
    },
  },
  created() {
    this.setCustomers();
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setCustomers() {
      const customerSegment = this.presentationData.business_models.find(
        (data) => data.type === "customer_segment"
      );
      const customersData = customerSegment && customerSegment.customers;
      if (!this.presentation.data.slidesData?.customers) {
        this.customers = customersData.map((customer, index) => ({
          ...customer,
          selected: index <= 5,
        }));
      } else {
        const { customers } = this.presentation.data.slidesData;
        this.customers = customersData.map((item) => {
          const itemInPresentation = customers.find(
            (_item) => _item.id === item.id
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async updateCustomersData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                customers: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected Customers, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
