<template>
  <c-box id="som">
    <!--       :isLocked="!getCurrentPlan.access.som" -->
    <TaskCard
      :title="'SOM'"
      :description="''"
      :hasVisibility="true"
      :hasImportance="true"
      :hasAssumption="false"
      :hasNotification="true"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="marketPotentialData"
      :accessKey="'market_potential_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'market_potential_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="som"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 200 100"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
        <rect x="200" y="0" rx="3" ry="3" width="80" height="20" />
        <rect x="300" y="0" rx="3" ry="3" width="80" height="20" />
        <rect x="0" y="40" rx="3" ry="3" width="100%" height="80" />
      </ContentLoader>
      <c-box v-else>
        <c-flex
          justify="space-between"
          align="center"
          mb="4"
          :flexWrap="{ base: 'wrap', lg: 'nowrap' }"
        >
          <c-flex
            align="center"
            :flexWrap="{ base: 'wrap', lg: 'nowrap' }"
            :mb="{ base: '6', lg: '0' }"
          >
            <c-input-group
              size="md"
              mr="4"
              :mb="{ base: '6', lg: '0' }"
              :w="{ base: '100%', lg: '150px' }"
              v-if="!disabled"
            >
              <c-input
                type="number"
                rounded="0"
                placeholder="5"
                v-model="somYears"
                :disabled="disabled"
              />
              <c-input-right-addon fontSize="sm">years</c-input-right-addon>
            </c-input-group>
            <!-- <c-button
              variant-color="vc-orange"
              variant="outline"
              @click="openGrowthModal"
              >Graph</c-button
            > -->
          </c-flex>
          <c-flex align="center" :flexWrap="{ base: 'wrap', lg: 'nowrap' }">
            <c-flex align="center" :mb="{ base: '6', lg: '0' }">
              <c-text fontSize="lg" fontWeight="500" mr="3">SOM</c-text>
              <c-text
                fontSize="3xl"
                fontWeight="600"
                mr="7"
                color="vc-orange.400"
                >{{ calculatedSom }} $</c-text
              >
            </c-flex>
            <c-stack :spacing="1" mr="10">
              <c-text fontSize="sm" fontWeight="500" color="gray.600"
                >Customers</c-text
              >
              <c-text fontWeight="500">{{ totalCustomersCount }}</c-text>
            </c-stack>
            <c-stack :spacing="1" mr="10">
              <c-text fontSize="sm" fontWeight="500" color="gray.600"
                >Average Price</c-text
              >
              <c-text fontWeight="600">{{ averagePrice }} USD</c-text>
            </c-stack>
            <c-flex
              v-if="percentageOfTam"
              align="center"
              :mt="{ base: '6', lg: '0' }"
            >
              <c-text fontWeight="600" mr="1">{{ percentageOfTam }}%</c-text>
              <c-text fontSize="sm" fontWeight="600" color="#798892"
                >of TAM</c-text
              >
            </c-flex>
          </c-flex>
        </c-flex>
        <MarketPotentialGrid
          :customers="customers"
          @addCustomer="onCustomerAdd"
          @updateCustomer="onCustomerUpdate"
          @deleteCustomer="onCustomerDelete"
        />
      </c-box>
    </TaskCard>
    <!-- <c-modal
      :is-open="isGrowthModalOpen"
      :on-close="closeGrowthModal"
      size="xl"
      is-centered
    >
      <c-modal-content ref="content" maxWidth="45rem" borderRadius="8px">
        <c-modal-header>SOM</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <GrowthGraph
            v-if="customers.length"
            :customers="customers"
            :marketPotential="marketPotentialData"
          />
          <c-text my="3" v-else
            >Provide customer data to see projected growth</c-text
          >
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal> -->
  </c-box>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import { ContentLoader } from 'vue-content-loader';

import TaskCard from './../TaskCard';
import MarketPotentialGrid from './MarketPotentialGrid';

import marketPotentialMixin from '@/mixins/market-potential.js';

import {
  getMarketPotentialCustomers,
  addMarketPotentialCustomer,
  updateMarketPotentialCustomer,
} from '@/services/market-potential';
import cloneDeep from 'lodash.clonedeep';
import { debounce } from '@/helpers/utils';

export default {
  mixins: [marketPotentialMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    ContentLoader,
    MarketPotentialGrid,
  },
  data() {
    return {
      // isGrowthModalOpen: false,
      marketPotentialType: 'som',
      customers: [],
      somYears: null,
      businessModelType: 'revenue',
      hasMounted: false,
    };
  },
  computed: {
    ...mapState('company', {
      tamValue: (state) => state.tamValue,
    }),
    ...mapGetters('subscription', ['getCurrentPlan']),
    totalPrice() {
      return this.customers.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.price,
        0
      );
    },
    averagePrice() {
      return this.totalPrice
        ? this.totalPrice /
            this.customers?.filter(
              (customer) => customer.price > 0 && customer.number > 0
            )?.length
        : 0;
    },
    totalCustomersCount() {
      return this.customers.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.number,
        0
      );
    },
    calculatedSom() {
      return this.averagePrice * this.totalCustomersCount || 0;
    },
    percentageOfTam() {
      return this.tamValue
        ? ((this.calculatedSom / this.tamValue.value) * 100).toFixed(2)
        : 0;
    },
  },
  watch: {
    calculatedSom() {
      this.setSom({
        customersCount: this.totalCustomersCount,
        value: this.calculatedSom,
      });
    },
    somYears: debounce(function () {
      this.updateData({ key: 'description', value: this.somYears });
    }, 600),
    // 'marketPotentialData.description': function () {
    //   this.somYears = this.marketPotentialData.description;
    // },
  },
  methods: {
    ...mapMutations({
      setSom: 'company/setSom',
    }),
    onDataFetch() {
      this.getCustomers();
    },
    onDataCreate() {
      this.setSom({ customersCount: 0, value: 0 });
    },
    onDescriptionBlur(e) {
      console.log(e.target.value);
    },
    async getCustomers() {
      this.isFetchingData = true;
      const res = await getMarketPotentialCustomers(
        this.marketPotentialData.id
      );
      this.customers = cloneDeep(res.data.market_potential_customer);
      this.isFetchingData = false;
      this.setSom({
        customersCount: this.totalCustomersCount,
        value: this.calculatedSom,
      });
    },
    async onCustomerAdd(customer) {
      try {
        const res = await addMarketPotentialCustomer({
          marketPotentialId: this.marketPotentialData.id,
          ...customer,
        });
        this.customers.push(
          cloneDeep(res.data.insert_market_potential_customer_one)
        );
        await this.getCustomers();
      } catch (e) {
        console.log(e);
      }
    },
    async onCustomerUpdate({ key, value, index }) {
      try {
        const set = { [key]: value };
        const id = this.customers[index].id;
        const res = await updateMarketPotentialCustomer({ id, set });
        this.customers[index][key] =
          res.data.update_market_potential_customer_by_pk[key];
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
