<template>
  <c-box id="market-strategy">
    <TaskCard
      title="Go to Market Strategy"
      :description="'Whats you go to market strategy?'"
      :hasVisibility="true"
      :hasNotification="true"
      :hasImportance="true"
      :hasAssumption="false"
      :canAssign="true"
      :hasProgress="true"
      :hasFooter="true"
      :hasComments="true"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="marketPotentialData"
      :accessKey="'market_potential_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'market_potential_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="go_to_market_strategy"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
      <MarketStrategyFields
        v-else
        title="Go to Market Strategy"
        :disabled="disabled"
        :marketStrategyData="marketPotentialData"
        @update="updateData"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
import TaskCard from './../TaskCard.vue';
import MarketStrategyFields from './MarketStrategyFields';

import marketPotentialMixin from '@/mixins/market-potential.js';

export default {
  mixins: [marketPotentialMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    MarketStrategyFields,
    ContentLoader,
  },
  data() {
    return {
      marketPotentialType: 'go_to_market_strategy',
      hasMounted: false,
    };
  },
};
</script>

<style></style>
