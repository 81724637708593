import {
  addRiskAnalysisMutation,
  addRiskAssessmentComponentAccessMutation,
  addRiskAssessmentValueMutation,
  createRiskAssessmentComponentMutation,
  removeRiskAssessmentComponentAccessMutation,
  updateRiskAnalysisMutation,
  updateRiskAssessmentComponentMutation,
  updateRiskAssessmentComponentVisibilityMutation,
  updateRiskAssessmentValueMutation,
  deleteRiskAnalysisMutation,
} from '../graphql/mutations';
import { getRiskAssessmentComponentQuery } from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const getRiskAssessmentComponent = ({ type, companyId }) => {
  return apolloInstance.query({
    query: getRiskAssessmentComponentQuery,
    variables: { type, companyId },
    fetchPolicy: 'no-cache',
  });
};

export const createRiskAssessmentComponent = (data) => {
  return apolloInstance.mutate({
    mutation: createRiskAssessmentComponentMutation,
    variables: { object: data },
  });
};

export const updateRiskAssessmentComponent = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateRiskAssessmentComponentMutation,
    variables: { id, set },
  });
};

export const addRiskAssessmentComponentAccess = (data) => {
  return apolloInstance.mutate({
    mutation: addRiskAssessmentComponentAccessMutation,
    variables: { object: data },
  });
};

export const removeRiskAssessmentComponentAccess = (id) => {
  return apolloInstance.mutate({
    mutation: removeRiskAssessmentComponentAccessMutation,
    variables: { id },
  });
};

export const updateRiskAssessmentComponentVisibility = ({
  addVisibility,
  removedVisibilities,
}) => {
  return apolloInstance.mutate({
    mutation: updateRiskAssessmentComponentVisibilityMutation,
    variables: { addVisibility, removedVisibilities },
  });
};

export const addRiskAssessmentValue = (data) => {
  return apolloInstance.mutate({
    mutation: addRiskAssessmentValueMutation,
    variables: { object: data },
  });
};

export const updateRiskAssessmentValue = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateRiskAssessmentValueMutation,
    variables: { id, set },
  });
};

export const addRiskAnalysis = (data) => {
  return apolloInstance.mutate({
    mutation: addRiskAnalysisMutation,
    variables: { object: data },
  });
};

export const updateRiskAnalysis = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateRiskAnalysisMutation,
    variables: { id, set },
  });
};

export const deleteRiskAnalysis = (id) => {
  return apolloInstance.mutate({
    mutation: deleteRiskAnalysisMutation,
    variables: { id },
  });
};
