var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"mt":"auto","w":"100%"}},[_c('carousel',{ref:"carousel",attrs:{"paginationEnabled":false,"navigationEnabled":true,"perPage":3,"navigationPrevLabel":'◀◀',"navigationNextLabel":'▶▶'},on:{"pageChange":_vm.handleSlide}},[_vm._l((_vm.slides),function(slide,slideIndex){return [_c('slide',{key:slide.label},[_c('c-pseudo-box',{attrs:{"cursor":"pointer","h":"100px","w":"150px","position":"relative","overflow":"hidden","borderRadius":"lg"},on:{"click":function($event){return _vm.handleSlide(slideIndex, slide)}}},[_c('c-flex',{attrs:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","backgroundImage":_vm.currentPage === slideIndex
                ? `linear-gradient(
                              to right top,
                             rgba(15, 198, 148, 1),
                              rgba(15, 198, 148, 1)
                          )`
                : `linear-gradient(
                              to right top,
                              rgba(0, 0, 0, 0.7),
                              rgba(0, 0, 0, 0.7)
                          )`,"justify":"center","align":"center"}},[_c('c-text',{attrs:{"color":"#fff","text-align":"center","fontWeight":"600"}},[_vm._v(_vm._s(slide.label))])],1)],1)],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }