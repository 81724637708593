import { render, staticRenderFns } from "./MarketPotentialGrid.vue?vue&type=template&id=5285ab1d&scoped=true"
import script from "./MarketPotentialGrid.vue?vue&type=script&lang=js"
export * from "./MarketPotentialGrid.vue?vue&type=script&lang=js"
import style0 from "./MarketPotentialGrid.vue?vue&type=style&index=0&id=5285ab1d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5285ab1d",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CText: require('@chakra-ui/vue').CText, CLink: require('@chakra-ui/vue').CLink, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable})
