import { render, staticRenderFns } from "./ValuesList.vue?vue&type=template&id=0f58136c&scoped=true"
import script from "./ValuesList.vue?vue&type=script&lang=js"
export * from "./ValuesList.vue?vue&type=script&lang=js"
import style0 from "./ValuesList.vue?vue&type=style&index=0&id=0f58136c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f58136c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CTag: require('@chakra-ui/vue').CTag, CLink: require('@chakra-ui/vue').CLink, CBox: require('@chakra-ui/vue').CBox, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CText: require('@chakra-ui/vue').CText})
