<template>
  <c-flex h="100%" mx="-3.5em">
    <c-box
      bg="#fff"
      h="100%"
      w="300px"
      boxShadow="md"
      pt="5"
      px="4"
      v-if="isEdit"
    >
      <c-heading fontSize="lg" as="h3" mb="5">Presentation Settings</c-heading>
      <c-box mb="5">
        <!-- <c-input v-model="presentation.title" /> -->
      </c-box>
      <c-pseudo-box
        mb="3"
        py="2"
        px="4"
        @click.self="onColorPickerWrapperClick"
        display="flex"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="4px"
        alignItems="center"
        class="color-picker"
        justifyContent="space-between"
      >
        <c-pseudo-box @click.self="onColorPickerWrapperClick" mr="3"
          >Primary color:</c-pseudo-box
        >
        <v-swatches
          ref="swatchRef"
          v-model="configForm.color"
          @input="updateColor"
          :swatches="'text-advanced'"
        ></v-swatches>
      </c-pseudo-box>
      <c-pseudo-box
        mb="3"
        py="2"
        px="4"
        @click.self="onColorPickerWrapperClick"
        display="flex"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="4px"
        alignItems="center"
        class="color-picker"
        justifyContent="space-between"
      >
        <c-pseudo-box @click.self="onColorPickerWrapperClick" mr="3"
          >Background:</c-pseudo-box
        >
        <v-swatches
          ref="swatchRef"
          v-model="configForm.backgroundColor"
          @input="updateStatePresentation"
          :swatches="'text-advanced'"
        ></v-swatches>
      </c-pseudo-box>
      <c-box>
        <FontSelect />
      </c-box>

      <c-box
        borderWidth="1px"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt="3"
        py="2"
        px="2"
      >
        <span @click="decreaseFontsize">
          <c-icon ml="2" name="minus"></c-icon>
        </span>

        <span>{{ configForm.fontSize }}px</span>
        <span @click="increaseFontsize">
          <c-icon ml="2" name="add"></c-icon>
        </span>
      </c-box>
    </c-box>
    <c-flex
      :maxWidth="isEdit ? 'calc(100% - 300px)' : '100%'"
      direction="column"
      :mx="!isShareView ? '5' : ''"
      h="100%"
      flex="1"
    >
      <c-flex v-if="!isShareView" justify="center" align="center" pb="5" mt="5">
        <c-button
          variant-color="vc-orange"
          variant="outline"
          :mb="{ base: '6', lg: '0' }"
          @click="onShareClick"
          v-if="isEdit"
          >Share</c-button
        >

        <c-button
          mx="3"
          color="#0FC694"
          variant-color="#0FC694"
          variant="outline"
          :mb="{ base: '6', lg: '0' }"
          @click="onPreviewClick"
          v-if="isEdit"
          >Preview</c-button
        >

        <c-button
          color="primary"
          variant-color="primary"
          variant="outline"
          :mb="{ base: '6', lg: '0' }"
          @click="onEditClick"
          v-if="!isEdit && !isShareView"
          >Edit</c-button
        >

        <c-button
          mx="3"
          color="primary"
          variant-color="primary"
          variant="outline"
          v-if="!isShareView"
          :mb="{ base: '6', lg: '0' }"
          @click="onPresentClick"
          >Present</c-button
        >

        <c-button
          mx="3"
          color="primary"
          variant-color="primary"
          variant="outline"
          :mb="{ base: '6', lg: '0' }"
          @click="onDownloadClick"
          >Download</c-button
        >
      </c-flex>

      <c-box
        mb="5"
        maxH="100%"
        :h="isShareView && !isDownload ? '82vh' : '100%'"
        w="100%"
        minW="100%"
        bg="#fff"
        boxShadow="md"
        :mt="!isShareView ? '5' : ''"
        class="vc-slide-container"
        position="relative"
      >
        <component
          :is="`VCDefault1Template`"
          v-bind="{ isEdit, isShareView }"
          ref="slideShow"
          @slideChanged="goToSlide"
        />
      </c-box>
      <c-flex justify="center" align="center" pb="5" v-if="!isDownload">
        <!-- <c-tooltip has-arrow label="Present" placement="right"> -->
        <c-icon-button
          variant-color="vc-orange"
          color="#fff"
          v-if="isShareView"
          @click="onPresentClick"
          variant="solid"
          ariaLabel="chalkboard"
          isRound
          mr="5"
          size="md"
          icon="chalkboard"
        >
          <c-icon name="chalkboard" />

          Present
        </c-icon-button>

        <c-icon-button
          class="slideNavBtn"
          mr="14"
          aria-label="left"
          variant-color="black"
          variant="link"
          icon="chevron-left"
          @click="previousSlide()"
        />
        <c-box w="55%" maxWidth="60%">
          <SlideSelector
            @changeSlide="changeSlide"
            ref="slideSelector"
            :goToSlide="goToSlide"
            :slides="slides"
          />
        </c-box>
        <c-icon-button
          class="slideNavBtn"
          ml="12"
          variant-color="black"
          variant="link"
          aria-label="right"
          icon="chevron-right"
          @click="nextSlide()"
        />
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import { EventBus } from '@/eventBus.js';
import VSwatches from 'vue-swatches';
// import VCDefaultTemplate from './components/templates/VCDefault/Index.vue';
import {
  getPitchDeckData,
  getPresentation,
  updatePresentation,
} from '@/services/presentation';
// import { fetchExternalComponent } from '@/helpers/utils';

import { mapState, mapMutations } from 'vuex';
import FontSelect from './components/FontSelect.vue';
import { updateCompany } from '@/services/company';
import SlideSelector from './components/SlideSelector.vue';
import { updateMarketPotentialComponent } from '@/services/market-potential';
import { getCosts } from '../../../services/business-model';
import { updateFoundationType } from '../../../services/foundation';
import { getCompetitionComponent } from '../../../services/competition';
import { uploadFile } from '../../../services/common';
import VCDefault1Template from './components/templates/VCDefault/Index.vue';

export default {
  provide: {
    updateCompany,
    updatePresentation,
    updateMarketPotentialComponent,
    getCompetitionComponent,
    getCosts,
    updateFoundationType,
    uploadFile,
  },
  components: {
    VSwatches,
    FontSelect,
    SlideSelector,
    VCDefault1Template,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  async beforeRouteEnter(to, from, next) {
    const presentationId = to.query.id || to.params.id;
    try {
      const res = await getPresentation(presentationId);
      let dataRes;
      const { type, companyId } = res.data.presentation_by_pk;

      switch (type) {
        case 'pitch_deck':
          {
            dataRes = await getPitchDeckData(companyId);
          }
          break;
        case 'foundation':
          {
            dataRes = await getPitchDeckData(companyId);
          }
          break;
        default:
          break;
      }
      next((vm) => {
        vm.setPresentation(res.data.presentation_by_pk);
        vm.setPresentationData({
          ...dataRes.data.company_by_pk,
          competition: dataRes.data.competition,
          marketPotential: dataRes.data.market_potential,
          tractions: dataRes.data.milestones,
          members: dataRes.data.team_members,
          comparison: dataRes.data.comparison,
          vision: dataRes.data.vision[0]?.description,
          belief: dataRes.data.belief[0]?.description,
          companySdg: dataRes.data.company_sdg[0],
          culture: dataRes.data.culture[0],
        });
      });
    } catch (e) {
      next((vm) => {
        vm.hasFetchingError = true;
      });
    }
  },
  data() {
    return {
      color: null,
      currentIndex: 0,
      hasFetchingError: false,
      isFullScreen: false,
      isKeyboardNavigationActive: true,
      isFetchingTemplate: false,
      templateComponent: null,
      configForm: {
        color: null,
        font: null,
        fontSize: 15,
        backgroundColor: null,
        marginTop: 20,
      },
    };
  },
  mounted() {
    this.getTemplate();
    this.setForm();

    // go back to presentation home if the current company changes
    if (this.activeCompany?.id !== this.presentation.companyId) {
      this.$router.push({ name: 'presentation' });
    }
  },
  computed: {
    ...mapState('presentation', {
      presentation: (state) => state.activePresentation,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      isShareView: (state) => state.isShareView,
    }),
    slideShowRef() {
      return this.$refs.slideShow;
    },
    isDownload() {
      return this.$route.query['print-pdf'];
    },
    slides() {
      const res = {
        foundation: [
          { label: 'Goals', value: 'goals' },
          { label: 'Values', value: 'values' },
          { label: 'Culture', value: 'culture' },
          { label: 'Market Goals', value: 'market_goals' },
          { label: 'Impact Profile', value: 'impact_profile' },
          { label: 'Traction', value: 'traction' },
          { label: 'Skills', value: 'skills' },
          { label: 'Team', value: 'team' },
          { label: 'Advisory Board', value: 'advisory_board' },
        ],
        pitch_deck: [
          { label: 'Elevator Pitch', value: 'elevator' },
          { label: 'Problem', value: 'problem' },
          { label: 'Solution', value: 'solution' },
          { label: 'How It Works', value: 'how_it_works' },
          { label: 'Value Proposition', value: 'value_proposition' },
          { label: 'Target Customers', value: 'target_customers' },
          { label: 'Business model', value: 'business_model' },
          { label: 'Market Size', value: 'market_size' },
          { label: 'Competition', value: 'competition' },
          {
            label: 'Point Of Differentiation',
            value: 'point_of_differentiation',
          },
          { label: 'Go to market strategy', value: 'go_to_market_strategy' },
          { label: 'Market goals', value: 'market_goals' },
          { label: 'Traction', value: 'traction' },
          { label: 'Team', value: 'team' },
          { label: 'Advisory Board', value: 'advisory_board' },
          { label: 'Capital', value: 'capital' },
          { label: 'Financials', value: 'financials' },
          { label: 'Goals', value: 'goals' },
        ],
      };
      return res[this.presentation.type];
    },
  },
  methods: {
    ...mapMutations({
      setPresentation: 'presentation/setPresentation',
      setPresentationData: 'presentation/setPresentationData',
      updatePresentation: 'presentation/updatePresentation',
    }),
    async copyURL(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$toast({
          title: 'Success!',
          description: `Link Copied Successfully`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      } catch ($e) {
        this.$toast({
          title: 'Error!!!',
          description: `Something went wrong, please retry`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    onPreviewClick() {
      const id = this.$route.params.id;

      this.$router.push({
        name: 'ViewPresentation',
        params: {
          id,
        },
      });
    },

    onShareClick() {
      const id = this.$route.params.id;

      this.copyURL(
        `${window.location.origin}/app/share?id=${id}&company_id=${this.activeCompany.id}&page=presentation`
      );
    },

    async onPresentClick() {
      this.isKeyboardNavigationActive = true;
      this.$forceUpdate();
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        const element = this.slideShowRef.$el;
        if (element.requestFullscreen) {
          await element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          await element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          await element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (element.msRequestFullscreen) {
          await element.msRequestFullscreen();
        }
      }
    },
    onEditClick() {
      const id = this.$route.params.id;

      this.$router.replace({
        name: 'EditPresentation',
        params: {
          id,
        },
      });
    },
    onColorPickerWrapperClick() {
      this.$refs.swatchRef.$el.querySelector('.vue-swatches__trigger').click();
    },
    // getTemplate() {
    //   console.log('env', process.env.NODE_ENV);
    //   const baseUrl =
    //     process.env.VUE_APP_TEMPLATE_URL || 'http://localhost:8200';
    //   this.isFetchingTemplate = true;
    //   const type = this.getTypeFolder(this.presentation.type);
    //   // .${this.presentation.theme.hash}
    //   const url = `${baseUrl}/${type}/${this.presentation.theme.slug}/${
    //     this.presentation.theme.slug
    //   }${
    //     process.env.NODE_ENV === 'development'
    //       ? '.' + this.presentation.theme.hash
    //       : '.' + this.presentation.theme.hash
    //   }.umd.min.js`;

    //   console.log('url', url);
    //   this.templateComponent = () => window['http://localhost:8201/'];
    // },
    getTypeFolder(str) {
      return str
        .split('_')
        .map((_str) => this.capitalize(_str))
        .join('');
    },
    setForm() {
      this.configForm.color = this.presentation.data.color;
    },
    async updateColor() {
      const prevColor = this.presentation.data.color;
      this.updatePresentation({
        data: { ...this.presentation.data, color: this.configForm.color },
      });
      try {
        await updatePresentation({
          id: this.presentation.id,
          append: { data: { color: this.configForm.color } },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, color: prevColor },
        });
        this.configForm.color = prevColor;
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    updateStatePresentation() {
      EventBus.$emit(this.currentSlideName, this.configForm);
    },
    decreaseFontsize() {
      this.configForm.fontSize = this.configForm.fontSize - 2;
      this.updateStatePresentation();
    },
    increaseFontsize() {
      this.configForm.fontSize = this.configForm.fontSize + 2;
      this.updateStatePresentation();
    },
    MarginSizeTop() {
      this.configForm.marginTop = this.configForm.marginTop + 50;
      this.updateStatePresentation();
    },
    MarginSizeBottom() {
      this.configForm.marginBottom = this.configForm.marginBottom - 50;
      this.updateStatePresentation();
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async nextSlide() {
      await this.slideShowRef?.nextSlide();
      this.$refs.slideSelector.onPageChange(
        this.slideShowRef.currentSlideIndex - 1
      );
    },

    async previousSlide() {
      await this.slideShowRef?.previousSlide();
      this.$refs.slideSelector.onPageChange(
        this.slideShowRef.currentSlideIndex - 1
      );
    },
    goToSlide(index) {
      // console.log(index);
      // const newSlideIndex = index + 1;
      // const direction =
      //   newSlideIndex > this.slideShowRef?.currentSlideIndex ? 'next' : 'prev';
      // this.slideShowRef?.changeDirection(direction);
      // this.$nextTick(() => {
      //   this.slideShowRef.currentSlideIndex = newSlideIndex;
      this.$refs.slideSelector.onPageChange(index);
      // });
    },
    changeSlide(index, slide) {
      this.currentSlideName = slide.value;
      this.slideShowRef?.setSlide(index);
      this.configForm.fontSize = this.presentation.data[this.currentSlideName]
        .fontSize
        ? this.presentation.data[this.currentSlideName].fontSize
        : 30;
      this.configForm.backgroundColor =
        this.presentation.data[this.currentSlideName].backgroundColor;
    },
    async onDownloadClick() {
      //
      const { id } = this.$route.params;

      window.open(
        `${window.location.origin}/app/share?id=${id}&company_id=${this.activeCompany.id}&page=presentation&print-pdf`
      );

      await this.slideShowRef?.exportPdf();
    },
  },
  watch: {
    // 'slideShowRef.currentSlideIndex'(v) {
    //   console.log(v);
    // },
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  ::v-deep {
    .vue-swatches {
      height: 25px;
      &__trigger {
        width: 70px !important;
        height: 25px !important;
        border-radius: 8px !important;
      }
    }
  }
}

button {
  &[data-chakra-component='CIconButton'] {
    svg {
      width: 2em;
      height: 2em;
    }
  }
}
</style>
