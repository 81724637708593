<template>
  <c-box>
    <TaskCard
      :title="title || 'Target Market'"
      :description="''"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="true"
      :disabled="disabled"
      :taskData="customerSegmentData"
      @update="updateData"
      explanationKey="customer_segment"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
      <CustomerList
        v-else
        :customerSegment="customerSegmentData"
        :disabled="disabled"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';

import { ContentLoader } from 'vue-content-loader';

import TaskCard from '../TaskCard.vue';
import CustomerList from './CustomerList.vue';

import {
  createBusinessModelByType,
  getBusinessModelByType,
  updateBusinessModel,
} from '@/services/business-model.js';

export default {
  props: ['disabled', 'title'],
  components: { TaskCard, CustomerList, ContentLoader },
  data() {
    return {
      customerSegmentData: {},
      businessModelType: 'customer_segment',
      prevData: {},
      isFetchingData: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  created() {
    this.getCustomerSegmentData();
  },
  methods: {
    getCustomerSegmentData() {
      this.isFetchingData = true;
      getBusinessModelByType({
        type: this.businessModelType,
        companyId: this.activeCompany.id,
      }).then((res) => {
        this.isFetchingData = false;
        if (res.data.business_model.length) {
          this.customerSegmentData = res.data.business_model[0];
          this.prevData = res.data.business_model[0];
        } else {
          this.setCustomerSegmentSeedData();
        }
      });
    },
    setCustomerSegmentSeedData() {
      this.isFetchingData = true;
      const data = {
        type: this.businessModelType,
        description: '',
        companyId: this.activeCompany.id,
        assumption: 'unverified',
      };
      createBusinessModelByType(data).then((res) => {
        this.customerSegmentData = res.data.insert_business_model_one;
        this.prevData = res.data.insert_business_model_one;
        this.isFetchingData = false;
      });
    },
    updateData({ key, value }) {
      const set = { [key]: value };
      updateBusinessModel({
        type: this.businessModelType,
        companyId: this.activeCompany.id,
        set,
      })
        .then(() => {
          this.customerSegmentData[key] = value;
        })
        .catch(() => {
          this.customerSegmentData[key] = this.prevData[key];
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating business model, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style></style>
