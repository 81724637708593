<template>
  <div class="importance">
    <v-select
      :clearable="false"
      :searchable="false"
      label="label"
      :reduce="(essence) => essence.value"
      class="vc-select importance__select"
      v-model="essence"
      :options="essenceLevels"
      placeholder="Select essence"
      :disabled="disabled"
      appendToBody
      :calculate-position="withPopper"
    >
      <template #selected-option="{ label, color, colorRgba }">
        <div
          class="importance__select__option"
          v-chakra="{ color, backgroundColor: colorRgba }"
        >
          <svg v-chakra="{ fill: color }">
            <use href="@/assets/icons/flag-line.svg#flag"></use>
          </svg>
          {{ label }}
        </div>
      </template>
      <template v-slot:option="{ label, color }">
        <span class="importance__select__item" v-chakra="{ color }">
          <svg v-chakra="{ fill: color }">
            <use href="@/assets/icons/flag-line.svg#flag"></use>
          </svg>
          <span class="text-black">
            {{ label }}
          </span>
        </span>
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"></span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  props: ['disabled', 'value'],
  data() {
    return {
      essence: '',
      placement: 'top',
      essenceLevels: [
        {
          label: 'Critical Essential',
          value: 'CRITICAL_ESSENTIAL',
          color: '#37d5aa',
        },
        { label: 'Very Essential', value: 'VERY_ESSENTIAL', color: '#56b5db' },
        { label: ' Essential', value: 'ESSENTIAL', color: '#2d2d2d' },
        { label: 'Less Essential', value: 'LESS_ESSENTIAL', color: '#ef5323' },
        {
          label: 'Least Essential',
          value: 'LEAST_ESSENTIAL',
          color: '#ee2f1f',
        },
      ],
    };
  },
  created() {
    if (this.value) {
      this.essence = this.value;
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width.toString();
      dropdownList.style.zIndex = (500000000).toString(); // Need to be larger than the z-index of the modal

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,

        modifiers: [
          {
            name: 'offset',
            options: {
              // We offset be window.height since it for some reason spawn outside the viewport
              // I suspect this is because we have an application where all views are supposed to be 100vh
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => {
        popper.destroy();
      };
    },
  },
  watch: {
    value() {
      this.essence = this.value;
    },
    essence() {
      if (this.essence !== this.value) {
        this.$emit('input', this.essence);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.importance {
  &__select {
    width: 250px;
    ::v-deep {
      .vs__dropdown-toggle[role='combobox'] {
        @apply text-xs;
        height: 35px;
        width: 100%;
        min-width: 120px;
        border: none;
        padding: 0;
      }
      .vs__dropdown-menu {
        min-width: 100px;
        z-index: 99999999999 !important;
      }
      .vs__selected {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      .vs__dropdown-option {
        height: 35px;
      }
      .vs__actions {
        padding: 0;
      }
      .vs__selected-options {
        input {
          font-weight: 600;
          color: #ef5323;
          height: 30px;
        }
      }
    }
    &.vs--disabled {
      ::v-deep {
        .vs__dropdown-toggle {
          background: transparent;
        }
      }
    }
    &__option,
    &__item {
      @apply h-full w-full font-medium flex items-center justify-center;
      border-radius: 8px;
      padding: 16px;
      margin-top: 15px;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    &__item {
      @apply justify-start text-xs;
    }
  }
}
</style>
