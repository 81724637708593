<template>
  <c-flex my="2em" wrap="wrap">
    <GridTable
      title="Key Activities"
      :columns="tableColumns"
      :addRowLabel="'Add new activity'"
      :onAddRow="addActivity"
      :disabled="disabled"
    >
      <tr
        v-for="(activity, index) in activities"
        :key="activity.id || activity.tempId"
      >
        <td class="grid__table__body__cell">
          <c-flex h="100%" justify="center" align-items="center">
            <c-popover placement="bottom" v-slot="{}">
              <c-popover-trigger cursor="pointer">
                <c-box
                  my="2"
                  v-chakra="{
                    height: 'auto',
                  }"
                >
                  <svg
                    v-chakra="{
                      width: '10px',
                      height: '16px',
                      fill: '#ef5323',
                    }"
                  >
                    <use href="@/assets/icons/vertical-dots.svg#dots" />
                  </svg>
                </c-box>
              </c-popover-trigger>
              <c-popover-content maxWidth="150px" zIndex="modal">
                <c-popover-body p="0">
                  <c-list fontSize="sm">
                    <c-list-item v-if="activity.id">
                      <c-box
                        cursor="pointer"
                        w="100%"
                        px="2"
                        py="2"
                        @click="deleteKeyActivity(index)"
                        >Delete</c-box
                      >
                    </c-list-item>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>

            <c-flex
              direction="column"
              align="center"
              textAlign="center"
              flex="1"
              ml="3"
              mr="auto"
            >
              <c-editable
                fontSize="lg"
                maxWidth="100%"
                fontWeight="600"
                my="2"
                :default-value="activity.name"
                :isDisabled="disabled"
                @change="onNameInputChange($event, index)"
              >
                <c-editable-preview
                  ref="activityTitle"
                  maxWidth="100%"
                  overflowWrap="break-word"
                />
                <c-editable-input @blur="onFieldBlur(index)" />
              </c-editable>
              <c-text
                v-if="!activity.name"
                color="vc-orange.400"
                fontSize="8px"
              >
                Name is required to add activity
              </c-text>
            </c-flex>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-box w="100%" h="100%" pt="4">
            <v-select
              :clearable="false"
              :searchable="false"
              class="vc-select"
              :reduce="(activityType) => activityType.value"
              v-model="activity.type"
              label="label"
              :options="activityTypes"
              default-value="PRODUCTION"
              placeholder="select"
              :disabled="disabled"
              @input="
                () => {
                  onFieldBlur(index);
                }
              "
            ></v-select>
          </c-box>
        </td>
        <td class="grid__table__body__cell">
          <EfficiencySelect
            :placeholder="'Select Efficiency'"
            v-model="activity.efficiency"
            @input="
              () => {
                onFieldBlur(index);
              }
            "
          />
        </td>
        <td class="grid__table__body__cell">
          <div v-if="showSaved(index)">
            <c-text as="span" color="#ef5323" fontSize="14px" v-if="saved"
              ><b>Saved successfully 👍</b></c-text
            >
          </div>

          <EditableText
            :type="`Key Activities${index}`"
            title="Key Activities"
            :showTitle="false"
            v-model="activity.description"
            @updateDescription="updateDescription"
            :editabelIndex="index"
            :disabled="disabled"
          />
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <AppSelect
              :options="teamMembers"
              label="id"
              placeholder="Select team member"
              :reduce="(member) => member.id"
              v-model="activity.employeeResponsible"
              :reset="true"
              :hasShadow="true"
              indicator-fill="orange"
              color="dark"
              size="large"
              :appendToBody="true"
              @input="
                (e) => {
                  onFieldBlur(index);
                }
              "
            >
              <template v-slot:option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
              <template v-slot:selected-option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
            </AppSelect>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <c-flex justify="space-between">
              <AppSelect
                :options="plans"
                label="label"
                :reduce="(plan) => plan.value"
                :value="activity.actionPlan"
                :reset="true"
                :hasShadow="true"
                indicator-fill="orange"
                color="dark"
                size="large"
                :appendToBody="true"
                @input="onActionPlanChange($event, index)"
              />
              <c-spinner
                v-if="isUpdatingPlan"
                thickness="1px"
                color="blue.500"
              ></c-spinner>
            </c-flex>
          </c-flex>
          <MilestoneCell
            v-if="activity.milestone"
            :milestone="activity.milestone"
            ref="milestoneCell"
            @removeMilestone="onRemoveMilestone(index)"
          />
        </td>
      </tr>
    </GridTable>
    <!-- <c-flex
      :w="{ base: '100%', lg: '250px' }"
      h="255px"
      :mr="{ base: '0px', lg: '1em' }"
      mb="1.5em"
      cursor="pointer"
      borderColor="#2d2d2d4d"
      borderRadius="4px"
      borderWidth="1px"
      direction="column"
      justify="center"
      align="center"
      tabindex="-1"
      v-if="!disabled"
    >
      <c-pseudo-box
        as="a"
        display="flex"
        h="100%"
        :_hover="{ textDecoration: 'none' }"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        @click="addActivity"
      >
        <svg
          v-chakra="{
            width: '30px',
            height: '30px',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
        </svg>
        <c-text color="vc-orange.400" fontWeight="600" fontSize="lg">
          Add Activities
        </c-text>
      </c-pseudo-box>
    </c-flex>
    <c-flex
      :w="{ base: '100%', lg: '250px' }"
      h="255px"
      :mr="{ base: '0px', lg: '1em' }"
      mb="1.5em"
      :borderColor="getActivityColor(activity.efficiency)"
      borderRadius="4px"
      borderWidth="1px"
      direction="column"
      v-for="(activity, index) in activities"
      :key="activity.id || activity.tempId"
      paddingTop="25px"
      paddingInline="20px"
      class="activity"
      position="relative"
    >
      <c-popover placement="bottom" v-if="!disabled">
        <c-popover-trigger
          position="absolute"
          right="15px"
          top="15px"
          cursor="pointer"
        >
          <c-box>
            <svg
              v-chakra="{
                width: '10px',
                height: '16px',
                fill: '#ef5323',
              }"
            >
              <use href="@/assets/icons/vertical-dots.svg#dots"></use>
            </svg>
          </c-box>
        </c-popover-trigger>
        <c-popover-content maxWidth="150px">
          <c-popover-body>
            <c-list>
              <c-list-item
                ><c-pseudo-box
                  cursor="pointer"
                  w="100%"
                  @click="deleteKeyActivities(index)"
                >
                  Delete
                </c-pseudo-box></c-list-item
              >
            </c-list>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
      <c-flex direction="column">
        <v-select
          :clearable="false"
          :searchable="false"
          class="vc-select"
          :reduce="(activityType) => activityType.value"
          v-model="activity.type"
          label="label"
          :options="activityTypes"
          default-value="EFFICIENT"
          placeholder="select"
          :disabled="disabled"
          @input="
            () => {
              onFieldBlur(index);
            }
          "
        ></v-select>
        <c-flex>
          <EfficiencySelect
            :placeholder="'Select Efficiency'"
            v-model="activity.efficiency"
            @input="
              () => {
                onFieldBlur(index);
              }
            "
          />
        </c-flex>
      </c-flex>
      <c-editable
        fontSize="lg"
        maxWidth="100%"
        fontWeight="600"
        my="2"
        :default-value="activity.name"
        :isDisabled="disabled"
        @change="onNameInputChange($event, index)"
      >
        <c-editable-preview
          ref="activityTitle"
          maxWidth="100%"
          overflowWrap="break-word"
        />
        <c-editable-input @blur="onFieldBlur(index)" />
      </c-editable>
      <c-text v-if="!activity.isNameValid" color="vc-orange.400" fontSize="8px">
        Name is required to add activity
      </c-text>
      <ListTextInput
        v-chakra="{ maxHeight: 'calc(100% - 65px)' }"
        :title="''"
        :showTitle="false"
        :value="activity.description"
        @blur="onListInputBlur($event, index)"
        :disabled="disabled"
      />
    </c-flex> -->

    <c-modal
      :is-open="isMilestoneModalOpen"
      :on-close="closeMilestoneModal"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            Add
            <c-text textTransform="capitalize" ml="1"> Milestone </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <MilestoneModal @onAddMilestone="onAddMilestone" />
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <MilestoneAchieved
          :milestoneId="milestoneId"
          @closeModal="closeAchievedDialog"
          @onAchievedMilestone="onAchievedMilestone"
        />
      </c-alert-dialog-content>
    </c-alert-dialog>
    <TeamDrawer
      title="Key Activities"
      :isOpen="isTeamDrawerOpen"
      @close="closeTeamDrawer"
      v-if="isTeamDrawerOpen"
    />
  </c-flex>
</template>

<script>
import { generateID } from '@/helpers/data';
import {
  addKeyActivities,
  deleteKeyActivities,
  getKeyActivities,
  updateKeyActivities,
} from '@/services/business-model';

import EditableText from '../EditableText.vue';
import EfficiencySelect from '../EfficiencySelect.vue';
import GridTable from '../GridTable.vue';
import TeamDrawer from '@/views/App/components/OpenAiDrawer.vue';
import cloneDeep from 'lodash.clonedeep';

import { mapState } from 'vuex';
import AppSelect from '@/views/App/components/AppSelect.vue';
import MilestoneCell from './MilestoneCell.vue';
import MilestoneModal from './MilestoneModal.vue';
import MilestoneAchieved from './MilestoneAchieved.vue';

export default {
  props: {
    keyActivitiesData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditableText,
    TeamDrawer,
    EfficiencySelect,
    GridTable,
    AppSelect,
    MilestoneCell,
    MilestoneModal,
    MilestoneAchieved,
  },
  data() {
    return {
      milestoneId: null,
      isAchievedDialogOpen: false,
      isMilestoneModalOpen: false,
      isUpdatingPlan: false,
      saved: false,
      isTeamDrawerOpen: false,
      currentActivityIndex: null,
      activityTypes: [
        { label: 'Production', value: 'PRODUCTION' },
        { label: 'Problem Solving', value: 'PROBLEM_SOLVING' },
        { label: 'Marketing', value: 'MARKETING' },
        {
          label: 'Sales & Customer Service',
          value: 'SALES_CUSTOMER_SERVICE',
        },
      ],
      activityLevels: [
        { label: 'Most Efficient', value: 'MOST_EFFICIENT', color: '#37d5aa' },
        { label: 'Very Efficient', value: 'VERY_EFFICIENT', color: '#56b5db' },
        { label: ' Efficient', value: 'EFFICIENT', color: '#2d2d2d' },
        { label: 'Less Efficient', value: 'LESS_EFFICIENT', color: '#ef5323' },
        {
          label: 'Least Efficient',
          value: 'LEAST_EFFICIENT',
          color: '#ee2f1f',
        },
      ],
      activities: [],
      tableColumns: [
        {
          action: () => null,
          component: null,
          title: 'Name',
          value: 'name',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Type',
          value: 'type',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Efficiency',
          value: 'efficiency',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Description',
          value: 'description',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Employee Responsible',
          value: 'employee',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Action Plan',
          value: 'milestone',
          disabled: false,
        },
      ],
      plans: [
        { label: 'Monitor', value: 'monitor' },
        { label: 'Act', value: 'act' },
        { label: 'Resolved', value: 'resolved' },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      teamMembers: (state) =>
        state.members.filter(
          (member) => member.isAccepted && member.user.isVerified
        ),
    }),
    showSaved() {
      return (index) => {
        return this.saved && this.currentActivityIndex === index;
      };
    },
  },
  created() {
    this.getActivities();
  },
  methods: {
    openAiDrawer(index) {
      this.currentActivityIndex = index;
      this.isTeamDrawerOpen = true;
    },
    closeTeamDrawer(results) {
      if (!results) {
        this.isTeamDrawerOpen = false;
        return;
      }
      if (typeof results === 'object') {
        this.activities[this.currentActivityIndex].name = results.label;
        this.activities[this.currentActivityIndex].description =
          results.description;

        this.onFieldBlur(this.currentActivityIndex);
      }

      this.isTeamDrawerOpen = false;
    },
    closeMilestoneModal() {
      this.isMilestoneModalOpen = false;
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
    },
    updateDescription(val, keyIndex) {
      this.activities[keyIndex].description = val;
      this.currentActivityIndex = keyIndex;
      this.onFieldBlur(keyIndex);
    },
    onActionPlanChange(e, index) {
      switch (e) {
        case 'monitor': {
          this.isUpdatingPlan = true;
          this.activities[index].actionPlan = 'monitor';
          this.activities[index].milestoneId = null;
          this.onFieldBlur(index);
          break;
        }
        case 'act': {
          this.isUpdatingPlan = true;
          this.activities[index].actionPlan = 'act';
          this.currentActivityIndex = index;
          this.isMilestoneModalOpen = true;
          break;
        }
        case 'resolved':
          this.isUpdatingPlan = true;
          this.activities[index].actionPlan = 'resolved';
          this.currentActivityIndex = index;
          this.milestoneId = this.activities[index].milestoneId;
          this.isAchievedDialogOpen = true;
          break;
        default:
          break;
      }
    },
    onAddMilestone(milestoneId) {
      updateKeyActivities({
        id: this.activities[this.currentActivityIndex].id,
        set: {
          milestoneId: milestoneId,
          actionPlan: 'act',
        },
      })
        .then((response) => {
          this.activities[this.currentActivityIndex] =
            response.data.update_key_activity_by_pk;

          const milestone = response.data.update_key_activity_by_pk.milestone;
          this.activities[this.currentActivityIndex].milestone = {
            ...milestone,
          };

          this.isUpdatingPlan = false;
        })
        .catch(() => {});

      this.closeMilestoneModal();
    },
    onAchievedMilestone() {
      updateKeyActivities({
        id: this.activities[this.currentActivityIndex].id,
        set: {
          actionPlan: 'resolved',
        },
      })
        .then((response) => {
          this.activities[this.currentActivityIndex] = cloneDeep(
            response.data.update_key_activity_by_pk
          );
        })
        .catch(() => {});
      this.isUpdatingPlan = false;
      this.closeAchievedDialog();
    },
    getActivities() {
      getKeyActivities(this.keyActivitiesData.id).then((res) => {
        this.activities = res.data.key_activity.map((activity) => {
          return {
            ...activity,

            isNameValid: true,
          };
        });
      });
    },
    getActivityColor(val) {
      return this.activityLevels.find((level) => level.value === val)?.color;
    },
    focusTitle() {
      const titleIndex = this.activities.length - 1;
      this.$nextTick(() => {
        this.$refs['activityTitle'][titleIndex].$el.focus();
      });
    },
    addActivity(results) {
      this.activities.push({
        id: null,
        type: 'PRODUCTION',
        name: results ? results.label : null,
        description: results ? results.description : null,
        efficiency: '',
        isNameValid: true,
        tempId: generateID(5),
        milestoneId: null,
      });
      this.focusTitle();
    },
    onNameInputChange(e, index) {
      if (typeof e === 'string') {
        this.activities[index].name = e;
      }
    },
    // onListInputBlur(index) {
    //   // this.activities[index].description = e;
    //   this.onFieldBlur(index);
    // },
    onFieldBlur(index) {
      const activity = this.activities[index];
      activity.isNameValid = activity.name && activity.name.trim();
      if (activity.isNameValid) {
        this.saveActivities({ ...activity }, index);

        this.saved = true;
        setTimeout(() => {
          this.saved = false;
        }, 3000);
      }
    },
    saveActivities(activity, index) {
      const {
        id,
        name,
        type,
        description,
        efficiency,
        actionPlan,
        employeeResponsible,
        milestoneId,
      } = activity;
      if (id) {
        return updateKeyActivities({
          id,
          set: {
            name,
            type,
            description,
            efficiency,
            actionPlan,
            employeeResponsible,
            milestoneId,
          },
        })
          .then((response) => {
            this.isUpdatingPlan = false;
            this.activities[index] = response.data.update_key_activity_by_pk;
          })
          .catch(() => {});
      } else {
        return addKeyActivities({
          name,
          type,
          description,
          efficiency,
          businessModelId: this.keyActivitiesData.id,
        })
          .then((res) => {
            this.activities[index].id = res.data.insert_key_activity_one.id;
          })
          .catch(() => {
            this.activities.splice(index, 1);
            this.$toast({
              title: 'An error occurred.',
              description: `Error while adding activity, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    deleteKeyActivity(index) {
      const activity = this.activities[index];
      this.activities.splice(index, 1);

      if (activity.id) {
        deleteKeyActivities(activity.id)
          .then(() => {})
          .catch(() => {
            this.activities.splice(index, 0, { ...activity });
            this.$toast({
              title: 'An error occurred.',
              description: `Error while deleting activity, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    onMilestoneAdd(milestone, index) {
      this.saveActivities({
        ...this.activities[index],
        milestoneId: milestone.id,
      }).then(() => {
        this.activities[index].milestone = { ...milestone };
        const milestoneCell = this.$refs.milestoneCell[index];
        milestoneCell.closeMilestoneModal();
        milestoneCell.isAddingMilestone = false;
        milestoneCell.clearMilestoneForm();
      });
    },
    onMilestoneUpdated(milestone, index) {
      this.activities[index].milestone = { ...milestone };
    },
    onRemoveMilestone(index) {
      this.saveActivities({
        ...this.activities[index],
        milestoneId: null,
      }).then(() => {
        this.activities[index].milestone = null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity {
  ::v-deep {
    .vs__open-indicator {
      @apply block;
      fill: $color-orange;
      width: 10px;
      transform: scale(1) translateY(0);
      path {
        d: path(
          'M10.555,13.073l-2.8-2.8.933-.932,1.865,1.865L12.42,9.343l.933.932Z'
        );
        fill: red;
        transform: translate(-7.757px, -9.343px);
      }
    }
    .vs__dropdown-toggle {
      height: 25px;
      width: max-content;
      min-width: 80px;
      font-size: smaller;
      border: none;
      padding: 0;
    }
    .vs__selected {
      color: $color-orange;
      font-weight: bold;
      padding-left: 0px;
      margin-left: 0px;
    }
    .vs__selected-options {
      padding-left: 0px;
    }
    .vs__actions {
      padding: 8px 6px 0 3px;
    }
  }
}
</style>
