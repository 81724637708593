import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      costItems: [],
      capitalData: {
        value: "0",
        funding: "0",
        runaway: "",
      },
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedItems() {
      return this.costItems.filter((item) => item.selected);
    },
  },
  created() {
    this.setCostItems();
  },
  watch: {
    presentation() {
      this.setCostItems();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
      updatePresentationData: "presentation/updatePresentationData",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setCostItems() {
      this.capitalData =
        this.presentation.data.slidesData?.capital || this.capitalData;

      console.log("capital", this.capitalData);
      //   const itemsArray = getItems(this.marketStrategyData.otherDescription);
      //   if (!this.presentation.data.slidesData.marketStrategyKeyItems) {
      //     this.keyItems = [...itemsArray];
      //   } else {
      //     const { marketStrategyKeyItems } = this.presentation.data.slidesData;
      //     this.keyItems = itemsArray?.map((item) => {
      //       const itemInPresentation = marketStrategyKeyItems?.find(
      //         (_item) => _item.text === item.text
      //       );
      //       return {
      //         ...item,
      //         selected: itemInPresentation ? itemInPresentation.selected : false,
      //       };
      //     });
      //   }
    },
    async onCapitalInputBlur() {
      const oldValue = this.presentationData?.capital;
      console.log("test", this.capitalData, oldValue);
      this.updatePresentationData({
        ...this.presentationData,
        capital: this.capitalData,
      });
      try {
        await this.updateCapitalData(this.capitalData);
      } catch (e) {
        console.log(e);
        this.updatePresentationData({
          ...this.presentationData,
          capital: oldValue,
        });
        this.capitalData = oldValue;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating capital details, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
    async updateCapitalData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                capital: { ...data },
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating capital data, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
