<template>
  <c-box>
    <c-grid
      w="100%"
      :template-columns="{ base: '1fr', lg: 'repeat(2, 1fr)' }"
      gap="12"
    >
      <c-box>
        <c-flex justify="space-between" align="center" mb="6">
          <c-heading as="h5" fontSize="lg" color="#495057" fontWeight="600">
            Ideal Market Position
          </c-heading>
        </c-flex>
        <c-box>
          <EditableText
            title="Ideal Market Position"
            :type="title + '1'"
            :value="marketStrategyData.description"
            @updateDescription="updateDescription"
            :disabled="disabled"
          />
        </c-box>
      </c-box>
      <c-box>
        <c-flex justify="space-between" align="center" mb="6">
          <c-heading as="h5" fontSize="lg" color="#495057" fontWeight="600">
            Strategic steps
          </c-heading>
        </c-flex>
        <c-box>
          <EditableText
            title="Strategic steps"
            :type="title + '2'"
            v-model="marketStrategyData.otherDescription"
            @updateDescription="updateOtherDescription"
            :disabled="disabled"
          />
        </c-box>
      </c-box>
    </c-grid>
  </c-box>
</template>

<script>
import EditableText from './../EditableText';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    marketStrategyData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    EditableText,
  },
  methods: {
    updateOtherDescription(val) {
      this.$emit('update', { key: 'otherDescription', value: val });
    },
    updateDescription(val) {
      this.marketStrategyData.description = val;
      this.$emit('update', { key: 'description', value: val });
    },
  },
};
</script>

<style></style>
