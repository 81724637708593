import { render, staticRenderFns } from "./CultureFields.vue?vue&type=template&id=1e8f7673"
import script from "./CultureFields.vue?vue&type=script&lang=js"
export * from "./CultureFields.vue?vue&type=script&lang=js"
import style0 from "./CultureFields.vue?vue&type=style&index=0&id=1e8f7673&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText})
