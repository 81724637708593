import { mapState, mapGetters } from 'vuex';

import {
  addRiskAssessmentComponentAccess,
  createRiskAssessmentComponent,
  getRiskAssessmentComponent,
  removeRiskAssessmentComponentAccess,
  updateRiskAssessmentComponent,
  updateRiskAssessmentComponentVisibility,
} from '@/services/risk-assessment';
import { cloneDeep } from 'lodash';
import { titlecase } from '@/helpers/utils';
import { generateID } from '../helpers/data';

export default {
  data() {
    return {
      riskAssessmentData: {},
      prevData: {},
      isFetchingData: false,
      hasAccess: true,
      isUpdatingMetadata: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner']),
  },
  created() {
    this.getRiskAssessmentData();
  },
  methods: {
    getRiskAssessmentData() {
      this.isFetchingData = true;
      getRiskAssessmentComponent({
        type: this.riskAssessmentType,
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          if (res.data.risk_assessment.length) {
            this.riskAssessmentData = cloneDeep(res.data.risk_assessment[0]);
            this.prevData = cloneDeep(res.data.risk_assessment[0]);
            this.isFetchingData = false;
            if (this.onDataFetch) {
              this.onDataFetch();
            }
          } else {
            this.isFetchingData = false;
            this.setRiskAssessmentSeedData();
          }
        })
        .finally(() => {
          this.hasMounted = true;
        });
    },
    setRiskAssessmentSeedData() {
      this.isFetchingData = true;
      const data = {
        type: this.riskAssessmentType,
        companyId: this.activeCompany.id,
      };
      createRiskAssessmentComponent(data).then((res) => {
        this.riskAssessmentData = cloneDeep(
          res.data.insert_risk_assessment_one
        );
        this.prevData = cloneDeep(res.data.insert_risk_assessment_one);
        this.isFetchingData = false;
      });
    },
    updateData({ key, value }) {
      const set = { [key]: value };
      this.riskAssessmentData[key] = value;
      updateRiskAssessmentComponent({
        id: this.riskAssessmentData.id,
        set,
      })
        .then((res) => {
          if (!res.data.update_risk_assessment_by_pk) {
            throw { error: 'unauthorized' };
          }
        })
        .catch((e) => {
          this.riskAssessmentData[key] = this.prevData[key];
          if (e.error === 'unauthorized') {
            this.$toast({
              title: 'Unauthorized',
              description: `You are not allowed to edit this information`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
            return;
          }
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating  ${titlecase(
              this.riskAssessmentType.split('_').join(' ')
            )} data, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    updateAccess(user) {
      const accessIndex =
        this.riskAssessmentData.risk_assessment_assignments.findIndex(
          (access) => access.user.id == user.id
        );
      if (accessIndex === -1) {
        const tempId = generateID(4);
        this.riskAssessmentData.risk_assessment_assignments.push({
          id: tempId,
          user,
        });
        this.addAccess(user, tempId);
      } else {
        this.removeAccess(accessIndex);
      }
    },
    async addAccess(user, tempId) {
      const data = {
        riskAssessmentId: this.riskAssessmentData.id,
        userId: user.id,
      };
      const accessIndex =
        this.riskAssessmentData.risk_assessment_assignments.findIndex(
          (access) => access.id == tempId
        );
      try {
        const res = await addRiskAssessmentComponentAccess(data);
        this.riskAssessmentData.risk_assessment_assignments[accessIndex] =
          res.data.insert_risk_assessment_assignment_one;
        this.prevData.risk_assessment_assignments = cloneDeep(
          this.riskAssessmentData.risk_assessment_assignments
        );
      } catch (e) {
        this.riskAssessmentData.risk_assessment_assignments = cloneDeep(
          this.prevData.risk_assessment_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while assigning user, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async removeAccess(accessIndex) {
      const access = this.riskAssessmentData.risk_assessment_assignments.splice(
        accessIndex,
        1
      )[0];
      try {
        await removeRiskAssessmentComponentAccess(access.id);
        this.prevData.risk_assessment_assignments = cloneDeep(
          this.riskAssessmentData.risk_assessment_assignments
        );
      } catch (e) {
        this.riskAssessmentData.risk_assessment_assignments = cloneDeep(
          this.prevData.risk_assessment_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing member access, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateVisibility(users) {
      this.isUpdatingMetadata = true;
      const newUsers = users.filter(
        (user) =>
          !this.riskAssessmentData.risk_assessment_visibilities.find(
            (visibility) => visibility.userId === user.id
          )
      );
      const removedVisibilities =
        this.riskAssessmentData.risk_assessment_visibilities.filter(
          (visibility) => !users.find((user) => visibility.userId === user.id)
        );
      const data = {
        addVisibility: newUsers.map((user) => ({
          userId: user.id,
          riskAssessmentId: this.riskAssessmentData.id,
        })),
        removedVisibilities: removedVisibilities.map(
          (visibility) => visibility.id
        ),
      };
      const visibilityData = cloneDeep(
        this.riskAssessmentData.risk_assessment_visibilities
      );
      try {
        const res = await updateRiskAssessmentComponentVisibility(data);
        res.data.delete_risk_assessment_visibility.returning.forEach(
          (deletedVisibility) => {
            const deletedVisibilityIndex = visibilityData.find(
              (visibility) => visibility.id === deletedVisibility.id
            );
            visibilityData.splice(deletedVisibilityIndex, 1);
          }
        );
        visibilityData.push(
          ...res.data.insert_risk_assessment_visibility.returning
        );
        this.riskAssessmentData.risk_assessment_visibilities =
          cloneDeep(visibilityData);
        this.prevData.risk_assessment_visibilities = cloneDeep(visibilityData);
        this.isUpdatingMetadata = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
