var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"w":"100%"}},[_c('c-stack',{attrs:{"spacing":2}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{attrs:{"fontSize":"sm","mr":"2","fontWeight":"500","color":"gray.500"}},[_vm._v(" Impact: ")]),_c('AppSelect',{attrs:{"options":[
          'Negligible',
          'Minor',
          'Moderate',
          'Significant',
          'Severe',
        ],"disabled":_vm.disabled,"color":'dark',"appendToBody":true,"indicatorFill":'orange'},model:{value:(_vm.impact),callback:function ($$v) {_vm.impact=$$v},expression:"impact"}})],1),_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{attrs:{"fontSize":"sm","mr":"2","fontWeight":"500","color":"gray.500"}},[_vm._v(" Likelihood: ")]),_c('AppSelect',{attrs:{"disabled":_vm.disabled,"options":[
          'Very Likely',
          'Likely',
          'Possible',
          'Unlikely',
          'Very Unlikely',
        ],"color":'dark',"appendToBody":true,"indicatorFill":'orange'},model:{value:(_vm.likelihood),callback:function ($$v) {_vm.likelihood=$$v},expression:"likelihood"}})],1),_c('c-box',{attrs:{"w":"100%"}},[_c('c-text',{attrs:{"fontSize":"sm","fontWeight":"500","color":"gray.500","mb":"4"}},[_vm._v(" Risk Force: "),_c('c-text',{attrs:{"as":"span","textTransform":"uppercase","fontWeight":"600","color":"#000","ml":"2"}},[_vm._v(" "+_vm._s(_vm.matrixValues[_vm.riskForce].title)+" ")])],1),_c('c-progress',{attrs:{"color":_vm.matrixValues[_vm.riskForce].color,"size":"sm","value":_vm.matrixValues[_vm.riskForce].value}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }