<template>
  <c-box
    w="100%"
    h="100%"
    px="30px"
    pt="10px"
    pb="80px"
    position="relative"
    bg="#fff"
  >
    <c-flex mb="20px" direction="column" justify="center" align="center">
      <c-text as="h3" mb="20px" fontSize="4xl" fontWeight="700">
        {{ type === 'traction' ? 'Traction' : 'Market Goals' }}
      </c-text>
      <c-box h=".5em" w="8em" :bg="`${presentation.data.color}80`"></c-box>
    </c-flex>
    <c-link
      textDecoration="underline"
      position="absolute"
      right="5"
      top="5"
      class="item-selector"
      fontSize="xl"
      v-if="isEdit"
      @click="isDrawerOpen = true"
    >
      Select {{ type === 'traction' ? 'Traction' : 'Market Goals' }} to display
    </c-link>
    <c-flex
      h="calc(100% - 70px)"
      mx="-30px"
      px="20px"
      position="relative"
      :backgroundImage="`linear-gradient(
        to bottom ,
        ${presentation.data.color}20,
        #fff
      )`"
    >
      <c-box
        h="100%"
        w="250px"
        position="relative"
        v-for="(traction, ix) in selectedTractions"
        :key="ix"
      >
        <c-box
          px="20px"
          :pt="ix % 2 === 0 ? '5px' : '20px'"
          :pb="ix % 2 === 0 ? '10px' : '5px'"
          position="absolute"
          h="50%"
          w="100%"
          :top="ix % 2 === 0 ? '0' : '50%'"
        >
          <c-box
            w="6"
            mt="1"
            h="6"
            mr="5"
            :bg="'#000'"
            borderRadius="50%"
            position="absolute"
            :top="ix % 2 === 0 ? '96%' : '-4%'"
            left="0"
            zIndex="4"
            transform="translate(-50%)"
          ></c-box>
          <c-text textAlign="left" mb="10px" fontSize="18px" fontWeight="700">
            {{ traction.title }}
          </c-text>
          <c-text
            w="100px"
            h="30px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            :bg="presentation.data.color"
            fontSize="14px"
            color="#fff"
            fontWeight="700"
          >
            {{ traction.status === 'IN_PROGRESS' ? 'In Progress' : 'Achieved' }}
          </c-text>
          <c-box mt="3" textAlign="left">
            <c-text color="#8A8A8A" fontSize="14px">
              {{ type === 'traction' ? 'Traction' : 'Market goal' }}
              value
            </c-text>

            <c-text fontSize="18px">
              {{ traction.milestoneValue }}
              <c-text v-if="traction.metricType === 'percentage'">%</c-text>
            </c-text>
          </c-box>
          <c-box mt="3" textAlign="left">
            <c-text color="#8A8A8A" fontSize="14px">Due Date</c-text>
            <c-text fontSize="18px">
              {{ traction.date | moment('DD MMM. YYYY') }}
            </c-text>
          </c-box>
        </c-box>
      </c-box>

      <c-box
        h="2px"
        position="absolute"
        top="51.5%"
        transform="translateY(-50%)"
        left="0"
        right="0"
        zIndex="3"
        :bg="'#8a8a8a'"
      ></c-box>
    </c-flex>
    <c-box position="absolute" right="5" bottom="5">
      <WaterMark :direction="'row'" />
    </c-box>
    <TextListDrawer
      :title="`Select visible ${
        type === 'traction' ? 'Tractions' : 'Market Goals'
      }`"
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :items="filteredTractions"
      :updateFn="updateTractionData"
      :isUpdatingData="isUpdatingData"
      :max="6"
    >
      <template #list-item="{ item }">
        <c-text as="span">{{ item.title }}</c-text>
      </template>
    </TextListDrawer>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import WaterMark from '@/components/WaterMark.vue';
import tractionMixin from '@/mixins/data/traction.js';
import TextListDrawer from '@/components/DataComponents/TextListDrawer.vue';

export default {
  props: {
    type: {
      type: String,
      default: 'market_goal',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [tractionMixin],
  components: { WaterMark, TextListDrawer },
  computed: {
    ...mapState({
      presentation: (state) => state.presentation.activePresentation,
    }),
  },
};
</script>

<style></style>
