var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problem_cont",style:({
    background: _vm.presentation.data.solution.backgroundColor,
  })},[_c('div',{style:({
      background:
        'linear-gradient(to bottom ,' + _vm.presentation.data.color + '80, #fff)',
      width: '25%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    })},[_c('c-flex',{attrs:{"direction":"column","align":"center"}},[_c('c-text',{attrs:{"mt":"12","ml":"26","color":"#000","fontWeight":"700","fontSize":"5xl"}},[_vm._v("Solution")]),_c('c-box',{attrs:{"ml":"auto","mt":"5","h":".5em","w":"8em","bg":`${_vm.presentation.data.color}80`}})],1),_c('div',{staticClass:"draggable-image",style:({
        backgroundImage: 'url(' + _vm.imageUrl + ')',
        backgroundSize: 'cover',
        width: '30em',
        height: '20em',
        left: '0',
        top: _vm.imagePosition.top + 'px',
        left: _vm.imagePosition.left + 'px',
      }),on:{"mousedown":_vm.startImageDrag,"mousemove":_vm.handleImageDrag,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}})],1),_c('c-flex',{attrs:{"backgroundImage":`linear-gradient(
      to bottom ,
      ${_vm.presentation.data.color}80,
      #fff
    )`,"backgroundSize":"cover","w":"15%","ml":"auto","h":"5%","justify":"center"}}),_c('div',{staticClass:"content_div"},[_c('div',{staticClass:"draggable-div",style:({
        top: _vm.position.top + 'px',
        left: _vm.position.left + 'px',
        fontSize: _vm.presentation.data.solution.fontSize + 'px',
      }),domProps:{"innerHTML":_vm._s(_vm.selectedItems)},on:{"mousedown":_vm.startDrag,"mousemove":_vm.handleDrag,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}}),_c('c-box',{attrs:{"ml":"auto","mt":"auto"}},[_c('WaterMark',{attrs:{"direction":'row'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }