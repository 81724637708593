<template>
  <c-box>
    <!-- :isLocked="!getCurrentPlan.access.vision" -->
    <TaskCard
      title="Vision"
      :description="'Set your company’s vision'"
      :hasComments="true"
      :hasMounted="hasMounted"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :disabled="disabled"
      :hasAccess="hasAccess"
      v-chakra="{
        mb: '2rem',
      }"
      :taskData="foundationData"
      :accessKey="'foundation_assignments'"
      :accessFn="updateAccess"
      :visibilityKey="'foundation_visibilities'"
      :visibilityFn="updateVisibility"
      @update="updateData"
      explanationKey="vision"
    >
      <c-text
        as="span"
        color="#ef5323"
        fontSize="sm"
        cursor="pointer"
        v-if="showChangesModal"
      >
        <b> Saved successfuly 👍</b>
      </c-text>
      <!-- <div
        style="display: flex; justify-content: flex-end"
        v-if="showChangesModal"
      >
        <c-popover
          :isOpen="showChangesModal"
          placement="top"
          :closeOnBlur="false"
        >
          <c-popover-trigger>
            <c-button
              right-icon="arrow-forward"
              variant-color="white"
              size="xs"
            ></c-button>
          </c-popover-trigger>
          <c-popover-content zIndex="4">
            <c-popover-arrow />
            <c-popover-close-button @click="closeAlert" />
            <c-popover-header
              font-weight="bold"
              background="#ee5224"
              color="white"
              >Updates!</c-popover-header
            >
            <c-popover-body p="2">
              <c-text fontSize="13px">
                Looks like you changed your Vision. Maybe you should consider
                changing your overal solution and problem.
              </c-text>
              <c-link @click="goToProblem">
                <c-tag size="md">Go to Problem</c-tag>
              </c-link>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
      </div> -->

      <EditableText
        v-model="foundationData.description"
        @updateDescription="updateDescription"
        :disabled="disabled"
        v-if="!isFetchingData"
        title="Vision"
        type="vision"
      />

      <ContentLoader v-else viewBox="0 0 250 130" primaryColor="#ddd">
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
// import { sanitizeUpdateObject } from '@/helpers/utils.js';
import foundationMixin from '@/mixins/foundation.js';
// import {subscribeToFoundationType} from '@/services/foundation';
import TaskCard from './../TaskCard';
import EditableText from './../EditableText';
import { mapGetters } from 'vuex';

export default {
  mixins: [foundationMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    EditableText,
    ContentLoader,
  },
  data() {
    return {
      foundationType: 'vision',
      hasMounted: false,
      isTeamDrawerOpen: false,
      saved: false,
      showChangesModal: false,
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    // toggleSharedCard() {
    //   console.log('this.disabled', this.disabled);
    //   if (this.disabled == true) {
    //     console.log('reff');
    //     return !this.getCurrentPlan.access.vision;
    //   } else {
    //     console.log('this');
    //     return false;
    //   }
    // },
  },
  methods: {
    updateDescription(newAIresults) {
      this.foundationData.description = newAIresults;

      this.updateData({
        key: 'description',
        value: this.foundationData.description,
      });
      this.showChangesModal = true;
      setTimeout(() => {
        this.showChangesModal = true;
      }, 3000);
    },
    closeAlert() {
      this.showChangesModal = false;
    },
    goToProblem() {
      this.$router.push({
        path: `/app/growth-framework/business-model`,
      });
    },
  },
};
</script>

<style></style>
