<template>
  <c-flex my="2em" wrap="wrap" class="revenue-fields">
    <GridTable
      title="Revenue"
      :columns="tableColumns"
      :addRowLabel="'Add new revenue'"
      :onAddRow="addRevenueSource"
      :disabled="disabled"
    >
      <tr
        v-for="(source, sourceIndex) in revenueSources"
        :key="source.id || source.tempId"
      >
        <td class="grid__table__body__cell">
          <c-flex h="100%" justify="center" align-items="start">
            <c-popover placement="bottom" v-slot="{}">
              <c-popover-trigger cursor="pointer">
                <c-box
                  my="3"
                  v-chakra="{
                    height: 'auto',
                  }"
                >
                  <svg
                    v-chakra="{
                      width: '10px',
                      height: '16px',
                      fill: '#ef5323',
                    }"
                  >
                    <use href="@/assets/icons/vertical-dots.svg#dots" />
                  </svg>
                </c-box>
              </c-popover-trigger>
              <c-popover-content maxWidth="150px" zIndex="modal">
                <c-popover-body p="0">
                  <c-list fontSize="sm">
                    <c-list-item v-if="source && source.id">
                      <c-box
                        cursor="pointer"
                        w="100%"
                        px="2"
                        py="2"
                        @click="deleteRevenueSource(sourceIndex)"
                        >Delete</c-box
                      >
                    </c-list-item>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>

            <!-- <c-flex
                direction="column"
                align="center"
                textAlign="center"
                flex="1"
                ml="3"
                mr="auto"
              >
                <c-editable
                  w="100%"
                  mb="0.7em"
                  class="channels__list__item__name"
                  placeholder="Click to enter channel name"
                  :default-value="channel.name"
                  :isDisabled="disabled"
                  @change="onInputChange($event, index, 'name')"
                >
                  <c-editable-preview
                    maxWidth="100%"
                    whiteSpace="normal"
                    wordWrap="break-word"
                  />
                  <c-editable-input @blur="onFieldBlur(index)" />
                </c-editable>
                <c-text
                  v-if="!channel.isNameValid"
                  color="vc-orange.400"
                  fontSize="8px"
                >
                  Name is required to add channel
                </c-text>
              </c-flex> -->
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-box my="3">
            <AppSelect
              :reduce="(type) => type.value"
              label="label"
              :value="source.type"
              :options="revenueTypes"
              indicator-fill="orange"
              color="orange"
              :disabled="disabled"
              @input="onPropertyInput($event, { key: 'type', sourceIndex })"
            />
          </c-box>
        </td>
        <td class="grid__table__body__cell">
          <c-flex align="center">
            <c-heading as="h6" fontSize="2xl" mr="1">
              <c-editable
                :default-value="source.value + ''"
                @submit="onPriceInputSubmit($event, sourceIndex)"
              >
                <c-editable-preview
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxWidth="100px"
                />
                <c-editable-input type="number" w="80px" />
              </c-editable>
              <c-text
                fontSize="9px"
                fontWeight="400"
                v-if="source.isInvalid"
                color="vc-orange.400"
              >
                Price is required to add revenue source
              </c-text>
            </c-heading>
            <AppSelect
              :reduce="(currency) => currency.code"
              label="code"
              :value="source.currency"
              :options="currencies"
              indicator-fill="orange"
              color="grey"
              size="large"
              :disabled="disabled"
              @input="onPropertyInput($event, { key: 'currency', sourceIndex })"
            />
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-box my="3">
            <AppSelect
              label="label"
              :reduce="(frequency) => frequency.value"
              :value="source.frequency"
              :options="frequencies"
              indicator-fill="orange"
              :appendToBody="true"
              color="dark"
              :disabled="disabled"
              @input="
                onPropertyInput($event, { key: 'frequency', sourceIndex })
              "
            />
          </c-box>
        </td>
        <td class="grid__table__body__cell">
          <c-flex my="3" align="center" flexWrap="wrap">
            <c-flex flexWrap="wrap">
              <c-pseudo-box
                color="#fff"
                p="2"
                minHeight="30px"
                minWidth="95px"
                fontWeight="600"
                borderRadius="4px"
                mr="3"
                mb="2"
                role="group"
                display="flex"
                alignItems="center"
                justifyContent="center"
                :backgroundColor="customerColorMap[customer.category]"
                v-for="customer in source.customers"
                :key="customer.id"
                position="relative"
              >
                <c-pseudo-box
                  display="none"
                  as="a"
                  :_groupHover="{ display: 'flex' }"
                  position="absolute"
                  top="-5px"
                  right="-8px"
                  cursor="pointer"
                  @click="removeCustomer({ customer, sourceIndex })"
                >
                  <svg
                    v-chakra="{
                      w: '4',
                      h: '4',
                    }"
                  >
                    <use href="@/assets/icons/icon-close-fill.svg#close"></use>
                  </svg>
                </c-pseudo-box>
                {{ customer.name }}
              </c-pseudo-box>
            </c-flex>
            <c-popover
              placement="bottom-end"
              v-if="!disabled"
              v-slot="{ onClose }"
            >
              <c-popover-trigger>
                <c-pseudo-box
                  as="a"
                  display="flex"
                  mr="2"
                  alignItems="center"
                  :_hover="{ textDecoration: 'none', cursor: 'pointer' }"
                  fontSize="sm"
                >
                  <svg
                    v-chakra="{
                      width: '18px',
                      height: '18px',
                      fill: '#ef5323',
                      mr: '1',
                    }"
                  >
                    <use
                      href="@/assets/icons/add-circle-line.svg#add-circle"
                    ></use>
                  </svg>
                  <c-text color="#ef5323" fontWeight="600">Add Group</c-text>
                </c-pseudo-box>
              </c-popover-trigger>
              <c-popover-content width="max-content">
                <c-popover-body p="0" w="max-content">
                  <c-list>
                    <template v-for="customer in customers">
                      <c-list-item
                        fontWeight="600"
                        :key="'list' + customer.id"
                        v-if="
                          !source.customers.find(
                            (_customer) => _customer.id === customer.id
                          )
                        "
                        :color="customerColorMap[customer.category]"
                      >
                        <c-pseudo-box
                          as="a"
                          display="flex"
                          :_hover="{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            bg: customerColorMap[customer.category],
                            color: 'white',
                          }"
                          cursor="pointer"
                          px="3"
                          py="2"
                          @click="
                            onCustomerClick({ customer, sourceIndex, onClose })
                          "
                        >
                          {{ customer.name }}
                        </c-pseudo-box>
                      </c-list-item>
                    </template>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <EditableText
            :title="'Revenue'"
            :type="`revenue${sourceIndex}`"
            v-model="source.description"
            :editabelIndex="sourceIndex"
            @updateDescription="updateDescription"
            :disabled="disabled"
          />
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <AppSelect
              :options="teamMembers"
              label="id"
              placeholder="Select team member"
              :reduce="(member) => member.id"
              :value="source.employeeResponsible"
              :reset="true"
              :hasShadow="true"
              indicator-fill="orange"
              color="dark"
              size="large"
              :appendToBody="true"
              @input="
                onPropertyInput($event, {
                  key: 'employeeResponsible',
                  sourceIndex,
                })
              "
            >
              <template v-slot:option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
              <template v-slot:selected-option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
            </AppSelect>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <c-flex justify="space-between">
              <AppSelect
                :options="plans"
                label="label"
                :reduce="(plan) => plan.value"
                :value="source.actionPlan"
                :reset="true"
                :hasShadow="true"
                indicator-fill="orange"
                color="dark"
                size="large"
                :appendToBody="true"
                @input="onActionPlanChange($event, index)"
              />
              <c-spinner
                v-if="isUpdatingPlan"
                thickness="1px"
                color="blue.500"
              ></c-spinner>
            </c-flex>
          </c-flex>
          <MilestoneCell
            v-if="source.milestone"
            :milestone="source.milestone"
            ref="milestoneCell"
            @removeMilestone="onRemoveMilestone(sourceIndex)"
          />
        </td>
      </tr>
    </GridTable>
    <!-- <c-flex
      :w="{ base: '100%', lg: '330px' }"
      h="350px"
      :mr="{ base: '0px', lg: '1em' }"
      mb="1.5em"
      cursor="pointer"
      borderColor="#2d2d2d4d"
      borderRadius="4px"
      borderWidth="1px"
      direction="column"
      justify="center"
      align="center"
      tabindex="-1"
      v-if="!disabled"
    >
      <c-pseudo-box
        as="a"
        display="flex"
        h="100%"
        :_hover="{ textDecoration: 'none' }"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        @click="addRevenueSource"
      >
        <svg
          v-chakra="{
            width: '30px',
            height: '30px',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
        </svg>
        <c-text color="vc-orange.400" fontWeight="600" fontSize="lg">
          Add Revenue Sources
        </c-text>
      </c-pseudo-box>
    </c-flex>
    <c-flex
      :w="{ base: '100%', lg: '330px' }"
      h="350px"
      overflowY="scroll"
      :mr="{ base: '0px', lg: '1em' }"
      mb="1.5em"
      borderColor="#2d2d2d"
      borderRadius="4px"
      borderWidth="1px"
      direction="column"
      v-for="(source, sourceIndex) in revenueSources"
      :key="source.id || source.tempId"
      paddingTop="25px"
      paddingInline="20px"
      position="relative"
      class="revenue"
    >
      <c-popover placement="bottom" v-if="!disabled">
        <c-popover-trigger
          position="absolute"
          right="15px"
          top="15px"
          cursor="pointer"
        >
          <c-box>
            <svg
              v-chakra="{
                width: '10px',
                height: '16px',
                fill: '#ef5323',
              }"
            >
              <use href="@/assets/icons/vertical-dots.svg#dots"></use>
            </svg>
          </c-box>
        </c-popover-trigger>
        <c-popover-content zIndex="modal" maxWidth="150px">
          <c-popover-body p="0">
            <c-list>
              <c-list-item>
                <c-pseudo-box
                  px="2"
                  py="2"
                  cursor="pointer"
                  @click="deleteRevenueSource(sourceIndex)"
                >
                  Delete
                </c-pseudo-box>
              </c-list-item>
            </c-list>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
      <c-box my="3">
        <AppSelect
          :reduce="(type) => type.value"
          label="label"
          :value="source.type"
          :options="revenueTypes"
          indicator-fill="orange"
          color="orange"
          :disabled="disabled"
          @input="onPropertyInput($event, { key: 'type', sourceIndex })"
        />
      </c-box>
      <c-flex justify="space-between" align="center">
        <c-flex align="center">
          <c-heading as="h6" fontSize="2xl" mr="1">
            <c-editable
              :default-value="source.value + ''"
              @submit="onPriceInputSubmit($event, sourceIndex)"
            >
              <c-editable-preview
                overflow="hidden"
                textOverflow="ellipsis"
                maxWidth="100px"
              />
              <c-editable-input type="number" w="80px" />
            </c-editable>
            <c-text
              fontSize="9px"
              fontWeight="400"
              v-if="source.isInvalid"
              color="vc-orange.400"
            >
              Price is required to add revenue source
            </c-text>
          </c-heading>
          <AppSelect
            :reduce="(currency) => currency.code"
            label="code"
            :value="source.currency"
            :options="currencies"
            indicator-fill="orange"
            color="grey"
            size="large"
            :disabled="disabled"
            @input="onPropertyInput($event, { key: 'currency', sourceIndex })"
          />
        </c-flex>
        <AppSelect
          label="label"
          :reduce="(frequency) => frequency.value"
          :value="source.frequency"
          :options="frequencies"
          indicator-fill="orange"
          :appendToBody="true"
          color="dark"
          :disabled="disabled"
          @input="onPropertyInput($event, { key: 'frequency', sourceIndex })"
        />
      </c-flex>
      <c-flex my="3" align="center" flexWrap="wrap">
        <c-flex flexWrap="wrap">
          <c-pseudo-box
            color="#fff"
            p="2"
            minHeight="30px"
            minWidth="95px"
            fontWeight="600"
            borderRadius="4px"
            mr="3"
            mb="2"
            role="group"
            display="flex"
            alignItems="center"
            justifyContent="center"
            :backgroundColor="customerColorMap[customer.category]"
            v-for="customer in source.customers"
            :key="customer.id"
            position="relative"
          >
            <c-pseudo-box
              display="none"
              as="a"
              :_groupHover="{ display: 'flex' }"
              position="absolute"
              top="-5px"
              right="-8px"
              cursor="pointer"
              @click="removeCustomer({ customer, sourceIndex })"
            >
              <svg
                v-chakra="{
                  w: '4',
                  h: '4',
                }"
              >
                <use href="@/assets/icons/icon-close-fill.svg#close"></use>
              </svg>
            </c-pseudo-box>
            {{ customer.name }}
          </c-pseudo-box>
        </c-flex>
        <c-popover placement="bottom-end" v-if="!disabled" v-slot="{ onClose }">
          <c-popover-trigger>
            <c-pseudo-box
              as="a"
              display="flex"
              mr="2"
              alignItems="center"
              :_hover="{ textDecoration: 'none', cursor: 'pointer' }"
              fontSize="sm"
            >
              <svg
                v-chakra="{
                  width: '18px',
                  height: '18px',
                  fill: '#ef5323',
                  mr: '1',
                }"
              >
                <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
              </svg>
              <c-text color="#ef5323" fontWeight="600">Add Group</c-text>
            </c-pseudo-box>
          </c-popover-trigger>
          <c-popover-content width="max-content">
            <c-popover-body p="0" w="max-content">
              <c-list>
                <template v-for="customer in customers">
                  <c-list-item
                    fontWeight="600"
                    :key="'list' + customer.id"
                    v-if="
                      !source.customers.find(
                        (_customer) => _customer.id === customer.id
                      )
                    "
                    :color="customerColorMap[customer.category]"
                  >
                    <c-pseudo-box
                      as="a"
                      display="flex"
                      :_hover="{
                        textDecoration: 'none',
                        cursor: 'pointer',
                        bg: customerColorMap[customer.category],
                        color: 'white',
                      }"
                      cursor="pointer"
                      px="3"
                      py="2"
                      @click="
                        onCustomerClick({ customer, sourceIndex, onClose })
                      "
                    >
                      {{ customer.name }}
                    </c-pseudo-box>
                  </c-list-item>
                </template>
              </c-list>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
      </c-flex>
      <ListTextInput
        v-chakra="{ flexBasis: '150px', overflowY: 'scroll', flexGrow: 0 }"
        :title="''"
        :showTitle="false"
        :disabled="disabled"
        :value="source.description"
        @input="onPropertyInput($event, { key: 'description', sourceIndex })"
      />
    </c-flex> -->

    <c-modal
      :is-open="isMilestoneModalOpen"
      :on-close="closeMilestoneModal"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            Add
            <c-text textTransform="capitalize" ml="1"> Milestone </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <MilestoneModal @onAddMilestone="onAddMilestone" />
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <MilestoneAchieved
          :milestoneId="milestoneId"
          @closeModal="closeAchievedDialog"
          @onAchievedMilestone="onAchievedMilestone"
        />
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex';

import { generateID, revenueTypes } from '@/helpers/data';
import currencies from '@/helpers/currencies';

import AppSelect from '../../../components/AppSelect';
import EditableText from './../../components/EditableText';
import {
  addRevenue,
  addRevenueCustomer,
  deleteRevenue,
  getRevenueSources,
  removeRevenueCustomer,
  updateRevenue,
} from '@/services/business-model';
import cloneDeep from 'lodash.clonedeep';
import GridTable from '../GridTable.vue';
import MilestoneCell from './MilestoneCell.vue';
import MilestoneModal from './MilestoneModal.vue';
import MilestoneAchieved from './MilestoneAchieved.vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    revenueData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AppSelect,
    EditableText,
    GridTable,
    MilestoneCell,
    MilestoneModal,
    MilestoneAchieved,
  },
  data() {
    return {
      milestoneId: null,
      isAchievedDialogOpen: false,
      isMilestoneModalOpen: false,
      isUpdatingPlan: false,
      currentRevenueIndex: null,
      revenueTypes,
      revenueSources: [],
      currencies,
      frequencies: [
        { label: 'Per Item', value: 'PER_ITEM' },
        { label: 'Per Service', value: 'PER_SERVICE' },
        { label: 'Yearly', value: 'YEARLY' },
        { label: 'Monthly', value: 'MONTHLY' },
        { label: 'Weekly', value: 'WEEKLY' },
        { label: 'Daily', value: 'DAILY' },
      ],
      customerColorMap: {
        government: '#3762d5',
        business: '#37d5aa',
        consumer: '#6bc1d1',
      },
      tableColumns: [
        {
          action: () => null,
          component: null,
          title: '',
          value: '',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Type',
          value: 'type',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Price',
          value: 'price',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Frequency',
          value: 'frequency',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Customers',
          value: 'Customers',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Description',
          value: 'description',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Employee Responsible',
          value: 'employee',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Action Plan',
          value: 'milestone',
          disabled: false,
        },
      ],
      plans: [
        { label: 'Monitor', value: 'monitor' },
        { label: 'Act', value: 'act' },
        { label: 'Resolved', value: 'resolved' },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      customers: (state) => state.customers,
      teamMembers: (state) =>
        state.members.filter(
          (member) => member.isAccepted && member.user.isVerified
        ),
    }),
  },
  created() {
    this.getRevenueSources();
  },
  methods: {
    getRevenueSources() {
      getRevenueSources(this.revenueData.id).then((res) => {
        this.revenueSources = res.data.revenue.map((revenue) => {
          return {
            ...revenue,
            customers: revenue.revenue_customers.map(
              (revenue_customer) => revenue_customer.customer
            ),
            isInvalid: false,
          };
        });
      });
    },
    addRevenueSource() {
      this.revenueSources.push({
        id: null,
        tempId: generateID(4),
        type: 'USAGE_FEE',
        value: 0,
        currency: 'USD',
        frequency: 'MONTHLY',
        customers: [],
        description: '',
        isInvalid: false,
        employeeResponsible: null,
      });
    },
    async onPriceInputSubmit(e, sourceIndex) {
      if (!(e & e.trim())) {
        this.revenueSources[sourceIndex].isInvalid = true;
        return;
      }
      this.revenueSources[sourceIndex].isInvalid = false;
      const {
        id,
        type,
        frequency,
        customers,
        description,
        currency,
        value,
        employeeResponsible,
      } = this.revenueSources[sourceIndex];
      this.revenueSources[sourceIndex].value = e;
      if (id) {
        this.updateRevenue({
          id,
          set: { value: +e },
          key: 'value',
          oldPropertyValue: value,
          sourceIndex,
        });
      } else {
        const data = {
          businessModelId: this.revenueData.id,
          type,
          value: +e,
          frequency,
          currency,
          description,
          employeeResponsible,
          revenue_customers: {
            data: customers.map((customer) => {
              return {
                customerId: customer.id,
              };
            }),
          },
        };
        try {
          const res = await addRevenue(data);
          this.revenueSources[sourceIndex].id = res.data.insert_revenue_one.id;
        } catch (e) {
          this.revenueSources.splice(sourceIndex, 1);
          this.$toast({
            title: 'An error occurred.',
            description: `Error while adding revenue, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    closeMilestoneModal() {
      this.isMilestoneModalOpen = false;
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
    },
    updateDescription(value, currentIndex) {
      const revenue = this.revenueSources[currentIndex];

      revenue.description = value;
      if (!value) {
        revenue.isInvalid = true;
        return;
      } else {
        revenue.isInvalid = false;
        if (!revenue.id) {
          return;
        }
        this.updateRevenue({
          id: revenue.id,
          set: { ['description']: value },
          key: 'description',
          oldPropertyValue: revenue.description,
          sourceIndex: currentIndex,
        });
      }
    },
    onActionPlanChange(e, revenueIndex) {
      switch (e) {
        case 'monitor': {
          this.isUpdatingPlan = true;
          this.revenueSources[revenueIndex].actionPlan = 'monitor';
          this.revenueSources[revenueIndex].milestoneId = null;
          updateRevenue({
            id: this.revenueSources[revenueIndex].id,
            set: { milestoneId: null, actionPlan: 'monitor' },
          })
            .then((response) => {
              this.isUpdatingPlan = true;
              this.revenueSources[revenueIndex] =
                response.data.update_revenue_by_pk;
            })
            .catch(() => {
              this.isUpdatingPlan = false;
            });
          break;
        }
        case 'act': {
          this.isUpdatingPlan = true;
          this.revenueSources[revenueIndex].actionPlan = 'act';
          this.currentRevenueIndex = revenueIndex;
          this.isMilestoneModalOpen = true;
          break;
        }
        case 'resolved':
          this.isUpdatingPlan = true;
          this.revenueSources[revenueIndex].actionPlan = 'resolved';
          this.currentRevenueIndex = revenueIndex;
          this.milestoneId = this.revenueSources[revenueIndex].milestoneId;
          this.isAchievedDialogOpen = true;
          break;
        default:
          break;
      }
    },
    onAddMilestone(milestoneId) {
      const revenue = this.revenueSources[this.currentRevenueIndex];
      updateRevenue({
        id: revenue.id,
        set: { milestoneId, actionPlan: 'act' },
      })
        .then((response) => {
          this.isUpdatingPlan = false;

          const milestone = response.data.update_revenue_by_pk.milestone;
          this.revenueSources[this.currentRevenueIndex].milestone = {
            ...milestone,
          };
          this.closeMilestoneModal();
        })
        .catch(() => {
          this.isUpdatingPlan = false;
          this.closeMilestoneModal();
        });
    },
    onAchievedMilestone() {
      updateRevenue({
        id: this.revenueSources[this.currentRevenueIndex].id,
        set: {
          actionPlan: 'resolved',
        },
      })
        .then((response) => {
          this.isUpdatingPlan = false;
          this.revenueSources[this.currentRevenueIndex] = cloneDeep(
            response.data.update_revenue_by_pk
          );
          this.closeAchievedDialog();
        })
        .catch(() => {
          this.isUpdatingPlan = false;
          this.closeAchievedDialog();
        });
    },
    onPropertyInput(e, { sourceIndex, key }) {
      const {
        value,
        id,
        [key]: oldPropertyValue,
      } = this.revenueSources[sourceIndex];
      this.revenueSources[sourceIndex][key] = e;
      if (!value) {
        this.revenueSources[sourceIndex].isInvalid = true;
        return;
      } else {
        this.revenueSources[sourceIndex].isInvalid = false;
        if (!id) {
          return;
        }
        this.updateRevenue({
          id,
          set: { [key]: e },
          key,
          oldPropertyValue,
          sourceIndex,
        });
      }
    },
    async updateRevenue({ id, set, key, oldPropertyValue, sourceIndex }) {
      try {
        return updateRevenue({ id, set });
      } catch (e) {
        this.revenueSources[sourceIndex][key] = oldPropertyValue;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating revenue, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async onCustomerClick({ customer, sourceIndex, onClose }) {
      onClose();
      const { value, id } = this.revenueSources[sourceIndex];
      this.revenueSources[sourceIndex].customers.push({ ...customer });
      if (!value) {
        this.revenueSources[sourceIndex].isInvalid = true;
        return;
      } else {
        this.revenueSources[sourceIndex].isInvalid = false;
        if (!id) {
          return;
        }
        const data = {
          revenueId: id,
          customerId: customer.id,
        };
        try {
          await addRevenueCustomer(data);
        } catch (e) {
          const customerIndex = this.revenueSources[
            sourceIndex
          ].customers.findIndex((_customer) => _customer.id === customer.id);
          this.revenueSources[sourceIndex].customers.splice(customerIndex, 1);
          this.$toast({
            title: 'An error occurred.',
            description: `Error while adding customer to revenue, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    async removeCustomer({ customer, sourceIndex }) {
      const customerIndex = this.revenueSources[
        sourceIndex
      ].customers.findIndex((_customer) => _customer.id === customer.id);
      this.revenueSources[sourceIndex].customers.splice(customerIndex, 1);
      try {
        await removeRevenueCustomer({
          revenueId: this.revenueSources[sourceIndex].id,
          customerId: customer.id,
        });
      } catch (e) {
        this.revenueSources[sourceIndex].customers.splice(customerIndex, 1, {
          ...customer,
        });
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing customer from revenue, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async deleteRevenueSource(sourceIndex) {
      const revenue = { ...this.revenueSources[sourceIndex] };
      this.revenueSources.splice(sourceIndex, 1);
      if (!revenue.id) {
        return;
      }
      try {
        await deleteRevenue(revenue.id);
      } catch (e) {
        this.revenueSources.splice(sourceIndex, 1, { ...revenue });
        this.$toast({
          title: 'An error occurred.',
          description: `Error while deleting revenue, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    onRemoveMilestone(index) {
      this.isUpdatingPlan = true;
      const revenue = this.revenueSources[index];
      this.updateRevenue({
        id: revenue.id,
        set: { milestoneId: null },
        key: 'milestoneId',
        oldPropertyValue: revenue.milestoneId,
        sourceIndex: index,
      }).then(() => {
        this.isUpdatingPlan = true;
        this.partners[index].milestone = null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  th,
  td {
    &:first-of-type {
      width: 50px;
    }
  }
}

.revenue-fields {
  ::v-deep {
    th {
      &:first-of-type {
        min-width: 50px;
        width: 50px;
      }
    }
  }
}
</style>
