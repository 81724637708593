import {
  createPresentationMutation,
  updatePresentationMutation,
} from '@/graphql/mutations';
import {
  getPitchDeckDataQuery,
  getPresentationQuery,
  getPresentationsQuery,
  getTemplatesQuery,
} from '@/graphql/queries';
import { apolloInstance } from '../vue-apollo';
import http from '@/helpers/api';

export const getTemplates = (type) => {
  return apolloInstance.query({
    query: getTemplatesQuery,
    variables: { type },
    fetchPolicy: 'no-cache',
  });
};

export const createPresentation = (data) => {
  return apolloInstance.mutate({
    mutation: createPresentationMutation,
    variables: { object: data },
  });
};

export const getPresentations = (companyId) => {
  return apolloInstance.query({
    query: getPresentationsQuery,
    variables: { companyId },
    fetchPolicy: 'network-only',
  });
};

export const getPresentation = (id) => {
  return apolloInstance.query({
    query: getPresentationQuery,
    variables: { id },
    fetchPolicy: 'network-only',
  });
};

export const getPitchDeckData = (companyId) => {
  return apolloInstance.query({
    query: getPitchDeckDataQuery,
    variables: { companyId },
    fetchPolicy: 'network-only',
  });
};

export const getFontsList = () => {
  return http.get(
    `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDAhDmncghc9KsWn0vH4pQgpR4MYVeDdmI`
  );
};

export const updatePresentation = ({ id, set, append }) => {
  return apolloInstance.mutate({
    mutation: updatePresentationMutation,
    variables: { id, set, append },
  });
};
