<template>
  <c-box
    w="100%"
    h="100%"
    px="100px"
    py="80px"
    position="relative"
    :bg="presentation.data.how_it_works.backgroundColor"
  >
    <c-flex>
      <c-text ml="0px" mr="10" as="h3" mb="60px" fontSize="4xl" fontWeight="700"
        >How It Works</c-text
      >
    </c-flex>
    <c-flex
      px="5"
      h="90%"
      position="relative"
      borderWidth=".5em"
      :borderColor="presentation.data.color"
      :backgroundImage="`linear-gradient(
        to bottom ,
        ${presentation.data.color}20,
        #fff
      )`"
    >
      <c-flex w="100%" align="center" justify="space-between">
        <c-box w="70%">
          <c-box mb="8" textAlign="left">
            <c-text fontSize="25px" fontWeight="600">Description</c-text>
            <EditableText
              :noHeight="true"
              :showOptions="false"
              :disabled="!isEdit"
              :value="howItWorksData.description"
              @updateDescription="updateDescription"
            />
          </c-box>
          <c-box mb="8" textAlign="left">
            <c-text fontSize="25px" fontWeight="600">Link</c-text>
            <EditableText
              :noHeight="true"
              :showOptions="false"
              :disabled="!isEdit"
              :value="howItWorksData.link"
              @updateDescription="updateLink"
            />
          </c-box>
        </c-box>
        <c-box maxW="50%" w="20em" h="15em">
          <video v-if="isVideo(howItWorksData.link)">
            <source :src="howItWorksData.link" type="video/mp4" />
          </video>
          <img
            v-else-if="
              !isVideo(howItWorksData.link) &&
              !isYoutubeVideo(howItWorksData.link)
            "
            :src="howItWorksData.link"
          />
        </c-box>
      </c-flex>
    </c-flex>

    <c-box position="absolute" right="5" bottom="5">
      <WaterMark :direction="'row'" />
    </c-box>
  </c-box>
</template>

<script>
import { mapMutations } from 'vuex';
import { EventBus } from '@/eventBus.js';
import howItWorksMixin from '@/mixins/data/howItWorks.js';
import WaterMark from '@/components/WaterMark.vue';
import { updatePresentation } from '@/services/presentation';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';

export default {
  mixins: [howItWorksMixin],
  components: { WaterMark, EditableText },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    EventBus.$on('how_it_works', async (configForm) => {
      this.updatePresentation({
        data: {
          ...this.presentation.data,
          how_it_works: configForm,
        },
      });

      try {
        const response = await updatePresentation({
          id: this.presentation.id,
          append: { data: { how_it_works: configForm } },
        });
        const pre = response.data.update_presentation_by_pk;
        this.updatePresentation({
          data: {
            ...this.presentation.data,
            how_it_works: pre.data.how_it_works,
          },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, how_it_works: configForm },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
    isVideo(filename) {
      return /\.mp4$/i.test(filename);
    },
    isYoutubeVideo(link) {
      return /youtube\.com/i.test(link);
    },
    updateDescription(description) {
      console.log(description);
      this.howItWorksData.description = description;
      this.onHowItWorksInputBlur('description');
    },
    updateLink(link) {
      this.howItWorksData.link = link;
      this.onHowItWorksInputBlur('link');
    },
  },
};
</script>

<style></style>
