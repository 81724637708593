import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=71bbd650"
import script from "./Team.vue?vue&type=script&lang=js"
export * from "./Team.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CLink: require('@chakra-ui/vue').CLink, CGrid: require('@chakra-ui/vue').CGrid})
