var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"p":"8","mr":"8","bg":"#fff","borderRadius":"8px","justify":"space-between","boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)"}},[_c('c-flex',{attrs:{"justify":"space-between","mb":"4"}},[_c('c-text',{attrs:{"fontSize":"xl","fontWeight":"600"}},[_vm._v("Responsibilities")])],1),_c('c-stack',{attrs:{"maxHeight":"300px","overflowY":"scroll","spacing":2}},[(!_vm.moduleAsssignments.length)?_c('c-text',[_vm._v(" You have not being assigned to any task ")]):_vm._e(),_vm._l((_vm.moduleAsssignments),function(assignment,index){return _c('c-pseudo-box',{key:assignment.id + '-' + index,attrs:{"boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)","w":"100%","p":"6","bg":"#fff","mr":"8","justify":"space-between"},on:{"click":function($event){return _vm.goToAssignment(assignment)}}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between"}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-flex',{attrs:{"px":"3","py":"2","mr":"3","w":"max-content","borderRadius":"4px","align":"center","color":_vm.importanceLevels[assignment.priority].color,"backgroundColor":_vm.importanceLevels[assignment.priority].colorRgba}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                fill: _vm.importanceLevels[assignment.priority].color,
                w: '4',
                h: '4',
                mr: '2',
              }),expression:"{\n                fill: importanceLevels[assignment.priority].color,\n                w: '4',\n                h: '4',\n                mr: '2',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/flag-line.svg") + "#flag"}})]),_c('c-text',{attrs:{"fontWeight":"500"}},[_vm._v(" "+_vm._s(_vm.importanceLevels[assignment.priority].name)+" ")])],1),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v(" "+_vm._s(_vm.titlecase( (assignment.type || assignment.__typename) .split('_') .join(' ') ))+" ")])],1),_c('c-link',[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              w: '5',
              h: '5',
              fill: '#ef5323',
            }),expression:"{\n              w: '5',\n              h: '5',\n              fill: '#ef5323',\n            }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/share-box-fill.svg") + "#share"}})])])],1),(assignment.description)?_c('c-text',{staticClass:"description",attrs:{"color":"gray.400","fontSize":"sm","fontWeight":"500","my":"2"},domProps:{"innerHTML":_vm._s(assignment.description)}}):_vm._e()],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }