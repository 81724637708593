var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-flex',{attrs:{"my":"3","maxHeight":"100%","w":"100%","align":"center","direction":"column","justify":"center"}},[_c('c-flex',{attrs:{"align":"center","direction":"column","h":"calc(100% - 10px)","overflowY":"scroll"}},[_vm._l((_vm.customers),function(customer,index){return [(_vm.isSelectedCustomer(customer.id))?_c('c-flex',{key:index,attrs:{"color":"#fff","p":"2","minHeight":"30px","minWidth":"95px","fontWeight":"600","fontSize":"xs","borderRadius":"4px","mb":"3","display":"flex","align":"center","justify":"center","backgroundColor":_vm.customerColorMap[customer.category]}},[_vm._v(" "+_vm._s(customer.name)+" ")]):_vm._e()]})],2),(!_vm.isPreview && !_vm.disabled)?_c('c-popover',{attrs:{"placement":"bottom","zIndex":"tooltip"},scopedSlots:_vm._u([{key:"default",fn:function(parentPopoverSlotProps){return [_c('c-popover-trigger',[_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","mr":"2","alignItems":"center","_hover":{ textDecoration: 'none', cursor: 'pointer' }}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            width: '18px',
            height: '18px',
            fill: '#ef5323',
            mr: '1',
          }),expression:"{\n            width: '18px',\n            height: '18px',\n            fill: '#ef5323',\n            mr: '1',\n          }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})]),_c('c-text',{attrs:{"color":"#ef5323","fontWeight":"600","fontSize":"sm"}},[_vm._v(" Add Group ")])],1)],1),_c('c-popover-content',{attrs:{"width":"max-content","zIndex":"tooltip"}},[_c('c-popover-body',{attrs:{"px":"0"}},[_c('c-list',[_c('c-list-item',{attrs:{"fontSize":"sm"}},[_c('c-popover',{attrs:{"trigger":"hover","placement":'right'},scopedSlots:_vm._u([{key:"default",fn:function({ onClose }){return [_c('c-popover-trigger',[_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","p":"2","px":"3","alignItems":"center","_hover":{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    bg: 'vc-orange.50',
                  },"justifyContent":"space-between","fontSize":"sm","cursor":"pointer"}},[_c('span',[_vm._v(" Your Groups ")]),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                      width: '10px',
                      height: '10px',
                      fill: '#ef5323',
                      ml: '2',
                    }),expression:"{\n                      width: '10px',\n                      height: '10px',\n                      fill: '#ef5323',\n                      ml: '2',\n                    }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-caret-right.svg") + "#caret"}})])])],1),_c('c-popover-content',{attrs:{"width":"max-content","zIndex":"tooltip"}},[_c('c-popover-body',{attrs:{"p":"0","width":"max-content","zIndex":"tooltip"}},[_c('c-list',_vm._l((_vm.customers),function(customer,index){return _c('c-list-item',{key:index,attrs:{"fontWeight":"600","color":_vm.customerColorMap[customer.category]}},[_c('c-pseudo-box',{attrs:{"as":"a","_hover":{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          bg: _vm.customerColorMap[customer.category],
                          color: 'white',
                        },"role":"group","px":"3","py":"2","display":"flex","alignItems":"center"},on:{"click":function($event){return _vm.onCustomerSelect(
                            customer,
                            onClose,
                            parentPopoverSlotProps.onClose
                          )}}},[_c('c-text',{attrs:{"mr":"2"}},[_vm._v(" "+_vm._s(customer.name))]),(_vm.isSelectedCustomer(customer.id))?_c('c-icon',{attrs:{"name":"check","color":_vm.customerColorMap[customer.category]}}):_vm._e()],1)],1)}),1)],1)],1)]}}],null,true)})],1)],1)],1)],1)]}}],null,false,3879688294)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }