import { logPageVisit } from '@/services/user';
import cloneDeep from 'lodash.clonedeep';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('tour', {
      tour: (state) => state.tourStages,
    }),
    ...mapState('company', {
      isShareView: (state) => state.isShareView,
    }),
  },
  mounted() {
    if (!this.isShareView) {
      this.logPageVisit();
      this.checkPageVisit();
    }
  },
  methods: {
    ...mapActions({
      startTour: 'tour/startTour',
    }),
    ...mapMutations({
      updateUser: 'auth/updateUser',
    }),
    async logPageVisit() {
      try {
        const res = await logPageVisit({
          userId: this.user.id,
          route: this.$route.path,
        });
        const pagesVisited = cloneDeep(this.user.page_visiteds);
        pagesVisited.push(cloneDeep(res.data.insert_page_visited_one));
        this.updateUser({ page_visiteds: pagesVisited });
      } catch (e) {
        console.log({ e });
      }
    },
    checkPageVisit() {
      const isFirstVisit = this.user.page_visiteds.every(
        (visit) => visit.route !== this.$route.path
      );
      if (isFirstVisit) {
        setTimeout(() => {
          const page = this.tour[1].tour_steps.find((tour) =>
            this.$route.path.endsWith(tour.route)
          );
          this.startTour({ tour: page, startIndex: 0 });
        }, 1000);
      }
    },
  },
};
