<template>
  <div class="competition">
    <GrowthSectionHeader
      :criteria="criteria"
      :completion="getCompetitionLevel"
    />
    <div class="competition__tasks">
      <c-stack :spacing="10">
        <c-box>
          <PointOfDifferentiation :disabled="isShareView" />
        </c-box>
        <c-box>
          <CompetitionCard :disabled="isShareView" />
        </c-box>
        <c-box>
          <ComparisonCard :disabled="isShareView" />
        </c-box>
      </c-stack>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import GrowthSectionHeader from './components/GrowthSectionHeader.vue';
import PointOfDifferentiation from './components/competition/PointOfDifferentiation.vue';
import CompetitionCard from './components/competition/CompetitionCard';
import ComparisonCard from './components/competition/ComparisonCard';
import scrollToComponent from '@/mixins/scroll-to-component.js';
import pageVisits from '@/mixins/page-visits.js';

export default {
  mixins: [scrollToComponent, pageVisits],
  components: {
    GrowthSectionHeader,
    PointOfDifferentiation,
    CompetitionCard,
    ComparisonCard,
  },
  data() {
    return {
      criteria: {
        title: 'Competition',
        description: `Competition is a process of identifying your current and potential competitors and different metrics to evaluate them and determine their strengths and weaknesses relative to your own business, product, and service. The goal is for you and your team to gather all the intelligence necessary in the market and develop a go-to-market strategy. It combines all the relevant sources of competitor analysis into one framework so you and your team can efficiently and effectively work with a point of differentiation, implementation, monitoring, and adjustment. 
                    `,
        tasks: [
          {
            title: 'Point of Differentiation',
            value: 'point_of_differentiation',
          },
          { title: 'Competition', value: 'competition' },
          { title: 'Comparison', value: 'comparison' },
        ],
      },
    };
  },
  computed: {
    ...mapState('company', {
      isShareView: (state) => state.isShareView,
    }),
    ...mapGetters('company', ['getCompetitionLevel']),
  },
};
</script>

<style lang="scss" scoped>
.competition {
  &__tasks {
    @apply px-6 py-3;
  }
  @screen lg {
    &__tasks {
      @apply px-0;
    }
  }
}
</style>
