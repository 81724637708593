import { mapState, mapMutations } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

export default {
  inject: ['updatePresentation', 'getCosts'],
  data() {
    return {
      costs: [],
      currency: 'USD',
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedItems() {
      return this.costs.filter((item) => item.selected);
    },
    costYears() {
      return [
        {
          key: 'YEAR_1',
          label: 'Year 1',
          total: this.totalCostForYear('YEAR_1'),
        },
        {
          key: 'YEAR_2',
          label: 'Year 2',
          total: this.totalCostForYear('YEAR_2'),
        },
        {
          key: 'YEAR_3',
          label: 'Year 3',
          total: this.totalCostForYear('YEAR_3'),
        },
      ];
    },
    selectedCostYears() {
      return [
        {
          key: 'YEAR_1',
          label: 'Year 1',
          total: this.totalSelectedCostForYear('YEAR_1'),
        },
        {
          key: 'YEAR_2',
          label: 'Year 2',
          total: this.totalSelectedCostForYear('YEAR_2'),
        },
        {
          key: 'YEAR_3',
          label: 'Year 3',
          total: this.totalSelectedCostForYear('YEAR_3'),
        },
      ];
    },
  },
  created() {
    this.setCosts();
  },
  watch: {
    presentation() {
      this.setCosts();
    },
  },
  methods: {
    ...mapMutations({
      updatePresentationState: 'presentation/updatePresentation',
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    totalCostForYear(key) {
      return this.costs.reduce(
        (accumulator, currentValue) =>
          accumulator +
          +currentValue.valueArray.find((_cost) => _cost.key === key)?.value,
        0
      );
    },
    totalSelectedCostForYear(key) {
      return this.selectedItems.reduce(
        (accumulator, currentValue) =>
          accumulator +
          +currentValue.valueArray.find((_cost) => _cost.key === key)?.value,
        0
      );
    },
    async handleGetCosts(costData) {
      let result = [];
      await this.getCosts(costData.id).then((res) => {
        result = res.data.cost.map((cost) => {
          return {
            ...cost,
            isValueValid: true,
            valueArray: this.costYears.map((costYear) => ({
              ...costYear,
              value:
                cost.valueArray?.find((_cost) => _cost.key === costYear.key)
                  ?.value || 0,
            })),
          };
        });
        console.log(costData, result);

        if (result.length) {
          this.currency = result[0].currency;
        }
      });

      return result;
    },
    async setCosts() {
      const costData = this.presentationData.business_models.find(
        (data) => data.type === 'costs'
      );

      const costsArray = await this.handleGetCosts(costData);

      if (!this.presentation.data.slidesData?.costs) {
        this.costs = [...costsArray];
      } else {
        const { costs } = this.presentation.data.slidesData;
        this.costs = costsArray.map((item) => {
          const itemInPresentation = costs.find(
            (_item) => _item.id === item.id
          );
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }

      // console.log("show costs", this.costs);
    },
    async updateCostsData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                costs: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while updating selected Cost, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
