<template>
  <c-box d="flex" w="100%" h="100%" position="relative" background="#fff">
    <c-box
      d="flex"
      justify="center"
      align-items="center"
      w="45%"
      h="100%"
      :bg="presentation.data.color"
    >
      <c-box justify="center">
        <c-text
          ml="20"
          mr="10"
          as="h3"
          mb="5"
          fontSize="4xl"
          fontWeight="700"
          color="#fff"
        >
          Point of
          <br />differentiation
        </c-text>
        <c-box
          mx="auto"
          h=".5em"
          w="8em"
          :bg="`${presentation.data.color}80`"
        ></c-box>
      </c-box>
    </c-box>
    <c-flex px="20" py="32" align="center">
      <c-link
        textDecoration="underline"
        position="absolute"
        right="5"
        top="5"
        class="item-selector"
        fontSize="xl"
        zIndex="2"
        v-if="isEdit"
        @click="isDrawerOpen = true"
        >Select points to display</c-link
      >
      <c-text maxWidth="60%" fontSize="1.8em" position="relative">
        <c-text
          position="absolute"
          fontSize="10em"
          top="0"
          left="-50em"
          zIndex="1"
          :color="presentation.data.color"
          lineHeight="0.7"
          >”f</c-text
        >
        <ul v-chakra listStyle="none" w="80%" zIndex="10">
          <template v-for="(item, index) in selectedItems">
            <li
              v-chakra="{
                mb: '20px',
              }"
              v-if="item.selected"
              :key="index"
            >
              <!-- <c-text fontWeight="600" fontSize="4xl">0{{ index + 1 }}</c-text> -->
              <c-text ml="3" textAlign="justify-all" fontSize="2xl">
                {{ item.text }}
              </c-text>
              <ul v-chakra ml="10">
                <li
                  v-for="(child, childIndex) in item.children"
                  :key="childIndex"
                >
                  <c-text textAlign="justify-all" fontSize="2xl">
                    {{ child }}
                  </c-text>
                </li>
              </ul>
            </li>
          </template>
        </ul>
        <!-- <span
          v-chakra
          v-html="pointOfDifferentiation.description"
          zIndex="2"
          position="relative"
        >
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrLorem
          ipsum dolor sit amet, consectetur adipiscing elit. Fusce pulvinar nibh
          eget magna dictum interdum. Fusce sagittis nisl velit, eu dapibus nisi
          posuere nec. Donec eget velit rhoncus lectus vulputate pulvinar vitae
          et augue. Curabitur pellentesque tempor enim at fermentum. Vestibulum
          ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          curae;
        </span>
        <br />
        <br />Lorem ipsum at dolor et amen con fingur gal del o mpadem-->
      </c-text>
    </c-flex>

    <c-box position="absolute" right="5" bottom="5">
      <WaterMark :direction="'row'" />
    </c-box>

    <TextListDrawer
      :title="'Select visible points'"
      :isDrawerOpen="isDrawerOpen"
      :closeDrawer="closeDrawer"
      :items="pointsOfDifferentiation"
      :updateFn="updatePointOfDifferentiationData"
      :isUpdatingData="isUpdatingData"
    />
  </c-box>
</template>

<script>
import pointOfDifferentiationMixin from '@/mixins/data/pointOfDifferentiation.js';
import WaterMark from '@/components/WaterMark.vue';
import TextListDrawer from '@/components/DataComponents/TextListDrawer.vue';

export default {
  mixins: [pointOfDifferentiationMixin],
  components: { WaterMark, TextListDrawer },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log(this.presentation, this.presentationData);
  },
};
</script>

<style></style>
