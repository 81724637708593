import { render, staticRenderFns } from "./WillingnessToPay.vue?vue&type=template&id=5d1fead5"
import script from "./WillingnessToPay.vue?vue&type=script&lang=js"
export * from "./WillingnessToPay.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CBox: require('@chakra-ui/vue').CBox})
