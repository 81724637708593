var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{staticClass:"segment",attrs:{"width":"max-content","maxWidth":"100%","overflowX":"scroll"}},_vm._l((_vm.segments),function(segment,index){return _c('label',{directives:[{name:"chakra",rawName:"v-chakra",value:({
      position: 'relative',
      cursor: 'pointer',
      mr: index < _vm.segments.length - 1 ? '2' : '',
    }),expression:"{\n      position: 'relative',\n      cursor: 'pointer',\n      mr: index < segments.length - 1 ? '2' : '',\n    }"}],key:segment.value},[_c('c-visually-hidden',{attrs:{"as":"input","type":"radio","name":_vm.name,"value":segment.value,"checked":segment.value === _vm.selectedSegment,"disabled":_vm.disabled},on:{"change":_vm.onChange}}),_c('c-control-box',{attrs:{"width":"max-content","min-width":"120px","h":"100%","type":"radio","display":"flex","alignItems":"center","justifyContent":"center","bg":"transparent","px":"4","_checked":{
        bg: 'white',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 3px 6px rgba(186, 186, 186, 0.16)',
      }}},[_c('c-box',{attrs:{"as":"span","fontSize":"sm","fontWeight":"500"}},[_vm._v(" "+_vm._s(segment.title)+" ")])],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }