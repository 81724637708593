import { mapState, mapMutations } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  inject: ["updatePresentation"],
  data() {
    return {
      categories: [],
      isDrawerOpen: false,
      isUpdatingData: false,
    };
  },
  computed: {
    ...mapState({
      presentationData: (state) => state.presentation.presentationData,
      presentation: (state) => state.presentation.activePresentation,
    }),
    selectedCategories() {
      return this.categories.filter((category) => category.selected);
    },
  },
  watch: {
    presentation() {
      this.setCategories();
    },
  },
  created() {
    this.setCategories();
  },
  methods: {
    ...mapMutations({
      updatePresentationState: "presentation/updatePresentation",
    }),
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    setCategories() {
      const competitionData = this.competitors;

      if (!this.presentation.data.slidesData?.comparison) {
        this.categories = competitionData.map((category, index) => ({
          ...category,
          selected: index <= 4,
        }));
      } else {
        const { comparison } = this.presentation.data.slidesData;
        console.log("luppy", competitionData);
        this.categories = competitionData.map((item) => {
          const itemInPresentation = comparison.find(
            (_item) => _item.id === item.id
          );
          console.log(itemInPresentation);
          return {
            ...item,
            selected: itemInPresentation ? itemInPresentation.selected : false,
          };
        });
      }
    },
    async updateCompetitionData(data) {
      try {
        this.isUpdatingData = true;
        const res = await this.updatePresentation({
          id: this.presentation.id,
          append: {
            data: {
              slidesData: {
                ...this.presentation.data.slidesData,
                comparison: [...data],
              },
            },
          },
        });
        this.isUpdatingData = false;
        this.isDrawerOpen = false;
        this.updatePresentationState({
          ...cloneDeep(res.data.update_presentation_by_pk),
        });
      } catch (e) {
        this.isUpdatingData = false;
        this.$toast({
          title: "Error!!!",
          description: `An error occurred while updating selected competition categories, please try again`,
          status: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
    labelRenderFn(label) {
      return label.toLowerCase().split("_").join(" ");
    },
  },
};
