import { render, staticRenderFns } from "./Notification.vue?vue&type=template&id=26b4cacf&scoped=true"
import script from "./Notification.vue?vue&type=script&lang=js"
export * from "./Notification.vue?vue&type=script&lang=js"
import style0 from "./Notification.vue?vue&type=style&index=0&id=26b4cacf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26b4cacf",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CLink: require('@chakra-ui/vue').CLink, CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerCloseButton: require('@chakra-ui/vue').CDrawerCloseButton, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CText: require('@chakra-ui/vue').CText, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer, CFlex: require('@chakra-ui/vue').CFlex})
