<template>
  <c-box>
    <AppSegment
      :segments="segments"
      name="market-potential"
      v-model="selectedSegment"
    />
    <transition name="fade" mode="out-in">
      <component :is="selectedSegmentComponent" :disabled="disabled" />
    </transition>
  </c-box>
</template>

<script>
import OverviewCard from '../market-potential/OverviewCard.vue';
import SAM from '../market-potential/SAM.vue';
import SOM from '../market-potential/SOM.vue';
import TAM from '../market-potential/TAM.vue';
import AppSegment from './../AppSegment';

export default {
  props: ['disabled'],
  components: { AppSegment },
  data() {
    return {
      segments: [
        { title: 'Market Potential', value: 'market-potential' },
        { title: 'TAM', value: 'tam' },
        { title: 'SAM', value: 'sam' },
        { title: 'SOM', value: 'som' },
      ],
      selectedSegment: 'market-potential',
      componentsEnum: {
        'market-potential': OverviewCard,
        tam: TAM,
        sam: SAM,
        som: SOM,
      },
    };
  },
  computed: {
    selectedSegmentComponent() {
      return this.componentsEnum[this.selectedSegment];
    },
  },
};
</script>

<style></style>
