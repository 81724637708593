<template>
  <div class="comparison">
    <table class="comparison__table">
      <thead class="comparison__table__header">
        <tr>
          <th class="comparison__table__header__cell">
            <c-text fontSize="xs" fontWeight="500"> Categories </c-text>
          </th>
          <th class="comparison__table__header__cell">
            <c-heading fontSize="xl" fontWeight="600"> You </c-heading>
          </th>
          <template v-for="competitor in competitors">
            <th
              class="comparison__table__header__cell"
              :key="competitor.name"
              v-if="selectedCompetitors.includes(competitor.id + '')"
            >
              <c-heading
                fontSize="xl"
                fontWeight="600"
                maxWidth="60%"
                marginInline="auto"
              >
                {{ competitor.name }}
              </c-heading>
            </th>
          </template>
          <th class="comparison__table__header__cell">
            <c-popover placement="bottom">
              <c-popover-trigger>
                <c-link
                  display="flex"
                  alignItems="center"
                  fontSize="xs"
                  fontWeight="500"
                >
                  <svg
                    fill="#495057"
                    v-chakra="{
                      width: '12px',
                      height: '12px',
                      mr: '2',
                    }"
                  >
                    <use href="@/assets/icons/icon-add.svg#add"></use>
                  </svg>
                  Select Competitor to compare
                </c-link>
              </c-popover-trigger>
              <c-popover-content w="180px">
                <c-popover-body p="0" w="max-content">
                  <c-checkbox-group
                    :default-value="selectedCompetitors"
                    @change="onCompetitorSelect($event)"
                  >
                    <c-checkbox
                      v-for="competitor in competitors"
                      :key="competitor.id"
                      :value="competitor.id + ''"
                      alignItems
                    >
                      <c-text fontSize="sm">
                        {{ competitor.name }}
                      </c-text>
                    </c-checkbox>
                  </c-checkbox-group>
                </c-popover-body>
              </c-popover-content>
            </c-popover>
          </th>
        </tr>
      </thead>
      <tbody class="comparison__table__body">
        <tr v-for="category in visibleFieldsCategories" :key="category.key">
          <td class="comparison__table__body__cell">
            <c-flex align="center" justify="space-between">
              <c-popover placement="bottom" v-slot="{}">
                <c-popover-trigger cursor="pointer">
                  <c-box
                    my="2"
                    mr="3"
                    v-chakra="{
                      height: 'auto',
                    }"
                  >
                    <svg
                      v-chakra="{
                        width: '10px',
                        height: '16px',
                        fill: '#ef5323',
                      }"
                    >
                      <use href="@/assets/icons/vertical-dots.svg#dots" />
                    </svg>
                  </c-box>
                </c-popover-trigger>
                <c-popover-content maxWidth="150px" zIndex="modal">
                  <c-popover-body p="0">
                    <c-list fontSize="sm">
                      <c-list-item>
                        <c-box
                          cursor="pointer"
                          w="100%"
                          px="2"
                          py="2"
                          @click="removeCategory(category)"
                          >Delete</c-box
                        >
                      </c-list-item>
                    </c-list>
                  </c-popover-body>
                </c-popover-content>
              </c-popover>

              <c-text mx="auto" textAlign="center">
                {{ category.title }}
              </c-text>
            </c-flex>
          </td>
          <td class="comparison__table__body__cell">
            <c-editable
              :default-value="
                selfCompetitor ? selfCompetitor[category.key] : null
              "
              v-if="category.type === 'text'"
              :value="selfCompetitor ? selfCompetitor[category.key] : null"
              :isDisabled="disabled"
              font-size="14px"
              textAlign="center"
              @input="(e) => (editableValue = e.target.value)"
              @submit="onEditableValueInput(null, category.key, selfCompetitor)"
            >
              <c-editable-preview />
              <c-editable-input />
            </c-editable>
            <component
              v-else
              :is="category.component"
              v-bind="category.props"
              :value="selfCompetitor ? selfCompetitor[category.key] : null"
              @input="
                onCompetitorValueInput(
                  $event,
                  null,
                  category.key,
                  selfCompetitor
                )
              "
              :disabled="disabled"
            />
          </td>
          <template v-for="(competitor, competitorIndex) in competitors">
            <td
              :key="competitor.name"
              class="comparison__table__body__cell"
              v-if="selectedCompetitors.includes(competitor.id + '')"
            >
              <c-editable
                v-if="category.type === 'text'"
                :default-value="competitor[category.key]"
                :value="competitor[category.key]"
                :isDisabled="disabled"
                font-size="14px"
                textAlign="center"
                @input="(e) => (editableValue = e.target.value)"
                @submit="
                  onEditableValueInput(competitorIndex, category.key, null)
                "
              >
                <c-editable-preview />
                <c-editable-input />
              </c-editable>
              <component
                v-else
                :is="category.component"
                v-bind="category.props"
                :disabled="disabled"
                :value="competitor[category.key]"
                @input="
                  onCompetitorValueInput($event, competitorIndex, category.key)
                "
              />
            </td>
          </template>
          <td class="comparison__table__body__cell"></td>
        </tr>
      </tbody>
      <tfoot class="comparison__table__footer">
        <tr class="comparison__table__footer__row">
          <td class="comparison__table__footer__cell" colspan="100">
            <c-popover placement="bottom">
              <c-popover-trigger>
                <c-link
                  display="flex"
                  alignItems="center"
                  color="#798892"
                  fontSize="sm"
                  fontWeight="500"
                  width="max-content"
                >
                  <svg
                    fill="#798892"
                    v-chakra="{
                      width: '12px',
                      height: '12px',
                      mr: '2',
                    }"
                  >
                    <use href="@/assets/icons/icon-add.svg#add"></use>
                  </svg>
                  <span> Select Category</span>
                </c-link>
              </c-popover-trigger>
              <c-popover-content w="max-content">
                <c-popover-body p="0">
                  <c-list>
                    <c-list-item fontSize="sm">
                      <c-popover trigger="hover" :placement="'right'">
                        <c-popover-trigger>
                          <c-pseudo-box
                            as="a"
                            display="flex"
                            py="2"
                            px="4"
                            alignItems="center"
                            :_hover="{
                              textDecoration: 'none',
                              cursor: 'pointer',
                              bg: 'vc-orange.50',
                            }"
                            justifyContent="space-between"
                            fontSize="sm"
                            cursor="pointer"
                          >
                            <span> Analysis </span>
                            <svg
                              v-chakra="{
                                width: '10px',
                                height: '10px',
                                fill: '#ef5323',
                                ml: '2',
                              }"
                            >
                              <use
                                href="@/assets/icons/icon-caret-right.svg#caret"
                              ></use>
                            </svg>
                          </c-pseudo-box>
                        </c-popover-trigger>
                        <c-popover-content width="max-content">
                          <c-popover-body p="3" width="max-content">
                            <c-checkbox-group
                              :default-value="analysisVisibleFields"
                              @change="onFieldsChange($event, 'analysis')"
                            >
                              <c-checkbox
                                v-for="(field, index) in analysisFields"
                                :key="'analysis-select' + field.title + index"
                                :value="field.key"
                              >
                                <c-text fontSize="sm">
                                  {{ field.title }}
                                </c-text>
                              </c-checkbox>
                            </c-checkbox-group>
                          </c-popover-body>
                        </c-popover-content>
                      </c-popover>
                    </c-list-item>
                    <c-list-item fontSize="sm">
                      <c-popover trigger="hover" :placement="'right'">
                        <c-popover-trigger>
                          <c-pseudo-box
                            as="a"
                            display="flex"
                            py="2"
                            px="4"
                            alignItems="center"
                            :_hover="{
                              textDecoration: 'none',
                              cursor: 'pointer',
                              bg: 'vc-orange.50',
                            }"
                            justifyContent="space-between"
                            fontSize="sm"
                            cursor="pointer"
                          >
                            <span> Conclusion </span>
                            <svg
                              v-chakra="{
                                width: '10px',
                                height: '10px',
                                fill: '#ef5323',
                                ml: '2',
                              }"
                            >
                              <use
                                href="@/assets/icons/icon-caret-right.svg#caret"
                              ></use>
                            </svg>
                          </c-pseudo-box>
                        </c-popover-trigger>
                        <c-popover-content width="max-content">
                          <c-popover-body p="3" width="max-content">
                            <c-checkbox-group
                              :default-value="conclusionVisibleFields"
                              @change="onFieldsChange($event, 'conclusion')"
                            >
                              <c-checkbox
                                v-for="(field, index) in conclusionFields"
                                :key="'cat-select' + field.title + index"
                                :value="field.key"
                              >
                                <c-text fontSize="sm">
                                  {{ field.title }}
                                </c-text>
                              </c-checkbox>
                            </c-checkbox-group>
                          </c-popover-body>
                        </c-popover-content>
                      </c-popover>
                    </c-list-item>
                    <!-- <c-list-item fontSize="sm" borderTopWidth="1px">
                      <c-text
                        fontWeight="500"
                        px="4"
                        py="2"
                        color="vc-orange.400"
                        >Your Category</c-text
                      >
                    </c-list-item> -->
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import competitorMixin from '@/mixins/competitor.js';

export default {
  mixins: [competitorMixin],
  props: ['disabled'],
  data() {
    return {
      editableValue: '',
    };
  },
  computed: {
    ...mapState('company', {
      competitors: (state) =>
        state.competitors.filter((competitor) => !competitor.isSelf),
      selfCompetitor: (state) =>
        state.competitors.find((competitor) => competitor.isSelf),
      selectedCompetitors: (state) => state.selectedCompetitors,
    }),
    visibleFields() {
      return [...this.analysisVisibleFields, ...this.conclusionVisibleFields];
    },
    visibleFieldsCategories() {
      return this.visibleFields.map(
        (field) =>
          this.analysisFields.find((_field) => _field.key === field) ||
          this.conclusionFields.find((_field) => _field.key === field)
      );
    },
  },
  methods: {
    ...mapMutations({
      setSelectedCompetitors: 'company/setSelectedCompetitors',
    }),
    onEditableValueInput(competitorIndex, fieldKey, data) {
      this.onCompetitorValueInput(
        this.editableValue,
        competitorIndex,
        fieldKey,
        data
      );
    },
    removeCategory(field) {
      switch (field.fieldType) {
        case 'analysis':
          {
            const index = this.analysisVisibleFields.indexOf(field.key);
            this.analysisVisibleFields.splice(index, 1);
            this.onFieldsChange(this.analysisVisibleFields, field.fieldType);
          }
          break;
        case 'conclusion':
          {
            const index = this.conclusionVisibleFields.indexOf(field.key);
            this.conclusionVisibleFields.splice(index, 1);
            this.onFieldsChange(this.conclusionVisibleFields, field.fieldType);
          }
          break;
        default:
          break;
      }
    },
    onCompetitorSelect(e) {
      this.setSelectedCompetitors([...e]);
    },
  },
};
</script>

<style lang="scss" scoped>
.comparison {
  @apply overflow-x-auto my-4;
  &__table {
    @apply w-full;
    &__header {
      &__cell {
        @apply px-4 py-3;
        border: 1px solid rgba(112, 112, 112, 0.2);
        border-width: 1px 1px 1px 0;
        height: 80px;
      }
    }
    &__body {
      &__cell {
        @apply px-4 py-3;
        vertical-align: top;
        min-height: 70px;
        min-width: 300px;
        border: 1px solid rgba(112, 112, 112, 0.2);
        border-width: 1px 1px 1px 0;
        &:first-child {
          @apply font-medium;
          color: $color-grey;
        }
      }
    }
    &__footer {
      &__cell {
        height: 50px;
        border: 1px solid rgba(112, 112, 112, 0.2);
        border-width: 1px 0 1px 0;
        color: $color-grey;
      }
    }
  }
}
</style>
