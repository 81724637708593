<template>
  <c-box p="5" boxShadow="0px 3px 6px rgba(186, 186, 186, 0.16)">
    <c-flex justify="space-between">
      <c-heading fontSize="md" fontWeight="600" as="h6">
        Price sensitivity
      </c-heading>
      <!-- <c-link
        display="flex"
        @click="onEditClick"
        color="#ef5323"
        fontWeight="500"
        v-if="!disabled"
      >
        <c-text as="span" fontSize="sm">Edit</c-text>
        <svg
          v-chakra="{
            w: '18px',
            h: '18px',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/edit-fill.svg#edit"></use>
        </svg>
      </c-link> -->
    </c-flex>
    <c-text fontSize="sm" mt="2" mb="8" color="#798892">
      On a scale from 0-10 how sensitive is your customer segment to price
      changes?
    </c-text>
    <AppSelect
      color="dark"
      :bordered="true"
      :reset="true"
      label="label"
      :reduce="(level) => level.value"
      :options="levels"
      v-model="sensitivity"
      indicator-fill="orange"
      :disabled="disabled"
    />
  </c-box>
</template>

<script>
import AppSelect from './../../../components/AppSelect.vue';

export default {
  props: ['disabled', 'value'],
  components: {
    AppSelect,
  },
  data() {
    return {
      sensitivity: 'NOT_SENSITIVE',
      levels: [
        { label: 'Not Sensitive (0 - 1)', value: 'NOT_SENSITIVE' },
        { label: 'Somewhat sensitive(2-5)', value: 'SOMEWHAT_SENSITIVE' },
        { label: 'Sensitive (6-8)', value: 'SENSITIVE' },
        { label: 'Extremely sensitive (9-10)', value: 'EXTREMELY_SENSITIVE' },
      ],
    };
  },
  created() {
    if (this.value) {
      this.sensitivity = this.value;
    }
  },
  watch: {
    value() {
      if (this.sensitivity !== this.value) {
        this.sensitivity = this.value;
      }
    },
    sensitivity() {
      this.$emit('updateField', {
        key: 'priceSensitivity',
        value: this.sensitivity,
      });
    },
  },
  methods: {
    onEditClick() {},
  },
};
</script>

<style></style>
