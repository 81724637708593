<template>
  <div class="importance">
    <v-select
      :clearable="false"
      :searchable="false"
      label="name"
      :reduce="(importance) => importance.value"
      class="vc-select importance__select"
      v-model="importance"
      :options="importanceLevels"
      placeholder="select"
      :disabled="disabled"
    >
      <template #selected-option="{ name, color, colorRgba }">
        <div
          class="importance__select__option"
          v-chakra="{ color, backgroundColor: colorRgba }"
        >
          <svg v-chakra="{ fill: color }">
            <use href="@/assets/icons/flag-line.svg#flag"></use>
          </svg>
          {{ name }}
        </div>
      </template>
      <template v-slot:option="{ name, color }">
        <span class="importance__select__item" v-chakra="{ color }">
          <svg v-chakra="{ fill: color }">
            <use href="@/assets/icons/flag-line.svg#flag"></use>
          </svg>
          {{ name }}
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: ['disabled', 'value'],
  data() {
    return {
      importance: 'urgent',
      importanceLevels: [
        {
          name: 'Urgent',
          value: 'urgent',
          color: '#ee2f1f',
          colorRgba: 'rgba(238, 48, 31, 0.1)',
        },
        {
          name: 'High',
          value: 'high',
          color: '#ef5323',
          colorRgba: 'rgba(239, 83, 35, 0.1)',
        },
        {
          name: 'Normal',
          color: '#ef7923',
          value: 'normal',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        {
          name: 'Low',
          value: 'low',
          color: '#37d5aa',
          colorRgba: 'rgba(55, 213, 170, 0.1)',
        },
        {
          name: 'Clear',
          value: 'clear',
          color: '#56b5db',
          colorRgba: 'rgba(86, 181, 219, 0.1)',
        },
      ],
    };
  },
  created() {
    if (this.value) {
      this.importance = this.value;
    }
  },
  watch: {
    value() {
      this.importance = this.value;
    },
    importance() {
      if (this.importance !== this.value) {
        this.$emit('input', this.importance);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.importance {
  &__select {
    width: 100px;
    ::v-deep {
      .vs__dropdown-toggle {
        @apply text-xs;
        height: 35px;
        width: 100%;
        border: none;
        padding: 0;
      }
      .vs__dropdown-menu {
        min-width: 100px;
      }
      .vs__selected {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      .vs__dropdown-option {
        height: 35px;
      }
      .vs__actions {
        padding: 0;
      }
    }
    &.vs--disabled {
      ::v-deep {
        .vs__dropdown-toggle {
          background: transparent;
        }
      }
    }
    &__option,
    &__item {
      @apply h-full w-full font-medium flex items-center justify-center;
      border-radius: 8px;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    &__item {
      @apply justify-start text-xs;
    }
  }
}
</style>
