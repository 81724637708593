import { render, staticRenderFns } from "./ValueProposition.vue?vue&type=template&id=0c67f5b0&scoped=true"
import script from "./ValueProposition.vue?vue&type=script&lang=js"
export * from "./ValueProposition.vue?vue&type=script&lang=js"
import style0 from "./ValueProposition.vue?vue&type=style&index=0&id=0c67f5b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c67f5b0",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CSliderTrack: require('@chakra-ui/vue').CSliderTrack, CSliderFilledTrack: require('@chakra-ui/vue').CSliderFilledTrack, CSliderThumb: require('@chakra-ui/vue').CSliderThumb, CSlider: require('@chakra-ui/vue').CSlider, CBox: require('@chakra-ui/vue').CBox})
