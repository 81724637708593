import { render, staticRenderFns } from "./EditPresentation.vue?vue&type=template&id=a09b3222&scoped=true"
import script from "./EditPresentation.vue?vue&type=script&lang=js"
export * from "./EditPresentation.vue?vue&type=script&lang=js"
import style0 from "./EditPresentation.vue?vue&type=style&index=0&id=a09b3222&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a09b3222",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CIconButton: require('@chakra-ui/vue').CIconButton})
