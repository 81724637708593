<template>
  <div class="business-model">
    <GrowthSectionHeader
      :criteria="criteria"
      :completion="getBusinessModelLevel"
    />
    <div class="business-model__tasks">
      <c-grid
        w="100%"
        :template-columns="{
          base: '1fr',
          lg: 'repeat(2, minmax(0, 1fr))',
          xl: 'repeat(3, minmax(0, 1fr))',
        }"
        autoRows="1fr"
        gap="8"
      >
        <OverallProblem :disabled="isShareView" />
        <OverallSolution :disabled="isShareView" />
        <c-box :grid-column-start="{ lg: 'span 2', xl: 'auto' }">
          <OverallValueProposition :disabled="isShareView" />
        </c-box>
      </c-grid>
      <c-box my="2em">
        <CustomerSegmentCard :disabled="isShareView" />
      </c-box>
      <c-box my="2em">
        <ChannelsCard :disabled="isShareView" />
      </c-box>
      <c-box my="2em">
        <KeyResourcesCard :disabled="isShareView" />
      </c-box>
      <c-box my="2em">
        <KeyActivitiesCard :disabled="isShareView" />
      </c-box>
      <c-box my="2em">
        <KeyPartnersCard :disabled="isShareView" />
      </c-box>
      <c-box my="2em">
        <RevenueModelCard :disabled="isShareView" />
      </c-box>
      <c-box my="2em">
        <CostModelCard :disabled="isShareView" />
      </c-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import GrowthSectionHeader from './components/GrowthSectionHeader.vue';
import OverallProblem from './components/business-model/OverallProblem.vue';
import KeyActivitiesCard from './components/business-model/KeyActivitiesCard.vue';
import KeyPartnersCard from './components/business-model/KeyPartnersCard.vue';
import KeyResourcesCard from './components/business-model/KeyResourcesCard.vue';
import RevenueModelCard from './components/business-model/RevenueModelCard.vue';
import CostModelCard from './components/business-model/CostModelCard.vue';
import OverallSolution from './components/business-model/OverallSolution.vue';
import ChannelsCard from './components/business-model/ChannelsCard.vue';
import OverallValueProposition from './components/business-model/OverallValueProposition.vue';
import CustomerSegmentCard from './components/business-model/CustomerSegmentCard.vue';
import scrollToComponent from '@/mixins/scroll-to-component.js';
import pageVisits from '@/mixins/page-visits.js';

export default {
  mixins: [scrollToComponent, pageVisits],
  components: {
    GrowthSectionHeader,
    KeyActivitiesCard,
    KeyPartnersCard,
    RevenueModelCard,
    CostModelCard,
    OverallProblem,
    OverallSolution,
    ChannelsCard,
    OverallValueProposition,
    CustomerSegmentCard,
    KeyResourcesCard,
  },
  data() {
    return {
      criteria: {
        title: 'Business Model',
        description: `The Business Model is a one page document that includes the key elements that make up your business idea. 
                      Allowing you to ensure that you and your team continuously have a common understanding of the most fundamental aspects of your company, 
                      whilst also serving as a one page business plan for potential investors and partners
                    `,
        tasks: [
          { title: 'Overall Problem', value: 'overall_problem' },
          { title: 'Overall Solution', value: 'overall_solution' },
          {
            title: 'Overall Value Proposition',
            value: 'overall_value_proposition',
          },
          { title: 'Customer Segment', value: 'customer_segment' },
          { title: 'Channels', value: 'channels' },
          { title: 'Key Resources', value: 'key_resources' },
          { title: 'Key Activities', value: 'key_activities' },
          { title: 'Key Resources', value: 'key_resources' },
          { title: 'Key Partners', value: 'key_partners' },
          { title: 'Revenue Model', value: 'revenue' },
          { title: 'Costs', value: 'costs' },
        ],
      },
    };
  },
  computed: {
    ...mapState('company', {
      isShareView: (state) => state.isShareView,
    }),
    ...mapGetters('company', ['getBusinessModelLevel']),
  },
};
</script>

<style lang="scss">
.business-model {
  @apply pb-4;
  &__tasks {
    @apply px-6;
  }
  @screen lg {
    &__tasks {
      @apply px-0;
    }
  }
}
</style>
