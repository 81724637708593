import { render, staticRenderFns } from "./RevenueModelFields.vue?vue&type=template&id=1cbdb62a&scoped=true"
import script from "./RevenueModelFields.vue?vue&type=script&lang=js"
export * from "./RevenueModelFields.vue?vue&type=script&lang=js"
import style0 from "./RevenueModelFields.vue?vue&type=style&index=0&id=1cbdb62a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cbdb62a",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CFlex: require('@chakra-ui/vue').CFlex, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CText: require('@chakra-ui/vue').CText, CHeading: require('@chakra-ui/vue').CHeading, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CSpinner: require('@chakra-ui/vue').CSpinner, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal, CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
