<template>
  <c-pseudo-box
    w="100%"
    h="100%"
    :bg="presentation.data.value_proposition.backgroundColor"
    display="flex"
  >
    <c-box w="35%">
      <c-flex ml="20px" mt="16" direction="column" align="start">
        <c-text color="#000" fontWeight="700" fontSize="4xl" zIndex="2"
          >Value Proposition</c-text
        >
        <c-box
          mt="5"
          h=".5em"
          w="8em"
          :bg="`${presentation.data.color}80`"
          zIndex="2"
        ></c-box>
      </c-flex>
    </c-box>
    <c-box
      w="45%"
      h="100%"
      :bgImage="`linear-gradient(
        to bottom ,
        ${presentation.data.color}80,
        #fff
      )`"
    >
      <div
        class="draggable-div tessst"
        :style="{
          top: position.top + 'px',
          left: position.left + 'px',
          fontSize: presentation.data.value_proposition.fontSize + 'px',
        }"
        @mousedown="startDrag"
        @mousemove="handleDrag"
        @mouseup="stopDrag"
        @mouseleave="stopDrag"
        v-html="selectedItems"
      ></div>
    </c-box>
    <c-box
      w="20%"
      :backgroundImage="`url(${imageUrl})`"
      backgroundSize="cover"
    ></c-box>
    <c-box position="absolute" bottom="5" left="5" ml="auto" mt="auto">
      <WaterMark :direction="'row'" />
    </c-box>
  </c-pseudo-box>
</template>

<script>
import { mapMutations } from 'vuex';
import { EventBus } from '@/eventBus.js';
import WaterMark from '@/components/WaterMark.vue';
import valuePropositionMixin from '@/mixins/data/valueProposition.js';
import { updatePresentation } from '@/services/presentation';

export default {
  mixins: [valuePropositionMixin],
  components: { WaterMark },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: "''",
      imageUrl:
        'https://vibrantcreator-files.s3.eu-north-1.amazonaws.com/image/prob_sol_default_1.png',
      isDragging: false,
      startPosition: { x: 0, y: 0 },
      position: { top: 300, left: 600 },
    };
  },
  mounted() {
    EventBus.$on('value_proposition', async (configForm) => {
      this.updatePresentation({
        data: {
          ...this.presentation.data,
          value_proposition: configForm,
        },
      });

      try {
        const response = await updatePresentation({
          id: this.presentation.id,
          append: { data: { value_proposition: configForm } },
        });
        const pre = response.data.update_presentation_by_pk;
        this.updatePresentation({
          data: {
            ...this.presentation.data,
            value_proposition: pre.data.value_proposition,
          },
        });
      } catch (e) {
        this.updatePresentation({
          data: { ...this.presentation.data, value_proposition: configForm },
        });
        this.$toast({
          title: 'Error!!!',
          description: `An error occured while updating color, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      updatePresentation: 'presentation/updatePresentation',
    }),
    startDrag(event) {
      this.isDragging = true;
      this.startPosition.x = event.clientX;
      this.startPosition.y = event.clientY;
    },
    handleDrag(event) {
      if (this.isDragging) {
        const offsetX = event.clientX - this.startPosition.x;
        const offsetY = event.clientY - this.startPosition.y;
        this.position.left += offsetX;
        this.position.top += offsetY;
        this.startPosition.x = event.clientX;
        this.startPosition.y = event.clientY;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
  },
};
</script>

<style>
.tessst {
  text-align: left !important;
}
</style>
