var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"id":"swot"}},[_c('TaskCard',{attrs:{"title":'SWOT',"canAssign":true,"hasProgress":true,"hasAssumption":false,"hasImportance":true,"hasFooter":true,"hasNotification":true,"hasVisibility":true,"hasComments":true,"hasMounted":_vm.hasMounted,"disabled":_vm.disabled,"taskData":_vm.riskAssessmentData,"accessKey":'risk_assessment_assignments',"accessFn":_vm.updateAccess,"visibilityKey":'risk_assessment_visibilities',"visibilityFn":_vm.updateVisibility,"isUpdatingMetaData":_vm.isUpdatingMetadata,"explanationKey":"swot"},on:{"update":_vm.updateData}},[(_vm.isFetchingData)?_c('ContentLoader',{attrs:{"viewBox":"0 0 300 200","primaryColor":"#ddd"}},[_c('rect',{attrs:{"x":"0","y":"0","rx":"3","ry":"3","width":"100","height":"50"}}),_c('rect',{attrs:{"x":"200","y":"0","rx":"3","ry":"3","width":"100","height":"50"}}),_c('rect',{attrs:{"x":"0","y":"100","rx":"3","ry":"3","width":"100","height":"50"}})]):_c('c-grid',{attrs:{"template-columns":{ base: '1fr', lg: 'repeat(2, 1fr)' },"gap":"12","my":"6"}},_vm._l((_vm.swot),function(area,areaIndex){return _c('c-box',{key:area.key,staticClass:"factor__item",attrs:{"w":"100%"},on:{"drop":function($event){return _vm.moveFactor($event, areaIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('c-flex',{attrs:{"mb":"8","align":"center"}},[_c('c-heading',{attrs:{"color":"#495057","fontSize":"lg","fontWeight":"600"}},[_vm._v(" "+_vm._s(area.title)+" ")]),(!_vm.disabled)?_c('c-link',{on:{"click":function($event){return _vm.addFactor(areaIndex)}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                width: '20px',
                height: '20px',
                ml: '0.8em',
              }),expression:"{\n                width: '20px',\n                height: '20px',\n                ml: '0.8em',\n              }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})])]):_vm._e()],1),_c('c-grid',{attrs:{"template-columns":{ base: '1fr', lg: 'repeat(2, 250px)' },"gap":"12"}},_vm._l((area.factors),function(factor,index){return _c('c-box',{key:factor.id || factor.tempId,staticClass:"point-card",attrs:{"h":"180px","w":"100%","p":"6","borderRadius":"8px","boxShadow":"3px 3px 10px rgba(186, 186, 186, 0.36)","draggable":!_vm.disabled},on:{"dragstart":function($event){return _vm.pickupFactor($event, index, areaIndex)},"drop":function($event){$event.stopPropagation();return _vm.moveFactor($event, areaIndex, index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '4',
                h: '4',
                cursor: 'pointer',
                fill: 'vc-orange.400',
                position: 'absolute',
                top: '-5px',
                right: '-2px',
                display: 'none',
              }),expression:"{\n                w: '4',\n                h: '4',\n                cursor: 'pointer',\n                fill: 'vc-orange.400',\n                position: 'absolute',\n                top: '-5px',\n                right: '-2px',\n                display: 'none',\n              }"}],staticClass:"point-card__delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteFactor({ factor, index, areaIndex, area })}}},[_c('use',{attrs:{"href":require("@/assets/icons/icon-close-fill.svg") + "#close"}})]),_c('c-editable',{attrs:{"default-value":factor.title || 'Title',"font-size":"lg","isDisabled":_vm.disabled,"w":"100%","fontWeight":"600","mb":"2"},on:{"submit":function($event){return _vm.onTitleSubmit($event, areaIndex, index)}}},[_c('c-editable-preview',{attrs:{"textAlign":"center","w":"100%"}}),_c('c-editable-input',{attrs:{"textAlign":"center"}})],1),_c('EditableText',{attrs:{"type":`${area.title}${index}`,"disabled":_vm.disabled,"editabelIndex":{ areaIndex, index }},on:{"updateDescription":_vm.updateDescription},model:{value:(factor.description),callback:function ($$v) {_vm.$set(factor, "description", $$v)},expression:"factor.description"}})],1)}),1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }