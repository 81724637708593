<template>
  <TaskCard
    title="Target Market"
    :description="''"
    :hasComments="true"
    :hasFooter="true"
    :hasMounted="hasMounted"
    :hasProgress="true"
    :canAssign="true"
    :hasImportance="true"
    :hasNotification="true"
    :hasVisibility="true"
    :hasAssumption="true"
    id="target-audience"
    :taskData="businessModelData"
    :accessKey="'business_model_assignments'"
    :accessFn="updateAccess"
    @update="updateData"
    :hasAccess="hasAccess"
    :visibilityKey="'business_model_visibilities'"
    :visibilityFn="updateVisibility"
    :isUpdatingMetaData="isUpdatingMetadata"
    :disabled="disabled"
    explanationKey="customer_segment"
  >
    <ContentLoader
      v-if="isFetchingData"
      viewBox="0 0 250 130"
      primaryColor="#ddd"
    >
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
    <CustomerSegment
      :disabled="disabled"
      v-else
      :customerSegment="businessModelData"
    />
  </TaskCard>
</template>

<script>
import TaskCard from './../TaskCard.vue';
import CustomerSegment from './CustomerSegment.vue';
import { ContentLoader } from 'vue-content-loader';

import businessModelMixin from '@/mixins/business-model.js';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [businessModelMixin],
  components: {
    TaskCard,
    ContentLoader,
    CustomerSegment,
  },
  data() {
    return {
      businessModelType: 'customer_segment',
      hasMounted: false,
    };
  },
  methods: {},
};
</script>

<style></style>
