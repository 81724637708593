<template>
  <div class="foundation">
    <GrowthSectionHeader
      :criteria="criteria"
      :completion="getFoundationLevel"
    />
    <div class="foundation__tasks">
      <c-grid
        w="100%"
        :template-columns="{ base: '1fr', lg: 'repeat(2, 1fr)' }"
        gap="12"
      >
        <c-box mb="2rem">
          <VisionCard id="vision" :disabled="isShareView" />
          <BeliefCard id="belief" :disabled="isShareView" />
        </c-box>
        <c-box>
          <c-box mb="2rem">
            <TaskCard
              :title="'Business Sectors'"
              :description="'Select which business sector your business operates in'"
              :hasProgress="false"
            >
              <SectorField
                :disabled="isShareView || !isCompanyAdmin"
                v-model="companySectors"
                :searchable="true"
                :options="sectors"
                :showHeader="true"
              />
            </TaskCard>
          </c-box>
          <c-box>
            <TaskCard
              :title="'Business Stage'"
              :description="'What current stage is your business on?'"
              :hasProgress="false"
              v-chakra="{
                mb: '2rem',
              }"
            >
              <BusinessStageSelect />
            </TaskCard>
          </c-box>
          <c-box>
            <TaskCard
              :title="'Customer Segment'"
              :description="'Select the type of customer segment your business focuses on.'"
              :hasProgress="false"
              v-chakra="{
                mb: '2rem',
              }"
            >
              <SectorField
                :disabled="isShareView || !isCompanyAdmin"
                :showHeader="false"
                v-model="companyCustomerSegments"
                :options="customerSegments"
                :searchable="false"
              />
            </TaskCard>
          </c-box>
          <!-- <c-box>
            <TaskCard
              :title="'Connect to Hub or Incubator'"
              :description="'Lorem ipsum dolor sit amet, consectetur adipiscing'"
              :hasProgress="true"
              v-chakra="{
                mb: '2rem',
              }"
            >
              <Incubators />
            </TaskCard>
          </c-box> -->
        </c-box>
      </c-grid>
      <c-stack :spacing="8">
        <c-box id="values">
          <ValuesCard :disabled="isShareView" />
        </c-box>
        <c-box id="culture">
          <CultureCard :disabled="isShareView" />
        </c-box>
        <c-box id="company-sdg">
          <SDGCard :disabled="isShareView" />
        </c-box>
        <c-box id="company-skill">
          <SkillsCard :disabled="isShareView" />
        </c-box>
        <c-box id="team">
          <TeamCard :disabled="isShareView" />
        </c-box>
        <c-box id="advisory-board">
          <BoardCard :disabled="isShareView" />
        </c-box>
      </c-stack>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import GrowthSectionHeader from './components/GrowthSectionHeader.vue';
import TaskCard from './components/TaskCard.vue';
import VisionCard from './components/foundation/VisionCard.vue';
import BeliefCard from './components/foundation/BeliefCard.vue';
import SDGCard from './components/foundation/SDGCard.vue';
import TeamCard from './components/foundation/TeamCard.vue';
import BoardCard from './components/foundation/BoardCard.vue';
import SkillsCard from './components/foundation/SkillsCard.vue';
import BusinessStageSelect from './components/BusinessStageSelect.vue';
import SectorField from '../../Onboarding/components/SectorField.vue';
// import Incubators from './components/foundation/Incubators.vue';
import ValuesCard from './components/foundation/ValuesCard.vue';
import CultureCard from './components/foundation/CultureCard.vue';

import { updateCompany, updateCompanySectors } from '@/services/company.js';
import scrollToComponent from '@/mixins/scroll-to-component.js';
import pageVisits from '@/mixins/page-visits.js';

export default {
  mixins: [scrollToComponent, pageVisits],
  components: {
    GrowthSectionHeader,
    TaskCard,
    VisionCard,
    BeliefCard,
    SDGCard,
    TeamCard,
    BoardCard,
    SkillsCard,
    BusinessStageSelect,
    SectorField,
    // Incubators,
    ValuesCard,
    CultureCard,
  },
  data() {
    return {
      criteria: {
        title: 'Foundation',
        description: `Foundation is the ground pillar that serves as your business compass. It conveys your purpose, direction, and the underlying values of your business. It serves as a powerful tool that keeps everyone in your team focused on where the organization is going and what it’s trying to achieve.
                    `,
        tasks: [
          { title: 'Vision', value: 'vision' },
          { title: 'Belief', value: 'belief' },
          { title: 'Sustainable Development Goals', value: 'company_sdg' },
          { title: 'Values', value: 'values' },
          { title: 'Culture', value: 'culture' },
          { title: 'Skills', value: 'company_skill' },
        ],
      },
      companySectors: [],
      customerSegments: [
        { id: 'B2B', name: 'Business to business' },
        { id: 'B2C', name: 'Business to consumer' },
        { id: 'B2G', name: 'Business to Government' },
        { id: 'C2C', name: 'Consumer to Consumer' },
        { id: 'G2C', name: 'Government to Consumer' },
        { id: 'G2B', name: 'Government to Business' },
      ],
      companyCustomerSegments: [],
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      isShareView: (state) => state.isShareView,
    }),
    ...mapState('config', {
      sectors: (state) => state.businessSectors,
    }),
    ...mapGetters('company', ['getFoundationLevel', 'isCompanyAdmin']),
  },
  created() {
    this.companySectors = this.getSectorIds();
    this.companyCustomerSegments = [...this.activeCompany.customerSegments];
  },
  watch: {
    companySectors(val, oldVal) {
      if (oldVal.length) {
        updateCompanySectors({
          data: this.companySectors,
          companySectors: this.getSectorIds(),
          companyId: this.activeCompany.id,
        }).then((res) => {
          this.setActiveCompany({
            ...this.activeCompany,
            company_sectors: [...res],
          });
        });
      }
    },
    companyCustomerSegments() {
      updateCompany({
        id: this.activeCompany.id,
        set: { customerSegments: this.companyCustomerSegments },
      }).then((res) => {
        this.setActiveCompany({
          ...this.activeCompany,
          customerSegments: [...res.data.update_company_by_pk.customerSegments],
        });
      });
      // if (oldVal.length) {
      // }
    },
  },
  methods: {
    ...mapActions({
      setActiveCompany: 'company/setActiveCompany',
    }),
    getSectorIds() {
      return this.activeCompany.company_sectors.map(
        (sector) => sector.sectorId
      );
    },
  },
};
</script>

<style lang="scss">
.foundation {
  @apply h-full;
  &__tasks {
    @apply px-6 pb-8;
  }
  @screen lg {
    &__tasks {
      @apply px-0;
    }
  }
}
</style>
