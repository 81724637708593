<template>
  <c-flex align="center">
    <c-link @click="isDrawerOpen = true">
      <svg v-chakra w="6" h="6">
        <use href="@/assets/icons/icon-bell.svg#bell"></use>
      </svg>
    </c-link>
    <c-drawer
      :isOpen="isDrawerOpen"
      placement="right"
      :on-close="closeDrawer"
      :initialFocusRef="() => $refs.inputInsideModal"
    >
      <c-drawer-overlay />
      <c-drawer-content>
        <c-drawer-close-button />
        <c-drawer-header>Notifications</c-drawer-header>

        <c-drawer-body overflowY="scroll">
          <div v-if="notifications.length === 0">
            <c-text fontSize="sm">No notifications</c-text>
          </div>
          <div v-else>
            <div v-for="notification in notifications" :key="notification.id">
              <div class="notification" @click="goToNotification(notification)">
                <div class="_message">
                  <div class="notification-dot"></div>
                  <div>
                    <c-text fontSize="sm">{{
                      capitalizeFirstLetter(notification.message)
                    }}</c-text>
                    <c-text fontSize="xs">{{
                      notification.createdAt | moment('from')
                    }}</c-text>
                  </div>
                </div>

                <div class="user_name">
                  <span>{{ getFirstLetter(notification.message) }}</span>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    notificationType: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      isDrawerOpen: false,
      pages: [
        {
          name: 'Foundation',
          components: ['values', 'market-goals', 'vision'],
        },
        {
          name: 'BusinessModel',
          components: [
            'overall-problem',
            'overall-solution',
            'overall-value-proposition',
            'customer-segment',
            'channels',
            'key-resources',
            'key-activities',
            'key-partners',
            'revenue',
            'costs',
          ],
        },
        {
          name: 'MarketPotential',
          components: ['go-to-market-strategy', 'overview', 'vision', ''],
        },
        {
          name: 'RiskAssessment',
          components: ['swot', 'pestle', 'vision', 'risk-analysis'],
        },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      notifications: (state) => state.notifications,
    }),
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getFirstLetter(string) {
      return string.charAt(0).toUpperCase();
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    goToNotification(notification) {
      console.log(notification);
      if (notification.data) {
        let type = notification.data.type;
        type = type.replace(/_/g, '-');
        const page = this.pages.find((_page) =>
          _page.components.includes(type)
        );
        if (page) {
          this.$router.push({
            name: page.name,
            query: {
              to_component: type,
            },
          });
          this.closeDrawer();
        }
      }
    },
  },
};
</script>

<style scoped>
.notification {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 5px;
}
.notification-dot {
  @apply h-3 w-3 rounded-full border cursor-pointer;
  background-color: green;
  border-color: green;
  margin-right: 10px;
  margin-top: 4px;
}

._message {
  display: flex;
}

/* hover bacground color */
.notification:hover {
  background-color: #e9edf4;
  border-color: #e2e8f0;
}

.user_name {
  float: right;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: #e2e8f0;
  border-radius: 100%;
}
</style>
