import { render, staticRenderFns } from "./PESTLE.vue?vue&type=template&id=809c6632&scoped=true"
import script from "./PESTLE.vue?vue&type=script&lang=js"
export * from "./PESTLE.vue?vue&type=script&lang=js"
import style0 from "./PESTLE.vue?vue&type=style&index=0&id=809c6632&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "809c6632",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CHeading: require('@chakra-ui/vue').CHeading, CLink: require('@chakra-ui/vue').CLink, CEditablePreview: require('@chakra-ui/vue').CEditablePreview, CEditableInput: require('@chakra-ui/vue').CEditableInput, CEditable: require('@chakra-ui/vue').CEditable, CBox: require('@chakra-ui/vue').CBox})
