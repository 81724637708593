<template>
  <div class="sector-select" :class="[`sector-select--${size}`]">
    <v-select
      label="name"
      :options="options || sectors"
      class="vc-select"
      :reduce="(option) => option.id"
      :clearable="false"
      v-model="selectedSectors"
      :multiple="true"
      :disabled="disabled"
      :searchable="searchable"
    >
      <template v-if="showHeader" #list-header="{ filteredOptions }">
        <li class="options-header" style="font-size: small">
          <c-text as="span" color="#ef5323">
            {{ filteredOptions.length || sectors.length }}
          </c-text>
          industries
        </li>
      </template>
    </v-select>
    <svg
      v-if="searchable"
      v-chakra="{
        w: '18px',
        h: '18px',
        top: '50%',
        right: '20px',
        fill: '#ef5323',
        position: 'absolute',
        transform: 'translateY(-50%)',
      }"
    >
      <use href="@/assets/icons/icon-search.svg#icon-search"></use>
    </svg>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: [
    'field',
    'value',
    'size',
    'disabled',
    'searchable',
    'options',
    'showHeader',
  ],
  data() {
    return {
      selectedSectors: [],
    };
  },
  computed: {
    ...mapState('config', {
      sectors: (state) => state.businessSectors,
    }),
  },
  created() {
    if (this.value && this.value.length) {
      this.selectedSectors = this.value;
    }
  },
  watch: {
    value(val) {
      this.selectedSectors = val;
    },
    selectedSectors(val) {
      if (val && val.length) {
        this.$emit('input', val);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sector-select {
  @apply relative;
  max-width: 70%;
  &--full {
    max-width: 100%;
  }
  ::v-deep {
    .vc-select {
      .vs {
        &__dropdown-toggle {
          min-height: 45px;
          height: unset;
        }
      }
    }
  }
  .options-header {
    @apply flex;
    padding: 3px 20px;
    span {
      @apply mr-3;
    }
  }
}
</style>
