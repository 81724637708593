var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-pseudo-box',{attrs:{"w":"100%","h":"100%","bg":_vm.presentation.data.value_proposition.backgroundColor,"display":"flex"}},[_c('c-box',{attrs:{"w":"35%"}},[_c('c-flex',{attrs:{"ml":"20px","mt":"16","direction":"column","align":"start"}},[_c('c-text',{attrs:{"color":"#000","fontWeight":"700","fontSize":"4xl","zIndex":"2"}},[_vm._v("Value Proposition")]),_c('c-box',{attrs:{"mt":"5","h":".5em","w":"8em","bg":`${_vm.presentation.data.color}80`,"zIndex":"2"}})],1)],1),_c('c-box',{attrs:{"w":"45%","h":"100%","bgImage":`linear-gradient(
      to bottom ,
      ${_vm.presentation.data.color}80,
      #fff
    )`}},[_c('div',{staticClass:"draggable-div tessst",style:({
        top: _vm.position.top + 'px',
        left: _vm.position.left + 'px',
        fontSize: _vm.presentation.data.value_proposition.fontSize + 'px',
      }),domProps:{"innerHTML":_vm._s(_vm.selectedItems)},on:{"mousedown":_vm.startDrag,"mousemove":_vm.handleDrag,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}})]),_c('c-box',{attrs:{"w":"20%","backgroundImage":`url(${_vm.imageUrl})`,"backgroundSize":"cover"}}),_c('c-box',{attrs:{"position":"absolute","bottom":"5","left":"5","ml":"auto","mt":"auto"}},[_c('WaterMark',{attrs:{"direction":'row'}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }