<template>
  <c-drawer :isOpen="isDrawerOpen" placement="right" :on-close="closeDrawer">
    <c-drawer-overlay />
    <c-drawer-content>
      <c-drawer-close-button />
      <c-drawer-header>Customers</c-drawer-header>

      <c-drawer-body>
        <form v-chakra my="8">
          <ul v-chakra listStyle="none">
            <li v-for="item in data" :key="item.id" v-chakra fontSize="xl">
              <input type="checkbox" v-model="item.selected" />
              <c-text as="span">
                {{
                  (labelRenderFn
                    ? labelRenderFn(item[itemLabel])
                    : item[itemLabel]) | capitalize
                }}
              </c-text>
            </li>
          </ul>
        </form>
      </c-drawer-body>
      <c-button
        @click="updateFn(data)"
        variant-color="blue"
        display="flex"
        justifyContent="center"
        :disabled="hasSelectedMore || hasNoneSelected"
      >
        <c-text as="span"> Save </c-text>
        <c-spinner ml="10px" v-if="isUpdatingData" color="#fff" />
      </c-button>
    </c-drawer-content>
  </c-drawer>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import { capitalize } from "../../helpers/filters.js";

export default {
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
    closeDrawer: {
      type: Function,
    },
    items: {
      type: Array,
    },
    updateFn: {
      type: Function,
    },
    isUpdatingData: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: String,
    },
    maximumItemsVisible: {
      type: Number,
    },
    labelRenderFn: {
      type: Function,
    },
  },
  filters: {
    capitalize,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    hasSelectedMore() {
      return (
        this.data.filter((items) => items.selected).length >
        this.maximumItemsVisible
      );
    },
    hasNoneSelected() {
      return !this.data.some((items) => items.selected);
    },
  },
  created() {
    this.data = cloneDeep(this.items);
  },
};
</script>

<style></style>
