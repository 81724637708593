<template>
  <div>
    <!-- <c-button variant-color="blue" mr="3"> Download </c-button> -->
    <div v-if="dataLoading" style="text-align: center">
      <c-spinner ml="2" color="orange" thickness="2px" />
      <br />
      <p>Coming soon</p>
    </div>

    <div v-else id="pdfContainer" class="pdfContainer"></div>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  data() {
    return {
      dataLoading: true,
    };
  },
  created() {
    this.embedPDF();
  },
  methods: {
    getLineCanvas() {
      return [
        {
          type: 'line',
          x1: 0,
          y1: 0,
          x2: 520,
          y2: 0,
          lineColor: 'black',
          lineWidth: 3,
        },
      ];
    },
    getPdfDefinition() {
      let self = this;
      return {
        pageSize: 'A4',
        pageMargins: [10, 0, 10, 0],
        info: {
          title: 'Bussiness Plan',
          author: 'Charles Obeng',
          subject: 'Startup Plan',
          keywords: 'Business',
        },
        watermark: {
          text: 'Vibrant Creator',
          opacity: 0.1,
        },
        footer: function (currentPage, pageCount) {
          return [
            {
              margin: [0, 10, 0, 0],
              canvas: self.getLineCanvas(),
            },
            {
              fontSize: 10,
              bold: true,
              text: 'Lets do it together',
              alignment: 'center',
              margin: [0, 5, 0, 0],
            },
            {
              columns: [
                { width: '*', text: 'For Enquiries & Support:' },
                {
                  width: 'auto',
                  text: '123 Anywhere Street, Any City, State, 12345',
                  link: 'link',
                  color: 'green',
                },
                {
                  width: 'auto',
                  text: 'email: charlesprohram@gmail.com',
                  link: 'mailto:charlesprohram@gmail.com',
                  color: 'green',
                },
                {
                  width: 'auto',
                  text: 'www.errandmarket.com',
                  link: 'http://bagofholding.com',
                  color: 'green',
                },
              ],
              columnGap: 10,
            },
            {
              fontSize: 10,
              text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
              alignment: 'right',
              margin: [0, -9, 20, 0],
            },
          ];
        },
        pageBreakBefore: function (currentNode, followingNodesOnPage) {
          return (
            currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
          );
        },
        content: [
          {
            columns: [
              {
                style: 'heading',
                stack: [
                  {
                    text: 'Business Plan Document',
                  },
                  {
                    text: 'Plan',
                    fontSize: 15,
                    bold: true,
                  },
                ],
              },
              {
                text: '',
              },
            ],
          },
        ],
        styles: {
          heading: {
            fontSize: 18,
            bold: true,
            // background: 'yellow',
            alignment: 'right',
            margin: [5, 2, 10, 20],
          },
        },
      };
    },
    embedPDF() {
      const pdfDocGenerator = pdfMake.createPdf(this.getPdfDefinition());
      pdfDocGenerator.getDataUrl((dataUrl) => {
        const targetElement = document.querySelector('#pdfContainer');
        while (targetElement.firstChild) {
          targetElement.removeChild(targetElement.firstChild);
        }
        const iframe = document.createElement('iframe');
        iframe.src = dataUrl;
        targetElement.appendChild(iframe);
      });
    },
    generatePDF() {
      pdfMake.createPdf(this.getPdfDefinition()).download('businessPlan.pdf');
    },
  },
};
</script>

<style>
.pdfContainer iframe {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  height: 900px;
  border-width: 1px;
  border-style: dashed;
  border-color: initial;
  border-image: initial;
}
</style>
