export default {
  mounted() {
    if (this.$route.query.to_component) {
      this.$nextTick(() => {
        setTimeout(() => {
          const element = document.getElementById(
            this.$route.query.to_component
          );
          element.scrollIntoView();
        }, 600);
      });
    }
  },
};
