<template>
  <c-drawer :isOpen="isDrawerOpen" placement="right" :on-close="closeDrawer">
    <c-drawer-overlay />
    <c-drawer-content>
      <c-drawer-close-button />
      <c-drawer-header>{{ title }}</c-drawer-header>

      <c-drawer-body>
        <form v-chakra my="8">
          <ul v-chakra listStyle="none">
            <li
              v-for="(item, problemIndex) in data"
              :key="problemIndex"
              v-chakra
              fontSize="xl"
            >
              <input type="checkbox" v-model="item.selected" />
              <slot name="list-item" v-bind:item="item">
                <c-text as="span">{{ item.text }}</c-text>
              </slot>
            </li>
          </ul>
        </form>
      </c-drawer-body>
      <c-button
        @click="updateFn(data)"
        :disabled="hasSelectedMore || hasNoneSelected"
        variant-color="blue"
        display="flex"
        justifyContent="center"
      >
        <c-text as="span">Save</c-text>
        <c-spinner ml="10px" v-if="isUpdatingData" color="#fff" />
      </c-button>
    </c-drawer-content>
  </c-drawer>
</template>

<script>
import cloneDeep from "lodash.clonedeep";

export default {
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
    closeDrawer: {
      type: Function,
    },
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
    updateFn: {
      type: Function,
    },
    isUpdatingData: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    hasSelectedMore() {
      return this.data.filter((items) => items.selected).length > this.max;
    },
    hasNoneSelected() {
      return this.data.filter((items) => items.selected).length == 0;
    },
  },
  methods: {
    setData() {
      this.data = cloneDeep(this.items);
    },
  },
  created() {
    this.setData();
  },
  watch: {
    items(v) {
      if (v.length > 0) this.setData();
    },
  },
};
</script>

<style></style>
