<template>
  <c-box id="point-of-differentiation">
    <!--      :isLocked="!getCurrentPlan.access.pointOfDifferentiation" -->
    <TaskCard
      title="Point of Differentiation"
      :description="'How are you different from your competitors?'"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="true"
      :isLocked="!getCurrentPlan.access.pointOfDifferentiation"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="competitionData"
      :accessKey="'competition_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'competition_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="point_of_differentiation"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>

      <div v-else>
        <c-text
          as="span"
          color="#ef5323"
          fontSize="sm"
          cursor="pointer"
          v-if="saved"
        >
          <b> Saved successfuly 👍</b>
        </c-text>
        <EditableText
          title="Point of Differentiation"
          type="point_of_differentiation"
          v-model="competitionData.description"
          :disabled="disabled"
          @updateDescription="updateDescription"
        />
      </div>
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

import EditableText from './../EditableText.vue';
import TaskCard from './../TaskCard.vue';

import competitionMixin from '@/mixins/competition.js';
import { mapGetters } from 'vuex';

export default {
  mixins: [competitionMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    EditableText,
    ContentLoader,
  },
  data() {
    return {
      competitionType: 'point_of_differentiation',
      hasMounted: false,
      saved: false,
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
  methods: {
    updateDescription(value) {
      this.competitionData.description = value;

      this.updateData({
        key: 'description',
        value: this.competitionData.description,
      });

      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },
  },
};
</script>

<style></style>
