import GroupSelect from '@/views/App/GrowthFramework/components/competition/GroupSelect.vue';
import ValueProposition from '@/views/App/GrowthFramework/components/competition/ValueProposition.vue';
import RevenueStreams from '@/views/App/GrowthFramework/components/competition/RevenueStreams.vue';
import Prices from '@/views/App/GrowthFramework/components/competition/Prices.vue';
import ImportanceLevel from '@/views/App/GrowthFramework/components/competition/ImportanceLevel.vue';
import EditableText from '@/views/App/GrowthFramework/components/EditableText.vue';
import AppSelect from '@/views/App/components/AppSelect.vue';

import { isObject } from '@/helpers/utils';
import {
  addCompetitor,
  updateCompetitor,
  addCompetitorCustomers,
  removeCompetitorCustomers,
} from '@/services/competition';

import { mapActions, mapMutations } from 'vuex';

import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    competitionData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      analysisFields: [
        {
          type: 'text',
          title: 'Details',
          key: 'details',
          component: EditableText,
          fieldType: 'analysis',
        },
        {
          type: 'multi-select',
          title: 'Customer Segment',
          key: 'competitor_customers',
          component: GroupSelect,
          fieldType: 'analysis',
        },
        {
          type: 'valueProposition',
          title: 'Value Proposition',
          key: 'valueProposition',
          component: ValueProposition,
          fieldType: 'analysis',
        },
        {
          type: 'multi-select',
          title: 'Revenue Streams',
          key: 'revenueStreams',
          component: RevenueStreams,
          fieldType: 'analysis',
        },
        {
          type: 'number',
          title: 'Prices',
          key: 'price',
          component: Prices,
          fieldType: 'analysis',
        },
        {
          type: 'multi-select',
          title: 'Customer Experience',
          component: AppSelect,
          key: 'customerExperience',
          props: {
            color: 'dark',
            bordered: true,
            reset: true,
            options: [
              { label: 'Excellent', value: 'excellent' },
              { label: 'Good', value: 'good' },
              { label: 'Average', value: 'average' },
              { label: 'Bad', value: 'bad' },
            ],
            placeholder: 'Rate Experience',
            indicatorFill: 'orange',
            fullWidth: true,
            label: 'label',
            reduce: (option) => option.value,
          },
          fieldType: 'analysis',
        },
        {
          type: 'text',
          title: 'Most prominent platforms ',
          component: EditableText,
          key: 'socialMediaEngagement',
          fieldType: 'analysis',
        },
        {
          type: 'text',
          title: 'Strengths',
          component: EditableText,
          key: 'strengths',
          props: { showTitle: false, placeholder: 'Click here to edit...' },
          fieldType: 'analysis',
        },
        {
          type: 'text',
          title: 'Weaknesses',
          component: EditableText,
          key: 'weaknesses',
          props: { showTitle: false, placeholder: 'Click here to edit...' },
          fieldType: 'analysis',
        },
      ],
      conclusionFields: [
        {
          type: 'text',
          title: 'Key Takeaway',
          component: EditableText,
          key: 'keyTakeaway',
          props: { showTitle: false, placeholder: 'Click here to edit...' },
          fieldType: 'conclusion',
        },
        {
          type: 'text',
          key: 'pointOfDifferentiation',
          title: 'Point of Differentiation',
          component: EditableText,
          fieldType: 'conclusion',
        },
        {
          type: 'multi-select',
          key: 'importanceLevel',
          title: 'Importance Level',
          component: ImportanceLevel,
          fieldType: 'conclusion',
        },
        {
          type: 'text',
          key: 'website',
          title: 'Website',
          component: EditableText,
          fieldType: 'conclusion',
        },
      ],
      analysisVisibleFields: [],
      conclusionVisibleFields: [],
    };
  },
  created() {
    this.setVisibleFields();
  },
  methods: {
    ...mapActions({
      updateCompetitorInStore: 'company/updateCompetitor',
    }),
    ...mapMutations({
      setCompetitorsInStore: 'company/setCompetitors',
      addCompetitorToStore: 'company/addCompetitor',
      deleteCompetitorInStore: 'company/deleteCompetitor',
    }),
    setVisibleFields() {
      this.conclusionVisibleFields = this.conclusionFields
        .filter(
          (field) => !this.competitionData.hiddenFields.includes(field.key)
        )
        .map((field) => field.key);
      if (!this.conclusionVisibleFields.length && this.selectedViews) {
        this.selectedViews.splice(this.selectedViews.indexOf('conclusion'));
      }
      this.analysisVisibleFields = this.analysisFields
        .filter(
          (field) => !this.competitionData.hiddenFields.includes(field.key)
        )
        .map((field) => field.key);
      if (!this.analysisVisibleFields.length && this.selectedViews) {
        this.selectedViews.splice(this.selectedViews.indexOf('analysis'));
      }
    },
    onCompetitorNameSubmit(e, competitorIndex) {
      // eslint-disable-next-line no-unused-vars
      const { id, tempId, ...competitorData } = cloneDeep(
        this.competitors[competitorIndex]
      );
      const value = this.competitorTempName;
      if (value && value.trim()) {
        this.updateCompetitorInStore({
          ...this.competitors[competitorIndex],
          name: value,
        });
        this.competitorTempName = '';
        if (id) {
          this.updateCompetitor({ id, key: 'name', value });
        } else {
          addCompetitor({ ...competitorData, name: value })
            .then((res) => {
              this.updateCompetitorInStore({
                ...this.competitors[competitorIndex],
                id: res.data.insert_competitor_one.id,
              });
            })
            .catch(() => {
              this.updateCompetitorInStore({
                ...this.competitors[competitorIndex],
                name: '',
              });
              this.$toast({
                title: 'An error occurred.',
                description: `Error while adding new customer, please try again.`,
                status: 'error',
                position: 'top',
                duration: 3000,
              });
            });
        }
      }
    },
    onCompetitorValueInput(
      e,
      competitorIndex = null,
      key,
      competitorData = null
    ) {
      console.log('1', e);
      const competitor =
        competitorIndex != null
          ? cloneDeep(this.competitors[competitorIndex])
          : cloneDeep(competitorData);
      const { name, id } = competitor;
      if (name && name.trim()) {
        const value = isObject(e) && e.target ? e.target.value : e;
        if (key !== 'competitor_customers') {
          this.updateCompetitorInStore({ ...competitor, [key]: value });
          // this.competitors[competitorIndex][key] = value;
        }
        this.updateCompetitor({
          id,
          index: competitorIndex,
          key,
          value,
          oldValue: competitor[key],
          competitor: competitorData,
        });
      } else {
        this.updateCompetitorInStore({ ...competitor, [key]: competitor[key] });
        this.$toast({
          title: 'Error!!!',
          description: `You need to add the competitor's name to save competitor`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    updateCompetitor({ id, key, value, oldValue, index, competitor }) {
      switch (key) {
        case 'competitor_customers':
          this.updateCompetitorCustomers({ id, oldValue, value, index });
          break;
        default:
          updateCompetitor({ id, set: { [key]: value } })
            .then(() => {})
            .catch(() => {
              const data = index != null ? this.competitors[index] : competitor;
              this.updateCompetitorInStore({
                ...data,
                [key]: oldValue,
              });
              this.$toast({
                title: 'Error!!!',
                description: `An error occured while updating competitor, Please try again`,
                status: 'error',
                position: 'top',
                duration: 3000,
              });
            });
          break;
      }
    },
    async deleteCompetitor(competitor, index) {
      await updateCompetitor({ id: competitor.id, set: { isDeleted: true } })
        .then(() => {})
        .catch(() => {
          this.updateCompetitorInStore({ index, competitor, deleteCount: 0 });

          this.$toast({
            title: 'Error!!!',
            description: `An error occured while deleting competitor, Please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    async updateCompetitorCustomers({ id, oldValue, value, index }) {
      const newCustomers = value.filter(
        (_customer) =>
          !oldValue.find((customer) => customer.customerId === _customer.id)
      );
      const removedCustomers = oldValue.filter(
        (_customer) =>
          !value.find((customer) => customer.id === _customer.customerId)
      );
      const newCustomersData = newCustomers.map((newCustomer) => {
        return {
          customerId: newCustomer.id,
          competitorId: id,
        };
      });
      const removedCustomersData = removedCustomers.map(
        (removedCustomer) => removedCustomer.id
      );
      await this.addCompetitorCustomers({
        data: newCustomersData,
        oldData: oldValue,
        index,
      });
      this.removeCompetitorCustomers({
        data: removedCustomersData,
        oldData: oldValue,
        index,
      });
    },
    addCompetitorCustomers({ data, oldData, index }) {
      if (!data.length) {
        return null;
      }
      return addCompetitorCustomers(data)
        .then((res) => {
          const customers = cloneDeep(
            this.competitors[index].competitor_customers
          );
          customers.push(
            ...cloneDeep(res.data.insert_competitor_customer.returning)
          );
          this.updateCompetitorInStore({
            ...this.competitors[index],
            competitor_customers: customers,
          });
        })
        .catch(() => {
          this.updateCompetitorInStore({
            ...this.competitors[index],
            competitor_customers: oldData,
          });
          this.$toast({
            title: 'Error!!!',
            description: `An error occured while updating competitor customers, Please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    removeCompetitorCustomers({ data, oldData, index }) {
      if (!data.length) {
        return null;
      }
      removeCompetitorCustomers(data)
        .then(() => {
          const customers = cloneDeep(
            this.competitors[index].competitor_customers
          );
          data.forEach((id) => {
            const customerIndex = customers.findIndex(
              (customer) => customer.customerId == id
            );
            customers.splice(customerIndex, 1);
          });
          this.updateCompetitorInStore({
            ...this.competitors[index],
            competitor_customers: customers,
          });
        })
        .catch(() => {
          this.updateCompetitorInStore({
            ...this.competitors[index],
            competitor_customers: oldData,
          });
          this.$toast({
            title: 'Error!!!',
            description: `An error occured while updating competitor customers, Please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    onFieldsChange(e, type) {
      switch (type) {
        case 'analysis':
          this.analysisVisibleFields = [...e];
          break;
        case 'conclusion':
          this.conclusionVisibleFields = [...e];
          break;
        default:
          break;
      }
    },
    onFieldSelectPopoverClose(e, closeParentPopover) {
      closeParentPopover();
      this.updateCompetitionHiddenFields();
    },
    updateCompetitionHiddenFields() {
      const analysisHiddenFields = this.analysisFields
        .filter((field) => !this.analysisVisibleFields.includes(field.key))
        .map((field) => field.key);
      const conclusionHiddenFields = this.conclusionFields
        .filter((field) => !this.conclusionVisibleFields.includes(field.key))
        .map((field) => field.key);
      const hiddenFields = [...analysisHiddenFields, ...conclusionHiddenFields];
      if (!this.disabled) {
        this.$emit('update', { key: 'hiddenFields', value: hiddenFields });
      }
    },
  },
};
