var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-flex',{attrs:{"my":"8","justify":"space-around","flexWrap":"wrap"}},[_c('c-box',{attrs:{"mb":{ base: 8, lg: 0 }}},[_c('c-pseudo-box',{attrs:{"w":"300px","h":"300px","position":"relative"}},[_vm._l((_vm.marketData),function(data){return [_c('c-pseudo-box',{key:data.key,attrs:{"bg":data.color,"w":`${
            data.key !== 'TAM'
              ? _vm.percentageOfTam(data.value) > 100
                ? 100
                : _vm.percentageOfTam(data.value)
              : '100'
          }%`,"h":`${
            data.key !== 'TAM'
              ? _vm.percentageOfTam(data.value) > 100
                ? 100
                : _vm.percentageOfTam(data.value)
              : '100'
          }%`,"position":"absolute","bottom":"0","left":"50%","transform":"translateX(-50%)","borderRadius":"50%","opacity":data.isHovering ? 0.6 : 1,"transition":"opacity 200ms ease"},on:{"mouseenter":function($event){data.isHovering = true},"mouseleave":function($event){data.isHovering = false}}})]})],2)],1),_c('c-stack',{attrs:{"spacing":6,"alignSelf":"center"}},_vm._l((_vm.marketData),function(data){return _c('c-pseudo-box',{key:data.key,attrs:{"display":"flex","opacity":data.isHovering ? 0.6 : 1},on:{"mouseenter":function($event){data.isHovering = true},"mouseleave":function($event){data.isHovering = false}}},[_c('c-box',{attrs:{"w":"22px","h":"22px","bg":data.color,"borderRadius":"50%","display":"inline-block","mr":"5"}}),_c('c-stack',{attrs:{"spacing":3}},[_c('c-heading',{attrs:{"fontSize":"md","fontWeight":"600"}},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('c-flex',{attrs:{"align":"center","flexWrap":"wrap"}},[_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"xl","fontWeight":"700","mr":"8"}},[_vm._v(" "+_vm._s(data.value)+" $ ")]),_c('c-flex',{attrs:{"align":"baseline"}},[_c('c-text',{attrs:{"fontSize":"sm","fontWeight":"600","mr":"2"}},[_vm._v(" "+_vm._s(data.customersCount)+" ")]),_c('c-text',{attrs:{"fontSize":"xs","color":"gray.400","fontWeight":"600"}},[_vm._v(" Customers ")])],1),(data.key !== 'TAM')?_c('c-flex',{attrs:{"align":"baseline","ml":{ base: 0, lg: 8 }}},[_c('c-text',{attrs:{"fontSize":"sm","fontWeight":"600","mr":"2"}},[_vm._v(" "+_vm._s(_vm.percentageOfTam(data.value))+"% ")]),_c('c-text',{attrs:{"fontSize":"xs","color":"gray.400","fontWeight":"600"}},[_vm._v(" of TAM ")])],1):_vm._e()],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }