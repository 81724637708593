import { render, staticRenderFns } from "./SDGFields.vue?vue&type=template&id=f236a148&scoped=true"
import script from "./SDGFields.vue?vue&type=script&lang=js"
export * from "./SDGFields.vue?vue&type=script&lang=js"
import style0 from "./SDGFields.vue?vue&type=style&index=0&id=f236a148&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f236a148",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CLink: require('@chakra-ui/vue').CLink, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPopoverCloseButton: require('@chakra-ui/vue').CPopoverCloseButton, CPopoverHeader: require('@chakra-ui/vue').CPopoverHeader, CCheckbox: require('@chakra-ui/vue').CCheckbox, CCheckboxGroup: require('@chakra-ui/vue').CCheckboxGroup, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText})
