import { render, staticRenderFns } from "./BusinessModel.vue?vue&type=template&id=68ef2a50"
import script from "./BusinessModel.vue?vue&type=script&lang=js"
export * from "./BusinessModel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CLink: require('@chakra-ui/vue').CLink, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CGrid: require('@chakra-ui/vue').CGrid})
